import React, {useEffect, useState} from 'react';
import LoggedInLayout from "layout/LoggedInLayout";
import {useTranslation} from "react-i18next";
import {Button, Card, Dropdown, Menu, Table} from "antd";
import {FilterCard} from "./components/FilterCard";
import {Totals} from "./components/Totals";
import {ClickGraph} from "components/Charts";
import {useAccounts, useStatistics} from "hooks";
import moment from "moment";
import {PremiumInfo} from "components/Premium";
import {Money} from "components/Money";
import {InfoPopover} from "components/InfoPopover";
import Media from "react-media";
import {saveAs} from "file-saver";
import client from "services/HurenApiClient";
import {DownloadOutlined, DownOutlined, FileExcelOutlined, FilePdfOutlined} from "@ant-design/icons";
import {getStatisticsUrl, b64toBlob} from "helpers";
import { countConversion } from '../../helpers';

const Statistics = () => {
    const { t } = useTranslation('common');
    const { activeAccount } = useAccounts();
    const [filters, setFilters] = useState({
        showGraph: true,
        showGrouping: true,
        period: 'day',
        categories: [],
        startDate: moment().subtract(1, 'month'),
        endDate: moment()
    });

    const {
        getClickGraph,
        getStatistics,
        getTotals,
        isTotalsDataLoaded,
        isTotalsDataError,
        isGraphDataLoaded, 
        isGraphDataError,
        graphData: {
            graph
        },
        payload: {
            rows
        },
        totals
    } = useStatistics();

    const toggleChart = (show) => {
        setFilters({...filters, showGraph: show});
    }

    const changePeriod = (e) => {
        setFilters({...filters, period: e.target.value});
    }

    const changeDateRange = (dates) => {
        if (dates != null) {
            setFilters({...filters, startDate: dates[0], endDate: dates[1]});
        }
    }

    const categorySelected = (categories) => {
        setFilters({...filters, categories: categories});
    };

    useEffect(() => {
        getClickGraph(activeAccount.companyId, filters.period, filters.startDate, filters.endDate, filters.categories);
        getStatistics(activeAccount.companyId, filters.period, filters.startDate, filters.endDate, filters.categories);
        getTotals(activeAccount.companyId, filters.period, filters.startDate, filters.endDate, filters.categories);
    }, [filters]); // eslint-disable-line react-hooks/exhaustive-deps

    const download = (type) => {
        const url = getStatisticsUrl('download', activeAccount.companyId, filters.period, filters.startDate, filters.endDate, filters.categories);
        const contentType = type === 'pdf' ? 'application/pdf;charset=utf-8' : 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        client.get(url + '&type=' + type + '&stats=general').then(res => {
            let blob = b64toBlob(res.data.file, contentType);
            saveAs(blob, res.data.filename);
        });
    }

    const tableHeader = (title, hint) => <>{title}<InfoPopover content={hint}/></>

    const tableColumns = [
        {
            title: t('pages.statistics.table.period'),
            dataIndex: 'period',
            key: 'period',
            sorter: (a, b) => ('' + a.period).localeCompare(b.period)
        },
        {
            title: tableHeader(t('pages.statistics.table.clicks'),t('pages.statistics.table.hint.clicks')),
            dataIndex: 'clicks',
            key: 'clicks',
            sorter: (a, b) => a.clicks - b.clicks,
            render: (text, record) => record.clicks,
        },
        {
            title: tableHeader(t('pages.statistics.table.calls'),t('pages.statistics.table.hint.calls')),
            dataIndex: 'call',
            key: 'call',
            sorter: (a, b) => a.call - b.call,
            render: (text, record) => record.call,
        },
        {
            title: tableHeader(t('pages.statistics.table.cost-per-click'),t('pages.statistics.table.hint.cost-per-click')),
            dataIndex: 'cost_per_click',
            key: 'cost_per_click',
            hideOnResponsive: true,
            sorter: (a, b) => a.cost_per_click - b.cost_per_click,
            render: (text, record) => record.cost_per_click != null ? <Money value={record.cost_per_click} /> : <></>
        },
        {
            title: tableHeader(t('pages.statistics.table.total-price'),t('pages.statistics.table.hint.total-price')),
            dataIndex: 'total_price',
            key: 'total_price',
            sorter: (a, b) => a.total_price - b.total_price,
            render: (text, record) => record.total_price != null ? <Money value={record.total_price} /> : <></>
        },
        {
            title: tableHeader(t('pages.statistics.table.quotations'),t('pages.statistics.table.hint.quotations')),
            dataIndex: 'quotations',
            key: 'quotations',
            hideOnResponsive: true,
            sorter: (a, b) => a.quotations - b.quotations,
        },
        {
            title: tableHeader(t('pages.statistics.table.product_calls'),t('pages.statistics.table.hint.product_calls')),
            dataIndex: 'product_calls',
            key: 'product_calls',
            hideOnResponsive: true,
            sorter: (a, b) => a.product_calls - b.product_calls,
        },
        {
            title: tableHeader(t('pages.statistics.table.conversion'),t('pages.statistics.table.hint.conversion')),
            dataIndex: 'conversion',
            key: 'conversion',
            hideOnResponsive: true,
            sorter: (a, b) => countConversion(a.clicks, a.quotations, a.product_calls) - countConversion(b.clicks,b.quotations,b.product_calls),
            render: (_, record) => `${countConversion(record.clicks, record.quotations, record.product_calls)} %`
        }
    ];

    var clickGraph;
    if (isGraphDataLoaded && !isGraphDataError) {
        clickGraph = <><ClickGraph graphData={graph} /></>;
    } else {
        dataTable = <>{t('pages.statistics.table.error')}</>;
    }

    var dataTable;
    if (isTotalsDataLoaded && !isTotalsDataError) {
        dataTable = <>
            <Media query="(min-width: 950px)">
                {matches => {
                    let filteredColumns = tableColumns.filter((column) => {
                        return matches || column.hideOnResponsive !== true;
                    });
                    return <Table
                        dataSource={rows}
                        rowKey="period"
                        columns={filteredColumns}
                        pagination={{
                            showSizeChanger: true
                        }}
                    />
                }}
            </Media>
        </>;
    } else {
        dataTable = <>{t('pages.statistics.table.error')}</>;
    }

    const exportDropdownMenu = {
        items: [
            {
                key: '1',
                label: (
                    <a onClick={() => download('pdf')}>
                        <FilePdfOutlined style={{marginRight: '5px'}}/>
                        PDF document
                    </a>
                )
            },
            {
                key: '2',
                label: (
                    <a onClick={() => download('excel')}>
                        <FileExcelOutlined style={{marginRight: '5px'}}/>
                        Excelsheet
                    </a>
                )
            }
        ]
    };

    return <LoggedInLayout pageTitle={t('pages.statistics.title')} headerExtras={
        [
            <Dropdown disabled={!((isGraphDataLoaded && !isGraphDataError) && (isTotalsDataLoaded && !isTotalsDataError))} key="export" menu={exportDropdownMenu}>
                <Button>
                    <DownloadOutlined />
                    Exporteer
                    <DownOutlined />
                </Button>
            </Dropdown>

        ]
    }>
        <FilterCard period={filters.period}
                    startDate={filters.startDate}
                    endDate={filters.endDate}
                    showChart={filters.showGraph}
                    showGroupByDayWeekMonth={filters.showGrouping}
                    toggleChart={toggleChart}
                    changeDateRange={changeDateRange}
                    changePeriod={changePeriod}
                    categorySelected={categorySelected}
        />

        <br />

        <Totals stats={totals} loading={!isTotalsDataLoaded}/>

        <br />

        {(filters.showGraph && (
            <Card
                size="small"
                title={<><PremiumInfo />{t('pages.statistics.graph.title')}</>}
                className="full-table"
            >
                {clickGraph}
            </Card>
        ))}

        <br />

        <Card
            size="small"
            title={t('pages.statistics.table.title')}
            className="full-table"
        >
            {dataTable}
        </Card>


    </LoggedInLayout>;
};

export default Statistics;