import React, {useState} from "react";
import {useTranslation} from 'react-i18next';
import {Button, Form, notification} from "antd";
import Drawer from "components/Drawer";
import RentalItemContentBlockFormItem from "components/RentalItemContentBlockFormItem";
import SubmitButton from "components/SubmitButton";
import {useAccounts, useRentalItem} from "hooks";
import 'react-quill/dist/quill.snow.css';

const { forwardRef, useImperativeHandle } = React;

const RentalItemContentBlocksForm = forwardRef((props, ref) => {
    const [formOpen, setFormOpen] = useState(false);
    const [contentBlocks, setContentBlocks] = useState([]);
    const [rentalItem, setRentalItem] = useState({id: null, active: false, name: null});

    const {t} = useTranslation('common');
    const {activeAccount} = useAccounts();

    const {
        putContentBlocks
    } = useRentalItem();

    // Allows use of the openForm function from the parent component.
    useImperativeHandle(ref, () => ({
        openForm(id, active, name, content_blocks) {
            setRentalItem({id: id, active: active, name: name});
            setContentBlocks(content_blocks)
            setFormOpen(true);
        }
    }));

    const closeForm = () => {
        setFormOpen(false);
        setContentBlocks([]);
    };

    const onFinish = () => {
        putContentBlocks(activeAccount.companyId, rentalItem.id, rentalItem.active, rentalItem.name, contentBlocks).then(() => {
            notification.open({
                message: t('notification.messages.saved.title'),
                description: t('notification.messages.saved.description'),
            });
            closeForm();
        }).catch(errorInfo => {
            if(errorInfo.errors)
                notification.open({
                    message: errorInfo.message,
                    description: errorInfo.errors.reduce((c,a) => c += t('pages.rentalitems.rental-item.content-blocks-form.errors.' + a) + ', ',''),
            });
            else
                notification.open({
                    message: t('notification.messages.error.title'),
                    description: t('notification.messages.error.description'),
            });
            closeForm();
        });
    }

    const addContentBlock = () => {
        const emptyNewElement = {
            title: '',
            body: '',
            type: ''
        }
        const newArray = [...contentBlocks,emptyNewElement]
        setContentBlocks(newArray)
    }

    const saveRow = (content, index) => {
        let newArray = [...contentBlocks]
        newArray[index] = content
        setContentBlocks(newArray);
    }

    const deleteRow = (index) => {
        let newArray = [...contentBlocks]
        newArray.splice(index, 1)
        setContentBlocks(newArray)
    }

    return (
        <Drawer
            title={t('pages.rentalitems.rental-item.content-blocks-form.title')}
            placement="right"
            onClose={() => closeForm()}
            open={formOpen}
            width={960}
        >
            <div>
                { contentBlocks.map( (contentBlock, index) => {
                    return <RentalItemContentBlockFormItem key={index} itemIndex={index} contentBlock={contentBlock} handleSave={saveRow} deleteRow={deleteRow} />
                }) }

                <div>
                    <Button onClick={() => addContentBlock()} style={{marginRight: '8px'}}>
                        {t('pages.rentalitems.rental-item.content-blocks-form.add')}
                    </Button>
                </div>

                <br/><br/>

                <div className="actions">
                    <Button onClick={() => closeForm()} style={{marginRight: '8px'}}>
                        {t('button.text.cancel')}
                    </Button>
                    <SubmitButton htmlType="button" onClick={() => onFinish()}>
                        {t('button.text.save')}
                    </SubmitButton>
                </div>
            </div>
        </Drawer>
    );
});

export default RentalItemContentBlocksForm;