import {Tag} from "antd";
import React from "react";
import {useTranslation} from "react-i18next";

export const PaymentTransferStatusTag = ({state}) => {
    const { t } = useTranslation('common');
    const colorMap = {
        pending: '#ff7800',
        transferred: '#75b118',
        canceled: '#ff4d4f',
    }
    return <Tag color={colorMap[state]}>{t(`entities:payment-transfer.state.${state}`)}</Tag>
}