import {useGlobalStore} from 'store';

import bindActions from 'store/bindActions';
import testOverlap from 'store/testOverlap';
import openingHoursReducer from 'store/openingHours';

const { Actions } = openingHoursReducer;

const useOpeningHours = () => {
    const {state, dispatch} = useGlobalStore();

    const {openingHours} = state;

    // Test if there's an overlapping between the actions and store
    testOverlap(openingHours, Actions);

    return {
        ...bindActions(Actions, dispatch),
        ...openingHours
    };
};

export default useOpeningHours;
