import React from "react";
import {Card, Col, Row, Statistic} from "antd";
import {useTranslation} from "react-i18next";
import { countConversion } from "../../../../helpers";

const Totals = ({stats, loading}) => {
    const { t } = useTranslation('common');

    const conversion = !stats.clicks ? 0 : (stats.calls + stats.quotations) / stats.clicks * 100

    var cpc = !stats.cpc ? 0 : stats.cpc;
    cpc = cpc.toFixed(2);

    var cost = !stats.cost ? 0 : stats.cost;
    cost = cost.toFixed(2);

    return <Row gutter={[16, 16]}>
        <Col xs={{span: 24}} sm={{span: 24}} md={{span: 4}}>
            <Card loading={loading} size="small">
                <Statistic title={t("pages.statistics.totals.clicks")}
                           value={stats.clicks}
                           precision={0}
                           decimalSeparator=","
                           groupSeparator="."
                />
            </Card>
        </Col>
        <Col xs={{span: 24}} sm={{span: 24}} md={{span: 4}}>
            <Card loading={loading} size="small">
                <Statistic title={t("pages.statistics.totals.calls")}
                           value={stats.calls}
                           precision={0}
                           decimalSeparator=","
                           groupSeparator="."
                />
            </Card>
        </Col>
        <Col xs={{span: 24}} sm={{span: 24}} md={{span: 4}}>
            <Card loading={loading} size="small">
                <Statistic title={t("pages.statistics.totals.cpc")}
                           value={cpc}
                           prefix="&euro;"
                           precision={2}
                           decimalSeparator=","
                           groupSeparator="."
                />
            </Card>
        </Col>
        <Col xs={{span: 24}} sm={{span: 24}} md={{span: 4}}>
            <Card loading={loading} size="small">
                <Statistic title={t("pages.statistics.totals.cost")}
                           value={cost}
                           prefix="&euro;"
                           precision={2}
                           decimalSeparator=","
                           groupSeparator="."
                />
            </Card>
        </Col>
        <Col xs={{span: 24}} sm={{span: 24}} md={{span: 4}}>
            <Card loading={loading} size="small">
                <Statistic title={t("pages.statistics.totals.quotations")}
                           value={stats.quotations}
                           precision={0}
                           decimalSeparator=","
                           groupSeparator="."
                />
            </Card>
        </Col>
        <Col xs={{span: 24}} sm={{span: 24}} md={{span: 4}}>
            <Card loading={loading} size="small">
                <Statistic title={t("pages.statistics.totals.product_calls")}
                           value={stats.product_calls}
                           precision={0}
                           decimalSeparator=","
                           groupSeparator="."
                />
            </Card>
        </Col>
        <Col xs={{span: 24}} sm={{span: 24}} md={{span: 4}}>
            <Card loading={loading} size="small">
                <Statistic title={t("pages.statistics.totals.conversion")}
                           value={countConversion(stats.clicks, stats.quotations, stats.product_calls + stats.calls)}
                           precision={2}
                           suffix='%'
                           decimalSeparator=","
                           groupSeparator="."
                />
            </Card>
        </Col>
    </Row>;
}

export default Totals;