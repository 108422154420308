import moment from "moment";

export const getStatisticsUrl = (endpoint, companyId, period = 'day', startDate = null, endDate = null, categories = []) => {
    let startDateParam = startDate ? moment(startDate): moment().startOf('month');
    let endDateParam = endDate ? moment(endDate): moment().endOf('month');
    let categoriesParam = '';

    categories.forEach((cat) => {
        categoriesParam += '&categories[]=' + cat;
    });

    let url = `/statistics/${companyId}/${endpoint}?period=${period}&startDate=${startDateParam.format("YYYY-MM-DD")}&endDate=${endDateParam.format("YYYY-MM-DD")}`;

    if (categoriesParam !== '') {
        url += categoriesParam;
    }
    return url;
}

export const getQuotationDownloadUrl = (companyId, status = 'all', startDate = null, endDate = null) => {
    let startDateParam = startDate ? moment(startDate): moment().startOf('month');
    let endDateParam = endDate ? moment(endDate): moment().endOf('month');

    return `/quotations/${companyId}/download?status=${status}&startDate=${startDateParam.format("YYYY-MM-DD")}&endDate=${endDateParam.format("YYYY-MM-DD")}`;
}

export const b64toBlob = (b64Data, contentType='', sliceSize=512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, {type: contentType});
}

export const hasPermission = (account, type, action = 'view') => {
    // If the type (ie "manage_ads") exists, we go on to check the actual permissions,
    // otherwise we just return false.
    if (type in account.permissions) {
        return account.permissions[type][action];
    } else {
        return false;
    }
}

export const countConversion = (clicks, quotations, calls) => {
    return !clicks ? 0 : ((calls + quotations) / clicks * 100).toFixed(2);
}
