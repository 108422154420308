import React from "react";
import {Trans, useTranslation} from "react-i18next";
import {InfoPopover} from "components/InfoPopover";

const RentalPrice = (params) => {
    const {t} = useTranslation('common');

    let price = (params.value.price*params.value.multiplier).toFixed(2).replace('.', ',');
    let timingType = params.value.period;
    let multiplier = params.value.multiplier;

    let text = t('pages.rentalitems.rental-items-card.table.price-period.'+timingType, {count:multiplier, price:price})

    return <React.Fragment>
        &euro; {text} { params.value.extra_text ? <InfoPopover content={ params.value.extra_text } /> : "" }
    </React.Fragment>;
};

export default RentalPrice;