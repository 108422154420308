import * as Types from "./Types";

export const initialState = {
    locale: 'nl'
};

export default function (state, action) {
    switch (action.type) {
        case Types.SET_LOCALE:
            return {
                ...state,
                locale: action.locale
            };
        default:
            return state;
    }
};
