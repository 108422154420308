import React from 'react';
import {useTranslation} from "react-i18next";
import LoggedInLayout from "layout/LoggedInLayout";
import {PremiumInfo} from "components/Premium";
import {RentalItemsOverviewCard} from "./components/RentalItemsOverviewCard";
import {Button} from "antd";
import {PlusOutlined} from "@ant-design/icons";
import {navigate} from "@reach/router";
import { useSmallDeviceSize } from "hooks";
import {hasPermission} from "../../helpers";
import {useAccounts} from "hooks";

const RentalItemsOverview = () => {
    const { t } = useTranslation('common');
    const isSmallDevice = useSmallDeviceSize();
    const {activeAccount} = useAccounts();

    return <LoggedInLayout pageTitle={<><PremiumInfo/> {t('pages.rentalitems.title')}</>} headerExtras={
        [
            <Button key="1" disabled={!hasPermission(activeAccount, 'manage_rental_items' ,'create')} hidden={isSmallDevice} type="primary" onClick={() => navigate('/')}>
                <PlusOutlined />
                {t('pages.rentalitems.new-rental-item')}
            </Button>,
        ]
    }>
        <RentalItemsOverviewCard />
    </LoggedInLayout>;
};

export default RentalItemsOverview;
