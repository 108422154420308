import React, {useEffect, useState} from 'react';
import {EditOutlined, EuroCircleOutlined, LinkOutlined, SearchOutlined} from "@ant-design/icons";
import {useTranslation} from "react-i18next";
import {Badge, Button, Col, Radio, Row, Table, TreeSelect} from "antd";
import Card from "components/Card";
import BudgetAlert from "components/BudgetAlert";
import StatusInfo from "components/StatusInfo";
import ClickPriceInfo from "components/ClickPriceInfo";
import AdvertisementPriceInfo from "components/AdvertisementPriceInfo";
import {useAccounts, useAdvertisements, useCategories, useDailyBudget, useSmallDeviceSize} from "hooks";
import Media from "react-media";
import {Money} from "components/Money";
import styled from "styled-components";
import AdvertisementsForm from "./AdvertisementsForm";
import {hasPermission} from "../../../../helpers";
import {navigate} from "@reach/router";
import {AdvertisementClickPriceTable} from "../AdvertisementClickPriceTable";

const AdCellRow = styled(Row)`
    padding: 8px 0;
    align-items: center;
`;

const AdvertisementsCard = () => {
    const {t} = useTranslation('common');
    const [advertisementsData, setAdvertisementsData] = useState([]);
    const [filters, setFilters] = useState({active: '', categories: []});

    const {activeAccount} = useAccounts();
    const {
        getCategories,
        categories
    } = useCategories();
    const {
        isDataLoaded,
        editAdvertisement,
        getAdvertisements,
        payload: {
            advertisements,
            total
        }
    } = useAdvertisements();

    const {
        getBudgetData,
        payload: {
            unlimited_budget,
            current_daily_limit,
            budget_used_today
        }
    } = useDailyBudget();

    const isSmallDevice = useSmallDeviceSize();

    const filterAdvertisements = () => {
        const activeFilter = filters.active;
        const categoryFilters = filters.categories;

        let results;
        if (categoryFilters && categoryFilters.length > 0) {
            results = advertisements.filter(advertisement => categoryFilters.some(key => advertisement.parent_tree.includes(key)));
        } else {
            results = advertisements;
        }

        if (activeFilter && activeFilter !== "") {
            results = results.filter(advertisement => {
                if (activeFilter === "1") {
                    return advertisement.active === true;
                } else if (activeFilter === "0") {
                    return advertisement.active === false;
                } else {
                    return false;
                }
            });
        }

        setAdvertisementsData(results);
    };

    const onChange = (values) => {
        setFilters(prevState => ({ ...prevState, categories: values }));
    };

    const onFilterActive = event => {
        setFilters(prevState => ({ ...prevState, active: event.target.value }));
    };

    useEffect(() => {
        getBudgetData(activeAccount.companyId);
        getAdvertisements(activeAccount.companyId);
        getCategories();
    }, [activeAccount]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setAdvertisementsData(advertisements);
    }, [advertisements]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        filterAdvertisements();
    }, [filters]); // eslint-disable-line react-hooks/exhaustive-deps

    const inactiveBudget = () => {
        if (!unlimited_budget) {
            const dailyBudgetExceeded = budget_used_today >= current_daily_limit;
            if (dailyBudgetExceeded || current_daily_limit === 0) {
                return true;
            }
        }
        return false;
    }

    const tableColumns = [
        {
            title: t('pages.advertisements.advertisements-card.table.category'),
            dataIndex: 'category',
            key: 'category',
            ellipsis: false,
            sorter: (a, b) => ('' + a.category.title).localeCompare(b.category.title),
            render: (text, record) => <AdCellRow type="flex">
                { !isSmallDevice ?
                    <Col span={10}>
                        <img width={64} height={47} src={record.category.images[0]}
                               style={{marginRight: '1em', marginBottom: '1px', boxSizing: 'content-box', border: '1px solid #d9d9d9'}}/>
                    </Col> : 
                    <Col span={10}>
                        <img width={64} height={47} src={record.category.images[0]}
                               style={{marginBottom: '1px', boxSizing: 'content-box', border: '1px solid #d9d9d9'}}/>
                    </Col>
                }
                <Col span={14}>
                    <Row className="category-cell">
                        <a href={record.category.url} target="_blank" rel="noopener noreferrer">
                            <h4 style={{color: 'rgba(0, 0, 0, 0.65)'}}>{record.category.title}</h4>
                        </a>
                    </Row>
                    <Row className="category-cell">
                        <a href={record.category.parent.url} target="_blank">
                            {record.category.parent.title}
                        </a>
                    </Row>
                </Col>
             </AdCellRow>
        },
        // Don't show the status column if we don't have permission.
        hasPermission(activeAccount, 'manage_ad_activation', 'view') ? {
            title: <>
                {t('pages.advertisements.advertisements-card.table.active')} &nbsp;
                <StatusInfo />
            </>,
            className: 'status-state-cell',
            dataIndex: 'active',
            key: 'active',
            ellipsis: true,
            hideOnResponsive: false,
            align: (isSmallDevice) ? 'right' : 'left',
            width: '15%',
            sorter: (a, b) => a.active - b.active,
            render: (text, record) => {
                if (inactiveBudget()) {
                    return <Badge color="orange" text={t('badges.no-budget')}/>
                }
                return record.active ? <Badge color="green" text={t('badges.active')}/> : <Badge color="red" text={t('badges.inactive')}/>
            }
        } : {},
        {
            title: <> 
                {t('pages.advertisements.advertisements-card.table.click_rate')} &nbsp;
                <ClickPriceInfo />
            </>,
            dataIndex: 'click_out_rate',
            key: 'click_out_rate',
            ellipsis: false,
            hideOnResponsive: true,
            width: '20%',
            sorter: (a, b) => a.click_out_rate - b.click_out_rate,
            render: (text, record) => <Money value={record.click_out_rate} />
        },
        {
            title: <> 
                {t('pages.advertisements.advertisements-card.table.price')} &nbsp;
                <AdvertisementPriceInfo />
            </>,
            dataIndex: 'price',
            key: 'price',
            ellipsis: false,
            hideOnResponsive: true,
            sorter: (a, b) => a.price - b.price,
            render: (text, record) => {
                if (record.price !== null) {
                    const periodText = record.category.rental_period_unit !== null ? t('pages.advertisements.advertisements-card.period.' + record.category.rental_period_unit) : '';
                    return <><Money
                        value={record.price}/> {periodText}</>;
                }
                return <></>;
            }
        },
        {
            title: "",
            dataIndex: 'click_out_url',
            key: 'click_out_url',
            ellipsis: false,
            hideOnResponsive: true,
            render: (text, record) => <Button type="link" icon={<LinkOutlined />} target="_blank" href={record.click_out_url}>{t('pages.advertisements.advertisements-card.table.url-text')}</Button>
        },
        {
            align: 'right',
            title: t('pages.advertisements.advertisements-card.table.actions'),
            dataIndex: 'actions',
            key: 'actions',
            ellipsis: false,
            width: '20%',
            render: (text, record) => {
                if (isSmallDevice) {
                    return <>
                        <Button
                            className="small-button"
                            type="primary"
                            icon={<EuroCircleOutlined />}
                            disabled={!hasPermission(activeAccount, 'manage_click_prices' ,'edit')}
                            onClick={() => navigate(`/advertisements/${record.id}/bid`)}
                        />
                        <Button
                            className="small-button"
                            icon={<EditOutlined />}
                            disabled={!hasPermission(activeAccount, 'manage_ads' ,'edit')}
                            onClick={() => editAdvertisement(activeAccount.companyId, record.id)} />
                    </>

                } else {
                    return <>
                        <Button
                            type="primary"
                            icon={<EuroCircleOutlined />}
                            disabled={!hasPermission(activeAccount, 'manage_click_prices' ,'edit')}
                            onClick={() => navigate(`/advertisements/${record.id}/bid`)}
                        >
                            {t('components:advertisements-card.table.actions.bid')}
                        </Button>
                        <Button
                            icon={<EditOutlined />}
                            disabled={!hasPermission(activeAccount, 'manage_ads' ,'edit')}
                            onClick={() => editAdvertisement(activeAccount.companyId, record.id)}>
                            {t('button.text.edit')}
                        </Button>
                    </>
                }
            }
        }
    ];

    let treeSelectWidth = '50%';
    if (isSmallDevice) {
        treeSelectWidth = '100%';
    }

    return <>
        <AdvertisementsForm />
        <Card
            size="small"
            className="company-advertisements-card"
            title={t('pages.advertisements.advertisements-card.title')}
        >
            <BudgetAlert unlimited_budget={unlimited_budget} budget_used_today={budget_used_today} current_daily_limit={current_daily_limit} />

            <Row gutter={[16, 16]} type="flex" justify="space-around" align="middle" style={{borderBottom: '1px solid #e8e8e8', paddingBottom: '16px', marginBottom: '16px'}}>
                <Col sm={{span: 24}} md={{span: 12}} style={{width: '100%'}}>
                    <TreeSelect
                        showSearch
                        style={{ width: treeSelectWidth }}
                        listHeight={500}
                        dropdownStyle={{ maxHeight: 500, overflow: 'auto' }}
                        placeholder={t('treeselect.placeholder')}
                        allowClear
                        autoClearSearchValue
                        multiple
                        showArrow={true}
                        suffixIcon={<SearchOutlined />}
                        onChange={onChange}
                        treeData={categories}
                        treeNodeFilterProp="title"
                    />
                </Col>

                <Col sm={{span: 24}} md={{span: 12}}>
                    { !inactiveBudget() ?
                    <Radio.Group onChange={onFilterActive} defaultValue="none">
                        <Radio.Button value="1"><Badge color="green" text={t('badges.active')} /></Radio.Button>
                        <Radio.Button value="0"><Badge color="red" text={t('badges.inactive')} /></Radio.Button>
                        <Radio.Button value="">{t('filters.none')}</Radio.Button>
                    </Radio.Group> : <></>}
                </Col>
            </Row>

            <Row gutter={[16, 16]}>
                <Col align="left">
                    { advertisementsData !== undefined ?
                        <strong>{ advertisementsData.length + ' ' + t('pages.advertisements.advertisements-card.advertisements') }</strong>
                    : <></> }
                </Col>
            </Row>
            <Media query="(min-width: 950px)">
                {matches => {
                    let filteredColumns = tableColumns.filter((column) => {
                        return matches || column.hideOnResponsive !== true;
                    });

                    return <Table
                        tableLayout="fixed"
                        dataSource={advertisementsData}
                        columns={filteredColumns}
                        loading={!isDataLoaded}
                        rowKey="id"
                        expandable={{
                            expandedRowRender: advertisement  => {
                                return <AdvertisementClickPriceTable clickPriceRules={advertisement?.click_price_rules}></AdvertisementClickPriceTable>
                            },
                            rowExpandable: clickPriceRule => true,
                            expandRowByClick: true
                        }}
                    />
                }}
            </Media>
        </Card>
    </>
};
export default AdvertisementsCard;