import React from 'react';
import {Layout, PageHeader} from "antd";
import Header from "./Header"
import "../assets/style/style.less";
import styled from 'styled-components';
import ErrorBoundary from "components/Errors/ErrorBoundary";
import {useTranslation} from "react-i18next";

const {Content} = Layout;

const FullHeightLayout = styled(Layout)`
    box-sizing: border-box;
    height: 100vh;
`;

const StyledContent = styled(Content)`
    padding: 0 24px 24px;
`;

const LoginLayout = ({pageTitle, children}) => {
    const {t} = useTranslation('common');

    return (
        <>
            <Layout>
                <Header loggedIn={false} />
                <FullHeightLayout>
                    <Layout>
                        {pageTitle && (<PageHeader title={pageTitle} />)}
                        <StyledContent>
                            <ErrorBoundary t={t}>
                                {children}
                            </ErrorBoundary>
                        </StyledContent>
                    </Layout>
                </FullHeightLayout>
            </Layout>
        </>
    );
};

export default LoginLayout;
