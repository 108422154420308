import React from "react";
import LoggedInLayout from "layout/LoggedInLayout";
import { useTranslation } from "react-i18next";
import {useParams} from "@reach/router";

const Conversation = () => {
  const { t } = useTranslation("common");
  const { id } = useParams();

  return (
    <LoggedInLayout pageTitle={t("pages.inbox.title")}>
      {/*TODO: Redirect to rental match*/}
      {/*<ConversationCard id={id} />*/}
    </LoggedInLayout>
  );
};

export default Conversation;
