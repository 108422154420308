import React, {useEffect} from "react";
import {Button, Descriptions, Form, notification, TimePicker} from "antd";
import {useTranslation} from 'react-i18next';
import moment from 'moment';
import TextareaCharacterCount from "components/TextareaCharacterCount";
import collect from 'collect.js';
import {useAccounts, useOpeningHours} from "hooks";
import SubmitButton from "components/SubmitButton";

const timeFormat = 'HH:mm';
const weekdays = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];

const OpeningHoursForm = () => {
    const {t} = useTranslation('common');
    const { activeAccount } = useAccounts();
    const [form] = Form.useForm();

    const {
        closeEditForm,
        getOpeningHours,
        putOpeningHours,
        isDataLoaded,
        isEditFormSaving,
        payload: {
            openingHours
        }
    } = useOpeningHours();

    const getOpeningHoursByDay = (weekday) => {
        if (isDataLoaded) {
            return {
                timeFrom: openingHours['opening_time_' + weekday],
                timeTo: openingHours['closing_time_' + weekday],
            };
        }
    };

    const onFinish = (values) => {
        const data = values.openingHours;

        let requestData = {};
        requestData.additionalText = values.additionalText;
        for (let i = 0, len = Object.keys(data).length; i < len; i++) {
            const weekday = Object.keys(data)[i];
            const record = data[weekday];

            if (record.timeFrom && record.timeTo) {
                requestData['openingTime' + weekday.charAt(0).toUpperCase() + weekday.slice(1)] = record.timeFrom.format(timeFormat);
                requestData['closingTime' + weekday.charAt(0).toUpperCase() + weekday.slice(1)] = record.timeTo.format(timeFormat);
            }
        }

        putOpeningHours(activeAccount.companyId, requestData)
            .then((res) => {
                notification.open({
                    message: t('notification.messages.saved.title'),
                    description: t('notification.messages.saved.description'),
                });
                closeEditForm();
            })
            .catch(e => {
                notification.open({
                    message: t('notification.messages.error.title'),
                    description: t('notification.messages.error.description'),
                });
                closeEditForm();
            });
    };

    const mappedWeekdays = weekdays.map((weekday, index) => {
        const data = getOpeningHoursByDay(weekday);

        return {
            ...data,
            weekday: weekday,
            closed: (typeof data === 'undefined')
        };
    });

    const initialOpeningHours = collect(mappedWeekdays).mapWithKeys((record) => {
        return [record.weekday, {
            timeFrom: (record.timeFrom && !record.closed ? moment(record.timeFrom, timeFormat) : undefined),
            timeTo: (record.timeTo && !record.closed ? moment(record.timeTo, timeFormat) : undefined)
        }]
    }).all();

    const initialAdditionalText = isDataLoaded ? openingHours.additional_text : '';

    useEffect(() => {
        getOpeningHours(activeAccount.companyId);
    }, [activeAccount]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            initialValues={{
                additionalText: initialAdditionalText,
                openingHours: initialOpeningHours
            }}
        >
            <Form.Item label={t('pages.company.opening-hours-form.items.opening-hours.label')}>
                <Descriptions column={1} bordered={true}>
                    {mappedWeekdays.map((record, index) =>
                        <Descriptions.Item key={index} label={t('days.' + record.weekday + '.long')}>
                            <Form.Item>
                                <Form.Item
                                    name={['openingHours', record.weekday, 'timeFrom']}
                                    noStyle
                                    dependencies={[['openingHours', record.weekday, 'timeTo']]}
                                    rules={[
                                                {required: false},
                                                ({ getFieldValue }) => ({
                                                    validator(rule, value) {
                                                        const toFieldValue = getFieldValue(['openingHours', record.weekday, 'timeTo']);
                                                        if (
                                                            (toFieldValue && moment(value).isBefore(toFieldValue)) ||
                                                            (!value && !toFieldValue)
                                                        ) {
                                                            return Promise.resolve();
                                                        }
                                                        return Promise.reject(t('pages.company.opening-hours-form.form.errors.time-from-invalid'));
                                                    },
                                                }),
                                            ]}
                                >
                                    <TimePicker
                                        placeholder={t('pages.company.opening-hours-form.closed')}
                                        format={timeFormat}
                                        minuteStep={15}
                                        inputReadOnly={true}
                                    />
                                </Form.Item>
                                &nbsp; - &nbsp;
                                <Form.Item
                                    name={['openingHours', record.weekday, 'timeTo']}
                                    noStyle
                                    dependencies={[['openingHours', record.weekday, 'timeFrom']]}
                                    rules={[
                                                {required: false},
                                                ({ getFieldValue }) => ({
                                                    validator(rule, value) {
                                                        const fromFieldValue = getFieldValue(['openingHours', record.weekday, 'timeFrom']);
                                                        if (
                                                            (fromFieldValue && moment(value).isAfter(fromFieldValue)) ||
                                                            (!value && !fromFieldValue)
                                                        ) {
                                                            return Promise.resolve();
                                                        }
                                                        return Promise.reject(t('pages.company.opening-hours-form.form.errors.time-to-invalid'));
                                                    },
                                                }),
                                            ]}
                                >
                                    <TimePicker
                                        placeholder={t('pages.company.opening-hours-form.closed')}
                                        format={timeFormat}
                                        minuteStep={15}
                                        inputReadOnly={true}
                                    />
                                </Form.Item>
                            </Form.Item>
                        </Descriptions.Item>
                    )}
                </Descriptions>
            </Form.Item>

            <Form.Item
                name="additionalText"
                label={t('pages.company.opening-hours-form.items.additional-text.label')}
                rules={[
                    {type: 'string', min: 0, max: 1500, message: t('form.errors.max-length')},
                    ({ getFieldValue }) => ({
                        validator(rule, value) {

                            let emailInstances = value.toString().match('(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))') ?? [];
                            let phoneInstances = value.toString().match('((\\+|00(\\s|\\s?\\-\\s?)?)31(\\s|\\s?\\-\\s?)?(\\(0\\)[\\-\\s]?)?|0)[1-9]((\\s|\\s?\\-\\s?)?[0-9])((\\s|\\s?-\\s?)?[0-9])((\\s|\\s?-\\s?)?[0-9])\\s?[0-9]\\s?[0-9]\\s?[0-9]\\s?[0-9]\\s?[0-9]') ?? [];
                            let urlInstances = value.toString().match('https?:\\/\\/(www\\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\\.[a-zA-Z0-9()]{1,6}\\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)') ?? [];

                            if (emailInstances.length < 1 && phoneInstances.length < 1 && urlInstances.length < 1) {
                                return Promise.resolve();
                            }

                            return Promise.reject(t('form.errors.contact-details'));
                        },
                    }),
                ]}
            >
                <TextareaCharacterCount max={1500} rows={5} />
            </Form.Item>

            <br/>

            <div className="actions">
                <Button onClick={() => closeEditForm()} style={{marginRight: '8px'}}>
                    {t('button.text.cancel')}
                </Button>
                <SubmitButton loading={isEditFormSaving}>
                    {t('button.text.save')}
                </SubmitButton>
            </div>
        </Form>
    );
};

export default OpeningHoursForm;
