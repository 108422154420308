import React, {useState} from "react";
import {Button, Col, Drawer, Row, Table} from "antd";
import {EditOutlined, PlusOutlined} from "@ant-design/icons";
import useFormatting from "../../hooks/useFormatting";
import {useSmallDeviceSize} from "hooks";
import {useTranslation} from "react-i18next";
import moment from "moment";
import OfferItemForm from "./OfferItemForm";
import {calculatePriceIncVat} from "../../helpers/taxes";

const OfferItemsCollectionForm = ({value: offerItems, disabled, onChange, rentalMatch}) => {
    const { t } = useTranslation('common');
    const { formatDateTime, formatCents } = useFormatting();
    const [offerItemDrawerData, setOfferItemDrawerData] = useState(null);
    const isSmallDevice = useSmallDeviceSize();

    /**
     * Opens the offer item drawer with a new offer item
     */
    const addOfferItem = () => {
        const offerItem = {
            type: null,
            quantity: 1,
            date_from: rentalMatch.quotation.date_from ? moment(rentalMatch.quotation.date_from) : null,
            date_until: rentalMatch.quotation.date_until ? moment(rentalMatch.quotation.date_until) : null,
        };
        setOfferItemDrawerData({
            offerItem: offerItem,
            mode: 'new'
        });
    };

    const closeOfferItemDrawer = () => {
        setOfferItemDrawerData(null)
    }

    const editOfferItem = (offerItem, index) => {
        setOfferItemDrawerData({offerItem: offerItem, mode: 'edit', offerItemIndex: index})
    }

    const removeOfferItem = (index) => {
        let newOfferItems = [...offerItems];
        newOfferItems.splice(index, 1);
        onChange(newOfferItems);
    }

    /**
     * After the offer item form has been submitted we:
     * 1. Update the list of offer items
     * 2. Close the offer item drawer
     */
    const onFinishOfferItemForm = (values) => {
        closeOfferItemDrawer();
        if (values.type === null) return;

        let newOfferItems = [...offerItems];
        if (offerItemDrawerData.mode === 'new') {
            newOfferItems.push(values);
        } else {
            newOfferItems[offerItemDrawerData.offerItemIndex] = values;
        }

        onChange(newOfferItems);
    };

    let drawerWidth = 520;
    if (isSmallDevice) {
        drawerWidth = '100%';
    }

    return (
        <>
            <Drawer
                title={
                    offerItemDrawerData !== null && offerItemDrawerData.mode === 'new' ?
                        t('components:offer-form.offer-item-drawer.title.new') :
                        t('components:offer-form.offer-item-drawer.title.edit')
                }
                placement="right"
                open={offerItemDrawerData !== null}
                width={drawerWidth}
                layout="vertical"
                onClose={closeOfferItemDrawer}
            >
                <OfferItemForm
                    offerItem={offerItemDrawerData === null ? null : offerItemDrawerData.offerItem}
                    mode={offerItemDrawerData === null ? null : offerItemDrawerData.mode}
                    onFinish={onFinishOfferItemForm}
                />
            </Drawer>
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <Table
                        dataSource={offerItems}
                        locale={{emptyText: t('components:offer-form.offer-items-table.empty-text')}}
                        rowKey={() => Math.random()} // Hacky hack, just don't use row selection
                        pagination={false}
                    >
                        <Table.Column
                            title={t('entities:offer-item.properties.name')}
                            key="name"
                            dataIndex="name"
                        />
                        <Table.Column
                            title={t('entities:offer-item.properties.quantity')}
                            key="quantity"
                            dataIndex="quantity"
                        />
                        <Table.Column
                            title={t('entities:offer-item.properties.date-from')}
                            key="date_from"
                            dataIndex="date_from"
                            render={(value, record) => formatDateTime(record.date_from)}
                        />
                        <Table.Column
                            title={t('entities:offer-item.properties.date-until')}
                            key="date_until"
                            dataIndex="date_until"
                            render={(value, record) => {
                                if (record.date_until === null) {
                                    return '-';
                                }
                                return formatDateTime(record.date_until);
                            }}
                        />
                        <Table.Column
                            title={t('entities:offer-item.properties.price-ex-vat')}
                            key="priceExVat"
                            dataIndex="price"
                            render={(value, record) => formatCents(value)}
                        />
                        <Table.Column
                            title={t('entities:offer-item.properties.price-inc-vat')}
                            key="priceIncVat"
                            dataIndex="price"
                            render={(value, record) => formatCents(calculatePriceIncVat(value, record.tax_rate.percentage))}
                        />
                        <Table.Column
                            title={t('tables.common.column.actions')}
                            key="actions"
                            align="right"
                            render={(value, record, index) => <>
                                <Button
                                    disabled={disabled}
                                    onClick={() => editOfferItem(record, index)}
                                >
                                    <EditOutlined/>
                                    {t('components:offer-form.offer-items-table.actions.edit')}
                                </Button>
                                <Button disabled={disabled} onClick={() => removeOfferItem(index)} danger>
                                    {t('components:offer-form.offer-items-table.actions.remove')}
                                </Button>
                            </>}
                        />
                    </Table>
                </Col>
            </Row>

            <Row gutter={[16, 16]}>
                <Col span={24} align="right">
                    <Button disabled={disabled} onClick={() => addOfferItem()}>
                        <PlusOutlined/>
                        {t('components:offer-form.add-offer-item')}
                    </Button>
                </Col>
            </Row>
        </>
    )
};

export default OfferItemsCollectionForm;