import React, {useEffect, useState} from 'react';
import LoggedInLayout from "layout/LoggedInLayout";
import {useTranslation} from "react-i18next";
import {Button, Card, Dropdown, Menu, Table} from "antd";
import {FilterCard} from "./components/FilterCard";
import {Totals} from "./components/Totals";
import {InfoPopover} from "components/InfoPopover";
import {useAccounts, useStatistics} from "hooks";
import moment from "moment";
import Media from "react-media";
import {Money} from "components/Money";
import {saveAs} from "file-saver";
import client from "services/HurenApiClient";
import {DownloadOutlined, DownOutlined, FileExcelOutlined, FilePdfOutlined} from "@ant-design/icons";
import {getStatisticsUrl, b64toBlob} from "helpers";
import { countConversion } from '../../helpers';

const LocationStatistics = () => {
    const { t } = useTranslation('common');
    const { activeAccount } = useAccounts();
    const [filters, setFilters] = useState({
        categories: [],
        startDate: moment().subtract(1, 'month'),
        endDate: moment()
    });

    const {
        getLocationStatistics,
        getLocationTotals,
        isTotalsDataLoaded,
        isTotalsDataError,
        payload: {
            rows
        },
        totals
    } = useStatistics();

    const changeDateRange = (dates) => {
        if (dates != null) {
            setFilters({...filters, startDate: dates[0], endDate: dates[1]});
        }
    }

    const categorySelected = (categories) => {
        setFilters({...filters, categories: categories});
    };

    useEffect(() => {
        getLocationStatistics(activeAccount.companyId, filters.startDate, filters.endDate, filters.categories);
        getLocationTotals(activeAccount.companyId, filters.startDate, filters.endDate, filters.categories);
    }, [filters]); // eslint-disable-line react-hooks/exhaustive-deps

    const download = (type) => {
        const url = getStatisticsUrl('download', activeAccount.companyId, filters.period, filters.startDate, filters.endDate, filters.categories);
        const contentType = type === 'pdf' ? 'application/pdf;charset=utf-8' : 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        client.get(url + '&type=' + type + '&stats=location').then(res => {
            let blob = b64toBlob(res.data.file, contentType);
            saveAs(blob, res.data.filename);
        });
    }

    const tableHeader = (title, hint) => <>{title}<InfoPopover content={hint}/></>

    const tableColumns = [
        {
            title: t('pages.statistics.locations.table.location'),
            dataIndex: 'location',
            key: 'location',
            sorter: (a, b) => ('' + a.location).localeCompare(b.location),
        },
        {
            title: t('pages.statistics.locations.table.city'),
            dataIndex: 'city',
            key: 'city',
            sorter: (a, b) => ('' + a.city).localeCompare(b.city),
        },
        {
            title: tableHeader(t('pages.statistics.locations.table.clicks'),t('pages.statistics.locations.table.hint.clicks')),
            dataIndex: 'clicks',
            key: 'clicks',
            sorter: (a, b) => a.clicks - b.clicks,
            render: (text, record) => record.clicks,
        },
        {
            title: tableHeader(t('pages.statistics.table.calls'),t('pages.statistics.table.hint.calls')),
            dataIndex: 'call',
            key: 'call',
            sorter: (a, b) => a.call - b.call,
            render: (text, record) => record.call,
        },
        {
            title: tableHeader(t('pages.statistics.locations.table.cost-per-click'),t('pages.statistics.locations.table.hint.cost-per-click')),
            dataIndex: 'cost_per_click',
            key: 'cost_per_click',
            hideOnResponsive: true,
            sorter: (a, b) => a.cost_per_click - b.cost_per_click,
            render: (text, record) => record.cost_per_click != null ? <Money value={record.cost_per_click} /> : <></>
        },
        {
            title: tableHeader(t('pages.statistics.locations.table.total-price'),t('pages.statistics.locations.table.hint.total-price')),
            dataIndex: 'total_price',
            key: 'total_price',
            sorter: (a, b) => a.total_price - b.total_price,
            render: (text, record) => record.total_price != null ? <Money value={record.total_price} /> : <></>
        },
        {
            title: tableHeader(t('pages.statistics.locations.table.quotations'),t('pages.statistics.locations.table.hint.quotations')),
            dataIndex: 'quotations',
            key: 'quotations',
            hideOnResponsive: true,
            sorter: (a, b) => a.quotations - b.quotations,
        },
        {
            title: tableHeader(t('pages.statistics.locations.table.product_calls'),t('pages.statistics.locations.table.hint.product_calls')),
            dataIndex: 'product_calls',
            key: 'product_calls',
            hideOnResponsive: true,
            sorter: (a, b) => a.product_calls - b.product_calls,
        },
        {
            title: tableHeader(t('pages.statistics.table.conversion'),t('pages.statistics.table.hint.conversion')),
            dataIndex: 'conversion',
            key: 'conversion',
            hideOnResponsive: true,
            sorter: (a, b) => {
                return countConversion(a.clicks, a.quotations, a.product_calls + a.call)
                    - countConversion(b.clicks, b.quotations, b.product_calls + b.call);
            },
            render: (_, record) => {
                return `${countConversion(record.clicks, record.quotations, record.product_calls + record.call)} %`
            }
        }
    ];

    var dataTable;
    if (isTotalsDataLoaded && !isTotalsDataError) {
        dataTable = <Media query="(min-width: 950px)">
                {matches => {
                    let filteredColumns = tableColumns.filter((column) => {
                        return matches || column.hideOnResponsive !== true;
                    });
                    return <Table
                        dataSource={rows}
                        rowKey="location"
                        columns={filteredColumns}
                        pagination={{
                            showSizeChanger: true
                        }}
                    />
                }}
            </Media>
    } else {
        dataTable = <>{t('pages.statistics.locations.table.error')}</>;
    }

    const exportDropdownMenu = {
        items: [
            {
                key: '1',
                label: (
                    <a onClick={() => download('pdf')}>
                        <FilePdfOutlined style={{marginRight: '5px'}}/>
                        PDF document
                    </a>
                )
            },
            {
                key: '2',
                label: (
                    <a onClick={() => download('excel')}>
                        <FileExcelOutlined style={{marginRight: '5px'}}/>
                        Excelsheet
                    </a>
                )
            }
        ]
    };

    return <LoggedInLayout pageTitle={t('pages.statistics.locations.title')} headerExtras={
        [
            <Dropdown disabled={!(isTotalsDataLoaded && !isTotalsDataError)} key="export" menu={exportDropdownMenu}>
                <Button>
                    <DownloadOutlined />
                    Exporteer
                    <DownOutlined />
                </Button>
            </Dropdown>

        ]
    }>
        <FilterCard startDate={filters.startDate}
                    endDate={filters.endDate}
                    showChart={false}
                    showChartToggle={false}
                    showGroupByDayWeekMonth={false}
                    changeDateRange={changeDateRange}
                    categorySelected={categorySelected}
        />

        <br />

        <Totals stats={totals} loading={!isTotalsDataLoaded}/>

        <br />

        <Card
            size="small"
            title={t('pages.statistics.table.title')}
            className="full-table"
        >
            {dataTable}
        </Card>


    </LoggedInLayout>;
};

export default LocationStatistics;