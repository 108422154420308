import React, {Suspense, useEffect} from 'react';
import {Button, Col, Layout,} from "antd";
import HeaderUserDropdown from "./HeaderUserDropdown";
import Logo from "components/Logo";
import {MenuOutlined} from "@ant-design/icons";
import LanguageSelector from "components/LanguageSelector";
import {useSmallDeviceSize} from "hooks";

const LayoutHeader = Layout.Header;

const Header = (props) => {
    const isLoggedIn = props.loggedIn;
    const onMenuButtonClicked = props.onMenuButtonClicked;
    const isSmallDevice = useSmallDeviceSize();

    let languageSelector;
    if (!isSmallDevice) {
        languageSelector = <Suspense fallback={null}>
            <LanguageSelector />
        </Suspense>;
    }

    if (isLoggedIn) {
        return (
            <LayoutHeader className="header">
                <Col span={8}>
                    <Button className="menu-button" icon={<MenuOutlined />} size="large" type="text" onClick={onMenuButtonClicked} />
                </Col>
                <Col span={8} className="logo">
                    <Logo width="120px"/>
                </Col>
                <Col span={8} className="session-settings">
                    { languageSelector }
                    <HeaderUserDropdown />
                </Col>
            </LayoutHeader>
        );
    }
    return (
        <LayoutHeader className="header">
            <Col span={8}>
            </Col>
            <Col span={8} className="logo">
                <Logo width="120px"/>
            </Col>
            <Col span={8} className="session-settings logged-out">
                <Suspense fallback={null}>
                    <LanguageSelector />
                </Suspense>
            </Col>
        </LayoutHeader>
    );
};

export default Header;
