import {createQueryString} from "../helpers/queryString";
import {useQuery} from "react-query";
import client from "services/HurenApiClient";

const fetchPaymentTransferCollection = (queryData) => async () => {
    let url = '/api/v2/payment-transfers';
    if (queryData) {
        url += '?' + createQueryString(queryData, '');
    }
    const res = await client.get(url);
    return res.data;
};
export const useGetPaymentTransferCollection = (collectionParameters, options) => {
    const createQueryData = () => {
        const queryData = {
            page: collectionParameters.page,
            limit: collectionParameters.limit,
            filters: {}
        };
        if (collectionParameters.searchQuery) {
            queryData.searchQuery = collectionParameters.searchQuery;
        }

        if (collectionParameters.filters.state) {
            queryData.filters.state = collectionParameters.filters.state;
        }

        if (collectionParameters.sort) {
            queryData.sort = collectionParameters.sort;
        }

        return queryData;
    };
    const queryData = createQueryData();
    return useQuery(['payment-transfers', queryData], fetchPaymentTransferCollection(queryData), options);
};