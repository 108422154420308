import React from "react";
import {useTranslation} from "react-i18next";
import {Button, Card} from "antd";
import {useAccounts} from "hooks";
import {EditOutlined} from "@ant-design/icons";
import PasswordForm from "./PasswordForm";

const PasswordCard = () => {
    const {t} = useTranslation('common');
    const { openEditForm } = useAccounts();

    return <Card
        size="small"
        title={t('pages.account.password-card.title')}
    >
        <PasswordForm />
        <div style={{textAlign: 'center'}}>
            <Button onClick={() => openEditForm()}>
                <EditOutlined />
                {t('button.text.edit')}
            </Button>
        </div>
    </Card>;
}

export default PasswordCard;