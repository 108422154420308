import React, {useEffect, useState} from 'react';
import {Col, Row, Select} from "antd";
import {useTranslation} from "react-i18next";
import {useGetAccountGroups} from "../../../queries/users";
import Search from "antd/lib/input/Search";

const UserOverviewFilters = ({filters: filtersProp, onChange, disabled = false}) => {

    const { t } = useTranslation('pages');

    const [groupCode, setGroupCode] = useState(filtersProp.group_code);
    const [searchQuery, setSearchQuery] = useState(filtersProp.searchQuery);

    useEffect(() => {
        if (onChange) {
            onChange(groupCode, searchQuery);
        }
    }, [groupCode, searchQuery])

    const onFilter = (value) => {
        setGroupCode(value)
    }

    const onSearch = (event) => {
        setSearchQuery(event.target.value)
    }

    let searchTimeout = null;
    useEffect(() => {
        clearTimeout(searchTimeout);
        searchTimeout = setTimeout(() => {
            setGroupCode(groupCode)
        }, 500);
        return () => {
            clearTimeout(searchTimeout);
        };
    }, [searchQuery]);

    const { data: accountGroups } = useGetAccountGroups();

    return (
        <Row gutter={[16, 32]}
             justify={"space-between"}
             type={'flex'}
             align={'middle'}
             style={{borderBottom: '1px solid #e8e8e8', paddingBottom: '16px', marginBottom: '16px'}}
        >
            <Col width={100}>
                <Select
                    placeholder={t("users.filter")}
                    allowClear={true}
                    onChange={(value) => {
                        onFilter(value)
                    }}
                    style={{width: 200}}
                >
                    {
                        accountGroups?.items.map(entry => {
                            return (
                                <Select.Option  value={entry.code? entry.code : ""}
                                                key={entry.id? entry.id : ""}
                                >
                                    {entry.name? entry.name : ''}
                                </Select.Option>
                            )
                        })
                    }
                </Select>
            </Col>
            <Col>
                <Search
                    placeholder={t("users.search")}
                    onChange={onSearch}
                />
            </Col>
        </Row>
    );
}

export default UserOverviewFilters;