import React, {useEffect} from 'react';
import { EditOutlined } from '@ant-design/icons';
import { Button, Descriptions } from "antd";
import Card from "components/Card";
import {InfoPopover} from "components/InfoPopover";
import {useTranslation} from "react-i18next";
import {PremiumInfo} from "components/Premium";
import OriginalTextSkeleton from "components/TextSkeleton";
import { useAccounts, useContactDetails } from "hooks";
import {hasPermission} from "../../../../helpers";
import ContactDetailsDrawer from "./ContactDetailsDrawer";

const TextSkeleton = (props) => {
    const {
        isDataLoaded,
    } = useContactDetails();

    return <OriginalTextSkeleton {...props} loading={!isDataLoaded}/>;
}

const ContactDetailsCard = () => {
    const {t} = useTranslation('common');
    const { activeAccount } = useAccounts();

    const {
        getCompanyContactDetails,
        openEditForm,
        isDataLoaded,
        payload: {
            phone,
            show_phone_in_clickbox,
            email,
            url,
            facebook_url
        }
    } = useContactDetails();

    useEffect(() => {
        getCompanyContactDetails(activeAccount)
    }, []); // eslint-disable-line react-hooks/exhaustive-deps



    return <>
        <ContactDetailsDrawer/>
        <Card
            className="company-contact-details-card"
            title={<><PremiumInfo/> {t('pages.company.contact-details-card.title')}</>}
            actions={[
                <Button disabled={!hasPermission(activeAccount, 'manage_contact_details', 'edit')} onClick={() => openEditForm()}>
                    <EditOutlined /> {t('button.text.edit')}
                </Button>
            ]}
        >
            <Descriptions column={1} className="label-50-percent">
                <Descriptions.Item key={1} label={t('pages.company.contact-details-card.label.phone-number.label')}>
                    <TextSkeleton>{phone}</TextSkeleton>
                </Descriptions.Item>
                <Descriptions.Item key={2} label={t('pages.company.contact-details-card.label.display-phone-number.label')}>
                    <TextSkeleton>
                        {isDataLoaded ? t('pages.company.contact-details-card.label.display-phone-number.options.' + show_phone_in_clickbox) : null}
                    </TextSkeleton>
                    &nbsp;
                    <InfoPopover
                        content={t('pages.company.contact-details-card.label.display-phone-number.info')}
                    />
                </Descriptions.Item>
                <Descriptions.Item key={3} label={t('pages.company.contact-details-card.label.email.label')}>
                    <TextSkeleton>{email}</TextSkeleton>
                </Descriptions.Item>
                <Descriptions.Item key={4} label={t('pages.company.contact-details-card.label.website.label')}>
                    <TextSkeleton>
                        <a href={url} target="_blank" rel="noopener noreferrer">{url}</a>
                    </TextSkeleton>
                </Descriptions.Item>
                <Descriptions.Item key={5} label={t('pages.company.contact-details-card.label.facebook.label')}>
                    <TextSkeleton>
                        <a href={facebook_url} target="_blank" rel="noopener noreferrer">{facebook_url}</a>
                    </TextSkeleton>
                </Descriptions.Item>
            </Descriptions>
        </Card>
    </>;
};

export default ContactDetailsCard;
