import React from "react";
import {CheckOutlined, FacebookOutlined} from '@ant-design/icons';
import {Button, Input, Select, notification, Form} from "antd";
import {useTranslation} from 'react-i18next';
import {useContactDetails} from "hooks";
import {useAccounts} from "hooks";

const {Option} = Select;

const ContactDetailsForm = () => {
    const {t} = useTranslation('common');
    const { activeAccount } = useAccounts();

    const {
        closeEditForm,
        putCompanyContactDetails,
        payload: {
            phone,
            show_phone_in_clickbox,
            email,
            url,
            facebook_url
        }
    } = useContactDetails();

    const onFinish = (values) => {
        putCompanyContactDetails(activeAccount, values)
            .then((res) => {
                notification.open({
                    message: t('notification.messages.saved.title'),
                    description: t('notification.messages.saved.description'),
                });
                closeEditForm();
            })
            .catch(e => {
                console.log(e);
                notification.open({
                    message: t('notification.messages.error.title'),
                    description: t('notification.messages.error.description'),
                });
                closeEditForm();
            });
    };

    return (
        <Form
            layout="vertical"
            onFinish={onFinish}
            initialValues={{
                phone: phone,
                email: email,
                showPhoneInClickbox: show_phone_in_clickbox,
                url: url,
                facebookUrl: facebook_url
            }}
        >
            <Form.Item
                name="phone"
                label={t('pages.company.contact-details-form.items.phone-number.label')}
                rules={[
                    {required: true, message: t('form.errors.required')},
                    {pattern: "^((\\+|00(\\s|\\s?\\-\\s?)?)31(\\s|\\s?\\-\\s?)?(\\(0\\)[\\-\\s]?)?|0)[1-9]((\\s|\\s?\\-\\s?)?[0-9])((\\s|\\s?-\\s?)?[0-9])((\\s|\\s?-\\s?)?[0-9])\\s?[0-9]\\s?[0-9]\\s?[0-9]\\s?[0-9]\\s?[0-9]$", message: t('form.errors.invalid-phone')}
                ]}
            >
                <Input placeholder={t('pages.company.contact-details-form.items.phone-number.placeholder')}/>
            </Form.Item>

            <Form.Item
                name="email"
                label={t('pages.company.contact-details-form.items.email.label')}
                rules={[
                    {type: 'email', message: t('form.errors.invalid-email')},
                    {required: true, message: t('form.errors.required')}
                ]}
            >
                <Input placeholder={t('pages.company.contact-details-form.items.phone-number.placeholder')}/>
            </Form.Item>

            <Form.Item
                name="showPhoneInClickbox"
                label={t('pages.company.contact-details-form.items.phone-display.label')}
                help={t('pages.company.contact-details-form.items.phone-display.help')}
                rules={[
                    {required: true, message: t('form.errors.required')}
                ]}
            >
                <Select>
                    <Option value="NONE">{t('pages.company.contact-details-form.items.phone-display.choice.none')}</Option>
                    <Option value="ACCOUNT">{t('pages.company.contact-details-form.items.phone-display.choice.account')}</Option>
                    <Option value="LOCATION">{t('pages.company.contact-details-form.items.phone-display.choice.location')}</Option>
                </Select>
            </Form.Item>

            <Form.Item
                name="url"
                label={t('pages.company.contact-details-form.items.website.label')}
                rules={[
                    {required: true, message: t('form.errors.required')},
                    {
                        pattern: "https?:\\/\\/(www\\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\\.[a-zA-Z0-9()]{1,6}\\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)",
                        message: t('form.errors.invalid-url')
                    }
                ]}
            >
                <Input placeholder={t('pages.company.contact-details-form.items.website.placeholder')}/>
            </Form.Item>

            <Form.Item
                name="facebookUrl"
                label={t('pages.company.contact-details-form.items.facebook.label')}
                rules={[
                    {
                        pattern: "(?:(?:http|https):\\/\\/)?(?:www.)?facebook.com\\/(?:(?:\\w)*#!\\/)?(?:pages\\/)?(?:[?\\w\\-]*\\/)?(?:profile.php\\?id=(?=\\d.*))?([\\w\\-]*)?",
                        message: t('form.errors.invalid-url')
                    }
                ]}
            >
                <Input
                    addonBefore={(<FacebookOutlined/>)}
                    placeholder={t('pages.company.contact-details-form.items.facebook.placeholder')}/>
            </Form.Item>
            <div className="actions">
                <Button onClick={() => closeEditForm()} style={{marginRight: '8px'}}>
                    {t('button.text.cancel')}
                </Button>
                <Button htmlType="submit" type="primary">
                    <CheckOutlined/>
                    {t('button.text.save')}
                </Button>
            </div>
        </Form>
    );
};

export default ContactDetailsForm;
