import * as Types from "./Types";

export const initialState = {
    isDataLoaded: false,
    isEditFormDataLoaded: false,
    isEditFormOpen: false,
    isEditFormSaving: false,
    editFormPayload: {},
    payload: {
        current_daily_limi: 0,
        budget_used_today: 0,
        minimum_daily_budget: 0
    }
};

export default function (state, action) {
    switch (action.type) {
        case Types.SET_IS_DATA_LOADED:
            return {
                ...state,
                isDataLoaded: action.isDataLoaded
            };
        case Types.SET_DATA:
            return {
                ...state,
                payload: action.payload,
                isDataLoaded: true
            };
        case Types.SET_IS_EDIT_FORM_DATA_LOADED:
            return {
                ...state,
                isEditFormDataLoaded: action.isEditFormDataLoaded
            };
        case Types.SET_IS_EDIT_FORM_SAVING:
            return {
                ...state,
                isEditFormSaving: action.isEditFormSaving
            };
        case Types.EDIT_FORM_OPEN:
            return {
                ...state,
                isEditFormOpen: true,
            };
        case Types.EDIT_FORM_CLOSE:
            return {
                ...state,
                isEditFormOpen: false,
            };
        case Types.SET_EDIT_FORM_DATA:
            return {
                ...state,
                editFormPayload: action.payload,
                isEditFormDataLoaded: true
            };
        default:
            return state;
    }
};
