import React, {useEffect, useMemo, useState} from "react";
import {useCreateOfferTransition, useGetEnabledTransitions} from "../../queries/offers";
import {Button, Form, Radio, Skeleton, Space, Modal} from "antd";
import {CheckOutlined} from "@ant-design/icons";
import {useTranslation} from "react-i18next";
import {useForm} from "antd/es/form/Form";
import TextareaCharacterCount from "../TextareaCharacterCount";
import {OfferTransition} from "../../types";

const {confirm} = Modal;

const OfferTransitionForm = ({offer, onFinish}) => {
    const { t } = useTranslation('common');
    const [form] = useForm();
    const [transition, setTransition] = useState({
        transition_name: null,
        arguments: {},
    });
    const { data: enabledTransitions, isLoading: enabledTransitionsIsLoading } = useGetEnabledTransitions(offer.id);
    const createOfferTransition = useCreateOfferTransition();

    const transitionNameOptions = useMemo(() => {
        if (!enabledTransitions) {
            return [];
        }

        return enabledTransitions.map(enabledTransition => {
            return {
                label: t('entities:offer.transition.' + enabledTransition.name),
                value: enabledTransition.name
            };
        });
    }, [enabledTransitions]);

    useEffect(() => {
        form.resetFields();
    }, [offer]);

    const onValuesChange = (values) => {
        const tempTransition = {...transition}
        tempTransition.arguments = {...transition.arguments, ...values.arguments}
        if (values.transition_name !== undefined) {
            tempTransition.transition_name = values.transition_name
        }
        setTransition(prevTransition => {
            return { ...prevTransition, ...tempTransition};
        });
    };

    const onInnerFinish = async (values) => {
        if (values['transition_name'] == OfferTransition.ACCEPT ||
            values['transition_name'] == OfferTransition.REJECT) {
            confirm({
                title: t('components:offer-transition-form.confirmation.title'),
                content: t('components:offer-transition-form.confirmation.content-'+values['transition_name']),
                okText: t('components:offer-transition-form.confirmation.ok'),
                cancelText: t('components:offer-transition-form.confirmation.cancel'),
                onOk() {
                    saveAndFinish(values);
                }
            })
        } else {
            saveAndFinish(values);
        }
    };

    const saveAndFinish = async (values) => {
        await createOfferTransition.mutateAsync({
            rentalMatchId: offer.rental_match.id,
            offerId: offer.id,
            transition: values,
        });

        if (onFinish) {
            onFinish(values);
        }
    }

    return (
        <>
            <Skeleton loading={enabledTransitionsIsLoading} active={true}>
                {enabledTransitions?.length > 0 ?
                    <Form
                        form={form}
                        layout="vertical"
                        initialValues={transition}
                        onValuesChange={onValuesChange}
                        onFinish={onInnerFinish}
                    >
                        <Form.Item
                            name="transition_name"
                            label={t('components:offer-transition-form.fields.transition-name.label')}
                            rules={[
                                {required: true, message: t('form.errors.required')}
                            ]}
                        >
                            <Radio.Group
                                options={transitionNameOptions}
                                optionType="button"
                                buttonStyle="solid"
                            />
                        </Form.Item>

                        {(transition.transition_name === 'reject') && (
                            <>
                                <Form.Item
                                    name={['arguments', 'statusDetail']}
                                    label={t('components:offer-transition-form.fields.status-detail.label')}
                                    rules={[
                                        {required: true, message: t('form.errors.required')}
                                    ]}
                                >
                                    <Radio.Group
                                        optionType="default"
                                        buttonStyle="solid"
                                    >
                                        <Space direction="vertical">
                                            <Radio value="rental_terms">
                                                {t('components:offer-transition-form.fields.status-detail.options.rejection.rental_terms')}
                                            </Radio>
                                            <Radio value="else">
                                                {t('components:offer-transition-form.fields.status-detail.options.rejection.else')}
                                            </Radio>
                                        </Space>
                                    </Radio.Group>
                                </Form.Item>
                                {transition.arguments.statusDetail === 'else' ?
                                    <Form.Item
                                        name={['arguments', 'statusDescription']}
                                        label={t('components:offer-transition-form.fields.status-description.label')}
                                    >
                                        <TextareaCharacterCount max={255}/>
                                    </Form.Item>
                                : <></> }
                            </>
                        )}

                        {(transition.transition_name === 'withdraw') && (
                            <>
                                <Form.Item
                                    name={['arguments', 'statusDetail']}
                                    label={t('components:offer-transition-form.fields.status-detail.label')}
                                    rules={[
                                        {required: true, message: t('form.errors.required')}
                                    ]}
                                >
                                    <Radio.Group
                                        optionType="default"
                                        buttonStyle="solid"
                                    >
                                        <Space direction="vertical">
                                            <Radio value="no_availability">
                                                {t('components:offer-transition-form.fields.status-detail.options.withdrawal.no_availability')}
                                            </Radio>
                                            <Radio value="else">
                                                {t('components:offer-transition-form.fields.status-detail.options.withdrawal.else')}
                                            </Radio>
                                        </Space>
                                    </Radio.Group>
                                </Form.Item>
                                {transition.arguments.statusDetail === 'else' ?
                                    <Form.Item
                                        name={['arguments', 'statusDescription']}
                                        label={t('components:offer-transition-form.fields.status-description.label')}
                                    >
                                        <TextareaCharacterCount max={255}/>
                                    </Form.Item>
                                    : <></>
                                }
                            </>
                        )}

                        <div className="actions">
                            <Button htmlType="submit" type="primary">
                                <CheckOutlined/>
                                {t('button.text.apply')}
                            </Button>
                        </div>
                    </Form>
                    :
                    <div>{t('components:offer-transition-form.messages.no-enabled-transitions')}</div>
                }
            </Skeleton>
        </>
    );
};

export default OfferTransitionForm;