import Card from "components/Card";
import {Col, Row} from "antd";
import {Link} from "@reach/router";
import React from "react";
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import useAccounts from "hooks/useAccounts";

const StyledUl = styled.ul`
    padding: 0 0 0 17px;
`;

const IntroCard = () => {
    const { t } = useTranslation('common');

    const {activeAccount} = useAccounts();

    return <Card className="dashboard-intro-card" title={t('pages.dashboard.intro-card.title')}>
        <Row>
            <Col xs={{span: 24}} lg={{span: 8}}>
                <strong>{t('pages.dashboard.intro-card.step-1.title')}</strong>
                <StyledUl>
                    <li>
                        <Link to="/company/settings">
                            {t('pages.dashboard.intro-card.step-1.links.company-settings')}
                        </Link>
                    </li>
                    <li>
                        <a href={"https://www.huren.nl/m" + activeAccount.companyId}  target="_blank" rel="noopener noreferrer">
                            {t('pages.dashboard.intro-card.step-1.links.profile-page')}
                        </a>
                    </li>
                    <li>
                        <Link to="/rentalitems">
                            {t('pages.dashboard.intro-card.step-1.links.products')}
                        </Link>
                    </li>
                </StyledUl>
            </Col>
            <Col xs={{span: 24}} lg={{span: 8}}>
                <strong>{t('pages.dashboard.intro-card.step-2.title')}</strong>

                <StyledUl>
                    <li>
                        <Link to="/advertisements/budget">
                            {t('pages.dashboard.intro-card.step-2.links.account-budget')}
                        </Link>
                    </li>
                    <li>
                        <Link to="/company/settings">
                            {t('pages.dashboard.intro-card.step-2.links.company-settings')}
                        </Link>
                    </li>
                    <li>
                        <Link to="/advertisements/create">
                            {t('pages.dashboard.intro-card.step-2.links.advertisement-create')}
                        </Link>
                    </li>
                </StyledUl>
            </Col>
            <Col xs={{span: 24}} lg={{span: 8}}>
                <strong>{t('pages.dashboard.intro-card.step-3.title')}</strong>
                <StyledUl>
                    <li>
                        <Link to="/statistics">
                            {t('pages.dashboard.intro-card.step-3.links.statistics')}
                        </Link>
                    </li>
                    <li>
                        <Link to="/help/promo">
                            {t('pages.dashboard.intro-card.step-3.links.promo')}
                        </Link>
                    </li>
                    <li>
                        <Link to="/advertisements/budget">
                            {t('pages.dashboard.intro-card.step-3.links.account-budget')}
                        </Link>
                    </li>
                </StyledUl>
            </Col>
        </Row>
    </Card>;
}

export default IntroCard;
