import React, { useEffect, useRef, useState, forwardRef } from "react";
import { Button, Input, Skeleton } from "antd";
import {
    useAccounts,
    useConversation,
    useInboxPoll,
    useSmallDeviceSize,
} from "hooks";
import { useTranslation } from "react-i18next";
import Message from "./Message";
import styled from "styled-components";
import {
    DeleteOutlined,
    EditOutlined,
    PaperClipOutlined,
} from "@ant-design/icons";

const MessagesContainer = styled.div`
  height: ${(props) => (props.mobile ? "100%" : "100%")};
  overflow: hidden;
  display: flex;
  flex-direction: column;

  & > .messages {
    display: flex;
    flex-direction: column-reverse;
    overflow-y: auto;
    flex-grow: 1;
  }

  & > .inputs {
    display: flex;
  }
`;
const AttachmentsContainer = styled.div`
  .attachment {
    display: flex;
    padding: 0.5rem 0;
    align-items: center;

    button {
      margin-right: 0.5rem;
    }

    svg {
      width: 14px;
    }

    p {
      margin: 0;
    }
  }
`;

const dateTimeFormat = "DD-MM-YYYY HH:mm";

const Chat = forwardRef((props, ref) => {
    const { t } = useTranslation("common");
    const { activeAccount } = useAccounts();

    const id = props.id;

    const fileInputRef = useRef(null);

    const [conversationData, setConversationData] = useState([]);

    const [message, setMessage] = useState("");

    const {
        isDataLoaded,
        getConversation,
        getConversationMessages,
        writeMessage,
        deleteAttachment,
        payload,
        attachments,
        uploadFile,
    } = useConversation();

    const { getInboxPoll } = useInboxPoll();

    useEffect(() => {
        getConversation(
            id,
            activeAccount.companyId,
            activeAccount.accountId
        );
        getInboxPoll(activeAccount.companyId, activeAccount.accountId);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (payload?.id) setConversationData([payload]);
    }, [payload]); // eslint-disable-line react-hooks/exhaustive-deps

    const isSmallDevice = useSmallDeviceSize();

    useEffect(() => {
        const poll = setInterval(() => {
            if (id && payload && isDataLoaded) {
                getConversationMessages(
                    id,
                    activeAccount.companyId,
                    conversationData[0].messages[0]
                        ? new Date(payload.messages[0].send_at).getTime() / 1000
                        : Date.now() / 1000,
                    activeAccount.accountId
                );
            }
        }, 10000);
        return () => clearInterval(poll);
    });

    const handleMessageSend = (e) => {
        e.preventDefault();
        writeMessage(
            { body: message, attachments },
            id,
            activeAccount.companyId,
            activeAccount.accountId
        );
        setMessage("");
    };

    return (
        <MessagesContainer mobile={isSmallDevice}>

            <div ref={ref} className="messages">
                {!isDataLoaded && <Skeleton />}
                {isDataLoaded && conversationData[0]?.messages.map((message, idx) => (
                    <Message
                        key={idx}
                        accountId={activeAccount.accountId}
                        rentalServiceProviderId={activeAccount.companyId}
                        rentalServiceProviderName={activeAccount.companyName}
                        sent_at={message.send_at}
                        {...message}
                    />
                ))}
            </div>
            <form className="inputs" onSubmit={handleMessageSend}>
                <Input.Group compact style={{ display: "flex" }}>
                    <Input
                        name="body"
                        type="text"
                        required={attachments.length <= 0}
                        value={message}
                        placeholder={t("pages.conversation.form.input")}
                        onChange={(e) => setMessage(e.target.value)}
                    />
                    <Button
                        type="button"
                        icon={<PaperClipOutlined />}
                        onClick={() => fileInputRef?.current?.click()}
                    />
                </Input.Group>
                <input
                    accept=".pdf,image/*"
                    hidden
                    multiple
                    type="file"
                    ref={fileInputRef}
                    onChange={(e) => {
                        if (e.target.files) {
                            for (let i = 0; i < e.target.files.length; i++) {
                                uploadFile(e.target.files.item(i));
                            }
                        }
                    }}
                />
                <Button
                    style={{ marginLeft: "12px" }}
                    htmlType="submit"
                    type="primary"
                >
                    <EditOutlined />
                    {t("pages.conversation.form.button")}
                </Button>
            </form>
            <AttachmentsContainer>
                {attachments.map(({ id, filename }) => (
                    <div className="attachment" key={id}>
                        <Button
                            type="text"
                            danger
                            icon={<DeleteOutlined />}
                            onClick={() => deleteAttachment(id)}
                        />
                        <p>{filename}</p>
                    </div>
                ))}
            </AttachmentsContainer>
        </MessagesContainer>
    );
});

export default Chat;