import React, {useRef} from "react";
import styled from "styled-components";
import moment from "moment";
import apiClient from "services/HurenApiClient";
import { saveAs } from "file-saver";
import {
  FileImageOutlined,
  FileOutlined,
  FilePdfOutlined,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import MessageOfferAttachment from "./MessageOfferAttachment";
import useOnScreen from "../../hooks/useOnScreen";

const dateTimeFormat = "DD-MM-YYYY HH:mm";

const MessageContainer = styled.div`
  padding: 12px;
  width: ${(props) => (props?.mine ? "70%" : "50%")};
  background-color: ${(props) => (props?.mine ? "#FBEBDA" : "#E0EDF2")};
  margin-left: ${(props) => (props?.mine ? "auto" : 0)};
  margin-bottom: 12px;

  & .topline {
    display: flex;
    justify-content: space-between;
  }

  & .attachments {
    display: flex;
    flex-direction: column;

    button {
      border: none;
      cursor: pointer;
      background-color: rgba(0, 0, 0, 0.05);
      padding: 0.5rem 1rem;
      margin: 0.125rem 0;
      font-size: 12px;
      text-align: left;
      display: flex;
      align-items: center;

      svg {
        margin-right: 0.5rem;
      }
    }
  }
`;

const Message = ({
  sender_name,
  sender_type,
  sent_at,
  body,
  attachments,
  offer_id,
  accountId,
  rentalServiceProviderId,
  rentalServiceProviderName,
}) => {
  // Translations
  const { t } = useTranslation("common");

  // Compane account ID with sender ID to determine if message is mine
  const isMessageMine = sender_type === "merchant";

  const ref = useRef();
  const onScreen = useOnScreen(ref);

  // Prepare sender display name
  const getName = () => {
    if (isMessageMine) {
      if (sender_name)
        return t("pages.conversation.company", {
          sender_name,
          rentalServiceProviderName,
        });
      return rentalServiceProviderName;
    }
    if (sender_name) return sender_name;
    return t("pages.conversation.renter");
  };

  const name = getName();

  const hasOffer = (!!offer_id);

  return (
    <MessageContainer ref={ref} mine={isMessageMine}>
      <div className="topline">
        <p>{name}:</p>
        <p>{moment(sent_at).format(dateTimeFormat)}</p>
      </div>
      <p>{body}</p>
      <div className="attachments">
        {attachments.map((attachment) => {
          const tokens = attachment.filename.split(".");
          const fileExt = tokens[tokens.length - 1].toLowerCase();
          let icon = <FileOutlined height={20} width={20} />;
          if (fileExt === "pdf")
            icon = <FilePdfOutlined height={20} width={20} />;
          if (
            ["png", "jpg", "jpeg", "tiff", "bmp", ""].includes(fileExt)
          )
            icon = <FileImageOutlined height={20} width={20} />;

          return (
            <button
              type="button"
              key={attachment.id}
              onClick={() =>
                apiClient
                  .get(attachment.url, {
                    responseType: "blob",
                    params: { rentalServiceProviderId },
                  })
                  .then((res) => {
                    saveAs(res.data, attachment.filename);
                  })
              }
            >
              {icon}
              {attachment.filename}
            </button>
          );
        })}
      </div>
      <div className="offer">
        {hasOffer && <MessageOfferAttachment onScreen={onScreen} offerId={offer_id} />}
      </div>
    </MessageContainer>
  );
};

export default Message;
