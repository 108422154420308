import React, {useState} from 'react';
import { Table } from "antd";
import Card from "components/Card";
import useMatchBreakpoint from "../hooks/useMatchBreakpoint";
import {useTranslation} from "react-i18next";
import useFormatting from "../hooks/useFormatting";
import {useGetPaymentTransferCollection} from "../queries/paymentTransfers";
import {PaymentTransferStatusTag} from "./PaymentTransferStatusTag";
import {PaymentTransfersFilters} from "./PaymentTransfersFilters";

const PaymentTransfersCard = () => {
    const matchBreakpoint = useMatchBreakpoint();

    const { t } = useTranslation('common');
    const { formatDateTime, formatCents } = useFormatting();

    const [collectionParameters, setCollectionParameters] = useState({
        page: 1,
        limit: 10,
        searchQuery: null,
        filters: {
            state: null,
        },
        sort: null
    });

    const { data: paymentTransferCollection, isLoading: paymentTransferCollectionIsLoading } = useGetPaymentTransferCollection(collectionParameters);

    const onFilter = (filters) => {
        const searchQuery = filters.searchQuery === '' ? null : filters.searchQuery;
        const state = filters.state === '' ? null : filters.state;
        const collectionFilters = {
            state: state,
            createdAfter: filters.createdAfter,
            createdBefore: filters.createdBefore,
        };

        setCollectionParameters(prevState => {
            return {...prevState, searchQuery: searchQuery, filters: collectionFilters}
        });
    };

    const onChangeTable = (pagination, f, sorter) => {
        let sort = null;
        if (sorter.columnKey && sorter.order) {
            sort = (sorter.order === 'descend' ? '-' : '') + sorter.columnKey;
        }
        setCollectionParameters(prevState => {
            return {... prevState, page: pagination.current, limit: pagination.pageSize, sort: sort}
        })
    };

    const filters = {
        state: collectionParameters.filters.state ?? '',
        searchQuery: collectionParameters.searchQuery ?? '',
    };

    return (
        <Card
            style={{ marginBottom: '16px' }}
            title={t('components:payment-transfers-card.title')}
        >
            <PaymentTransfersFilters filters={filters} onChange={onFilter} />
            <Table
                loading={paymentTransferCollectionIsLoading}
                dataSource={paymentTransferCollection?.items}
                rowKey={"id"}
                scroll={{ x: 1080 }}
                onChange={onChangeTable}
                pagination={{
                    showLessItems: true,
                    total: paymentTransferCollection?.total,
                    showSizeChanger: true
                }}
            >
                <Table.Column
                    title={t('entities:payment-transfer.properties.transfer-group')}
                    dataIndex="transfer_group"
                    key="transfer_group"
                    sorter={true}
                    width={120}
                />
                <Table.Column
                    title={t('entities:payment-transfer.properties.created-at')}
                    dataIndex="created_at"
                    key="created_at"
                    sorter={true}
                    width={70}
                    render={(text) => {
                        return formatDateTime(text);
                    }}
                />
                <Table.Column
                    title={t('entities:payment-transfer.properties.transfer-at')}
                    dataIndex="created_at"
                    key="created_at"
                    sorter={true}
                    width={70}
                    render={(text) => {
                        return formatDateTime(text);
                    }}
                />
                <Table.Column
                    title={t('entities:payment-transfer.properties.net')}
                    dataIndex="net"
                    key="net"
                    sorter={true}
                    width={80}
                    render={(net, paymentTransfer) => {
                        return formatCents(net);
                    }}
                />
                <Table.Column
                    title={t('entities:payment-transfer.properties.state')}
                    dataIndex="state"
                    key="state"
                    fixed="right"
                    sorter={true}
                    align={matchBreakpoint({ xs: 'right', md: 'left' })}
                    width={matchBreakpoint({ xs: 100, md: 120 })}
                    render={(state, paymentTransfer) => {
                        return <PaymentTransferStatusTag state={state} small={matchBreakpoint({ xs: true, md: false})} />
                    }}
                />
            </Table>
        </Card>
    );
};

export default PaymentTransfersCard;
