import React from 'react';
import {useGetSubscriptionFeatures, useGetSubscriptionMetrics} from "../../queries/subscription";
import {useTranslation} from "react-i18next";

const ActiveRentalItemsStatus = () => {
    const { data: subscriptionMetrics } = useGetSubscriptionMetrics();
    const { data: subscriptionFeatures } = useGetSubscriptionFeatures();
    const { t } = useTranslation();

    const numberOfActiveRentalItems = subscriptionMetrics?.active_rental_items;
    const maxActiveRentalItems = subscriptionFeatures?.max_active_rental_items;

    if (!subscriptionMetrics || !subscriptionFeatures) {
        return <span></span>
    }

    return <span className={numberOfActiveRentalItems >= maxActiveRentalItems ? 'error-text': ''}>
        {t('components:subscription-active-rental-items-status.text', {'count': numberOfActiveRentalItems, 'max': maxActiveRentalItems})}
    </span>
}

export default ActiveRentalItemsStatus;