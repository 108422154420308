import React from 'react';
import styled from "styled-components";

const LinePlaceholder = styled.div`
    background-color: #F5F5F5;
    height: 15px;
    display: inline-block;
`;

const MIN_WIDTH=50;
const MAX_WIDTH=130;

const TextSkeleton = ({loading, children}) => {
    if(loading){
        return <LinePlaceholder style={{width: (MIN_WIDTH + (Math.random() * (MAX_WIDTH-MIN_WIDTH)) + 'px') }}/>;
    }

    return children ?? "";
};

export default TextSkeleton;
