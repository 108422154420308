import React, {useEffect, useState} from 'react';
import {Input, Select, Skeleton, Button, notification, Form, Switch, Descriptions, TimePicker} from "antd";
import {useTranslation} from "react-i18next";
import {useLocations, useAccounts} from "hooks";
import SubmitButton from "components/SubmitButton";
import InputCharacterCount from "components/InputCharacterCount";
import TextareaCharacterCount from "components/TextareaCharacterCount";
import moment from "moment";
import collect from "collect.js";
import ActiveLocationsStatus from "../../../../components/Subscription/ActiveLocationsStatus";
import {useGetSubscriptionFeatures, useGetSubscriptionMetrics} from "../../../../queries/subscription";
import {useQueryClient} from "react-query";
import MaxActiveLocationsReachedAlert from "../../../../components/Subscription/MaxActiveLocationsReachedAlert";

const {Option} = Select;

const timeFormat = 'HH:mm';
const weekdays = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];

const LocationForm = () => {
    const {t} = useTranslation('common');
    const {activeAccount} = useAccounts();
    const [form] = Form.useForm();

    const queryClient = useQueryClient();

    const {
        getCities,
        putLocation,
        createLocation,
        getLocations,
        isEditFormSaving,
        isEditFormDataLoaded,
        isCityDataLoaded,
        editFormPayload,
        closeEditForm,
        cities,
        currentPage,
        limit
    } = useLocations();

    const {
        id,
        active,
        name,
        email,
        address,
        postcode,
        city,
        phone,
        url,
        delivery_location,
        opening_hours
    } = editFormPayload;

    const [initiallyActive, setInitiallyActive] = useState(active);

    useEffect(() => {
        setInitiallyActive(editFormPayload.active);
    }, [editFormPayload]);

    const { data: subscriptionMetrics } = useGetSubscriptionMetrics();
    const { data: subscriptionFeatures } = useGetSubscriptionFeatures();

    const numberOfActiveLocations = subscriptionMetrics?.active_locations;
    const maxActiveLocations = subscriptionFeatures?.max_active_locations;

    const onFinish = (values) => {
        // opening hours handling
        const data = values.openingHours;

        let requestData = {};
        requestData.additionalText = values.additionalText;
        for (let i = 0, len = Object.keys(data).length; i < len; i++) {
            const weekday = Object.keys(data)[i];
            const record = data[weekday];

            if (record.timeFrom && record.timeTo) {
                requestData['openingTime' + weekday.charAt(0).toUpperCase() + weekday.slice(1)] = record.timeFrom.format(timeFormat);
                requestData['closingTime' + weekday.charAt(0).toUpperCase() + weekday.slice(1)] = record.timeTo.format(timeFormat);
            }
        }

        values.openingHours = requestData;

        // new location
        if (id === null) {
            createLocation(activeAccount.companyId, values)
                .then(async (res) => {
                    notification.open({
                        message: t('notification.messages.saved.title'),
                        description: t('notification.messages.saved.description'),
                    });

                    getLocations(activeAccount.companyId, currentPage - 1, limit); // reload table, currentPage - 1 because the table starts at page 1 :(

                    await queryClient.invalidateQueries('subscription-metrics');

                    closeEditForm();
                })
                .catch(e => {
                    console.log(e);
                    notification.open({
                        message: t('notification.messages.error.title'),
                        description: t('notification.messages.error.description'),
                    });
                    closeEditForm();
                });
        } else {
            putLocation(activeAccount.companyId, id, values)
                .then(async (res) => {
                    notification.open({
                        message: t('notification.messages.saved.title'),
                        description: t('notification.messages.saved.description'),
                    });

                    getLocations(activeAccount.companyId, currentPage - 1, limit); // reload table, currentPage - 1 because the table starts at page 1 :(

                    await queryClient.invalidateQueries('subscription-metrics');

                    closeEditForm();
                })
                .catch(e => {
                    console.log(e);
                    notification.open({
                        message: t('notification.messages.error.title'),
                        description: t('notification.messages.error.description'),
                    });
                    closeEditForm();
                });
        }
    };

    useEffect(() => {
        getCities()
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        form.resetFields(); // reload fields to initial value on re-render
    }, [editFormPayload]); // eslint-disable-line react-hooks/exhaustive-deps

    const getOpeningHoursByDay = (weekday) => {
        if (isEditFormDataLoaded) {
            return {
                timeFrom: opening_hours['opening_time_' + weekday],
                timeTo: opening_hours['closing_time_' + weekday],
            };
        }
    };
    
    const mappedWeekdays = weekdays.map((weekday, index) => {
        const data = getOpeningHoursByDay(weekday);

        return {
            ...data,
            weekday: weekday,
            closed: (typeof data === 'undefined')
        };
    });

    const initialOpeningHours = collect(mappedWeekdays).mapWithKeys((record) => {
        return [record.weekday, {
            timeFrom: (record.timeFrom && !record.closed ? moment(record.timeFrom, timeFormat) : undefined),
            timeTo: (record.timeTo && !record.closed ? moment(record.timeTo, timeFormat) : undefined)
        }]
    }).all();

    const initialAdditionalText = isEditFormDataLoaded ? opening_hours.additional_text : '';

    return (
        <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            initialValues={{
                active: active,
                name: name,
                email: email,
                address: address,
                postcode: postcode,
                city: city,
                phone: phone,
                url: url,
                deliveryLocation: delivery_location,
                additionalText: initialAdditionalText,
                openingHours: initialOpeningHours
            }}
        >
            <Skeleton loading={!isEditFormDataLoaded}>
                <Form.Item
                    name="name"
                    label={t('pages.company.locations-form.items.name.label')}
                    rules={[{required: true, message: t('form.errors.required')}]}
                >
                    <InputCharacterCount max={50} placeholder={t('pages.company.locations-form.items.name.placeholder')}/>
                </Form.Item>

                <Form.Item
                    name="email"
                    label={t('pages.company.locations-form.items.email.label')}
                    rules={[
                        {type: 'email', message: t('form.errors.invalid-email')}
                    ]}
                >
                    <Input placeholder={t('pages.company.locations-form.items.email.placeholder')}/>
                </Form.Item>

                <Form.Item
                    name="address"
                    label={t('pages.company.locations-form.items.address.label')}
                    rules={[{required: !delivery_location, message: t('form.errors.required')}]}
                    hidden={delivery_location}
                >
                    <Input placeholder={t('pages.company.locations-form.items.address.placeholder')}/>
                </Form.Item>

                <Form.Item
                    name="postcode"
                    label={t('pages.company.locations-form.items.postcode.label')}
                    rules={[{required: !delivery_location, message: t('form.errors.required')}]}
                    hidden={delivery_location}
                >
                    <Input placeholder={t('pages.company.locations-form.items.postcode.placeholder')}/>
                </Form.Item>

                <Form.Item
                    name="url"
                    label={t('pages.company.locations-form.items.url.label')}>
                    <Input placeholder={t('pages.company.locations-form.items.url.placeholder')}/>
                </Form.Item>

                <Form.Item
                    name="city"
                    label={t('pages.company.locations-form.items.city.label')}
                    rules={[{required: true, message: t('form.errors.required')}]}
                >
                    <Select
                        style={{width: '100%'}}
                        showSearch
                        loading={!isCityDataLoaded}
                        placeholder={t('pages.company.locations-form.items.city.placeholder')}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {cities.map((city, index) =>
                            <Option key={index} value={city.name}>
                                {city.name}
                            </Option>
                        )}
                    </Select>
                </Form.Item>

                <Form.Item label={t('pages.company.locations-form.items.country.label')}>
                    <Input disabled={true} value="Nederland"/>
                </Form.Item>

                <Form.Item
                    name="phone"
                    label={t('pages.company.locations-form.items.phone.label')}
                    rules={[
                        {required: true, message: t('form.errors.required')},
                        {pattern: "^((\\+|00(\\s|\\s?\\-\\s?)?)31(\\s|\\s?\\-\\s?)?(\\(0\\)[\\-\\s]?)?|0)[1-9]((\\s|\\s?\\-\\s?)?[0-9])((\\s|\\s?-\\s?)?[0-9])((\\s|\\s?-\\s?)?[0-9])\\s?[0-9]\\s?[0-9]\\s?[0-9]\\s?[0-9]\\s?[0-9]$", message: t('form.errors.invalid-phone')}
                    ]}
                >
                    <Input placeholder={t('pages.company.locations-form.items.phone.placeholder')}/>
                </Form.Item>

                {activeAccount.permissions.manage_location_activation.edit ?
                    <Form.Item
                        name="active"
                        valuePropName="checked"
                        label={<span>
                        {t('pages.company.locations-form.items.active.label')}{' '}
                            <ActiveLocationsStatus />
                    </span>}
                    >
                        <Switch disabled={!initiallyActive && numberOfActiveLocations >= maxActiveLocations} />
                    </Form.Item> : <></>
                }

                {!initiallyActive && (
                    <MaxActiveLocationsReachedAlert
                        style={{'marginBottom': '14px'}}
                        message={t('components:location-form.max-active-locations-reached-warning.message')}
                    />
                )}

                <Form.Item label={t('pages.company.opening-hours-form.items.opening-hours.label')}>
                    <Descriptions column={1} bordered={true}>
                        {mappedWeekdays.map((record, index) =>
                            <Descriptions.Item key={index} label={t('days.' + record.weekday + '.long')}>
                                <Form.Item>
                                    <Form.Item
                                        name={['openingHours', record.weekday, 'timeFrom']}
                                        noStyle
                                        dependencies={[['openingHours', record.weekday, 'timeTo']]}
                                        rules={[
                                            {required: false},
                                            ({ getFieldValue }) => ({
                                                validator(rule, value) {
                                                    const toFieldValue = getFieldValue(['openingHours', record.weekday, 'timeTo']);
                                                    if (
                                                        (toFieldValue && moment(value).isBefore(toFieldValue)) ||
                                                        (!value && !toFieldValue)
                                                    ) {
                                                        return Promise.resolve();
                                                    }
                                                    return Promise.reject(t('pages.company.opening-hours-form.form.errors.time-from-invalid'));
                                                },
                                            }),
                                        ]}
                                    >
                                        <TimePicker
                                            placeholder={t('pages.company.opening-hours-form.closed')}
                                            format={timeFormat}
                                            minuteStep={15}
                                            inputReadOnly={true}
                                        />
                                    </Form.Item>
                                    &nbsp; - &nbsp;
                                    <Form.Item
                                        name={['openingHours', record.weekday, 'timeTo']}
                                        noStyle
                                        dependencies={[['openingHours', record.weekday, 'timeFrom']]}
                                        rules={[
                                            {required: false},
                                            ({ getFieldValue }) => ({
                                                validator(rule, value) {
                                                    const fromFieldValue = getFieldValue(['openingHours', record.weekday, 'timeFrom']);
                                                    if (
                                                        (fromFieldValue && moment(value).isAfter(fromFieldValue)) ||
                                                        (!value && !fromFieldValue)
                                                    ) {
                                                        return Promise.resolve();
                                                    }
                                                    return Promise.reject(t('pages.company.opening-hours-form.form.errors.time-to-invalid'));
                                                },
                                            }),
                                        ]}
                                    >
                                        <TimePicker
                                            placeholder={t('pages.company.opening-hours-form.closed')}
                                            format={timeFormat}
                                            minuteStep={15}
                                            inputReadOnly={true}
                                        />
                                    </Form.Item>
                                </Form.Item>
                            </Descriptions.Item>
                        )}
                    </Descriptions>
                </Form.Item>

                <Form.Item
                    name="additionalText"
                    label={t('pages.company.opening-hours-form.items.additional-text.label')}
                    rules={[
                        {type: 'string', min: 0, max: 1500, message: t('form.errors.max-length')},
                        ({ getFieldValue }) => ({
                            validator(rule, value) {

                                if (value === null) return Promise.resolve();

                                let emailInstances = value.toString().match('(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))') ?? [];
                                let phoneInstances = value.toString().match('((\\+|00(\\s|\\s?\\-\\s?)?)31(\\s|\\s?\\-\\s?)?(\\(0\\)[\\-\\s]?)?|0)[1-9]((\\s|\\s?\\-\\s?)?[0-9])((\\s|\\s?-\\s?)?[0-9])((\\s|\\s?-\\s?)?[0-9])\\s?[0-9]\\s?[0-9]\\s?[0-9]\\s?[0-9]\\s?[0-9]') ?? [];
                                let urlInstances = value.toString().match('https?:\\/\\/(www\\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\\.[a-zA-Z0-9()]{1,6}\\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)') ?? [];

                                if (emailInstances.length < 1 && phoneInstances.length < 1 && urlInstances.length < 1) {
                                    return Promise.resolve();
                                }

                                return Promise.reject(t('form.errors.contact-details'));
                            },
                        }),
                    ]}
                >
                    <TextareaCharacterCount max={1500} rows={5} />
                </Form.Item>
            </Skeleton>

            <div className="actions">
                <Button onClick={() => closeEditForm()} style={{marginRight: 8}}>
                    {t('button.text.cancel')}
                </Button>
                <SubmitButton disabled={!isEditFormDataLoaded} loading={isEditFormSaving}>
                    {t('button.text.save')}
                </SubmitButton>
            </div>
        </Form>
    )
};

export default LocationForm;
