export default function (state, actions) {
    const stateKeys = Object.keys(state);
    const actionKeys = Object.keys(actions);

    if (stateKeys.every(elem => actionKeys.indexOf(elem) > -1)) {
        throw new Error(
            'Detected  overlapping in the keys between state and action functions.' +
            'This causes problems in the hooks because the action will overwrite the state ' +
            'with the same key.'
        );
    }
}
