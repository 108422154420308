import React, {useCallback, useState} from "react";
import {Trans, useTranslation} from 'react-i18next';
import {Divider, Form, notification, message, Modal, Upload} from "antd";
import { PlusOutlined } from '@ant-design/icons';
import {useRentalItem} from "hooks";
import Drawer from "components/Drawer";
import SubmitButton from "components/SubmitButton";
import {useAccounts} from "hooks";
import DragableUploadListItem from "components/DragableUploadListItem";

import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

const { forwardRef, useImperativeHandle } = React;

const RentalItemImageForm = forwardRef((props, ref) => {
    const [form] = Form.useForm();
    const [formOpen, setFormOpen] = useState(false);
    const [formImages, setFormImages] = useState([]);

    const [formState, setFormState] = useState({loading: false, filename: null, error: null});

    const {
        getRentalItem,
        putRentalItemImage,
        removeRentalItemImage,
        rearrangeRentalItemImages,
        payload: {
            images
        }
    } = useRentalItem();

    const {t} = useTranslation('common');
    const {activeAccount} = useAccounts();

    // Allows use of the openForm function from the parent component.
    useImperativeHandle(ref, () => ({
        openForm(images) {
            let mappedImages = [];
            images.forEach(e => mappedImages.push(mapRentalItemImage(e)))
            setFormImages(mappedImages);
            
            setFormOpen(true);
        }
    }));

    const mapRentalItemImage = (imageObject) => {
        return {
            uid: imageObject.id,
            status: 'done',
            url: imageObject.image_url,
            name: imageObject.alt_text
        }
    }

    const closeForm = () => {
        setFormOpen(false);
    };

    const onFinish = (values) => {
        closeForm();
    };

    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    }

    const handleChange = info => {
        if (info.file.status === 'uploading') {
            setFormState({loading: true, filename: null, error: null});
        }

        if (info.file.status === 'done') {
            getBase64(info.file.originFileObj, image => {
                setFormState({loading: false, filename: info.file.name, error: null});
            });
        }

        if (info.file.status === 'removed') {
            let params = new URLSearchParams(location.search); // eslint-disable-line
            removeRentalItemImage(activeAccount.companyId, params.get('id'), info.file.uid).then(res => {
                notification.open({
                    message: t('notification.messages.removed-image.title'),
                    description: t('notification.messages.removed-image.description'),
                });
            });
        }

        setFormImages(info.fileList);
    }

    // Dummy request to get the uploader to NOT send an XHR request.
    const dummyRequest = ({ file, onSuccess }) => {
        getBase64(file, image => {
            let params = new URLSearchParams(location.search); // eslint-disable-line
            putRentalItemImage(activeAccount.companyId, params.get('id'), image, file.name)
                .then(res => {
                    onSuccess("ok");

                    getRentalItem(activeAccount.companyId, params.get('id')).then(res => {
                        let mappedImages = [];
                        res.data.images.forEach(e => mappedImages.push(mapRentalItemImage(e)))
                        setFormImages(mappedImages);
                    });

                    notification.open({
                        message: t('notification.messages.saved-image.title'),
                        description: t('notification.messages.saved-image.description'),
                    });
                });
        });
    };

    const beforeUpload = (file) => {
        const isValidFormat = file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png';
        if (!isValidFormat) {
            message.error(t('pages.rentalitems.rental-item.images-form.validation.image-format'));
            setFormState({loading: false, filename: null, newImage: null, error: t('pages.rentalitems.rental-item.images-form.validation.image-format')})
        }
        const sizeValid = file.size / 1024 < 2048;
        if (!sizeValid) {
            message.error(t('pages.rentalitems.rental-item.images-form.validation.size'));
            setFormState({loading: false, filename: null, newImage: null, error: t('pages.rentalitems.rental-item.images-form.validation.image-format')})
        }

        if (!isValidFormat || !sizeValid) {
            file.status = 'error';
        }

        return isValidFormat && sizeValid;
    }

    const handleFileRemove = (file) => {
        const {confirm} = Modal

        if (file.status !== 'done') {
            return true;
        }

        return new Promise((resolve, reject) => {
            confirm({
                title: t('pages.rentalitems.rental-item.images-form.delete-confirmation'),
                onOk: () => {
                    resolve(true)
                },
                onCancel: () =>{
                  reject(true)
                }
            })
        })
    }

    const handleRearrangeFiles = (files) => {
        var orderedImages = files.map(e => e.uid);
        let params = new URLSearchParams(location.search); // eslint-disable-line
        rearrangeRentalItemImages(activeAccount.companyId, params.get('id'), orderedImages);
    }

    const moveRow = useCallback(
        (dragIndex: number, hoverIndex: number) => { 
            const newImages = [...formImages];
            const element = newImages.splice(dragIndex, 1)[0];
            newImages.splice(hoverIndex, 0, element);

            handleRearrangeFiles(newImages);
            setFormImages(newImages);
        },
        [formImages, setFormImages],
    );

    const uploadButton = (
      <div>
        <PlusOutlined />
        <div style={{ marginTop: 8 }}>Upload</div>
      </div>
    );

    const uploadProps = {
        name: "image",
        listType: "picture-card",
        accept: ".png,.jpg,.jpeg,.gif",
        className: "image-uploader",
        fileList: formImages,
        customRequest: dummyRequest,
        beforeUpload: beforeUpload,
        onChange: handleChange,
        onRemove: handleFileRemove,
        directory: false,
        maxCount: 15,
        multiple: false,
        showUploadList: {
            showPreviewIcon: false, 
            showDownloadIcon: false, 
            showRemoveIcon: true
        },
        itemRender: (originNode, file, currFileList) => (
            <DragableUploadListItem
                originNode={originNode}
                file={file}
                fileList={currFileList}
                moveRow={moveRow}
              />
        )
    }

    return (
        <Drawer
            form={form}
            title={t('pages.rentalitems.rental-item.images-form.title')}
            placement="right"
            onClose={() => closeForm()}
            open={formOpen}
            width={700}
        >
            <Form
                form={form}
                layout="vertical"
                onFinish={onFinish}
                initialValues={{
                }}
            >

                <Trans t={t} i18nKey="pages.rentalitems.rental-item.images-form.intro" components={[<ul />, <li />]}></Trans>
                <Divider/>
                <DndProvider backend={HTML5Backend}>
                    <Form.Item
                        label={t('pages.rentalitems.rental-item.images-form.heading')}
                        help={formState.error !== null ? formState.error : ''}
                        valuePropName='images'
                    >
                        <Upload
                            {...uploadProps}
                        >
                            {formImages.length >= 15 ? null : uploadButton}
                        </Upload>
                    </Form.Item>
                </DndProvider>

                <div className="actions">
                    <SubmitButton>
                        {t('button.text.save')}
                    </SubmitButton>
                </div>
            </Form>
        </Drawer>
    );
});

export default RentalItemImageForm;
