import React from 'react';
import {
    Statistic,
    Divider
} from "antd";
import {useTranslation} from "react-i18next";

const RatingStats = ({rating, count}) => {
    const { t } = useTranslation('common');

    return <>
        <Statistic
            decimalSeparator=","
            groupSeparator="."
            precision={2}
            title={t('pages.dashboard.stats.rating.rating')}
            value={rating}
        />
        <Divider/>
        <Statistic
            decimalSeparator=","
            groupSeparator="."
            title={t('pages.dashboard.stats.rating.count')}
            value={count}
        />
    </>;
};

export default RatingStats;
