import React, {useEffect, useState} from "react";
import {Button, Form, notification, Radio, Space} from "antd";
import {CheckOutlined} from "@ant-design/icons";
import {useTranslation} from "react-i18next";
import {useForm} from "antd/es/form/Form";
import {OrderCancellationReasons} from "../../types";
import TextArea from "antd/es/input/TextArea";
import {useCreateOrderTransition} from "../../queries/orders";

const OrderCancelForm = ({order, onFinish}) => {

    const { t } = useTranslation('common');
    const [form] = useForm();

    const [isSaving, setIsSaving] = useState(false);

    const createTransition = useCreateOrderTransition();

    useEffect(() => {
        form.resetFields();
    }, [order]);

    const onInnerFinish = async (values) => {
        await createTransition.mutateAsync({
            orderId: order.id,
            transition: {
                transition_name: 'cancel',
                arguments: values,
            },
        });

        notification.success({
            message: t('components:order-cancel-form.success-notification.message')
        });

        if (onFinish) {
            onFinish(values);
        }
    };

    const cancellationReasonChoices = [
        {
            label: t('entities:order.cancellation-reasons.else'),
            value: OrderCancellationReasons.ELSE
        }
    ];

    return (
        <>
            <Form
                form={form}
                layout="vertical"
                onFinish={onInnerFinish}
            >
                <Form.Item
                    label={t('entities:order.properties.cancellation-reason')}
                    name="cancellationReason"
                    rules={[
                        {required: true, message: t('form.errors.required')}
                    ]}
                >
                    <Radio.Group
                        optionType="default"
                        buttonStyle="solid"
                    >
                        <Space direction="vertical">
                            {cancellationReasonChoices.map(statusDetail => {
                                return <Radio value={statusDetail.value}>
                                    {statusDetail.label}
                                </Radio>
                            })}
                        </Space>
                    </Radio.Group>
                </Form.Item>

                <Form.Item
                    name="cancellationDescription"
                    label={t('entities:order.properties.cancellation-description')}
                >
                    <TextArea rows={6} />
                </Form.Item>

                <div className="actions">
                    <Button disabled={isSaving} htmlType="submit" type="primary">
                        <CheckOutlined/>
                        {t('button.text.save')}
                    </Button>
                </div>
            </Form>
        </>
    );
};

export default OrderCancelForm;