import * as Types from "./Types";

export const initialState = {
    isLoggedIn: false,
    loading: false,
    error: null,
};

export default function (state, action) {
    switch (action.type) {
        case Types.LOGIN:
            return {
                ...state,
                isLoggedIn: true,
                error: null,
                loading: false,
            };
        case Types.LOGOUT:
            return initialState;
        case Types.LOGIN_ERROR:
            return {
                ...state,
                isLoggedIn: false,
                error: action.error,
                loading: false,
            };
        case Types.LOGIN_LOADING:
            return {
                ...state,
                isLoggedIn: false,
                error: null,
                loading: true,
            };
        default:
            return state;
    }
};
