import React from "react";
import {useTranslation} from "react-i18next";
import {Button, Card, Col, notification, Row, Table} from "antd";
import {Money} from "components/Money";
import {InfoPopover} from "components/InfoPopover";
import Media from "react-media";
import {useAccounts, useAdvertisements, useAdvertisementsCart} from "hooks";
import {RightCircleOutlined} from "@ant-design/icons";
import {navigate} from "@reach/router";

const ConfirmStep = (props) => {
    const {t} = useTranslation('common');
    const {activeAccount} = useAccounts();

    const {
        cartItems
    } = useAdvertisementsCart();

    const {
        createAdvertisements
    } = useAdvertisements();

    const confirm = () => {
        const count = cartItems.length;
        createAdvertisements(activeAccount.companyId, cartItems);
        notification.open({
            message: (t('pages.advertisements.advertisements-wizard.table.confirm.success-title', {count: count})),
            description: (t('pages.advertisements.advertisements-wizard.table.confirm.success-message', {count:count}))
        });
        navigate('/advertisements');
    };

    const tableColumns = [
        {
            title: <>
                {t('pages.advertisements.advertisements-wizard.table.confirm.clickbox-category')}&nbsp;
                <InfoPopover content={t('pages.advertisements.advertisements-wizard.table.confirm.clickbox-category-info')} />
            </>,
            dataIndex: 'category',
            key: 'category',
            ellipsis: true,
            render: (text, record) => <Row type="flex" style={{alignItems: 'center'}}>
                <Col span={10}>
                    <img width={64} height={45} src={record.images[0]} style={{ marginRight: '1em', marginBottom: '1px', border: '1px solid #d9d9d9' }}/>
                </Col>
                <Col span={14}>
                    <Row>
                        <a href={record.url} target="_blank" rel="noopener noreferrer">
                            <h4 style={{color: 'rgba(0, 0, 0, 0.65)'}}>{record.title}</h4>
                        </a>
                    </Row>
                    <Row>
                        <a href={record.parent.url} target="_blank" rel="noopener noreferrer">
                            {record.parent.title}
                        </a>
                    </Row>
                </Col>
             </Row>
        },
        {
            title: <>
                {t('pages.advertisements.advertisements-wizard.table.confirm.click-rate')}&nbsp;
                <InfoPopover content={t('pages.advertisements.advertisements-wizard.table.confirm.click-rate-info')} />
            </>,
            dataIndex: 'click_out_rate',
            hideOnResponsive: true,
            key: 'click_out_rate',
            ellipsis: true,
            render: (text, record) => <Money digits="2" value={record.click_out_rate} />
        },
        {
            title: <>
                {t('pages.advertisements.advertisements-wizard.table.confirm.price')}&nbsp;
                <InfoPopover content={t('pages.advertisements.advertisements-wizard.table.confirm.price-info')} />
            </>,
            dataIndex: 'price',
            key: 'price',
            ellipsis: true,
            render: (text, record) => <> 
                <Money digits="2" value={record.price} />
                <span>&nbsp;{t('pages.advertisements.advertisements-form.items.price.period.' + record.rental_period_unit)} {t('pages.advertisements.advertisements-form.items.price.extra')}</span>
            </>

        },
        {
            title: <>
                {t('pages.advertisements.advertisements-wizard.table.confirm.url')}&nbsp;
                <InfoPopover content={t('pages.advertisements.advertisements-wizard.table.confirm.url-info')} />
            </>,
            dataIndex: 'click_out_url',
            key: 'click_out_url',
            ellipsis: true,
            render: (text, record) => <Button type="link" target="_blank" href={record.click_out_url}>{record.click_out_url}</Button>
        }
    ];

    return <Card size="small"
                 className="company-advertisements-wizard-select"
                 title={t('pages.advertisements.advertisements-wizard.steps.confirm.title')}
    >
        <Media query="(min-width: 950px)">
            {matches => {

                let filteredColumns = tableColumns.filter((column) => {
                    return matches || column.hideOnResponsive !== true;
                });

                return <Table
                    tableLayout="fixed"
                    dataSource={cartItems}
                    columns={filteredColumns}
                    rowKey="id"
                    pagination="false"
                />
            }}
        </Media>
        <Row>
            <Col span={12} align="left">
                <Button type="link"
                        onClick={props.prevStep}>{t('pages.advertisements.advertisements-wizard.steps.confirm.edit-advertisements')}</Button>
            </Col>
            <Col span={12} align="right">
                <Button size="large" type="primary" onClick={confirm}>{t('pages.advertisements.advertisements-wizard.steps.confirm.confirm')}&nbsp;<RightCircleOutlined /></Button>
            </Col>
        </Row>
    </Card>;
}

export default ConfirmStep;