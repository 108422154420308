import React, { Suspense } from "react";
import {
  DashboardOutlined,
  PieChartOutlined,
  ProfileOutlined,
  QuestionOutlined,
  SettingOutlined,
  ShopOutlined,
  MailOutlined,
  MessageOutlined,
  TagOutlined, CalendarOutlined,
} from "@ant-design/icons";
import { Menu } from "antd";
import { Link, Location } from "@reach/router";
import { useTranslation } from "react-i18next";
import { useAccounts, useSmallDeviceSize } from "hooks";
import {useFetchStatus} from "../queries/me";
import LanguageSelector from "components/LanguageSelector";
import { hasPermission } from "../helpers";

const SidebarMenu = ({ collapsed, onMenuItemSelect }) => {
  const { t } = useTranslation("common");
  const { activeAccount } = useAccounts();
  const isSmallDevice = useSmallDeviceSize();

  const { data: status } = useFetchStatus();

  var unreadConversationsCount = 0;
  if (status && 'conversations' in status) {
    unreadConversationsCount = status.conversations.length;
  }

  var unreadOffersCount = 0;
  if (status && 'offers' in status) {
    unreadOffersCount = status.offers.length;
  }

  var unreadRentalMatchesCount = 0;
  if (status && 'rental_matches' in status) {
    unreadRentalMatchesCount = status.rental_matches.length;
  }

  let actionableOrdersCount = 0;
  if (status && 'orders' in status) {
    actionableOrdersCount = status.orders.length;
  }

  const openKeys = !collapsed
    ? [
        "dashboard",
        "account",
        "profile",
        "advertisements",
        "statistics",
      ]
    : [];

  var languageSelector;
  if (isSmallDevice) {
    languageSelector = (
      <li className="submenu-language-selector">
        <Suspense fallback={null}>
          <LanguageSelector />
        </Suspense>
      </li>
    );
  }

  const menuItems = [
    {
      key: '/',
      label: (
          <Link to="/">
            <DashboardOutlined />
            <span>{t("sidebar.menu.items.dashboard.title")}</span>
          </Link>
      )
    },
    {
      key: 'profile',
      label: (
          <>
            <ShopOutlined />
            <span>{t("sidebar.menu.headers.profile")}</span>
          </>
      ),
      children: [
        {
          key: '/company/settings',
          disabled: !hasPermission(
              activeAccount,
              "manage_company_profile",
              "view"
          ),
          label: (
              <Link to="/company/settings">
                {t("sidebar.menu.items.company-settings.title")}
              </Link>
          )
        },
        {
          key: '/rentalitems',
          disabled: !hasPermission(
              activeAccount,
              "manage_rental_items",
              "view"
          ),
          label: (
              <Link to="/rentalitems">
                {t("sidebar.menu.items.rental-shop-overview.title")}
              </Link>
          )
        },
        {
          key: '/company/locations',
          disabled: !hasPermission(
              activeAccount,
              "manage_locations",
              "view"
          ),
          label: (
              <Link to="/company/locations">
                {t("sidebar.menu.items.company-locations.title")}
              </Link>
          )
        },
        {
          key: '/company/reviews',
          disabled: !hasPermission(
              activeAccount,
              "manage_reviews",
              "view"
          ),
          label: (
              <Link to="/company/reviews">
                {t("sidebar.menu.items.company-reviews.title")}
              </Link>
          )
        }
      ]
    },
    {
      key: 'advertisements',
      label: (
          <>
            <ProfileOutlined />
            <span>
                    {t("sidebar.menu.headers.advertisements")}
                  </span>
          </>
      ),
      children: [
        {
          key: '/advertisements',
          disabled: !hasPermission(activeAccount, "manage_ads", "view"),
          label: (
              <Link to="/advertisements">
                {t("sidebar.menu.items.advertisements.title")}
              </Link>
          )
        },
        {
          key: '/advertisements/create',
          disabled: !hasPermission(activeAccount, "manage_ads", "create"),
          label: (
              <Link to="/advertisements/create">
                {t("sidebar.menu.items.advertisement-wizard.title")}
              </Link>
          )
        },
        {
          key: '/advertisements/budget',
          disabled: !hasPermission(
              activeAccount,
              "manage_daily_budget",
              "view"
          ),
          label: (
              <Link to="/advertisements/budget">
                {t("sidebar.menu.items.budget.title")}
              </Link>
          )
        }
      ]
    },
    {
      key: '/rental-matches',
      disabled: !hasPermission(activeAccount, "manage_offers", "view"),
      label: (
          <Link to="/rental-matches">
            <MailOutlined />
            <span>
              {t("sidebar.menu.items.quotations.title")}{" "}
              {unreadRentalMatchesCount > 0 && `(${unreadRentalMatchesCount})`}
            </span>
          </Link>
      )
    },
    {
      key: '/orders',
      disabled: !hasPermission(activeAccount, "manage_offers", "view"),
      label: (
          <Link to="/orders">
            <CalendarOutlined />
            <span>
                    {t("sidebar.menu.items.orders.title")}{" "}
              {actionableOrdersCount > 0 && `(${actionableOrdersCount})`}
                  </span>
          </Link>
      )
    },
    {
      key: '/offers',
      disabled: !hasPermission(activeAccount, "manage_offers", "view"),
      label: (
          <Link to="/offers">
            <TagOutlined />
            <span>
                        {t("sidebar.menu.items.offers.title")}{" "}
              {unreadOffersCount > 0 && `(${unreadOffersCount})`}
                      </span>
          </Link>
      )
    },
    {
      key: '/messages',
      disabled: !hasPermission(activeAccount, "manage_offers", "view"),
      label: (
          <Link to="/messages">
            <MessageOutlined />
            <span>
                    {t("sidebar.menu.items.messages.title")}{" "}
              {unreadConversationsCount > 0 && `(${unreadConversationsCount})`}
                  </span>
          </Link>
      )
    },
    {
      key: 'statistics',
      label: (
          <>
            <PieChartOutlined />
            <span>{t("sidebar.menu.headers.statistics")}</span>
          </>
      ),
      children: [
        {
          key: '/statistics',
          label: (
              <Link to="/statistics">
                {t("sidebar.menu.items.statistics-overview.title")}
              </Link>
          )
        },
        {
          key: '/statistics/advertisement',
          label: (
              <Link to="/statistics/advertisement">
                {t(
                    "sidebar.menu.items.statistics-advertisements.title"
                )}
              </Link>
          )
        },
        {
          key: '/statistics/location',
          label: (
              <Link to="/statistics/location">
                {t("sidebar.menu.items.statistics-locations.title")}
              </Link>
          )
        },
        {
          key: '/statistics/product',
          label: (
              <Link to="/statistics/product">
                {t("sidebar.menu.items.statistics-products.title")}
              </Link>
          )
        }
      ]
    },
    {
        key: 'settings',
        disabled: !hasPermission(activeAccount, "manage_users", "view"),
        label: (
            <>
                <SettingOutlined />
                <span>{t("sidebar.menu.headers.settings")}</span>
            </>
        ),
        children: [
            {
                key: 'settings/users',
                label: (
                    <Link to='/settings/users'>
                        {t("sidebar.menu.items.users.title")}
                    </Link>
                )
            }
        ]
    },
    {
      key: 'help',
      label: (
          <>
            <QuestionOutlined />
            <span>{t("sidebar.menu.headers.help")}</span>
          </>
      ),
      children: [
        {
          key: '/help/contact',
          label: (
              <Link to="/help/contact">
                {t("sidebar.menu.items.contact.title")}
              </Link>
          )
        },
        {
          key: '/help/faq',
          label: (
              <a
                  href="https://intercom.help/hurennl/nl/collections/8448915-ik-ben-een-verhuurder"
                  target="_blank"
                  rel="noopener noreferrer"
              >
                {t("sidebar.menu.items.faq.title")}
              </a>
          )
        },
        {
          key: '/help/promo',
          label: (
              <Link to="/help/promo">
                {t("sidebar.menu.items.promo.title")}
              </Link>
          )
        },
        {
          key: '/help/ebooks',
          disabled: !hasPermission(activeAccount, "manage_ebooks", "view"),
          label: (
              <Link to="/help/ebooks">
                {t("sidebar.menu.items.ebooks.title")}
              </Link>
          )
        }
      ]
    },
    {
      key: 'language-selector',
      label: languageSelector
    }
  ];

  return (
      <Location>
        {({ location }) => {
          return (
              <Menu
                  mode="inline"
                  forceSubMenuRender={true}
                  defaultOpenKeys={openKeys}
                  selectedKeys={[
                    location.pathname.includes("messages")
                        ? "/messages"
                        : location.pathname,
                  ]}
                  style={{ borderRight: 0 }}
                  onClick={onMenuItemSelect}
                  items={menuItems}
              >
              </Menu>
          );
        }}
      </Location>
  );
};

export default SidebarMenu;
