import React, {useEffect} from 'react';
import {
    Alert,
    Button,
    Col,
    Divider,
    Form,
    Input,
    InputNumber,
    notification,
    Row,
    Select,
    Skeleton, Switch
} from "antd";
import gtag from 'ga-gtag';
import {Trans, useTranslation} from 'react-i18next';
import Drawer from "components/Drawer";
import {useAccounts, useAdvertisements} from "hooks";
import SubmitButton from "components/SubmitButton";
import {Money} from "components/Money";
import {hasPermission} from "../../../../helpers";

const { Option } = Select;

const AdvertisementsForm = () => {
    const {activeAccount} = useAccounts();
    const [form] = Form.useForm();
    let prefixValue = '';

    const {
        putAdvertisement,
        getAdvertisements,
        isEditFormOpen,
        isEditFormSaving,
        isEditFormDataLoaded,
        editFormPayload,
        closeEditForm,
        currentPage,
        limit
    } = useAdvertisements();

    const {t} = useTranslation('common');

    const onFinish = (values) => {
        if (values.clickOutUrl !== undefined && values.clickOutUrl !== '') {
            values.clickOutUrl = prefixValue + values.clickOutUrl;
        }

        putAdvertisement(activeAccount.companyId, id, values)
            .then((res) => {
                var trackingString = '';
                if (values.click_out_rate > click_out_rate) {
                    trackingString = 'increased';
                } else if (values.click_out_rate < click_out_rate) {
                    trackingString = 'decreased';
                }

                if (trackingString) {
                    gtag('event', 'changed_click_price', {
                        event_category: 'MA_edit_ad',
                        event_label: trackingString
                    });
                }

                notification.open({
                    message: t('notification.messages.saved.title'),
                    description: t('notification.messages.saved.description'),
                });

                getAdvertisements(activeAccount.companyId, currentPage -1, limit); // reload table, currentPage - 1 because the table starts at page 1 :(

                closeEditForm();
            })
            .catch(e => {
                console.log(e);
                notification.open({
                    message: t('notification.messages.error.title'),
                    description: t('notification.messages.error.description'),
                });
                closeEditForm();
            });
    };

    let {
        id,
        active,
        category,
        rental_item,
        price,
        click_out_url,
        click_out_rate,
        ad_url_was_generated,
        is_rental_shop_ad
    } = editFormPayload;

    useEffect(() => {
        if (isEditFormDataLoaded) {
            form.resetFields(); // reload fields to initial value on re-render
            form.setFieldsValue({
                clickOutUrl: ad_url_was_generated ? '' : click_out_url.replace(/(http:\/\/|https:\/\/)/g, ''),
                price: price ? price.toFixed(2) : price,
            });
        }
    }, [editFormPayload]); // eslint-disable-line react-hooks/exhaustive-deps

    const selectBefore = () => {
        if (isEditFormDataLoaded) {
            let prefix = click_out_url && !click_out_url.includes('https://') ? 'http://' : 'https://';
            prefixValue = prefix;
            return <Select onChange={(value) => prefixValue = value} defaultValue={prefix} className="select-before">
                <Option value="https://">https://</Option>
                <Option value="http://">http://</Option>
            </Select>
        }
    };

    return <Drawer
        title={t('pages.advertisements.advertisements-form.title')}
        open={isEditFormOpen}
        onClose={() => closeEditForm()}
        width={520}
    >
        <Skeleton loading={!isEditFormDataLoaded}>
            {isEditFormDataLoaded ? <>
                <Row gutter={[0, 24]}>
                    {
                        category.images.map((image, index) => (
                            <Col key={index}><img width={64} height={45} key={'image_' + index} size={64} src={image} style={{ marginRight: '1em', border: '1px solid #d9d9d9' }} /></Col>
                        ))
                    }
                </Row>
                <Row gutter={[0, 24]}>
                    <Col sm={12} md={8}>
                        {t('pages.advertisements.advertisements-form.meta.category')}
                    </Col>
                    <Col sm={12} md={16}>
                        <Button type="link" target="_blank" href={category.url} style={{padding:0}}>{category.title}</Button>
                    </Col>
                </Row>
                <Row gutter={[0, 24]}>
                    <Col sm={12} md={8}>
                        {t('pages.advertisements.advertisements-form.meta.parent')}
                    </Col>
                    <Col sm={12} md={16}>
                        <Button type="link" target="_blank" href={category.parent.url} style={{padding:0}}>{category.parent.title}</Button>
                    </Col>
                </Row>
                { hasPermission(activeAccount, 'manage_click_prices', 'view') ?
                    <Row gutter={[0, 24]}>
                        <Col sm={12} md={8}>
                            {t('pages.advertisements.advertisements-form.meta.click-out-rate')}
                        </Col>
                        <Col sm={12} md={16}>
                            <Money value={click_out_rate} />
                        </Col>
                    </Row>
                    : <></>
                }
                {hasPermission(activeAccount, 'manage_ad_urls', 'view') ?
                <Row gutter={[0, 24]}>
                    <Col sm={12} md={8}>
                        {t('pages.advertisements.advertisements-form.meta.click-out-url')}
                    </Col>
                    <Col sm={12} md={16}>
                        <a href={click_out_url} target="_blank" rel="noopener noreferrer">{click_out_url}</a>
                    </Col>
                </Row> : <></>}
            </> : <></>
            }
        </Skeleton>

        <Divider />

        <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            initialValues={{
                category: category,
                rentalItem: rental_item,
                price: price,
                clickOutUrl: ad_url_was_generated ? '' : click_out_url,
                clickOutRate: click_out_rate,
                active: active,
                adUrlWasGenerated: ad_url_was_generated
            }}
        >
            <Skeleton loading={!isEditFormDataLoaded}>
                <Form.Item label={t('pages.advertisements.advertisements-form.items.price.label')}>
                    <Form.Item
                        name="price"
                        noStyle
                        rules={[{required: true, message: t('form.errors.required')}]}
                    >
                        <InputNumber decimalSeparator="," precision={2}
                                     formatter={value => `€ ${value}`.replace(/^€\d+(,\d{2})$/g, ',')}
                                     parser={value => value.replace(/€\s?/g, '')} />
                    </Form.Item>

                    {category !== undefined ?
                        <span>&nbsp;{t('pages.advertisements.advertisements-form.items.price.period.' + category.rental_period_unit)} {t('pages.advertisements.advertisements-form.items.price.extra')}</span> : <></>
                    }
                </Form.Item>
                {hasPermission(activeAccount, 'manage_ad_urls', 'edit') ?
                    (ad_url_was_generated) ?
                        (rental_item) ?
                        <Trans t={t} i18nKey={'pages.advertisements.advertisements-form.items.rental-item.url'}>Deze advertentie is gekoppeld aan het product "{rental_item.name}"</Trans>
                        :
                        <Trans t={t} i18nKey={'pages.advertisements.advertisements-form.items.rental-item.category'}>Deze advertentie is gekoppeld aan de categorie "{category.name}" en toont automatisch relevante producten</Trans>
                    :
                    <Form.Item
                        name="clickOutUrl"
                        label={t('pages.advertisements.advertisements-form.items.click-out-url.label')}
                        rules={
                            [{
                                required: !is_rental_shop_ad,
                                pattern: /^(?:\S+(?::\S*)?@)?(?:(?!10(?:\.\d{1,3}){3})(?!127(?:\.​\d{1,3}){3})(?!169\.254(?:\.\d{1,3}){2})(?!192\.168(?:\.\d{1,3}){2})(?!172\.(?:1[​6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1​,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00​a1-\uffff0-9]+-?)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]+-?)*[a-z\u​00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/[^\s]*)?$/i,
                                message: t('form.errors.invalid-url')
                            }]}
                    >
                        <Input addonBefore={selectBefore()}
                               placeholder={t('pages.advertisements.advertisements-form.items.click-out-url.placeholder')}/>
                    </Form.Item>
                 : <></>}
                {hasPermission(activeAccount, 'manage_ad_activation', 'edit') ?
                    <Form.Item
                        name="active"
                        valuePropName="checked"
                        label={t('pages.advertisements.advertisements-form.items.active.label')}
                        style={{marginBottom: '40px'}}>
                        <Switch />
                    </Form.Item> : <></>
                }
            </Skeleton>

            <div className="actions">
                <Button onClick={() => closeEditForm()} style={{marginRight: 8}}>
                    {t('button.text.cancel')}
                </Button>
                <SubmitButton disabled={!isEditFormDataLoaded} loading={isEditFormSaving}>
                    {t('button.text.save')}
                </SubmitButton>
            </div>
        </Form>
    </Drawer>;
};

export default AdvertisementsForm;
