import {useGlobalStore} from 'store';

import bindActions from 'store/bindActions';
import testOverlap from 'store/testOverlap';
import faqReducer from 'store/faq';

const { Actions } = faqReducer;

const useFaq = () => {
    const {state, dispatch} = useGlobalStore();

    const {faq} = state;

    // Test if there's an overlapping between the actions and store
    testOverlap(faq, Actions);

    return {
        ...bindActions(Actions, dispatch),
        ...faq
    };
};

export default useFaq;
