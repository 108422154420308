import React from 'react';
import {
    Statistic,
    Divider
} from "antd";
import {useTranslation} from "react-i18next";
import {InfoPopover} from "components/InfoPopover";

const ClickStats = ({clicks_this_month, calls_this_month, clicks_last_month, calls_last_month}) => {
    const { t } = useTranslation('common');

    return <>
        <Statistic
            title={t('pages.dashboard.stats.click.this-month')}
            value={clicks_this_month}
            decimalSeparator=","
            groupSeparator="."
            suffix={
                <InfoPopover content={t('pages.dashboard.stats.click.calls-info', {calls: calls_this_month})} />
            }
        />
        <Divider/>
        <Statistic
            title={t('pages.dashboard.stats.click.previous-month')}
            value={clicks_last_month}
            decimalSeparator=","
            groupSeparator="."
            suffix={
                <InfoPopover content={t('pages.dashboard.stats.click.calls-info',  {calls: calls_last_month})} />
            }
        />
    </>;
};

export default ClickStats;
