import * as Types from "./Types";

export const initialState = {
    isDataLoaded: false,
    payload: {},
    isEditFormOpen: false
};

export default function (state, action) {
    switch (action.type) {
        case Types.SET_IS_DATA_LOADED:
            return {
                ...state,
                isDataLoaded: action.isDataLoaded
            };
        case Types.EDIT_FORM_OPEN:
            return {
                ...state,
                isEditFormOpen: true,
            };
        case Types.EDIT_FORM_CLOSE:
            return {
                ...state,
                isEditFormOpen: false,
            };
        case Types.SET_DATA:
            return {
                ...state,
                payload: action.payload,
                isDataLoaded: true
            };
        default:
            return state;
    }
};
