import {useMediaQuery} from "react-responsive";

const useMatchBreakpoint = () => {
    const isXs = useMediaQuery({ minWidth: 481 });
    const isSm = useMediaQuery({ minWidth: 577 });
    const isMd = useMediaQuery({ minWidth: 769 });
    const isLg = useMediaQuery({ minWidth: 993 });
    const isXl = useMediaQuery({ minWidth: 1201 });
    const isXxl = useMediaQuery({ minWidth: 1601 });

    return (matchers) => {

        let currentBreakpoint = 'xs';
        let breakpointsToCheck = ['xxl', 'xl', 'lg', 'md', 'sm', 'xs'];
        if (isXxl) {
            currentBreakpoint = 'xxl';
        } else if(isXl) {
            currentBreakpoint = 'xl';
        } else if (isLg) {
            currentBreakpoint = 'lg';
        } else if(isMd) {
            currentBreakpoint = 'md';
        } else if(isSm) {
            currentBreakpoint = 'sm';
        } else if(isXs) {
            currentBreakpoint = 'xs';
        }
        let foundBreakpoint = false;

        for (var i = 0; i < breakpointsToCheck.length; i++) {
            const breakpoint = breakpointsToCheck[i];
            if (breakpoint === currentBreakpoint) {
                foundBreakpoint = true;
            }
            if (!foundBreakpoint) {
                continue;
            }
            if (breakpoint in matchers) {
                return matchers[breakpoint];
            }
        }
        return null;
    };
};

export default useMatchBreakpoint;
