import React, {useEffect} from 'react'
import TokenStorage from "../../services/TokenStorage";

const Logout = () => {

  useEffect(()=>{
    TokenStorage.clear();
    window.Intercom('shutdown');
  },[])

  return <div></div>
}

export default Logout