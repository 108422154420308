import React from 'react';
import LoggedInLayout from "layout/LoggedInLayout";

import {useTranslation} from "react-i18next";
import {LocationsCard} from "./components/LocationsCard";
import {Button} from "antd";
import {PlusOutlined} from "@ant-design/icons";
import {useAccounts, useLocations} from "hooks";
import {hasPermission} from "../../helpers";

const CompanyLocations = () => {
    const { t } = useTranslation('common');
    const {activeAccount} = useAccounts();

    const {
        editLocation
    } = useLocations();

    return <LoggedInLayout pageTitle={t('pages.company.locations.title')} headerExtras={
        [
            <Button key="1" type="primary" disabled={!hasPermission(activeAccount, 'manage_locations', 'create')} onClick={() => editLocation(activeAccount.companyId, null)}>
                <PlusOutlined />
                {t('pages.company.locations.new-location-button')}
            </Button>,
        ]
    }>
        <LocationsCard />
    </LoggedInLayout>;
};

export default CompanyLocations;
