import { logger } from "./middlewares";
import authReducer from "./auth";
import accountsReducer from "./accounts";
import advertisementsReducer from "./advertisements";
import advertisementsCartReducer from "./advertisementsCart";
import categoriesReducer from "./categories";
import dailyBudgetReducer from "./dailyBudget";
import advertisementBudgetReducer from "./advertisementBudget";
import contactDetailsReducer from "./contactDetails";
import invoiceDetailsReducer from "./invoiceDetails";
import paymentDetailsReducer from "./paymentDetails";
import locationsReducer from "./locations";
import logoReducer from "./logo";
import openingHoursReducer from "./openingHours";
import profileReducer from "./profile";
import promoReducer from "./promo";
import rentalItemsReducer from "./rentalItems";
import rentalItemReducer from "./rentalItem";
import dashboardStatsReducer from "./dashboardStats";
import subscriptionReducer from "./subscription";
import faqReducer from "./faq";
import reviewReducer from "./reviews";
import invoiceReducer from "./invoices";
import statisticsReducer from "./statistics";
import localeReducer from "./locale";
import cancellationReducer from "./cancellation";
import inboxReducer from "./inbox";
import inboxPollReducer from "./inboxPoll";
import conversationReducer from "./conversation";
import featuresReducer from "./features";
import { LOGOUT } from "./auth/Types";
import chatReducer from "./chat";

// We are actually creating a combinedReducer method originating from the Redux library.
// This way we have a single application state, which is accessible from anywhere in the application.
// In here we can also add middleware.
//
// I would like to point out the following articles:
// 1. https://medium.com/strands-tech-corner/react-state-management-without-redux-d39c7087039d
// 2. https://github.com/bexic/react-without-redux

export const initialState = {
  version: { app_hash: process.env.REACT_APP_VERSION_HASH },
  auth: authReducer.initialState,
  accounts: accountsReducer.initialState,
  categories: categoriesReducer.initialState,
  advertisements: advertisementsReducer.initialState,
  advertisementsCart: advertisementsCartReducer.initialState,
  dailyBudget: dailyBudgetReducer.initialState,
  advertisementBudget: advertisementBudgetReducer.initialState,
  contactDetails: contactDetailsReducer.initialState,
  invoiceDetails: invoiceDetailsReducer.initialState,
  paymentDetails: paymentDetailsReducer.initialState,
  locations: locationsReducer.initialState,
  logo: logoReducer.initialState,
  openingHours: openingHoursReducer.initialState,
  profile: profileReducer.initialState,
  promo: promoReducer.initialState,
  rentalItems: rentalItemsReducer.initialState,
  rentalItem: rentalItemReducer.initialState,
  dashboardStats: dashboardStatsReducer.initialState,
  subscription: subscriptionReducer.initialState,
  faq: faqReducer.initialState,
  reviews: reviewReducer.initialState,
  statistics: statisticsReducer.initialState,
  invoices: invoiceReducer.initialState,
  locale: localeReducer.initialState,
  cancellation: cancellationReducer.initialState,
  inbox: inboxReducer.initialState,
  inboxPoll: inboxPollReducer.initialState,
  conversation: conversationReducer.initialState,
  features: featuresReducer.initialState,
  chat: chatReducer.initialState,
};

export default function rootReducer(state, action) {
  const {
    auth,
    accounts,
    advertisements,
    advertisementsCart,
    categories,
    dailyBudget,
    advertisementBudget,
    contactDetails,
    invoiceDetails,
    paymentDetails,
    locations,
    logo,
    openingHours,
    profile,
    promo,
    rentalItems,
    rentalItem,
    dashboardStats,
    subscription,
    faq,
    reviews,
    invoices,
    statistics,
    locale,
    cancellation,
    inbox,
    inboxPoll,
    conversation,
    features,
    chat
  } = state;

  let currentState;
  if (action.type === LOGOUT) {
    currentState = initialState;
  } else {
    currentState = {
      version: { app_hash: process.env.REACT_APP_VERSION_HASH },
      auth: authReducer.Reducer(auth, action),
      accounts: accountsReducer.Reducer(accounts, action),
      advertisements: advertisementsReducer.Reducer(
        advertisements,
        action
      ),
      advertisementsCart: advertisementsCartReducer.Reducer(
        advertisementsCart,
        action
      ),
      categories: categoriesReducer.Reducer(categories, action),
      dailyBudget: dailyBudgetReducer.Reducer(dailyBudget, action),
      advertisementBudget: advertisementBudgetReducer.Reducer(
        advertisementBudget,
        action
      ),
      contactDetails: contactDetailsReducer.Reducer(
        contactDetails,
        action
      ),
      invoiceDetails: invoiceDetailsReducer.Reducer(
        invoiceDetails,
        action
      ),
      paymentDetails: paymentDetailsReducer.Reducer(
        paymentDetails,
        action
      ),
      locations: locationsReducer.Reducer(locations, action),
      logo: logoReducer.Reducer(logo, action),
      openingHours: openingHoursReducer.Reducer(openingHours, action),
      profile: profileReducer.Reducer(profile, action),
      promo: promoReducer.Reducer(promo, action),
      rentalItems: rentalItemsReducer.Reducer(rentalItems, action),
      rentalItem: rentalItemReducer.Reducer(rentalItem, action),
      dashboardStats: dashboardStatsReducer.Reducer(
        dashboardStats,
        action
      ),
      subscription: subscriptionReducer.Reducer(subscription, action),
      faq: faqReducer.Reducer(faq, action),
      reviews: reviewReducer.Reducer(reviews, action),
      invoices: invoiceReducer.Reducer(invoices, action),
      statistics: statisticsReducer.Reducer(statistics, action),
      locale: localeReducer.Reducer(locale, action),
      cancellation: cancellationReducer.Reducer(cancellation, action),
      inbox: inboxReducer.Reducer(inbox, action),
      inboxPoll: inboxPollReducer.Reducer(inboxPoll, action),
      conversation: conversationReducer.Reducer(conversation, action),
      features: featuresReducer.Reducer(features, action),
      chat: chatReducer.Reducer(chat, action),
    };
  }
  logger(action, state, currentState);

  return currentState;
}
