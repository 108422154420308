import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import {Steps} from "antd";
import {CheckSquareOutlined, LinkOutlined, SolutionOutlined} from "@ant-design/icons";
import SelectStep from "./Steps/Select";
import LinkStep from "./Steps/Link";
import ConfirmStep from "./Steps/Confirm";

const AdvertisementsWizard = () => {
    const {t} = useTranslation('common');
    const [current, setCurrent] = useState(0);

    const next = () => {
        setCurrent(current + 1);
    }

    const prev = () => {
        setCurrent(current - 1);
    }

    const onStepClick = (stepClicked) => {
        if (stepClicked <= current) {
            setCurrent(stepClicked);
        }
    }

    const steps = [
        {
            key: 'select',
            title: t('wizard.steps.select'),
            content: <SelectStep nextStep={next} />,
            icon: <CheckSquareOutlined />
        },
        {
            key: 'link',
            title: t('wizard.steps.link'),
            content: <LinkStep nextStep={next} prevStep={prev} />,
            icon: <LinkOutlined />
        },
        {
            key: 'finish',
            title: t('wizard.steps.finish'),
            content: <ConfirmStep prevStep={prev} />,
            icon: <SolutionOutlined />
        },
    ];

    return <>
        <Steps type="navigation" current={current} onChange={onStepClick} items={steps} />
        {steps[current].content}
    </>
};

export default AdvertisementsWizard;
