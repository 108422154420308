import React from "react";
import {useTranslation} from "react-i18next";
import {InfoPopover} from "./InfoPopover";

const ClickPriceInfo = () => {
    const {t} = useTranslation('common');

    return <InfoPopover
        content={<>
            <div>
                {t('components.click-price-info.text')}
            </div>
        </>}
    />;
};

export default ClickPriceInfo;