import React, {useEffect, useRef, useState} from 'react';
import LoggedInLayout from "layout/LoggedInLayout";
import {useTranslation} from "react-i18next";
import {navigate, useParams} from "@reach/router";
import client from "services/HurenApiClient";
import Card from "components/Card";
import {Alert, Button, Col, Drawer, Modal, notification, Row, Skeleton, Space} from "antd";
import {CheckOutlined, CloseOutlined, PlusOutlined} from "@ant-design/icons";
import {OffersTable} from "./components/OffersTable";
import {Trans} from "react-i18next";
import useMatchBreakpoint from "../../hooks/useMatchBreakpoint";
import {QuotationCard} from "../../components/QuotationCard/QuotationCard";
import {useGetRentalMatch, useGetRentalMatchOffers} from "../../queries/rentalMatches";
import {canManageQuotationOffers} from "../../helpers/quotations";
import {QuotationStatus} from "../../types";
import RentalMatchTransitionForm from "../../components/RentalMatchTransitionForm/RentalMatchTransitionForm";
import {
    useCreateOrderTransition,
    useGetEnabledTransitions as useGetEnabledOrderTransitions
} from "../../queries/orders";
import {isTransitionEnabled} from "../../helpers/transitions";
import OrderCancelForm from "../../components/Order/OrderCancelForm";

const RentalMatch = () => {
	const { t } = useTranslation('common');
    const { id } = useParams();

    const messagesRef = useRef();
    const offersRef = useRef();

    const { data: rentalMatch, refetch: refetchRentalMatch } = useGetRentalMatch(id);
    const isAccepted = rentalMatch && rentalMatch.status === 'accepted';
    const { data: offers, isFetching: offersIsLoading } = useGetRentalMatchOffers(id, {
        enabled: !!isAccepted,
    });
    const isRejected = rentalMatch && rentalMatch.status === 'rejected';
    const [transitioning, setTransitioning] = useState(false)
    const [enabledTransitions, setEnabledTransitions] = useState(null);
    const [cancelDrawerVisible, setCancelDrawerVisible] = useState(false);
    const [selectedTransition, setSelectedTransition] = useState(null);
    const matchBreakpoint = useMatchBreakpoint();

    const isInOrderState = !!rentalMatch?.quotation.order;

    const { data: enabledOrderTransitions } = useGetEnabledOrderTransitions(rentalMatch?.quotation.order?.id, {
        enabled: isInOrderState
    });
    const createOrderTransition = useCreateOrderTransition();

    function scrollToComponent() {
        if (window.location.hash === '#messages' && messagesRef.current) {
            messagesRef.current.scrollIntoView();
        }
        if (window.location.hash === '#offers' && offersRef.current) {
            offersRef.current.scrollIntoView();
        }
    }
    useEffect( () => {
        scrollToComponent();
    }, [messagesRef.current, offersRef.current] )

    const loadData = async () => {
        client.get(`/api/v2/rental-matches/${id}/enabled-transitions`).then(res => {
            setEnabledTransitions(res.data.items);
            setTransitioning(false);
        });
    }

    const transition = (transitionName) => {
        setSelectedTransition(transitionName);
        if (transitionName === 'accept') {
            setTransitioning(true);

            client.post(`/api/v2/rental-matches/${id}/transitions`, {
                transition_name: transitionName
            }).then(res => {
                refetchRentalMatch();
                loadData();
            });
        } else {
            setDrawerVisible(true);
        }
    }

    useEffect(() => {
        if (!rentalMatch) {
            return;
        }
        loadData();
    }, [rentalMatch]);

    let name = null;
    if (rentalMatch) {
        name = rentalMatch.quotation.first_name + " " + rentalMatch.quotation.last_name;
    }

    const [drawerVisible, setDrawerVisible] = useState(false);

    const onFinishTransitionForm = (transition) => {
        setDrawerVisible(false);
        refetchRentalMatch();
        loadData();
    };

    let actions = null;
    if (isInOrderState) {
        actions = (
            <Button
                disabled={!isTransitionEnabled(enabledOrderTransitions, 'cancel')}
                onClick={() => setCancelDrawerVisible(true)}
                danger
            >
                {t('pages:rental-match.actions.cancel')}
            </Button>
        );
    }

    return (
        <LoggedInLayout
            pageTitle={
                <>
                    <Trans t={t} ns="common" i18nKey="pages.rental-match.title">Huuraanvraag van {{name}}</Trans>
                    <Space/> ({rentalMatch && rentalMatch.quotation.id})
                </>
            }
            headerExtras={actions}
        >
            {rentalMatch?.quotation.status === QuotationStatus.WITHDRAWN && (
                <Alert type="warning" style={{'marginBottom': '14px'}} message={t('pages:rental-match.quotation-is-withdrawn')} />
            )}
            {rentalMatch?.quotation.has_order && !rentalMatch.quotation.order && (
                <Alert type="warning" style={{'marginBottom': '14px'}} message={t('pages:rental-match.renter-accepted-offer-from-other-merchant')} />
            )}
            {rentalMatch?.status === QuotationStatus.REJECTED && (
                <Alert type="warning" style={{'marginBottom': '14px'}} message={t('pages:rental-match.rental-match-rejected')} />
            )}
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <QuotationCard quotation={rentalMatch?.quotation} rentalMatch={rentalMatch}/>
                </Col>
            </Row>
            <Row gutter={[16, 16]}>
                <Col align="middle" span={24}>
                    {!enabledTransitions ? <Skeleton.Button active={true} loading={true}/> :
                        <>
                            {enabledTransitions.map(enabledTransition => {

                                const getType = (transitionName) => {
                                    if (transitionName === 'accept' || transitionName === 'mark_reserved') {
                                        return 'primary';
                                    }
                                    return 'secondary';
                                }

                                const getIcon = (transitionName) => {
                                    if (transitionName === 'accept' || transitionName === 'mark_reserved') {
                                        return <CheckOutlined />;
                                    }

                                    if (transitionName === 'reject' || transitionName === 'mark_not_reserved') {
                                        return <CloseOutlined />;
                                    }
                                }

                                return <Button
                                    key={enabledTransition.name}
                                    type={getType(enabledTransition.name)}
                                    onClick={() => transition(enabledTransition.name)}
                                    icon={getIcon(enabledTransition.name)}
                                    disabled={transitioning}
                                    style={{marginRight: '8px', verticalAlign: 'middle'}}
                                >
                                    {t('entities:rental-match.transition.' + enabledTransition.name)}
                                </Button>
                            })}
                        </>
                    }
                </Col>
            </Row>

            {!rentalMatch ?
                <>
                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <Card title={t('pages.rental-match.offers.title')}>
                                <Skeleton loading={true} active={true} paragraph={{'rows': 1}} />
                            </Card>
                        </Col>
                        <Col span={24}>
                            <Card title={t('pages.rental-match.messages.title')}>
                                <Skeleton loading={true} active={true} paragraph={{'rows': 1}} />
                            </Card>
                        </Col>
                    </Row>
                </>
                :
                <></>
            }

            {rentalMatch && isAccepted ?
                <>
                    <Row gutter={[16, 16]}>
                        <Col md={24}>
                            <Card
                                title={t('pages.rental-match.offers.title')}
                                extra={
                                    <Button type="primary" disabled={!canManageQuotationOffers(rentalMatch.quotation)} onClick={() => navigate(`/rental-matches/${rentalMatch.id}/create-offer`)}>
                                        <PlusOutlined/>
                                        {t('pages.rental-match.offers.add')}
                                    </Button>
                                }
                            >
                                <span ref={offersRef}></span>
                                <OffersTable showActions={canManageQuotationOffers(rentalMatch.quotation)} loading={offersIsLoading} offers={offers}/>
                            </Card>
                        </Col>
                    </Row>
                </> :
                <></>
            }

            {rentalMatch && !isAccepted && !isRejected ?
                <>
                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <Card title={t('pages.rental-match.offers.title')}>
                                {t('pages.rental-match.offers.not-accepted')}
                            </Card>
                        </Col>
                        <Col span={24}>
                            <Card title={t('pages.rental-match.messages.title')}>
                                {t('pages.rental-match.messages.not-accepted')}
                            </Card>
                        </Col>
                    </Row>
                </>
                 :
                <></>
            }

            <Drawer
                title={t('entities:rental-match.transition.reject')}
                placement="right"
                open={drawerVisible}
                width={520}
                layout="vertical"
                onClose={() => {
                    setDrawerVisible(false);
                }}
            >
                {rentalMatch && <RentalMatchTransitionForm transitionName={selectedTransition} onFinish={onFinishTransitionForm} rentalMatch={rentalMatch}/>}
            </Drawer>

            <Drawer
                title={t('entities:order.transition.cancel')}
                placement="right"
                open={cancelDrawerVisible}
                width={matchBreakpoint({ sm: '100%', md: 520})}
                layout="vertical"
                onClose={() => {
                    setCancelDrawerVisible(false);
                }}
            >
                {rentalMatch?.quotation.order && <OrderCancelForm onFinish={() => setCancelDrawerVisible(false)} order={rentalMatch?.quotation.order}/>}
            </Drawer>
        </LoggedInLayout>
    );
};

export default RentalMatch;
