export const getOrderStatus = (order, accountStatus) => {
    const defaultStatus = {
        id: order.id,
        state: order.state,
        unread_messages_count: 0,
    };
    if (!accountStatus) {
        return defaultStatus;
    }
    const foundStatus = accountStatus.orders.find((orderStatus) => orderStatus.id === order.id);
    if (foundStatus) {
        return foundStatus;
    }

    return defaultStatus;
}