import {useGlobalStore} from 'store';

import bindActions from 'store/bindActions';
import testOverlap from 'store/testOverlap';
import rentalItemsReducer from 'store/rentalItems';

const { Actions } = rentalItemsReducer;

const useRentalItems = () => {
    const {state, dispatch} = useGlobalStore();

    const {rentalItems} = state;

    // Test if there's an overlapping between the actions and store
    testOverlap(rentalItems, Actions);

    return {
        ...bindActions(Actions, dispatch),
        ...rentalItems
    };
};

export default useRentalItems;
