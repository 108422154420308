import React, {useState, useEffect} from "react";
import {Line} from "react-chartjs-2";
import {useTranslation} from "react-i18next";
import moment from "moment";
import {useSmallDeviceSize} from "hooks";

const ClickGraph = (graphData) => {
    const {t} = useTranslation('common');
    const [chartData, setChartData] = useState({});

    useEffect(() => {
        setChartData(getChartData(graphData));
    }, [graphData]); // eslint-disable-line react-hooks/exhaustive-deps

    const getChartData = (data) => {
        return {
            labels: data.graphData.labels.map(label => moment(label).format('DD-MM')),
            datasets: [
                {
                    label: t("charts.click-graph.label"),
                    yAxisID: 'clicks',
                    fill: true,
                    lineTension: 0.1,
                    backgroundColor: "rgba(75,192,192,0.4)",
                    borderColor: "#0192c3",
                    borderCapStyle: "butt",
                    borderDash: [],
                    borderDashOffset: 0.0,
                    borderJoinStyle: "miter",
                    pointBorderColor: "#0192c3",
                    pointBackgroundColor: "#fff",
                    pointBorderWidth: 3,
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: "#0192c3",
                    pointHoverBorderColor: "#0192c3",
                    pointHoverBorderWidth: 2,
                    pointRadius: 0,
                    pointHitRadius: 10,
                    data: data.graphData.points
                }
            ]
        }
    };

    const isSmallDeviceSize = useSmallDeviceSize();
    let chartHeight = 120;
    if (isSmallDeviceSize) {
        chartHeight = 280;
    }

    return <Line
        borderColor="#0192c3"
        height={chartHeight}
        data={chartData}
        options={{
            maintainAspectRatio: true,
            scales: {
                xAxes: [
                    {
                        gridLines: {
                            display:false,
                        }
                    }
                ],
                yAxes: [
                    {
                        id: 'clicks',
                        type: 'linear',
                        position: 'left',
                        ticks: {
                            beginAtZero: true,
                            min: 0
                        },
                        gridLines: {
                            borderDash: [2, 2],
                        },
                    }
                ]
            },
            legend: {
                display: true,
                position: 'bottom'
            }
        }}
    />
};

export default ClickGraph;
