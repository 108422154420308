import React, {useEffect, useState} from "react";
import {Trans, useTranslation} from 'react-i18next';
import {CheckOutlined, CloseOutlined} from '@ant-design/icons';
import gtag from 'ga-gtag';
import {Alert, Button, Divider, Form, InputNumber, notification, Skeleton, Switch, Typography} from "antd";
import {PremiumInfo} from "components/Premium";
import Drawer from "components/Drawer";
import {Money} from "components/Money";
import SubmitButton from "components/SubmitButton";
import {useAccounts, useDailyBudget} from "hooks";

const {Paragraph} = Typography;

const BudgetForm = (props) => {
    const [form] = Form.useForm();
    const {getFieldValue, validateFields} = form;
    const {unlimitedBudget, currentDailyLimit} = props;

    const {t} = useTranslation('common');
    const {activeAccount} = useAccounts();

    const [switchUnlimitedDailyBudget, setSwitchUnlimitedDailyBudget] = useState(false);

    const {
        putBudget,
        getEditFormData,
        isEditFormOpen,
        isEditFormSaving,
        isEditFormDataLoaded,
        closeEditForm,
        editFormPayload: {
            unlimited_budget,
            current_daily_limit,
            minimum_daily_budget
        }
    } = useDailyBudget();

    const onFinish = (values) => {
        validateFields()
            .then(values => {
                let newUnlimited = getFieldValue('unlimitedDailyBudget');
                let newBudget = getFieldValue('dailyBudget');

                var eventLabel = '';
                if (newBudget > currentDailyLimit) {
                    eventLabel = 'increased';
                } else if (newBudget > currentDailyLimit) {
                    eventLabel = 'decreased';
                } else if (newUnlimited && newUnlimited !== unlimitedBudget) {
                    eventLabel = 'activated';
                } else if (!newUnlimited && newUnlimited !== unlimitedBudget) {
                    eventLabel = 'deactivated';
                }

                gtag('event', 'changed_budget', {
                    event_category: 'ma_budget',
                    event_label: eventLabel
                });

                putBudget(activeAccount.companyId, newUnlimited, newBudget).then((res) => {
                    notification.open({
                        message: newUnlimited ? t('pages.advertisements.budget-form.success-unlimited') : (<Trans t={t} i18nKey={'pages.advertisements.budget-form.success-budget'}>Your new daily budget of <Money value={newBudget} /> has been activated.</Trans>),
                    });
                    closeEditForm();
                })
            })
            .catch(errorInfo => {
            });
    };

    useEffect(() => {
        getEditFormData(activeAccount.companyId);
    }, [isEditFormOpen]);  // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setSwitchUnlimitedDailyBudget(unlimited_budget);
    }, [unlimited_budget]); // eslint-disable-line react-hooks/exhaustive-deps


    return (
        <Drawer
            form={form}
            title={<><PremiumInfo/> {t('pages.advertisements.budget-form.title')}</>}
            placement="right"
            onClose={() => closeEditForm()}
            open={isEditFormOpen}
            width={520}
        >
            <Skeleton loading={!isEditFormDataLoaded}>
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={onFinish}
                    initialValues={{
                        unlimitedDailyBudget: unlimited_budget,
                        dailyBudget: current_daily_limit
                    }}
                >
                    <Paragraph>
                        {t('pages.advertisements.budget-form.content.intro')}
                        <ul style={{margin: '10px 0'}}>
                            <li>{t('pages.advertisements.budget-form.content.list.click-prices')}</li>
                            <li>{t('pages.advertisements.budget-form.content.list.always-visible')}</li>
                            <li>{t('pages.advertisements.budget-form.content.list.every-day')}</li>
                        </ul>
                        {t('pages.advertisements.budget-form.content.outro')}
                    </Paragraph>

                    <Divider/>

                    <Form.Item
                        name="unlimitedDailyBudget"
                        label={t('pages.advertisements.budget-form.items.unlimited-daily-budget.label')}
                        help={t('pages.advertisements.budget-form.items.unlimited-daily-budget.help')}
                        valuePropName="checked"
                    >
                        <Switch
                            checkedChildren={(<CheckOutlined/>)}
                            unCheckedChildren={(<CloseOutlined/>)}
                            onChange={(value) => setSwitchUnlimitedDailyBudget(value)}
                        />
                    </Form.Item>

                    {!switchUnlimitedDailyBudget && (<>
                        <Form.Item
                            name="dailyBudget"
                            label={t('pages.advertisements.budget-form.items.daily-budget.label')}
                            extra={t('pages.advertisements.budget-form.items.daily-budget.help')}
                            rules={[
                                {
                                    type: "number",
                                    min: minimum_daily_budget,
                                    message: (<Trans t={t} i18nKey={'pages.advertisements.budget-form.items.daily-budget.error'}>Your daily budget cannot be less than <Money value={minimum_daily_budget} /></Trans>)
                                }
                            ]}
                        >
                            <InputNumber decimalSeparator="," precision={2}
                                         formatter={value => `€ ${value}`.replace(/^€\d+(,\d{2})$/g, ',')}
                                         parser={value => value.replace(/€\s?/g, '')} />
                        </Form.Item>
                        {minimum_daily_budget > 0 && <Alert
                            style={{marginBottom: '14px'}}
                            type="warning"
                            showIcon
                            message={(<Trans t={t} i18nKey="pages.advertisements.budget-form.items.alert-minimum-daily-budget">
                                Within your subscription there's a minimum of <Money value={minimum_daily_budget}/> budget. It is not possible to setup a daily budget that is lower then <Money value={minimum_daily_budget}/>.
                            </Trans>)}
                        />}
                    </>)}

                    <div className="actions">
                        <Button onClick={() => closeEditForm()} style={{marginRight: '8px'}}>
                            {t('button.text.cancel')}
                        </Button>
                        <SubmitButton disabled={!isEditFormDataLoaded} loading={isEditFormSaving} >
                            {t('button.text.save')}
                        </SubmitButton>
                    </div>
                </Form>
            </Skeleton>
        </Drawer>
    );
};

export default BudgetForm;
