import React, {useEffect} from 'react';
import { EditOutlined } from '@ant-design/icons';
import { Button, Descriptions } from "antd";
import Card from "components/Card";
import {useTranslation} from "react-i18next";
import OriginalTextSkeleton from "components/TextSkeleton";
import {useProfile, useAccounts} from "hooks";
import {hasPermission} from "../../../../helpers";
import ProfileDrawer from "./ProfileDrawer";

const TextSkeleton = (props) => {
    const {
        isDataLoaded,
    } = useProfile();
    return  <OriginalTextSkeleton {...props} loading={!isDataLoaded}/>
};

const ProfileCard = () => {
    const {t} = useTranslation('common');
    const {activeAccount} = useAccounts();

    const {
        getCompanyProfile,
        openEditForm,
        isDataLoaded,
        payload: {
            name,
            profile_description,
            delivery_costs_description,
            shipping_details_description,
            unique_selling_points
        }
    } = useProfile();

    useEffect(() => {
        getCompanyProfile(activeAccount)
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return <>
        <ProfileDrawer/>
        <Card
            className="company-profile-card"
            title={t('pages.company.company-profile-card.title')}
            actions={[
                <Button disabled={!hasPermission(activeAccount, 'manage_company_profile', 'edit')} onClick={() => openEditForm()}>
                    <EditOutlined /> {t('button.text.edit')}
                </Button>
            ]}
        >
            <Descriptions column={1} className="label-50-percent">
                <Descriptions.Item key={1} label={t('pages.company.company-profile-card.label.company-name.label')}>
                    <TextSkeleton>{name}</TextSkeleton>
                </Descriptions.Item>
                <Descriptions.Item key={2} label={t('pages.company.company-profile-card.label.profile-text.label')}>
                    <TextSkeleton>
                        <div dangerouslySetInnerHTML={{ __html: profile_description }} />
                    </TextSkeleton>
                </Descriptions.Item>
                <Descriptions.Item key={3} label={t('pages.company.company-profile-card.label.delivery-text.label')}>
                    <TextSkeleton>{delivery_costs_description}</TextSkeleton>
                </Descriptions.Item>
                <Descriptions.Item key={4} label={t('pages.company.company-profile-card.label.shipping-text.label')}>
                    <TextSkeleton>{shipping_details_description}</TextSkeleton>
                </Descriptions.Item>
                <Descriptions.Item key={4} label={t('pages.company.company-profile-card.label.unique-selling-points.label')}>
                    <TextSkeleton>
                        <ul>
                            {unique_selling_points?.map((uniqueSellingPoint, index) => {
                                return (
                                    <li key={index}>{uniqueSellingPoint}</li>
                                )
                            })}
                        </ul>
                    </TextSkeleton>
                </Descriptions.Item>
            </Descriptions>
        </Card>
    </>;
};

export default ProfileCard;
