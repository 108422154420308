import React, {useEffect} from 'react';
import {useTranslation} from "react-i18next";
import {useAccounts, useDailyBudget} from "hooks";
import {Button, Card} from "antd";
import {EditOutlined} from "@ant-design/icons";
import {DailyBudgetInfo} from "components/DailyBudgetInfo";
import BudgetAlert from "components/BudgetAlert";
import BudgetForm from "./BudgetForm";
import {hasPermission} from "../../../../helpers";

const BudgetCard = () => {
    const {t} = useTranslation('common');
    const {activeAccount} = useAccounts();

    const {
        getBudgetData,
        openEditForm,
        isDataLoaded,
        payload: {
            unlimited_budget,
            current_daily_limit,
            budget_used_today
        }
    } = useDailyBudget();

    useEffect(() => {
        getBudgetData(activeAccount.companyId)
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return <>
        <BudgetForm unlimitedBudget={unlimited_budget} currentDailyLimit={current_daily_limit} />
        <Card
            loading={!isDataLoaded}
            className="account-budget-card"
            size="small"
            title={t('pages.advertisements.budget-card.title')}
            actions={[
                <Button disabled={!hasPermission(activeAccount, 'manage_daily_budget', 'edit')} onClick={() => openEditForm()}>
                    <EditOutlined /> {t('button.text.change-daily-limit')}
                </Button>
            ]}
        >
            <BudgetAlert unlimited_budget={unlimited_budget} budget_used_today={budget_used_today} current_daily_limit={current_daily_limit} />
            <DailyBudgetInfo unlimited_budget={unlimited_budget} budget_used_today={budget_used_today} current_daily_limit={current_daily_limit} />
        </Card>
    </>;
}

export default BudgetCard;