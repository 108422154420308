import React from 'react';
import {useGetSubscriptionFeatures, useGetSubscriptionMetrics} from "../../queries/subscription";
import {Alert} from "antd";

const MaxActiveLocationsReachedAlert = ({ message, style }) => {
    const { data: subscriptionMetrics } = useGetSubscriptionMetrics();
    const { data: subscriptionFeatures } = useGetSubscriptionFeatures();

    const numberOfActiveLocations = subscriptionMetrics?.active_locations;
    const maxActiveLocations = subscriptionFeatures?.max_active_locations;

    if (!subscriptionMetrics || !subscriptionFeatures) {
        return <span />
    }

    if (numberOfActiveLocations >= maxActiveLocations) {
        return <Alert
            style={style}
            type="warning"
            message={message}
        />
    }

    return <span />
}

export default MaxActiveLocationsReachedAlert;