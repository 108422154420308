import React, {useEffect, useState} from "react";
import Media from "react-media";
import {Badge, Button, Table, Tag, Space} from "antd";
import moment from "moment";
import {MessageOutlined, EyeOutlined} from "@ant-design/icons";
import {navigate} from "@reach/router";
import humanizeDuration from "humanize-duration";
import {useTranslation} from "react-i18next";
import {useSmallDeviceSize} from "hooks";
import {hasPermission} from "helpers";
import {useAccounts, useChat} from "hooks";
import RentalMatchStatus from "components/RentalMatchStatus";
import {QuotationStatusTag} from "../../../components/QuotationStatusTag";
import {useFetchStatus} from "../../../queries/me";

export const RentalMatchesTable = ({rentalMatches, loading, total, onChange}) => {
    const { t, i18n } = useTranslation('common');
    const isSmallDevice = useSmallDeviceSize();
    const dateTimeFormat = "DD-MM-YYYY HH:mm";
    const {activeAccount} = useAccounts();
    const { doOpenChat } = useChat();

    const { data: status } = useFetchStatus();

    const humanizer = humanizeDuration.humanizer({
        language: i18n.language,
        serialComma: false,
        conjunction: i18n.language === "nl" ? " en " : " and "
    });

    const getQuotationProductName = (quotation) => {
        if (quotation.rental_item) {
            return quotation.rental_item.name;
        }
        return quotation.clickbox_category.title;
    };

    const tableColumns = [
        {
            title: t('tables.rental-matches.column.created-at'),
            dataIndex: 'created_at',
            key: 'created_at',
            ellipsis: true,
            sorter: true,
            render: (text, record) => record.quotation.created_at ?
                <>{moment(record.quotation.created_at).format(dateTimeFormat)}</> :
                <></>
        },
        {
            title: t('tables.rental-matches.column.name'),
            dataIndex: 'first_name',
            key: 'name',
            hideOnResponsive: true,
            sorter: true,
            render: (text, record) => record.quotation.first_name + ' ' + record.quotation.last_name
        },
        {
            title: t('tables.rental-matches.column.product'),
            dataIndex: 'clickbox_category_name',
            key: 'product_name',
            sorter: (a, b) => getQuotationProductName(a.quotation).localeCompare(getQuotationProductName(b.quotation)),
            render: (text, record) => getQuotationProductName(record.quotation)
        },
        {
            title: t('tables.rental-matches.column.rental-date'),
            dataIndex: 'rental_start_date',
            key: 'date_from',
            hideOnResponsive: true,
            sorter: true,
            render: (text, record) => record.quotation.date_from
                ? <>{moment(record.quotation.date_from).format(dateTimeFormat)}</> :
                <>-</>
        },
        {
            title: t('tables.rental-matches.column.rental-duration'),
            dataIndex: 'rental_duration',
            key: 'date_until',
            hideOnResponsive: true,
            render: (text, rentalMatch) => {
                if (rentalMatch.quotation.date_from === null || rentalMatch.quotation.date_until === null) {
                    return t('entities:quotation.for-unknown-rental-duration');
                }
                return humanizer(moment(rentalMatch.quotation.date_from).diff(moment(rentalMatch.quotation.date_until)));
            }
        },
        {
            title: t('tables.rental-matches.column.status'),
            dataIndex: 'status',
            key: 'status',
            hideOnResponsive: true,
            sorter: true,
            render: (text, rentalMatch) => {
                return <RentalMatchStatus status={rentalMatch.status} />
            }
        },
        {
            title: t('tables.rental-matches.column.quotation-status'),
            dataIndex: 'quotation_status',
            key: 'quotation_status',
            hideOnResponsive: true,
            render: (text, rentalMatch) => {
                return <QuotationStatusTag quotation={rentalMatch.quotation} />;
            }
        },
        {
            align: 'right',
            title: t('tables.rental-matches.column.actions'),
            dataIndex: 'actions',
            key: 'actions',
            render: (text, record) => {
                let unreadMessagesCount = 0;
                if (status && status.rental_matches) {
                    let rentalMatchStatus = status.rental_matches.find(obj => {
                        return obj.id === record.id
                    });
                    if (rentalMatchStatus) {
                        unreadMessagesCount = rentalMatchStatus.unread_messages_count;
                    }
                }
                
                return <Space size={0} direction={"horizontal"}>
                    <Button
                        style={{marginLeft:'0.25rem'}}
                        className={isSmallDevice ? 'small-button' : ''}
                        disabled={!hasPermission(activeAccount, 'manage_offers', 'edit')}
                        onClick={() => navigate('/rental-matches/' + record.id)}
                    >
                        {t('button.text.view')}
                    </Button>
                    <Button
                        disabled={!record.conversation}
                        size="middle"
                        onClick={(e) => {
                            doOpenChat(record.conversation.id);
                        }}
                    >
                        <Badge size="small" count={unreadMessagesCount}>
                            <MessageOutlined/>
                        </Badge>
                    </Button>
                </Space>
            }
        }
    ];

    return (
        <Media query="(min-width: 950px)">
            {matches => {
                let filteredColumns = tableColumns.filter((column) => {
                    return matches || column.hideOnResponsive !== true;
                });

                return <Table
                    loading={loading}
                    tableLayout="auto"
                    dataSource={rentalMatches}
                    columns={filteredColumns}
                    onChange={onChange}
                    rowKey="id"
                    pagination={{
                        showLessItems: true,
                        total: total,
                        showSizeChanger: true
                    }}
                />
            }}
        </Media>
    )
};