import React from "react";
import {Card, Descriptions, Typography} from "antd";
import {useTranslation} from "react-i18next";
import useFormatting from "../../../hooks/useFormatting";
import useMatchBreakpoint from "../../../hooks/useMatchBreakpoint";
import {Money} from "../../../components/Money";
import {PaymentTransferStatusTag} from "../../../components/PaymentTransferStatusTag";

export const OrderPaymentTransferCard = ({order: order, isLoading: isLoading}) => {
    const { t } = useTranslation('common');
    const matchBreakpoint = useMatchBreakpoint();
    const { formatDateTime} = useFormatting();
	const paymentTransfer = order.payment.payment_transfer;
	const commissionAmount = paymentTransfer.commission_incl / 100;
	const commissionPercentage = parseFloat((commissionAmount / paymentTransfer.amount_incl) * 10000).toFixed(2) ;
	return <Card
            className={'order-card ant-card-small'}
            title={t('components:order-details.payment.title')}
            extra={<PaymentTransferStatusTag state={paymentTransfer.state} small={matchBreakpoint({ xs: true, md: false})} />}
	    >
	        <Descriptions column={1} bordered={true}>
				<Descriptions.Item label={t('components:order-details.payment.table.process_date')}>
					{formatDateTime(paymentTransfer.transfer_at)}
				</Descriptions.Item>
				<Descriptions.Item label={t('components:order-details.payment.table.amount')}>
					<Money value={paymentTransfer.net/100} />
				</Descriptions.Item>
                <Descriptions.Item label={t('components:order-details.payment.table.explanation')}>
					{t('components:order-details.payment.table.explanation-details.total')} <Money value={paymentTransfer.amount_incl/100}/><br/>
					<Typography><small>{t('components:order-details.payment.table.explanation-details.commission')} <Money value={-commissionAmount}></Money>{"( " + commissionPercentage + "% )"} {t('components:order-details.payment.table.explanation-details.incl-vat')}</small></Typography>
					<small>{t('components:order-details.payment.table.explanation-details.admin-cost')} <Money value={-paymentTransfer.administration_costs_incl/100}></Money> {t('components:order-details.payment.table.explanation-details.incl-vat')}</small><br/>
					<strong>{t('components:order-details.payment.table.explanation-details.net')} <Money value={paymentTransfer.net/100} /></strong>
				</Descriptions.Item>
            </Descriptions>
    </Card>;
};