import React, {useState} from 'react';
import LoggedInLayout from "layout/LoggedInLayout";
import {useTranslation} from "react-i18next";
import {navigate, useParams} from "@reach/router";
import Card from "components/Card";
import {Col, Row} from "antd";
import {QuotationCard} from "../../components/QuotationCard/QuotationCard";
import OfferForm from "../../components/OfferForm/OfferForm";
import {notification} from "antd";
import {useGetRentalMatch} from "../../queries/rentalMatches";
import {useCreateOffer} from "../../queries/offers";

const CreateOffer = () => {
	const { t } = useTranslation('common');
    const { id } = useParams();
    const { data: rentalMatch } = useGetRentalMatch(id);
    const createOffer = useCreateOffer();
    const [offerFormDisabled, setOfferFormDisabled] = useState(false);

    const isAccepted = rentalMatch && rentalMatch.status === 'accepted';

    const onFinish = async (offer) => {
        setOfferFormDisabled(true);
        await createOffer.mutate({
            rentalMatchId: id,
            offer: offer
        });
        await navigate(`/rental-matches/${id}#offers`);
        notification.success({
            message: t('notifications:create-offer.success.message'),
            description: t('notifications:create-offer.success.description'),
        });
    };

    const onCancel = () => {
        navigate(`/rental-matches/${id}#offers`);
    };    

    return (
        <LoggedInLayout
            pageTitle={t('pages.create-offer.title')}
        >
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <QuotationCard quotation={rentalMatch?.quotation} rentalMatch={rentalMatch}/>
                </Col>
            </Row>

            {rentalMatch && isAccepted ?
                <Card title={t('pages.create-offer.offer-form-card.title')}>
                    <OfferForm
                        mode="new"
                        disabled={offerFormDisabled}
                        rentalMatch={rentalMatch}
                        onFinish={onFinish}
                        onCancel={onCancel}
                    />
                </Card>
                :
                <></>
            }
        </LoggedInLayout>
    );
};

export default CreateOffer;
