import React, {useEffect, useState, useRef} from 'react';
import {EditOutlined} from "@ant-design/icons";
import {useTranslation} from "react-i18next";
import {Badge, Button, Col, Descriptions, notification, Row} from "antd";
import OriginalTextSkeleton from "components/TextSkeleton";
import Card from "components/Card";
import {useAccounts, useRentalItem} from "hooks";
import {hasPermission} from "../../../../helpers";
import {Money} from 'components/Money';
import {InfoPopover} from "components/InfoPopover";
import RentalItemPriceForm from "./RentalItemPriceForm";
import RentalItemImageForm from "./RentalItemImageForm";
import ContactDetailsForm from "../../../company/components/ContactDetailsCard/ContactDetailsForm";
import RentalItemDetailsForm from "./RentalItemDetailsForm";
import RentalItemConditionFeatureForm from "./RentalItemConditionFeatureForm";
import RentalItemDescriptionFeatureForm from "./RentalItemDescriptionFeatureForm";
import RentalItemContentBlocksForm from "./RentalItemContentBlocksForm";

const TextSkeleton = props => {
    const {
        isDataLoaded,
    } = useRentalItem();

    return <OriginalTextSkeleton {...props} loading={!isDataLoaded}/>;
};

const RentalItemCard = () => {
    const {t} = useTranslation('common');

    const {activeAccount} = useAccounts();

    const {
        isDataLoaded,
        getRentalItem,
        getRentalItemFeatureDetails,
        payload: {
            id,
            active,
            name,
            images,
            main_static_content_block_title,
            main_static_content_block_body,
            extra_price_text,
            description_features,
            user_generated_description_features,
            rental_conditions_features,
            user_generated_rental_conditions_features,
            static_content_blocks,
            show_by_default_price_including_vat,
            rental_prices,
            pricing_model_name,
            category
        }
    } = useRentalItem();

    const [loadingConditionFeatures, setLoadingConditionFeatures] = useState(false);
    const [loadingDescriptionFeatures, setLoadingDescriptionFeatures] = useState(false);

    useEffect(() => {
        let params = new URLSearchParams(location.search); // eslint-disable-line
        getRentalItem(activeAccount.companyId, params.get('id'));
    }, [activeAccount]); // eslint-disable-line react-hooks/exhaustive-deps

    const rentalItemPriceFormRef = useRef();
    const editRentalItemPrice = (rentalPrices, mainStaticContentBlockBody, extraPriceText, showByDefaultPriceIncludingVat, taxRate) => {
        rentalItemPriceFormRef.current.openForm(
            rentalPrices,
            mainStaticContentBlockBody,
            extraPriceText,
            showByDefaultPriceIncludingVat,
            taxRate,
            pricing_model_name
        );
    };

    const rentalItemImageFormRef = useRef();
    const editRentalItemImages = (images) => {
        rentalItemImageFormRef.current.openForm(images);
    };

    const rentalItemDetailsForm = useRef();
    const openDetailsForm = () => {
        rentalItemDetailsForm.current.openForm(id, active, name);
    };

    const rentalItemConditionFeatureFormRef = useRef();
    const openRentalItemConditionFeatureForm = () => {
        setLoadingConditionFeatures(true);
        let params = new URLSearchParams(location.search); // eslint-disable-line
        getRentalItemFeatureDetails(activeAccount.companyId, params.get('id'), 'condition')
            .then((res) => {
                setLoadingConditionFeatures(false);
                rentalItemConditionFeatureFormRef.current.openForm(id, res.data.features, res.data.userGeneratedFeatures, res.data.systemFeatures);
            })
            .catch(e => {
                // TODO: give proper error here, pretty sure this translation key gives a "could not save" error
                console.log(e);
                setLoadingConditionFeatures(false);
                notification.open({
                    message: t('notification.messages.error.title'),
                    description: t('notification.messages.error.description'),
                });
            });
    };

    const rentalItemDescriptionFeatureFormRef = useRef();
    const openRentalItemDescriptionFeatureForm = () => {
        setLoadingDescriptionFeatures(true);
        let params = new URLSearchParams(location.search); // eslint-disable-line
        getRentalItemFeatureDetails(activeAccount.companyId, params.get('id'), 'description')
            .then((res) => {
                setLoadingDescriptionFeatures(false);
                rentalItemDescriptionFeatureFormRef.current.openForm(id, res.data.features, res.data.userGeneratedFeatures, res.data.systemFeatures);
            })
            .catch(e => {
                // TODO: give proper error here, pretty sure this translation key gives a "could not save" error
                console.log(e);
                setLoadingDescriptionFeatures(false);
                notification.open({
                    message: t('notification.messages.error.title'),
                    description: t('notification.messages.error.description'),
                });
            });
    };

    const rentalItemContentBlocksFormRef = useRef();
    const openRentalItemContentBlocksForm = () => {
        rentalItemContentBlocksFormRef.current.openForm(id, active, name, static_content_blocks);
    }

    return <>
        <RentalItemDetailsForm ref={rentalItemDetailsForm} />
        <RentalItemPriceForm ref={rentalItemPriceFormRef} />
        <RentalItemImageForm ref={rentalItemImageFormRef} />
        <RentalItemConditionFeatureForm ref={rentalItemConditionFeatureFormRef} />
        <RentalItemDescriptionFeatureForm ref={rentalItemDescriptionFeatureFormRef} />
        <RentalItemContentBlocksForm ref={rentalItemContentBlocksFormRef} />

        <Row gutter={[16, 16]}>
            <Col md={{span: 24}} lg={{span: 12}}>
                <Card
                title={t('pages.rentalitems.rental-item.table.product-information.title')}
                actions={[
                    <Button disabled={!hasPermission(activeAccount, 'manage_rental_item_details', 'edit')}  onClick={() => openDetailsForm()}>
                        <EditOutlined /> {t('button.text.edit')}
                    </Button>
                ]}>
                    <Descriptions column={1} className="label-50-percent">
                        <Descriptions.Item key={1} label={t('pages.rentalitems.rental-item.table.product-information.product-title')}>
                            <TextSkeleton>{name}</TextSkeleton>
                        </Descriptions.Item>
                        <Descriptions.Item key={2} label={t('pages.rentalitems.rental-item.table.product-information.status')}>
                            <TextSkeleton>{active ? <Badge color="green" text={t('badges.active')}/> : <Badge color="red" text={t('badges.inactive')}/>}</TextSkeleton>
                        </Descriptions.Item>
                        <Descriptions.Item key={2} label={t('pages.rentalitems.rental-item.table.product-information.category')}>
                            <TextSkeleton>{category?.title}</TextSkeleton>
                        </Descriptions.Item>
                        
                    </Descriptions>
                </Card>
            </Col>
            <Col md={{span: 24}} lg={{span: 12}}>
                <Card
                title={t('pages.rentalitems.rental-item.table.images.title')}
                actions={[
                    <Button disabled={!hasPermission(activeAccount, 'manage_rental_item_images', 'edit')} onClick={() => editRentalItemImages(images)}>
                        <EditOutlined /> {t('button.text.edit')}
                    </Button>
                ]}>
                    {
                        isDataLoaded ? images.map( (image, index) => {
                            return <img width={64} height={47} key={index} src={image.image_url}
                               style={{marginBottom: '1px', boxSizing: 'content-box', border: '1px solid #d9d9d9'}}/>;
                           }) : ""
                   }
                </Card>
                <br />
                <Card
                title={t('pages.rentalitems.rental-item.table.rental-prices.title')}
                actions={[
                    <Button disabled={!hasPermission(activeAccount, 'manage_rental_item_prices', 'edit')} onClick={() => editRentalItemPrice(rental_prices, main_static_content_block_body, extra_price_text, show_by_default_price_including_vat, category.tax_rate || 21)}>
                        <EditOutlined /> {t('button.text.edit')}
                    </Button>
                ]}>
                    <Descriptions column={1} className="label-50-percent">
                        {(isDataLoaded && pricing_model_name) ?
                            <Descriptions.Item key="pricing-model-name" label={t('pages.rentalitems.rental-item.table.rental-prices.pricing-model-name')}>
                                <TextSkeleton>
                                    {t(`entities:rental-item.pricing-models.${pricing_model_name}`)}
                                </TextSkeleton>
                            </Descriptions.Item>
                            : "" }
                        {
                            isDataLoaded ? rental_prices.map( (value) => {
                                return <Descriptions.Item key={value.id} label={t('pages.rentalitems.rental-item.table.rental-prices.price-period.'+value.period, {count:value.multiplier})}>
                                    { <Money value={value.price*value.multiplier}/> }
                                    { value.extra_text ? <InfoPopover content={ value.extra_text } /> : "" }
                                </Descriptions.Item>;
                            }) : ""
                        }
                        { 
                            (isDataLoaded && main_static_content_block_body) ?
                                <Descriptions.Item key="main-description" label={t('pages.rentalitems.rental-item.table.rental-prices.main-description')}>
                                    <TextSkeleton>
                                        <div dangerouslySetInnerHTML={{ __html: main_static_content_block_body }} />
                                    </TextSkeleton>
                                </Descriptions.Item>
                            : "" }
                        { 
                            (isDataLoaded && extra_price_text) ?
                                <Descriptions.Item key="second-description" label={t('pages.rentalitems.rental-item.table.rental-prices.main-description')}>
                                    <TextSkeleton>
                                        <div dangerouslySetInnerHTML={{ __html: extra_price_text }} />
                                    </TextSkeleton>
                                </Descriptions.Item>
                            : "" }
                    </Descriptions>
                </Card>
            
            </Col>
        </Row>
        <Row gutter={[16, 16]}>
            <Col md={{span: 24}} lg={{span: 12}}>
                <Card
                title={t('pages.rentalitems.rental-item.table.product-features.title')}
                actions={[
                    <Button loading={loadingDescriptionFeatures} disabled={!hasPermission(activeAccount, 'manage_rental_item_description_features', 'edit')} onClick={() => openRentalItemDescriptionFeatureForm()}>
                        <EditOutlined /> {t('button.text.edit')}
                    </Button>
                ]}>
                    <Descriptions column={1} className="label-50-percent">
                        {
                            isDataLoaded ? Object.keys(description_features).map( (key, index) => {
                                return <Descriptions.Item key={index} label={key}>
                                    <div dangerouslySetInnerHTML={{ __html: description_features[key] }} />
                                </Descriptions.Item>;
                            }) : ""
                        }
                        {
                            isDataLoaded ? Object.keys(user_generated_description_features).map( (key, index) => {
                                return <Descriptions.Item key={index} label={key}>
                                    <div dangerouslySetInnerHTML={{ __html: user_generated_description_features[key] }} />
                                </Descriptions.Item>;
                            }) : ""
                        }
                    </Descriptions>
                </Card>
            </Col>
            <Col md={{span: 24}} lg={{span: 12}}>
                <Card
                title={t('pages.rentalitems.rental-item.table.condition-features.title')}
                actions={[
                    <Button loading={loadingConditionFeatures} disabled={!hasPermission(activeAccount, 'manage_rental_item_condition_features', 'edit')} onClick={() => openRentalItemConditionFeatureForm()}>
                        <EditOutlined /> {t('button.text.edit')}
                    </Button>
                ]}>
                    <Descriptions column={1} className="label-50-percent">
                        {
                            isDataLoaded ? Object.keys(rental_conditions_features).map( (key) => {
                                return <Descriptions.Item key={1} label={key}>
                                    <div dangerouslySetInnerHTML={{ __html: rental_conditions_features[key] }} />
                                </Descriptions.Item>;
                            }) : ""
                        }
                        {
                            isDataLoaded ? Object.keys(user_generated_rental_conditions_features).map( (key) => {
                                return <Descriptions.Item key={1} label={key}>
                                    <div dangerouslySetInnerHTML={{ __html: user_generated_rental_conditions_features[key] }} />
                                </Descriptions.Item>;
                            }) : ""
                        }
                    </Descriptions>
                </Card>
            </Col>
            <Col md={{span: 24}} lg={{span: 12}}>
                <Card
                title={t('pages.rentalitems.rental-item.table.content-blocks.title')}
                actions={[
                    <Button disabled={!hasPermission(activeAccount, 'manage_rental_item_content_boxes', 'edit')} onClick={() => openRentalItemContentBlocksForm()}>
                        <EditOutlined /> {t('button.text.edit')}
                    </Button>
                ]}>
                    <Descriptions column={1} className="label-50-percent">
                        {
                            isDataLoaded ? Object.values(static_content_blocks).map( (value) => {
                                return <Descriptions.Item key={1} label={value.title}>
                                    <div dangerouslySetInnerHTML={{ __html: value.body }} />
                                </Descriptions.Item>;
                            }) : ""
                        }
                    </Descriptions>
                </Card>
            </Col>
        </Row>
    </>
};
export default RentalItemCard;