import {useGlobalStore} from 'store';

import bindActions from 'store/bindActions';
import testOverlap from 'store/testOverlap';
import advertisementsReducer from 'store/advertisements';

const { Actions } = advertisementsReducer;

const useAdvertisements = () => {
    const {state, dispatch} = useGlobalStore();

    const {advertisements} = state;

    // Test if there's an overlapping between the actions and store
    testOverlap(advertisements, Actions);

    return {
        ...bindActions(Actions, dispatch),
        ...advertisements,
    };
};

export default useAdvertisements;
