import React from 'react';

import {
    FilePdfOutlined,
    LogoutOutlined,
    ShopOutlined,
    UserOutlined,
    UserSwitchOutlined,
} from '@ant-design/icons';
import {Avatar, Button, Dropdown, Layout, Menu} from "antd";
import {PremiumBadge} from "components/Premium";
import {Link, navigate} from "@reach/router";
import {useTranslation} from "react-i18next";
import {useAccounts, useAuth, useSmallDeviceSize} from "hooks";

const {Content} = Layout;

const HeaderUserDropdown = () => {
    const { t } = useTranslation('common');
    const { logout } = useAuth();
    const { activeAccount, userAccounts } = useAccounts();
    const isSmallDeviceSize = useSmallDeviceSize();

    let overlayWidth = 350;
    let overlayTrigger = 'hover';
    if (isSmallDeviceSize) {
        overlayWidth = '100%';
        overlayTrigger = 'click';
    }

    const submenuItems = [
        {
            key: '/account',
            icon: <UserOutlined style={{marginRight: '5px'}} />,
            label: (
                <Link to="/account">
                    <span>{t('header.user-dropdown.menu.account.title')}</span>
                </Link>
            )
        },
        {
            key: '/account/invoicing',
            icon: <FilePdfOutlined style={{marginRight: '5px'}} />,
            label: (
                <Link to="/account/invoicing">
                    <span>{t('header.user-dropdown.menu.invoicing.title')}</span>
                </Link>
            )
        }
    ];

    const menu = {
        items: [
            {
                key: '1',
                label: (
                    <Content style={{
                        padding: '10px',
                        textAlign: 'center',
                        backgroundColor: 'white'
                    }}>
                        <div style={{marginBottom: '10px', paddingBottom: '10px', borderBottom: '1px solid #e8e8e8'}}>
                            {activeAccount.hasBudget ?
                                <div style={{
                                    borderBottom: '1px solid #e8e8e8',
                                    marginBottom: '10px',
                                    paddingBottom: '5px'
                                }}>
                                    <PremiumBadge/>
                                </div> : <></>
                            }
                            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                <div style={{textAlign: 'left'}}>
                                    <strong>{activeAccount.firstName} {activeAccount.lastName}</strong><br/>
                                    {activeAccount.email}<br/>
                                </div>
                            </div>

                            <Button style={{marginTop: '10px'}} onClick={() => window.open('https://www.huren.nl/m' + activeAccount.companyId)}>
                                <ShopOutlined style={{marginRight: '5px'}} />
                                <span>{t('header.user-dropdown.view-profile')}</span>
                            </Button>
                        </div>

                        <Menu mode="vertical" style={{ border: 'none', boxShadow: 'none' }} items={submenuItems} />

                        <Menu.Divider />

                        { userAccounts.length > 1 ?
                            <Button className="account-switch-button" style={{marginTop: '10px'}} onClick={() => navigate('/accounts')}>
                                <UserSwitchOutlined style={{marginRight: '5px'}} />
                                {t('button.text.switch-accounts')}
                            </Button>
                            : <></>
                        }
                        <Button className="sign-off-button" style={{marginTop: '10px'}} onClick={() => logout()}>
                            <LogoutOutlined style={{marginRight: '5px'}} />
                            {t('button.text.sign-off')}
                        </Button>
                    </Content>
                )
            }
        ]
    };

    return (
        <Dropdown overlayStyle={{width: overlayWidth}} trigger={overlayTrigger} overlayClassName="user-menu-dropdown" menu={menu}>
            <div className="item usermenu">
                <Avatar size="small" icon={<UserOutlined />} alt="avatar"/>
                <span hidden={isSmallDeviceSize} className="username">{activeAccount.companyName}</span>
            </div>
        </Dropdown>
    );
};

export default HeaderUserDropdown;
