import React from "react";
import Drawer from "components/Drawer";
import {useTranslation} from "react-i18next";
import LogoForm from "./LogoForm";
import {useLogo} from "hooks";

const LogoDrawer = () => {
    const {t} = useTranslation('common');
    const {
        closeEditForm,
        isEditFormOpen,
    } = useLogo();

    return (
        <Drawer
            title={t('pages.company.company-logo-form.title')}
            placement="right"
            onClose={() => closeEditForm()}
            open={isEditFormOpen}
            width={520}
        >
            <LogoForm/>
        </Drawer>
    );
};

export default LogoDrawer;
