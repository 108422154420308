import React, {useEffect, useState} from "react";
import {Trans, useTranslation} from "react-i18next";
import {Avatar, Badge, Button, Card, Checkbox, Col, Dropdown, Layout, List, Modal, Row, Table, Tree} from "antd";
import {useAccounts, useAdvertisements, useAdvertisementsCart, useCategories} from "hooks";
import Paragraph from "antd/es/typography/Paragraph";
import Search from "antd/es/input/Search";
import {InfoPopover} from "components/InfoPopover";
import {Money} from "components/Money";
import {
    CheckCircleOutlined,
    CloseCircleOutlined,
    PlusOutlined,
    RightCircleOutlined,
    RightOutlined
} from "@ant-design/icons";
import {hasPermission} from "../../../../../helpers";

const {confirm} = Modal;
const {Content} = Layout;

const SelectStep = (props) => {
    const {t} = useTranslation('common');
    const [filters, setFilters] = useState({category: '', linked: true});
    const [treeData, setTreeData] = useState([]);
    const [treeState, setTreeState] = useState({dataList: [], expandedKeys: [], searchValue: '', autoExpandParent: true});
    const {activeAccount} = useAccounts();

    const {
        cartItems,
        addItem,
        removeItem,
    } = useAdvertisementsCart();

    const {
        getAdvertisements,
        payload: {
            advertisements
        }
    } = useAdvertisements();

    const {
        getCategories,
        searchCategories,
        categories,
        clickboxCategories,
        setClickboxCategories
    } = useCategories();

    useEffect(() => {
        getCategories();
        getAdvertisements(activeAccount.companyId);
        showClickboxCategories();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        showClickboxCategories();
    }, [filters]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        generateList(categories);
    }, [categories]); // eslint-disable-line react-hooks/exhaustive-deps

    // Filters the right side when a tree item is clicked,
    // or the "Show existing advertisements" is toggled.
    const showClickboxCategories = async () => {
        const categoryFilter = filters.category;
        const linkedFilter = filters.linked;

        if (typeof categoryFilter !== 'undefined' && categoryFilter !== '') {
            await searchCategories(activeAccount.companyId, categoryFilter, linkedFilter);
        }
        else {
            setClickboxCategories([]);
        }
    };

    const toggleCurrent = (event) => {
        const checked = event.target.checked;
        setFilters(prevState => ({...prevState, linked: checked}));
    }

    const onSelect = (selectedKeys) => {
        setFilters(prevState => ({ ...prevState, category: selectedKeys[0] }));
    };

    // Generate a quickly searchable list from the categories.
    const dataList = [];
    const generateList = (data) => {
        for (let i = 0; i < data.length; i++) {
            const node = data[i];
            const { key, title } = node;
            dataList.push({ key: key.toString(), title: title });
            if (node.children) {
                generateList(node.children);
            }
        }
        setTreeState(prevState => ({...prevState, dataList: dataList}));
        setTreeData(dataToTreeNodes(data));
    };

    // Reset the state when manually expanding the tree.
    const onExpand = (expandedKeys) => {
        setTreeState(prevState => ({
            ...prevState,
            expandedKeys: [...expandedKeys],
            autoExpandParent: false
        }));
    }

    // Search bar handler.
    const onSearch = (e) => {
        const { value } = e.target;
        if (value.length > 2) {
            const expandedKeys = treeState.dataList
                .map(item => {
                    let title = item.title.toLowerCase();
                    if (title.startsWith(value.toLowerCase())) {
                        return getParentKey(item.key.toString(), categories);
                    }
                    return null;
                })
                .filter((item, i, self) => {
                    return item && self.indexOf(item) === i;
                });
            setTreeState(prevState => ({
                ...prevState,
                expandedKeys: [...expandedKeys],
                searchValue: value,
                autoExpandParent: true
            }));
        } else {
            setTreeState(prevState => ({
                ...prevState,
                expandedKeys: [],
                searchValue: null,
                autoExpandParent: false
            }));
        }
    };

    // Gets the parent to expand when searching the tree.
    const getParentKey = (key, tree) => {
        let parentKey = null;
        for (let i = 0; i < tree.length; i++) {
            const node = tree[i];
            if (node.children) {
                if (node.children.some(item => item.key.toString() === key)) {
                    parentKey = node.key;
                } else if (getParentKey(key, node.children)) {
                    parentKey = getParentKey(key, node.children);
                }
            }
        }
        if (parentKey !== null) return parentKey.toString();
        return null;
    };

    const treeNodeTitle = (node) => {
        const searchValue = treeState.searchValue;
        return searchValue !== null && searchValue.length > 2 && node.title.toLowerCase().startsWith(searchValue) ? (
            <span>
                <span className="category-tree-search-value">{node.title}</span>
            </span>
        ) : (
            <span>{node.title}</span>
        );
    }

    const dataToTreeNodes = (data) => {
        return data.map(item => {
            return {
                title: item.title,
                key: item.key.toString(),
                children: item.children.length > 0 ? dataToTreeNodes(item.children) : []
            };
        });
    }

    const checkout = () => {
        props.nextStep();
    }

    const tableColumns = [
        {
            title: <>
                    {t('pages.advertisements.advertisements-wizard.table.select.category')}&nbsp;
                    <InfoPopover content={t('pages.advertisements.advertisements-wizard.table.select.category-info')} />
                </>,
            dataIndex: 'category',
            key: 'category',
            ellipsis: true,
            width: '60%',
            render: (text, record) =>
                <Row>
                    <Col style={{display: 'flex'}}>
                        {record.images.map((value, index) => {
                            if (index > 0) return <></>;
                            return <img width={64} height={45} key={index} size={64} src={value} style={{marginLeft: '1em', boxSizing: 'content-box', border: '1px solid #d9d9d9'}}/>
                        })}
                        <div style={{marginLeft: '16px'}}>
                            <a href={record.url} target="_blank" rel="noopener noreferrer">
                                <h4 style={{color: 'rgba(0, 0, 0, 0.65)'}}>{record.title}</h4>
                            </a>
                            <a href={record.parent.url}
                               target="_blank" rel="noopener noreferrer">{record.parent.title}</a>
                        </div>
                    </Col>
                </Row>
        },
        {
            title: <>
                    {t('pages.advertisements.advertisements-wizard.table.select.click-rate')}&nbsp;
                    <InfoPopover content={t('pages.advertisements.advertisements-wizard.table.select.click-rate-info')} />
                </>,
            dataIndex: 'click_out_rate',
            key: 'click_out_rate',
            ellipsis: true,
            render: (text, record) => <Money digits="2" value={record.click_out_rate} />
        },
        {
            align: 'right',
            title: t('pages.advertisements.advertisements-wizard.table.select.actions'),
            dataIndex: 'actions',
            key: 'actions',
            ellipsis: true,
            render: (text, record) =>
                <Button
                    key={'actions' + record.id}
                    type="secondary"
                    disabled={!hasPermission(activeAccount, 'manage_ads', 'create') || (cartItems.filter(item => { return item.id === record.id}).length > 0 || record.linked) || (advertisements.filter(item => item.category.id === record.id)).length > 0}
                    onClick={() => {
                        confirm({
                            icon: (<CheckCircleOutlined style={{color: '#75b118'}} />),
                            width: 550,
                            title: t('cart.added', {'category': record.title}),
                            okText: (
                                <React.Fragment>
                                    {t('cart.checkout')}&nbsp;<RightCircleOutlined />
                                </React.Fragment>
                            ),
                            cancelText: t('cart.select-more'),
                            onOk() {
                                addItem(record);
                                checkout();
                            },
                            onCancel() {
                                addItem(record);
                            },
                        })
                    }}>

                    {record.linked ? t('cart.linked') : (<>
                            <PlusOutlined />&nbsp;
                            {cartItems.filter(item => { return item.id === record.id}).length > 0 ? t('cart.selected') : t('cart.add')}
                        </>
                    )}
                </Button>
        }
    ];

    const dropdownMenu = {
        items: [

        ]
    };

    cartItems.forEach(cartItem => {
        dropdownMenu.items.push({
            key: cartItem.id,
            icon: <Avatar shape="square" src={cartItem.images[0]}/>,
            label: (
                <span>
                    {cartItem.title}
                    <a style={{ float: 'right'}} onClick={() => {
                        confirm({
                            title: t('cart.remove'),
                            content: t('cart.remove-text', {'category': cartItem.title}),
                            okText: t('cart.actions.ok'),
                            cancelText: t('cart.actions.cancel'),
                            onOk() {
                                removeItem(cartItem)
                            }
                        })
                    }}>
                        <CloseCircleOutlined />
                    </a>
                </span>
            )
        });
    });

    return <Card size="small"
        className="company-advertisements-wizard-select"
        title={t('pages.advertisements.advertisements-wizard.steps.select.title')}
    >

        <Paragraph>
            <Trans t={t} i18nKey="pages.advertisements.advertisements-wizard.steps.select.intro-text">
                <strong>How does this work?</strong><br/>
                We have over 1000 pages on our website for you to advertise on.
                After you've completed these 3 steps (and you have a budget set) your advertisement will be visible on Huren.nl.
                For each visitor that clicks on your advertisement, you'll pay a small fee (click price).
                Good luck creating an advertisement!
            </Trans>
        </Paragraph>
        <Paragraph>
            <Trans t={t} i18nKey="pages.advertisements.advertisements-wizard.steps.select.steps" components={[<ol />, <li />]}>
            <strong>What are the steps?</strong><br/>
            <ol>
                <li>
                    Select a category available on our website below and see which advertisements are available in that category.
                </li>
                <li>Select 1 or more pages to advertise on.</li>
                <li>
                    Enter which destination URL or landing page on your website for us to send potential renters to.
                    This is how you connecty your website to us.
                </li>
                <li>
                    Check the advertisement details and put them online. You can then manage your advertisements on the advertisement overview page.
                </li>
            </ol>
            </Trans>
        </Paragraph>

        <Row gutter={[16, 16]} type="flex" justify="space-around" align="middle" style={{borderBottom: '1px solid #e8e8e8', paddingBottom: '16px', marginBottom: '16px'}}>
            <Col span={8}>
                <Search placeholder={t('treeselect.placeholder')} onChange={onSearch} />
            </Col>
            <Col span={8}>
                <Checkbox defaultChecked={true} onChange={toggleCurrent}>
                    {t('pages.advertisements.advertisements-wizard.steps.select.show-existing')}&nbsp;
                    <InfoPopover
                        content={t('pages.advertisements.advertisements-wizard.steps.select.show-existing-long')}
                    />
                </Checkbox>
            </Col>
            <Col span={8} align="right">
                <Dropdown disabled={!cartItems.length > 0} menu={dropdownMenu}>
                    <Button
                        type="primary"
                        disabled={!cartItems.length > 0}
                        onClick={checkout}
                    >
                        <Badge count={cartItems.length} style={{marginRight: '8px'}} />
                            {t('cart.checkout')} &nbsp;
                        <RightOutlined />
                    </Button>
                </Dropdown>

            </Col>
        </Row>

        <Row gutter={[16, 16]}>
            <Col span={6}>
                {treeData.length > 0 ? (
                    <Tree
                        autoExpandParent={treeState.autoExpandParent}
                        expandedKeys={treeState.expandedKeys}
                        blockNode={true}
                        onSelect={onSelect}
                        onExpand={onExpand}
                        treeData={treeData}
                        titleRender={treeNodeTitle}
                    />
                ) : t('pages.advertisements.advertisements-wizard.steps.select.loading-categories')}
            </Col>
            <Col span={18}>
                <Table
                    tableLayout="fixed"
                    dataSource={clickboxCategories}
                    columns={tableColumns}
                    rowKey="id"
                    pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '20', '50', '100']}}
                />
            </Col>
        </Row>
    </Card>;
}

export default SelectStep;