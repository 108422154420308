import client from "services/HurenApiClient";
import * as Types from "./Types";

export const setIsDataLoaded = (loaded) => {
    return {
        type: Types.SET_IS_DATA_LOADED,
        isDataLoaded: loaded
    };
};

export const setData = (payload) => {
    return {
        type: Types.SET_DATA,
        payload: payload
    };
};

export const setIsReplyFormSaving = (saving) => {
    return {
        type: Types.SET_IS_REPLY_FORM_SAVING,
        isReplyFormSaving: saving
    };
};
export const closeReplyForm = () => {
    return {
        type: Types.REPLY_FORM_CLOSE
    };
};

export const openReplyForm = () => {
    return {
        type: Types.REPLY_FORM_OPEN
    };
};

export const setReplyFormData = (payload) => {
    return {
        type: Types.SET_REPLY_FORM_DATA,
        payload: payload
    };
};

export const setIsReplyFormDataLoaded = (loaded) => {
    return {
        type: Types.SET_IS_REPLY_FORM_DATA_LOADED,
        isReplyFormDataLoaded: loaded
    };
};

export const replyToReview = (companyId, reviewId) => {
    return async function (dispatch) {
        await getReplyFormData(companyId, reviewId)(dispatch);
        dispatch(openReplyForm());
    };
};

export const getReplyFormData = (companyId, reviewId) => {
    return async function(dispatch) {
        dispatch(setIsReplyFormDataLoaded(false));

        return client.get('/company/' + companyId + '/reviews/' + reviewId).then(res => {
            dispatch(setReplyFormData(res.data));
        });
    }
};

export const getReviews = (companyId) => {
    return async function(dispatch){
        dispatch(setIsDataLoaded(false));

        return client.get('/company/' + companyId + '/reviews').then(res => {
            dispatch(setData(res.data));
        });
    };
};

export const putReviewReply = (companyId, reviewId, data) => {
    return async function (dispatch) {
        dispatch(setIsReplyFormSaving(true));
        return client.put('/company/' + companyId + '/reviews/' + reviewId, {
            "review_form" : data
        }).then(res => {
            dispatch(setIsReplyFormSaving(false));
        });
    };
}

