import React, {useEffect, useState} from 'react';
import {Col, DatePicker, Radio, Row} from "antd";
import Card from "components/Card";
import {ClickGraph} from "components/Charts";
import {PremiumInfo} from "components/Premium";
import {useTranslation} from "react-i18next";
import {useAccounts, useSmallDeviceSize, useStatistics} from "hooks";
import moment from "moment";

const {RangePicker} = DatePicker;

const ClickGraphCard = () => {
    const { t } = useTranslation('common');
    const {activeAccount} = useAccounts();

    const [filters, setFilters] = useState({
        period: 'day',
        startDate: moment().subtract(1, 'month'),
        endDate: moment()
    });

    const {
        getClickGraph,
        isGraphDataLoaded,
        isGraphDataError,
        graphData: {
            graph
        }
    } = useStatistics();

    useEffect(() => {
        getClickGraph(activeAccount.companyId, filters.period, filters.startDate, filters.endDate);
    }, [filters]); // eslint-disable-line react-hooks/exhaustive-deps


    const changePeriod = (e) => {
        setFilters({...filters, period: e.target.value});
    }

    const changeDateRange = (dates) => {
        if (dates != null) {
            setFilters({...filters, startDate: dates[0], endDate: dates[1]});
        }
    }

    let gutterHeight = 48;
    if (useSmallDeviceSize()) {
        gutterHeight = 16;
    }

    var clickGraph;
    if (isGraphDataLoaded && !isGraphDataError) {
        clickGraph = <>
            <Row gutter={[16, gutterHeight]}>
                <Col span={24} align="right">
                    <Radio.Group defaultValue={filters.period} style={{marginBottom: '8px'}} onChange={changePeriod}>
                        <Radio.Button value="day">{t('units.time.day')}</Radio.Button>
                        <Radio.Button value="week">{t('units.time.week')}</Radio.Button>
                        <Radio.Button value="month">{t('units.time.month')}</Radio.Button>
                    </Radio.Group>
                    <RangePicker format="DD-MM-YYYY" inputReadOnly={true} defaultValue={[filters.startDate, filters.endDate]} style={{marginLeft: '14px'}} onChange={changeDateRange} ranges={
                        {
                            [t('ranges.dates.this-month')]: [moment().startOf('month'), moment().endOf('month')],
                            [t('ranges.dates.previous-month')]: [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                            [t('ranges.dates.this-year')]: [moment().startOf('year'), moment().endOf('year')],
                            [t('ranges.dates.previous-year')]: [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')],
                        }
                    }/>
                </Col>
            </Row>
            <ClickGraph graphData={graph} />
        </>;
    } else {
        clickGraph = <>{t('pages.dashboard.chart.click-graph-card.error')}</>;
    }

    return <Card loading={!isGraphDataLoaded} title={<><PremiumInfo/> {t('pages.dashboard.chart.click-graph-card.title')}</>}>
        {clickGraph}
    </Card>;
};

export default ClickGraphCard
