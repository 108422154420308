import client from "services/HurenApiClient";
import * as Types from "./Types";

export const setIsDataLoaded = (loaded) => {
    return {
        type: Types.SET_IS_DATA_LOADED,
        isDataLoaded: loaded
    };
};

export const setData = (payload) => {
    return {
        type: Types.SET_DATA,
        payload: payload
    };
};

export const getPromoInfo = (companyId) => {
    return async function(dispatch){
        dispatch(setIsDataLoaded(false));

        return client.get('/promo/' + companyId + '/info').then(res => {
            dispatch(setData(res.data));
        });
    };
};
