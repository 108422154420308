import {useGlobalStore} from 'store';

import bindActions from 'store/bindActions';
import testOverlap from 'store/testOverlap';
import contactDetailsReducer from 'store/contactDetails';

const { Actions } = contactDetailsReducer;

const useContactDetails = () => {
    const {state, dispatch} = useGlobalStore();

    const {contactDetails} = state;

    // Test if there's an overlapping between the actions and store
    testOverlap(contactDetails, Actions);

    return {
        ...bindActions(Actions, dispatch),
        ...contactDetails,
    };
};

export default useContactDetails;
