import client from "services/HurenApiClient";
import * as Types from "./Types";

export const setIsDataLoaded = (loaded) => {
    return {
        type: Types.SET_IS_DATA_LOADED,
        isDataLoaded: loaded
    };
};

export const setData = (payload) => {
    return {
        type: Types.SET_DATA,
        payload: payload
    };
};

export const setEditFormData = (payload) => {
    return {
        type: Types.SET_EDIT_FORM_DATA,
        payload: payload
    };
};

export const setIsEditFormDataLoaded = (loaded) => {
    return {
        type: Types.SET_IS_EDIT_FORM_DATA_LOADED,
        isEditFormDataLoaded: loaded
    };
};

export const setIsEditFormSaving = (saving) => {
    return {
        type: Types.SET_IS_EDIT_FORM_SAVING,
        isEditFormSaving: saving
    };
};
export const closeEditForm = () => {
    return {
        type: Types.EDIT_FORM_CLOSE
    };
};

export const openEditForm = () => {
    return {
        type: Types.EDIT_FORM_OPEN
    };
};

export const getEditFormData = (companyId) => {
    return async function(dispatch) {
        dispatch(setIsEditFormDataLoaded(false));

        return client.get('/budget/' + companyId).then(res => {
            dispatch(setEditFormData(res.data));
        });
    }
};

export const getBudgetData = (companyId) => {
    return async function (dispatch) {
        dispatch(setIsDataLoaded(false));

        return client.get('/budget/' + companyId).then(res => {
            dispatch(setData(res.data));
        });
    };
};

export const putBudget = (companyId, unlimited, newBudget) => {
    return async function(dispatch) {
        return client.put('/budget/' + companyId, {
            "budget_form" : {
                "unlimitedBudget": unlimited,
                "currentDailyLimit": newBudget
            }
        }).then(res => {
            dispatch(setData(res.data));
        });
    }
};
