import React, {useEffect} from "react";
import Media from "react-media";
import {useTranslation} from "react-i18next";
import {Button, Card, Col, Form, Input, InputNumber, Row, Select, Table} from "antd";
import {useAdvertisementsCart} from "hooks";
import {InfoPopover} from "components/InfoPopover";
import {Money} from "components/Money";
import {RightCircleOutlined} from "@ant-design/icons";

const { useForm } = Form;
const { Option } = Select;

const LinkStep = (props) => {
    const {t} = useTranslation('common');
    const [form] = useForm();
    const {getFieldValue, setFieldsValue, validateFields} = form;
    const prefixValues = {};

    const {
        cartItems
    } = useAdvertisementsCart();

    useEffect(() => {
        cartItems.forEach(item => {
            if (item.click_out_url !== undefined) {
                let url = item.click_out_url.replace(/http:\/\/|https:\/\//, '');

                setFieldsValue({
                    ['price_' + item.id]: item.price,
                    ['click_out_url_' + item.id]: url
                });
            }
        })
    }, [cartItems]); // eslint-disable-line react-hooks/exhaustive-deps

    const confirm = () => {
        validateFields()
            .then(() => {
                cartItems.forEach(function (item) {
                    item.price = getFieldValue('price_' + item.id);
                    let prefix = prefixValues['click_out_url_prefix_' + item.id];
                    item.click_out_url = prefix + getFieldValue('click_out_url_' + item.id);
                })

                props.nextStep();
            })
            .catch(errorInfo => {
                console.log(errorInfo);
            });
    };

    const prefixSelected = (itemId, value) => {
        prefixValues['click_out_url_prefix_' + itemId] = value;
    }

    const selectBefore = (itemId, urlValue) => {
        let prefix = urlValue && !urlValue.includes('https://') ? 'http://' : 'https://';
        prefixSelected(itemId, prefix);
        return <Select onChange={(value) => prefixSelected(itemId, value)} defaultValue={prefix} className="select-before">
            <Option value="https://">https://</Option>
            <Option value="http://">http://</Option>
        </Select>
    };

    const tableColumns = [
        {
            title: <>
                {t('pages.advertisements.advertisements-wizard.table.link.clickbox-category')}&nbsp;
                <InfoPopover content={t('pages.advertisements.advertisements-wizard.table.link.clickbox-category-info')} />
            </>,
            dataIndex: 'category',
            key: 'category',
            ellipsis: true,
            render: (text, record) => <Row type="flex" style={{alignItems: 'center'}}>
                <Col span={10}>
                    <img width={64} height={45} src={record.images[0]} style={{ marginRight: '1em', marginBottom: '1px', border: '1px solid #d9d9d9' }}/>
                </Col>
                <Col span={14}>
                    <Row>
                        <a href={record.url} target="_blank" rel="noopener noreferrer">
                            <h4 style={{color: 'rgba(0, 0, 0, 0.65)'}}>{record.title}</h4>
                        </a>
                    </Row>
                    <Row>
                        <a href={record.parent.url} target="_blank" rel="noopener noreferrer">
                            {record.parent.title}
                        </a>
                    </Row>
                </Col>
             </Row>
        },
        {
            title: <>
                {t('pages.advertisements.advertisements-wizard.table.link.click-rate')}&nbsp;
                <InfoPopover content={t('pages.advertisements.advertisements-wizard.table.link.click-rate-info')} />
            </>,
            dataIndex: 'click_out_rate',
            hideOnResponsive: true,
            key: 'click_out_rate',
            ellipsis: true,
            render: (text, record) => <Money digits="2" value={record.click_out_rate} />
        },
        {
            title: <>
                {t('pages.advertisements.advertisements-wizard.table.link.price')}&nbsp;
                <InfoPopover content={t('pages.advertisements.advertisements-wizard.table.link.price-info')} />
            </>,
            dataIndex: 'price',
            key: 'price',
            ellipsis: true,
            render: (text, record) =>
                <Form.Item label="">
                    <Form.Item name={'price_' + record.id} required="true" noStyle rules={[{ required: true, message: t('form.errors.only-numeric') }]}>
                        <InputNumber id={'price_' + record.id} decimalSeparator="," precision={2}
                                     formatter={value => `€ ${value}`.replace(/^€\d+(,\d{2})$/g, ',')}
                                     parser={value => value.replace(/€\s?/g, '')} value={record.price} />
                    </Form.Item>
                    <span>&nbsp;{t('pages.advertisements.advertisements-form.items.price.period.' + record.rental_period_unit)} {t('pages.advertisements.advertisements-form.items.price.extra')}</span>
                </Form.Item>
        },
        {
            title: <>
                {t('pages.advertisements.advertisements-wizard.table.link.url')}&nbsp;
                <InfoPopover content={t('pages.advertisements.advertisements-wizard.table.link.url-info')} />
            </>,
            dataIndex: 'click_out_url',
            key: 'click_out_url',
            ellipsis: true,
            render: (text, record) =>
                <Form.Item name={'click_out_url_' + record.id} required="true" rules={
                    [{
                        required: true,
                        pattern: /^(?:\S+(?::\S*)?@)?(?:(?!10(?:\.\d{1,3}){3})(?!127(?:\.​\d{1,3}){3})(?!169\.254(?:\.\d{1,3}){2})(?!192\.168(?:\.\d{1,3}){2})(?!172\.(?:1[​6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1​,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00​a1-\uffff0-9]+-?)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]+-?)*[a-z\u​00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/[^\s]*)?$/i,
                        message: t('form.errors.invalid-url')
                     }]}>
                    <Input addonBefore={selectBefore(record.id, record.click_out_url ?? '')} id={'click_out_url_' + record.id} />
                </Form.Item>
        }
    ];

    return <Card size="small"
                 className="company-advertisements-wizard-link"
                 title={t('pages.advertisements.advertisements-wizard.steps.link.title')}
    >
        <Form form={form}>
            <Media query="(min-width: 950px)">
                {matches => {

                    let filteredColumns = tableColumns.filter((column) => {
                        return matches || column.hideOnResponsive !== true;
                    });

                    return <Table
                        tableLayout="fixed"
                        dataSource={cartItems}
                        columns={filteredColumns}
                        rowKey="id"
                        pagination="false"
                    />
                }}
            </Media>
        </Form>
        <Row>
            <Col span={12} align="left">
                <Button type="link"
                        onClick={props.prevStep}>{t('pages.advertisements.advertisements-wizard.steps.link.select-more')}</Button>
            </Col>
            <Col span={12} align="right">
                <Button type="primary"
                        onClick={confirm}>{t('pages.advertisements.advertisements-wizard.steps.link.confirm')}&nbsp;
                    <RightCircleOutlined/></Button>
            </Col>
        </Row>
    </Card>;
}

export default LinkStep;