import React from 'react';
import {Router} from "@reach/router";
import PublicRoute from "./components/Router/PublicRoute";
import AuthenticatedRoute from "./components/Router/AuthenticatedRoute";
import AccountRoute from "./components/Router/AccountRoute";

import TokenCheck from "./pages/auth/TokenCheck";
import Logout from "./pages/auth/Logout";
import AccountSettings from "./pages/account/AccountSettings";
import AccountSelector from "./pages/account/AccountSelector";
import AdvertisementOverview from "./pages/advertisements/AdvertisementOverview";
import AdvertisementWizard from "./pages/advertisements/AdvertisementWizard";
import AdvertisementStatistics from "./pages/statistics/AdvertisementStatistics";
import RentalItemsOverview from "./pages/rental_shop/RentalItemsOverview";
import RentalItem from "./pages/rental_shop/RentalItem";
import Dashboard from "./pages/dashboard/Dashboard";
import CompanySettings from "./pages/company/Settings";
import CompanyLocations from "./pages/company/Locations";
import CompanyReviews from "./pages/company/Reviews";
import Budget from "./pages/advertisements/Budget";
import Invoicing from "./pages/account/Invoicing";
import Statistics from "./pages/statistics/Statistics";
import Contact from "./pages/help/Contact";
import Promo from "./pages/help/Promo";
import Ebooks from "./pages/help/Ebooks";
import Inbox from './pages/inbox/Inbox';
import Conversation from './pages/conversation/Conversation';
import RentalMatches from "./pages/rental-matches/RentalMatches";

import NotFound from "./pages/NotFound";
import LocationStatistics from "./pages/statistics/LocationStatistics";
import ProductsStatistics from './pages/statistics/ProductsStatistics';
import RentalMatch from "./pages/rental-match/RentalMatch";
import Quotations from "./pages/quotations/Quotations";
import CreateOffer from "./pages/create-offer/CreateOffer";
import {Offers} from "./pages/offers/Offers";
import EditOffer from "./pages/edit-offer/EditOffer";
import AdvertisementBidding from "./pages/advertisements/AdvertisementBidding";
import Orders from "./pages/orders/Orders";
import OrderDetail from "./pages/orders/OrderDetail";
import UserOverview from "./pages/settings/UserOverview"

const Routes = () => {
    return <div>
        <Router basename={'/account'}>
            <AccountRoute path="/" component={Dashboard} />
            <AccountRoute path="/company/settings" component={CompanySettings} />
            <AccountRoute path="/company/locations" component={CompanyLocations} />
            <AccountRoute path="/company/reviews" component={CompanyReviews} />
            <AccountRoute path="/rentalitems" component={RentalItemsOverview} />
            <AccountRoute path="/rentalitem" component={RentalItem} />
            <AccountRoute path="/advertisements" component={AdvertisementOverview} />
            <AccountRoute path="/advertisements/:id/bid" component={AdvertisementBidding} />
            <AccountRoute path="/advertisements/create" component={AdvertisementWizard} />
            <AccountRoute path="/advertisements/budget" component={Budget} />
            <AccountRoute path="/account" component={AccountSettings} />
            <AccountRoute path="/account/invoicing" component={Invoicing} />
            <AccountRoute path="/quotations" component={Quotations}/>
            <AccountRoute path="/rental-matches" component={RentalMatches} />
            <AccountRoute path="/rental-matches/:id" component={RentalMatch} />
            <AccountRoute path="/rental-matches/:id/create-offer" component={CreateOffer} />
            <AccountRoute path="/rental-matches/:rentalMatchId/offers/:offerId/edit" component={EditOffer} />
            <AccountRoute path="/statistics" component={Statistics} />
            <AccountRoute path="/statistics/advertisement" component={AdvertisementStatistics} />
            <AccountRoute path="/statistics/location" component={LocationStatistics} />
            <AccountRoute path="/statistics/product" component={ProductsStatistics} />
            <AccountRoute path="/messages/:id" component={Conversation} />
            <AccountRoute path="/messages" component={Inbox} />
            <AccountRoute path="/help/contact" component={Contact} />
            <AccountRoute path="/help/promo" component={Promo} />
            <AccountRoute path="/help/ebooks" component={Ebooks} />
            <AccountRoute path="/offers" component={Offers} />
            <AccountRoute path="/orders" component={Orders} />
            <AccountRoute path="/orders/:orderId" component={OrderDetail} />
            <AccountRoute path="/settings/users" component={UserOverview} />

            <AuthenticatedRoute path="/accounts" component={AccountSelector} />
            <PublicRoute path="/logout" component={Logout} />

            <PublicRoute path="/check" component={TokenCheck} />
            <PublicRoute default component={NotFound} />
        </Router>
    </div>
};

export default Routes;
