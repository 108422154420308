import React from 'react';
import LoggedInLayout from "layout/LoggedInLayout";

import {useTranslation} from "react-i18next";
import {ReviewsCard} from "./components/ReviewsCard";

const CompanyReviews = () => {
    const { t } = useTranslation('common');

    return <LoggedInLayout pageTitle={t('pages.company.reviews.title')}>
        <ReviewsCard />
    </LoggedInLayout>;
};

export default CompanyReviews;
