import React, {useEffect} from 'react';
import {Col, Row, Skeleton} from "antd";
import {useTranslation} from "react-i18next";
import { DailyBudgetInfo } from "components/DailyBudgetInfo";
import AdvertisementsStats from "./AdvertisementsStats";
import RatingStats from "./RatingStats";
import CostStats from "./CostStats";
import ClickStats from "./ClickStats";
import Card from "components/Card";
import {useDashboardStats, useAccounts} from "hooks";

const CardWrapper = (props) => {
    return <Card style={{height: '100%'}} {...props}>
        {props.loading ? <Skeleton active={true}/> : props.children}
    </Card>
};

const Stats = () => {
    const {activeAccount} = useAccounts();

    const { t } = useTranslation('common');

    const {
        getStatsData,
        isDataLoaded,
        payload: {
            daily_budget,
            advertising_stats,
            rating_stats,
            click_stats,
            cost_stats
        }
    } = useDashboardStats();

    useEffect(() => {
        getStatsData(activeAccount)
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    var costStats, clickStats
    if (isDataLoaded) {
        if (cost_stats === null) { 
            costStats = <>{t('pages.dashboard.stats.payment.costs.error')}</>;
        } else {
            costStats = <><CostStats {...cost_stats} /></>;
        }

        if (click_stats === null) { 
            clickStats = <>{t('pages.dashboard.stats.click.error')}</>;
        } else {
            clickStats = <><ClickStats {...click_stats} /></>;
        }
    }

    return <Row gutter={[16, 24]} type="flex">
        <Col xs={{span: 24}} md={{span: 12}} lg={{span: 8}}>
            <CardWrapper loading={!isDataLoaded}>
                <DailyBudgetInfo {...daily_budget} />
            </CardWrapper>
        </Col>
        <Col xs={{span: 24}} md={{span: 6}} lg={{span: 4}}>
            <CardWrapper loading={!isDataLoaded}>
                <AdvertisementsStats {...advertising_stats} />
            </CardWrapper>
        </Col>
        <Col xs={{span: 24}} md={{span: 6}} lg={{span: 4}}>
            <CardWrapper loading={!isDataLoaded}>
                <RatingStats {...rating_stats} />
            </CardWrapper>
        </Col>
        <Col xs={{span: 24}} md={{span: 12}} lg={{span: 4}}>
            <CardWrapper loading={!isDataLoaded}>
                {costStats}
            </CardWrapper>
        </Col>
        <Col xs={{span: 24}} md={{span: 12}} lg={{span: 4}}>
            <CardWrapper loading={!isDataLoaded}>
                {clickStats}
            </CardWrapper>
        </Col>
    </Row>;
};

export default Stats;
