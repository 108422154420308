import {useGlobalStore} from 'store';

import bindActions from 'store/bindActions';
import testOverlap from 'store/testOverlap';
import invoiceDetailsReducer from 'store/invoiceDetails';

const { Actions } = invoiceDetailsReducer;

const useInvoiceDetails = () => {
    const {state, dispatch} = useGlobalStore();

    const {invoiceDetails} = state;

    // Test if there's an overlapping between the actions and store
    testOverlap(invoiceDetails, Actions);

    return {
        ...bindActions(Actions, dispatch),
        ...invoiceDetails,
    };
};

export default useInvoiceDetails;
