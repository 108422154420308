import client from "services/HurenApiClient";
import * as Types from "./Types";
import { setData } from "../subscription/Actions";

export const openCancellationForm = () => {
    return {
        type: Types.CANCELLATION_FORM_OPEN
    };
};

export const closeCancellationForm = () => {
    return {
        type: Types.CANCELLATION_FORM_CLOSE
    };
};

export const updateCancellation = (companyId, values) => {
    return async function (dispatch) {
        return client.put('/company/' + companyId + '/cancel', {
            "data" :  {
                "cancellation_date" : values.cancellationDate.format('DD-MM-YYYY')
            }
        }).then(res => {
            dispatch(setData(res.data));
        });
    };
};