import {useGlobalStore} from 'store';

import bindActions from 'store/bindActions';
import testOverlap from 'store/testOverlap';
import conversationReducer from 'store/conversation';

const { Actions } = conversationReducer;

const useConversation = () => {
    const {state, dispatch} = useGlobalStore();

    const {conversation} = state;

    // Test if there's an overlapping between the actions and store
    testOverlap(conversation, Actions);

    return {
        ...bindActions(Actions, dispatch),
        ...conversation
    };
};

export default useConversation;
