import React, {useEffect} from 'react';
import { EditOutlined } from '@ant-design/icons';
import {Button, Descriptions} from "antd";
import Card from "components/Card";
import {useTranslation} from "react-i18next";
import {PremiumInfo} from "components/Premium";
import {useLogo, useAccounts} from "hooks";
import {InfoPopover} from "components/InfoPopover";
import OriginalTextSkeleton from "components/TextSkeleton";
import moment from "moment";
import {hasPermission} from "../../../../helpers";
import LogoDrawer from "./LogoDrawer";

const TextSkeleton = (props) => {
    const {
        isDataLoaded,
    } = useLogo();
    return <OriginalTextSkeleton {...props} loading={!isDataLoaded}/>
};

const LogoCard = () => {
    const {t} = useTranslation('common');
    const {activeAccount} = useAccounts();

    const {
        getCompanyLogo,
        openEditForm,
        isDataLoaded,
        payload: {
            logo,
            tagline
        }
    } = useLogo();

    useEffect(() => {
        getCompanyLogo(activeAccount.companyId);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps



    return <>
        <LogoDrawer/>
        <Card
            className="company-profile-card"
            title={<>
                <PremiumInfo/> {t('pages.company.company-logo-card.title')}&nbsp;
                <InfoPopover content={t('pages.company.company-logo-card.info-text')} />
            </>}
            actions={[
                <Button disabled={!hasPermission(activeAccount, 'manage_logo', 'edit')} onClick={() => openEditForm()}>
                    <EditOutlined /> {t('button.text.edit')}
                </Button>
            ]}
        >
            <div style={{textAlign: 'center', marginBottom: '10px'}}>
                <img src={logo + '?cache=' + Date.now()} key={moment.now()} width={300} height={75} />
            </div>
            <Descriptions column={1} className="label-50-percent">
                <Descriptions.Item label={t('pages.company.company-logo-card.label.tagline.label')}>
                    <TextSkeleton>{ tagline }</TextSkeleton>
                </Descriptions.Item>
            </Descriptions>

        </Card>
    </>;
};

export default LogoCard;
