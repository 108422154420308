import client from "services/HurenApiClient";
import * as Types from "./Types";
import {getStatisticsUrl} from "helpers";

export const setIsDataLoaded = (loaded) => {
    return {
        type: Types.SET_IS_DATA_LOADED,
        isDataLoaded: loaded
    };
};

export const setIsDataError = (loaded) => {
    return {
        type: Types.SET_IS_DATA_ERROR,
        isDataError: loaded
    };
};

export const setIsGraphDataLoaded = (loaded) => {
    return {
        type: Types.SET_IS_GRAPH_DATA_LOADED,
        isGraphDataLoaded: loaded
    };
};

export const setIsGraphDataError = (error) => {
    return {
        type: Types.SET_IS_GRAPH_DATA_ERROR,
        isGraphDataError: error
    };
};

export const setIsTotalsDataLoaded = (loaded) => {
    return {
        type: Types.SET_IS_TOTALS_DATA_LOADED,
        isTotalsDataLoaded: loaded
    };
};

export const setIsTotalsDataError = (error) => {
    return {
        type: Types.SET_IS_TOTALS_DATA_ERROR,
        isTotalsDataError: error
    };
};

export const setData = (payload) => {
    return {
        type: Types.SET_DATA,
        payload: payload
    };
};

export const setGraphData = (payload) => {
    return {
        type: Types.SET_GRAPH_DATA,
        payload: payload
    };
};

export const setTotalsData = (payload) => {
    return {
        type: Types.SET_TOTALS_DATA,
        payload: payload
    };
};

export const getClickGraph = (companyId, period = 'day', startDate = null, endDate = null, categories = []) => {
    return async function(dispatch){
        dispatch(setIsGraphDataLoaded(false));

        return client.get(getStatisticsUrl('click-graph', companyId, period, startDate, endDate, categories)).then(res => {
            if ('error' in res.data && res.data.error === true) {
                dispatch(setIsGraphDataError(true));
            } else {
                dispatch(setGraphData(res.data));
            }
        });
    };
};

export const getStatistics = (companyId, period = 'day', startDate = null, endDate = null, categories = []) => {
    return async function(dispatch){
        dispatch(setIsDataLoaded(false));

        return client.get(getStatisticsUrl('table', companyId, period, startDate, endDate, categories)).then(res => {
            if ('error' in res.data && res.data.error === true) {
                dispatch(setIsGraphDataError(true));
            } else {
                dispatch(setData(res.data));
            }
        });
    };
};

export const getTotals = (companyId, period = 'day', startDate = null, endDate = null, categories = []) => {
    return async function(dispatch){
        dispatch(setIsTotalsDataLoaded(false));

        return client.get(getStatisticsUrl('totals', companyId, period, startDate, endDate, categories)).then(res => {
            if ('error' in res.data && res.data.error === true) {
                dispatch(setIsTotalsDataError(true));
            } else {
                dispatch(setTotalsData(res.data));
            }
        });
    };
};

export const getAdvertisementStatistics = (companyId, startDate = null, endDate = null, categories = []) => {
    return async function(dispatch){
        dispatch(setIsDataLoaded(false));

        return client.get(getStatisticsUrl('advertisements', companyId, null, startDate, endDate, categories)).then(res => {
            if ('error' in res.data && res.data.error === true) {
                dispatch(setIsDataError(true));
            } else {
                dispatch(setData(res.data));
            }
        });
    };
};

export const getAdvertisementTotals = (companyId, startDate = null, endDate = null, categories = []) => {
    return async function(dispatch){
        dispatch(setIsTotalsDataLoaded(false));

        return client.get(getStatisticsUrl('advertisements/totals', companyId, null, startDate, endDate, categories)).then(res => {
            if ('error' in res.data && res.data.error === true) {
                dispatch(setIsTotalsDataError(true));
            } else {
                dispatch(setTotalsData(res.data));
            }
        });
    };
};


export const getLocationStatistics = (companyId, startDate = null, endDate = null, categories = []) => {
    return async function(dispatch){
        dispatch(setIsDataLoaded(false));

        return client.get(getStatisticsUrl('locations', companyId, null, startDate, endDate, categories)).then(res => {
            if ('error' in res.data && res.data.error === true) {
                dispatch(setIsDataError(true));
            } else {
                dispatch(setData(res.data));
            }
        });
    };
};

export const getLocationTotals = (companyId, startDate = null, endDate = null, categories = []) => {
    return async function(dispatch){
        dispatch(setIsTotalsDataLoaded(false));

        return client.get(getStatisticsUrl('locations/totals', companyId, null, startDate, endDate, categories)).then(res => {
            if ('error' in res.data && res.data.error === true) {
                dispatch(setIsTotalsDataError(true));
            } else {
                dispatch(setTotalsData(res.data));
            }
        });
    };
};

export const getProductsStatistics = (companyId, startDate = null, endDate = null, categories = []) => {
    return async function(dispatch){
        dispatch(setIsDataLoaded(false));

        return client.get(getStatisticsUrl('product/table', companyId, null, startDate, endDate, categories)).then(res => {
            if ('error' in res.data && res.data.error === true) {
                dispatch(setIsDataError(true));
            } else {
                dispatch(setData(res.data));
            }
        });
    };
};

export const getProductsTotals = (companyId, startDate = null, endDate = null, categories = []) => {
    return async function(dispatch){
        dispatch(setIsTotalsDataLoaded(false));

        return client.get(getStatisticsUrl('product/totals', companyId, null, startDate, endDate, categories)).then(res => {
            if ('error' in res.data && res.data.error === true) {
                dispatch(setIsTotalsDataError(true));
            } else {
                dispatch(setTotalsData(res.data));
            }
        });
    };
};
