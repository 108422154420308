import {useGlobalStore} from 'store';

import bindActions from 'store/bindActions';
import testOverlap from 'store/testOverlap';
import inboxPollReducer from 'store/inboxPoll';

const { Actions } = inboxPollReducer;

const useInboxPoll = () => {
    const {state, dispatch} = useGlobalStore();

    const {inboxPoll} = state;

    // Test if there's an overlapping between the actions and store
    testOverlap(inboxPoll, Actions);

    return {
        ...bindActions(Actions, dispatch),
        ...inboxPoll
    };
};

export default useInboxPoll;
