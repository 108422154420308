import React from "react";
import { PremiumInfo } from "components/Premium";
import { useTranslation } from "react-i18next";
import Drawer from "components/Drawer";
import { useFeatures } from "hooks";
import FeaturesForm from "./FeaturesForm";

const FeaturesDrawer = () => {
  const { t } = useTranslation("common");

  const {
    closeEditForm,
    isEditFormOpen,
  } = useFeatures();

  return (
    <Drawer
      title={
        <>
          <PremiumInfo /> {t("pages.company.features-form.title")}
        </>
      }
      placement="right"
      onClose={() => closeEditForm()}
      open={isEditFormOpen}
      width={520}
    >
      <FeaturesForm/>
    </Drawer>
  );
};

export default FeaturesDrawer;
