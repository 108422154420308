import React from 'react';
import {Col, Row} from "antd";
import LoggedInLayout from "layout/LoggedInLayout";

import {useTranslation} from "react-i18next";
import {AccountInfoCard} from "./components/AccountInfoCard";
import {PasswordCard} from "./components/PasswordCard";

const AccountSettings = () => {
    const { t } = useTranslation('common');

    return <LoggedInLayout pageTitle={t('pages.account.settings.title')}>
        <Row gutter={[16, 16]}>
            <Col md={{span: 24}} lg={{span: 12}}>
                <AccountInfoCard />
            </Col>
        </Row>
        <Row gutter={[16, 16]}>
            <Col md={{span: 24}} lg={{span: 12}}>
                <PasswordCard />
            </Col>
        </Row>
    </LoggedInLayout>;
};

export default AccountSettings;
