import {useGlobalStore} from 'store';

import bindActions from 'store/bindActions';
import testOverlap from 'store/testOverlap';
import dashboardStatsReducer from 'store/dashboardStats';

const { Actions } = dashboardStatsReducer;

const useDashboardStats = () => {
    const {state, dispatch} = useGlobalStore();

    const {dashboardStats} = state;

    // Test if there's an overlapping between the actions and store
    testOverlap(dashboardStats, Actions);

    return {
        ...bindActions(Actions, dispatch),
        ...dashboardStats,
    };
};

export default useDashboardStats;
