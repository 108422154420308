import React, {useEffect} from 'react';
import Card from "components/Card";
import {useTranslation} from "react-i18next";
import {useAccounts, useReviews, useSmallDeviceSize} from "hooks";
import {Button, Col, Divider, Rate, Row, Table} from "antd";
import Media from "react-media";
import moment from "moment";
import {CommentOutlined} from "@ant-design/icons";
import {hasPermission} from "../../../../helpers";
import ReplyDrawer from "./ReplyDrawer";

const dateFormat = 'DD-MM-YYYY';

const ReviewsCard = () => {
    const {t} = useTranslation('common');
    const {activeAccount} = useAccounts();

    const {
        isDataLoaded,
        getReviews,
        replyToReview,
        payload: {
            reviews,
            total
        }
    } = useReviews();

    useEffect(() => {
        getReviews(activeAccount.companyId)
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const isSmallDevice = useSmallDeviceSize();

    const tableColumns = [
        {
            title: t('pages.company.reviews-card.table.author_name'),
            dataIndex: 'author_name',
            key: 'author_name',
            className: 'author-column',
            ellipsis: true,
            sorter: (a, b) => ('' + a.author_name).localeCompare(b.author_name)
        },
        {
            title: t('pages.company.reviews-card.table.author_city'),
            dataIndex: 'author_city',
            key: 'author_city',
            ellipsis: true,
            hideOnResponsive: true,
            sorter: (a, b) => ('' + a.author_city).localeCompare(b.author_city)
        },
        {
            title: t('pages.company.reviews-card.table.rental_date'),
            dataIndex: 'rental_date',
            key: 'rental_date',
            ellipsis: true,
            hideOnResponsive: true,
            sorter: (a, b) => new Date(b.rental_date) - new Date(a.rental_date),
            render: (text, record) => record.rental_date ? <>{moment(record.rental_date).format(dateFormat)}</> : <></>
        },
        {
            title: t('pages.company.reviews-card.table.rating'),
            dataIndex: 'rating',
            key: 'rating',
            width: '20%',
            className: 'rating-column',
            ellipsis: true,
            sorter: (a, b) => b.rating - a.rating,
            render: (text, record) => isSmallDevice ? <>{record.rating*2}</> : <Rate allowHalf={true} count={5} disabled defaultValue={record.rating}/>
        },
        {
            title: t('pages.company.reviews-card.table.clickbox_category'),
            dataIndex: 'clickbox_category',
            key: 'clickbox_category',
            ellipsis: true,
            hideOnResponsive: true,
            sorter: (a, b) => ('' + a.clickbox_category).localeCompare(b.clickbox_category)
        }
    ];

    return <>
        <Card
            className="company-reviews-card"
            title={t('pages.company.reviews-card.title')}
        >
            <ReplyDrawer/>
            <Media query="(min-width: 950px)">
                {matches => {

                    let filteredColumns = tableColumns.filter((column) => {
                        return matches || column.hideOnResponsive !== true;
                    });

                    return <Table
                        tableLayout="fixed"
                        dataSource={reviews}
                        columns={filteredColumns}
                        loading={!isDataLoaded}
                        rowKey="id"
                        expandable={{
                            expandedRowRender: record => {
                                return <>
                                    <Row gutter={[10, 10]}>
                                        <Col sm={24} md={20}>
                                            {record.content}
                                        </Col>
                                        <Col span={4}>
                                            { record.reply_content !== null ? <></> : <Button disabled={!hasPermission(activeAccount, 'manage_reviews', 'edit')} icon={<CommentOutlined />} onClick={() => replyToReview(activeAccount.companyId, record.id)}>{t('button.text.reply')}</Button> }
                                        </Col>
                                    </Row>
                                    { record.reply_content !== null ?
                                        <>
                                        <Divider />
                                        <Row gutter={[10, 10]}>
                                            <Col sm={10} md={2}>{t('pages.company.reviews-card.reply')}</Col>
                                            <Col sm={24} md={18}>
                                                {record.reply_content}
                                            </Col>
                                            <Col span={4}>
                                                <Button icon={<CommentOutlined />} onClick={() => replyToReview(activeAccount.companyId, record.id)}>{t('button.text.edit')}</Button>
                                            </Col>
                                        </Row>
                                        </>
                                        : <></>
                                    }
                                </>
                            },
                            rowExpandable: record => record.content !== null,
                            expandRowByClick: true
                        }}
                        pagination={{
                            showLessItems: true,
                            total: total,
                            showSizeChanger: true
                        }}
                    />
                }}
            </Media>
        </Card>
    </>;
};

export default ReviewsCard;
