import React, {useState} from 'react';
import LoggedInLayout from "layout/LoggedInLayout";
import {
    MessageOutlined,
    EyeOutlined,
    FilePdfOutlined,
    FileExcelOutlined,
    DownloadOutlined,
    DownOutlined
} from "@ant-design/icons";
import {navigate} from "@reach/router";
import {
    Table,
    Button,
    Col,
    Row,
    Space,
    Badge, Dropdown,
} from "antd";
import Card from "components/Card";
import {useChat} from "hooks";
import useMatchBreakpoint from "../../hooks/useMatchBreakpoint";
import {useTranslation} from "react-i18next";
import useFormatting from "../../hooks/useFormatting";
import {createExportQueryData, useGetOrderCollection} from "../../queries/orders";
import {useFetchStatus} from "../../queries/me";
import {getOrderStatus} from "../../helpers/orders";
import {LegacyOrderPrice} from "../../components/Order/LegacyOrderPrice";
import {OrdersFilters} from "./components/OrdersFilters";
import {OrderStatusTag} from "../../components/Order/OrderStatusTag";
import {createQueryString} from "../../helpers/queryString";
import {b64toBlob} from "../../helpers";
import client from "services/HurenApiClient";
import {saveAs} from "file-saver";

const Orders = () => {
    const matchBreakpoint = useMatchBreakpoint();
    const { data: accountStatus } = useFetchStatus();

    const { t } = useTranslation('common');
    const { formatDateTime, formatCents } = useFormatting();

    const [collectionParameters, setCollectionParameters] = useState({
        page: 1,
        limit: 10,
        searchQuery: null,
        filters: {
            state: null,
            // Set initial date constraints for range picker
            createdAfter: null,
            createdBefore: null
        },
        sort: null
    });

    const { data: orderCollection, isLoading: orderCollectionIsLoading } = useGetOrderCollection(collectionParameters);

    const { doOpenChat } = useChat();

    const onExport = (type) => {
        const contentType = type === 'pdf' ? 'application/pdf;charset=utf-8' : 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        const exportQueryData = createExportQueryData(collectionParameters, type);

        client.get('/api/v2/orders?' + createQueryString(exportQueryData)).then(res => {
            let blob = b64toBlob(res.data.file, contentType);
            saveAs(blob, res.data.filename);
        });
    }

    const onFilter = (filters) => {
        const searchQuery = filters.searchQuery === '' ? null : filters.searchQuery;
        const state = filters.state === '' ? null : filters.state;
        const collectionFilters = {
            state: state,
            createdAfter: filters.createdAfter,
            createdBefore: filters.createdBefore,
        };

        setCollectionParameters(prevState => {
            return {...prevState, searchQuery: searchQuery, filters: collectionFilters}
        });
    };

    const onChangeTable = (pagination, f, sorter) => {
        let sort = null;
        if (sorter.columnKey && sorter.order) {
            sort = (sorter.order === 'descend' ? '-' : '') + sorter.columnKey;
        }
        setCollectionParameters(prevState => {
            return {... prevState, page: pagination.current, limit: pagination.pageSize, sort: sort}
        })
    };

    const filters = {
        state: collectionParameters.filters.state ?? '',
        createdAfter: collectionParameters.filters.createdAfter,
        createdBefore: collectionParameters.filters.createdBefore,
        searchQuery: collectionParameters.searchQuery ?? '',
    };

    const exportDropdownMenu = {
        items: [
            {
                key: '1',
                label: (
                    <a onClick={() => onExport('pdf')}>
                        <FilePdfOutlined style={{marginRight: '5px'}}/>
                        PDF document
                    </a>
                )
            },
            {
                key: '2',
                label: (
                    <a onClick={() => onExport('xlsx')}>
                        <FileExcelOutlined style={{marginRight: '5px'}}/>
                        Excelsheet
                    </a>
                )
            }
        ]
    };

    return (
        <LoggedInLayout
            pageTitle={t('pages:orders.title')}
            headerExtras={
                [
                    <Dropdown key="export" menu={exportDropdownMenu}>
                        <Button>
                            <DownloadOutlined />
                            Exporteer
                            <DownOutlined />
                        </Button>
                    </Dropdown>
                ]
            }
        >
            <Row gutter={[16, 16]}>
                <Col lg={24} md={24} sm={24} xs={24}>
                    <Card
                        style={{ marginBottom: '16px' }}
                        title={t('pages:orders.title')}
                    >
                        <OrdersFilters filters={filters} onChange={onFilter} />
                        <Table
                            loading={orderCollectionIsLoading}
                            dataSource={orderCollection?.items}
                            rowKey={"id"}
                            scroll={{ x: 1080 }}
                            onChange={onChangeTable}
                            pagination={{
                                showLessItems: true,
                                total: orderCollection?.total,
                                showSizeChanger: true
                            }}
                        >
                            <Table.Column
                                title={t('entities:order.properties.created-at')}
                                dataIndex="created_at"
                                key="created_at"
                                sorter={true}
                                width={170}
                                render={(text) => {
                                    return formatDateTime(text);
                                }}
                            />
                            <Table.Column
                                title="#"
                                dataIndex="id"
                                key="id"
                                sorter={true}
                                width={80}
                            />
                            <Table.Column
                                title={t('entities:order.properties.name')}
                                dataIndex="name"
                                key="name"
                                sorter={true}
                                render={(_, order) => {
                                    return `${order.billing_address.first_name} ${order.billing_address.last_name}`;
                                }}
                            />
                            <Table.Column
                                title={t('entities:order.properties.product-name')}
                                dataIndex="product_name"
                                key="product_name"
                                sorter={true}
                                render={(_, order) => {
                                    let productName = order.clickbox_category.title;

                                    if (order.rental_item) {
                                        productName = `${productName} | ${order.rental_item.name}`
                                    }

                                    return productName;
                                }}
                            />
                            <Table.Column
                                title={t('entities:order.properties.date-from')}
                                dataIndex="date_from"
                                key="date_from"
                                width={160}
                                sorter={true}
                                render={(text) => {
                                    return formatDateTime(text);
                                }}
                            />
                            <Table.Column
                                title={t('entities:order.properties.total')}
                                dataIndex="price_incl"
                                key="price_incl"
                                width={150}
                                sorter={true}
                                render={(text, order) => {
                                    if (order.type === 'legacy') {
                                        return <LegacyOrderPrice />
                                    }
                                    return <>
                                        {formatCents(order.price_incl)}<br/>
                                        <small>{formatCents(order.price_excl)} {t('common:pricing.ex-vat')}</small>
                                    </>;
                                }}
                            />
                            <Table.Column
                                title={t('entities:order.properties.status')}
                                dataIndex="state"
                                key="state"
                                fixed="right"
                                sorter={true}
                                align={matchBreakpoint({ xs: 'right', md: 'left' })}
                                width={matchBreakpoint({ xs: 50, md: 155 })}
                                render={(text, order) => {
                                    return <OrderStatusTag order={order} small={matchBreakpoint({ xs: true, md: false})} />
                                }}
                            />
                            <Table.Column
                                title={t('common:tables.common.column.actions')}
                                fixed="right"
                                align="right"
                                width={matchBreakpoint({ xs: 130, md: 170 })}
                                render={(text, order) => {
                                    const orderStatus = getOrderStatus(order, accountStatus);
                                    return <Space size={0} direction={"horizontal"}>
                                        <Button
                                            size="middle"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                navigate(`/orders/${order.id}`)
                                            }}
                                        >
                                            <EyeOutlined/> {t('common:tables.common.action.show')}
                                        </Button>
                                        <Button
                                            size="middle"
                                            onClick={(e) => {
                                                doOpenChat(order.conversation.id);
                                            }}
                                        >
                                            <Badge size="small" count={orderStatus.unread_messages_count}>
                                                <MessageOutlined/>
                                            </Badge>
                                        </Button>
                                    </Space>
                                }}
                            />
                        </Table>
                    </Card>
                </Col>
            </Row>
        </LoggedInLayout>
    );
};

export default Orders;
