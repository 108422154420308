import React from "react";
import {Card, Descriptions} from "antd";
import {useTranslation} from "react-i18next";
import {useGetOrderExtraFormValues} from "../../../queries/orders";

export const OrderExtraFormValuesCard = ({orderId: orderId}) => {
    const { t } = useTranslation('common');
    const { data: extraFormValues, isLoading: isLoading } = useGetOrderExtraFormValues(orderId);

    if (!isLoading && extraFormValues.items.length <= 0) {
    	return <></>;
    }

    return <Card
	        className={'order-card ant-card-small'}
	        title={t('components:order-details.extra-information.title')}
	    >
	        <Descriptions column={1} bordered={true}>
				{ !isLoading && extraFormValues.items.map((item, key) => {
					let value = item.value;
		            if (value === "no") {
		                value = t('entities:order.delivery_question_answer.delivery_no')
		            } else if (value === "yes") {
		                value = t('entities:order.delivery_question_answer.delivery_yes')
		            }

					return <Descriptions.Item label={t('entities:order.extra_form_values.' + item.name)} key={key}>
						{value}
					</Descriptions.Item>
				}) }
			</Descriptions>
    </Card>;
};