import {CheckOutlined} from '@ant-design/icons';
import {Button, Comment, Descriptions, Divider, Form, notification, Skeleton, Tooltip} from "antd";
import React, {useEffect} from "react";
import TextareaCharacterCount from "components/TextareaCharacterCount";
import {useTranslation} from "react-i18next";
import {useAccounts, useReviews} from "hooks";
import moment from "moment";
import 'moment/locale/nl';

const ReplyForm = () => {
    const {t} = useTranslation('common');
    const {activeAccount} = useAccounts();
    const [form] = Form.useForm();

    const {
        getReviews,
        putReviewReply,
        isReplyFormDataLoaded,
        closeReplyForm,
        replyFormPayload
    } = useReviews();

    const onFinish = (values) => {
        putReviewReply(activeAccount.companyId, replyFormPayload.id, values)
            .then((res) => {
                notification.open({
                    message: t('notification.messages.saved.title'),
                    description: t('notification.messages.saved.description'),
                });

                getReviews(activeAccount.companyId);
                closeReplyForm();
            })
            .catch(e => {
                console.log(e);
                notification.open({
                    message: t('notification.messages.error.title'),
                    description: t('notification.messages.error.description'),
                });
            });
    };

    useEffect(() => {
        form.resetFields(); // reload fields to initial value on re-render
    }, [replyFormPayload]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <Skeleton loading={!isReplyFormDataLoaded}>
                <Comment
                    author={<>{replyFormPayload.author_name} {t('pages.company.review-reply-form.from')} {replyFormPayload.author_city}</>}
                    content={<>
                        <p>
                            {replyFormPayload.content}
                        </p>
                        <Descriptions column={2}>
                            {replyFormPayload.clickbox_category !== '' ?
                                <Descriptions.Item label={t('pages.company.review-reply-form.what')}>
                                    <a href={replyFormPayload.clickbox_category_url} target="_blank" rel="noopener noreferrer">{replyFormPayload.clickbox_category}</a>
                                </Descriptions.Item> : <></>
                            }
                            {replyFormPayload.rental_date !== null ?
                                <Descriptions.Item label={t('pages.company.review-reply-form.when')}>
                                    {moment(replyFormPayload.rental_date).format('DD-MM-YYYY')}
                                </Descriptions.Item>
                                : <></>
                            }
                        </Descriptions>
                    </>
                    }
                    datetime={
                        replyFormPayload.rental_date === null ?
                            <></>
                            :
                            <Tooltip title={moment(replyFormPayload.rental_date).format('YYYY-MM-DD')}>
                                <span>{moment(replyFormPayload.rental_date).locale('nl').fromNow()}</span>
                            </Tooltip>
                    }
                />

            </Skeleton>

            <Divider />

            <Form
                form={form}
                layout="vertical"
                onFinish={onFinish}
                initialValues={{
                    replyContent: replyFormPayload.reply_content
                }}
            >
                <Skeleton loading={!isReplyFormDataLoaded}>
                    <Form.Item
                        name="replyContent"
                        label={t('pages.company.review-reply-form.reply-text.label')}
                        rules={[{type: 'string', max: 500, message: t('form.errors.max-length')}]}
                    >
                        <TextareaCharacterCount max={500} rows={10}/>
                    </Form.Item>
                    <div className="actions">
                        <Button onClick={() => closeReplyForm()} style={{marginRight: '8px'}}>
                            {t('button.text.cancel')}
                        </Button>
                        <Button htmlType="submit" type="primary">
                            <CheckOutlined/>
                            {t('button.text.save')}
                        </Button>
                    </div>
                </Skeleton>
            </Form>
        </>
    );
};

export default ReplyForm;
