import React, {useEffect} from 'react';
import {
    Statistic,
    Divider
} from "antd";
import {useTranslation} from "react-i18next";
import {useAccounts, useDailyBudget} from "hooks";

const AdvertisementsStats = ({active_ads, inactive_ads}) => {
    const { t } = useTranslation('common');
    const {activeAccount} = useAccounts();

    const {
        getBudgetData,
        payload: {
            unlimited_budget,
            current_daily_limit,
            budget_used_today
        }
    } = useDailyBudget();

    const inactiveBudget = () => {
        if (!unlimited_budget) {
            const dailyBudgetExceeded = budget_used_today >= current_daily_limit;
            if (dailyBudgetExceeded || current_daily_limit === 0) {
                return true;
            }
        }
        return false;
    }

    useEffect(() => {
        getBudgetData(activeAccount.companyId);
    }, [activeAccount]); // eslint-disable-line react-hooks/exhaustive-deps

    return <>
        <Statistic title={t('pages.dashboard.stats.advertisement.active-ads')} value={inactiveBudget() ? '-' : active_ads} decimalSeparator="," groupSeparator="." />
        <Divider/>
        <Statistic title={t('pages.dashboard.stats.advertisement.inactive-ads')} value={inactiveBudget() ? '-' : inactive_ads} decimalSeparator="," groupSeparator="." />
    </>;
};

export default AdvertisementsStats;
