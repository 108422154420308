import {useGlobalStore} from 'store';

import bindActions from 'store/bindActions';
import testOverlap from 'store/testOverlap';
import rentalItemReducer from 'store/rentalItem';

const { Actions } = rentalItemReducer;

const useRentalItem = () => {
    const {state, dispatch} = useGlobalStore();

    const {rentalItem} = state;

    // Test if there's an overlapping between the actions and store
    testOverlap(rentalItem, Actions);

    return {
        ...bindActions(Actions, dispatch),
        ...rentalItem
    };
};

export default useRentalItem;
