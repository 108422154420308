import apiClient from "./HurenApiClient";

export default class TokenStorage {
    static LOCAL_STORAGE_TOKEN = 'token';
    static LOCAL_STORAGE_REFRESH_TOKEN = 'refresh_token';

    static isAuthenticated() {
        return this.getToken() !== null;
    }

    static getAuthentication() {
        return {
            'Authorization': 'Bearer ' + this.getToken(),
            'Content-Type': 'application/json'
        };
    }

    static getNewToken() {
        return new Promise((resolve, reject) => {
            const refreshUrl = process.env.REACT_APP_MERCHANT_API_AUTH_URL + '/token/refresh';
            apiClient
                .post(refreshUrl,
                    {
                        refresh_token: this.getRefreshToken()
                    }
                )
                .then(response => {
                    if (process.env.NODE_ENV === 'development') {
                        console.debug('Refresh token succesfull', response);
                    }
                    TokenStorage.storeToken(response.data.token);
                    TokenStorage.storeRefreshToken(response.data.refresh_token);

                    resolve(response.data.token);
                })
                .catch((error) => {
                    console.debug('Refresh token error: ', error.response);
                    reject(error);
                });
        });
    }

    static storeToken(token) {
        localStorage.setItem(TokenStorage.LOCAL_STORAGE_TOKEN, token);
    }

    static storeRefreshToken = (refreshToken) => {
        localStorage.setItem(TokenStorage.LOCAL_STORAGE_REFRESH_TOKEN, refreshToken);
    }

    static clear = () => {
        localStorage.removeItem(TokenStorage.LOCAL_STORAGE_TOKEN);
        localStorage.removeItem(TokenStorage.LOCAL_STORAGE_REFRESH_TOKEN);
    }

    static getRefreshToken() {
        return localStorage.getItem(TokenStorage.LOCAL_STORAGE_REFRESH_TOKEN);
    }

    static getToken() {
        return localStorage.getItem(TokenStorage.LOCAL_STORAGE_TOKEN);
    }
}