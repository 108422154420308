import {useQuery} from "react-query";
import client from "services/HurenApiClient";

const fetchSubscriptionMetrics = async () => {
    const res = await client.get('/api/v2/subscription/metrics');
    return res.data;
};

export const useGetSubscriptionMetrics = () => useQuery('subscription-metrics', fetchSubscriptionMetrics);

const fetchSubscriptionFeatures = async () => {
    const res = await client.get('/api/v2/subscription/features');
    return res.data;
};

export const useGetSubscriptionFeatures = () => useQuery('subscription-features', fetchSubscriptionFeatures);