import React from 'react';
import { Result, Button } from 'antd';
import {Link} from "@reach/router";
import {HomeOutlined} from "@ant-design/icons"
import {useTranslation} from "react-i18next";

const NotFound = () => {
    const { t } = useTranslation('common');

    return  <Result
        title={t('pages.not-found.title')}
        subTitle={t('pages.not-found.sub-title')}
        extra={<Link to="/"><Button icon={<HomeOutlined />}> {t('pages.not-found.link')}</Button></Link>}
    />;
};

export default NotFound;
