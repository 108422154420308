import {useQuery} from "react-query";
import {createQueryString} from "../helpers/queryString";
import apiClient from "../services/HurenApiClient";

const fetchGeoLocations = (queryData) => async () => {
    let url = `/api/v2/geo-locations`;
    if (queryData) {
        url = url + '?' + createQueryString(queryData);
    }
    const res = await apiClient.get(url);
    return res.data.items;
};
export const useGetGeoLocations = (queryData) => {
    return useQuery(['geo-locations', queryData], fetchGeoLocations(queryData));
};