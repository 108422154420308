import React, {forwardRef, useEffect, useImperativeHandle, useState} from 'react';
import Card from "components/Card";
import {useAccounts} from "hooks";
import {useTranslation} from "react-i18next";
import {b64toBlob} from "../../../helpers";
import client from "services/HurenApiClient";
import {saveAs} from "file-saver";
import {RentalMatchesTable} from "./RentalMatchesTable";
import {RentalMatchesFilters} from "./RentalMatchesFilters";
import {useGetRentalMatchCollection} from "../../../queries/rentalMatches";
import moment from "moment";
import {createQueryString} from "../../../helpers/queryString";

const createExportQueryData = (collectionParameters, outputType) => {
    const queryData = {};
    if (collectionParameters.searchQuery) {
        queryData.searchQuery = collectionParameters.searchQuery;
    }
    queryData.filters = {
        createdAfter: collectionParameters.filters.createdAfter.toISOString(),
        createdBefore: collectionParameters.filters.createdBefore.toISOString(),
    };
    if (collectionParameters.filters.status) {
        queryData.filters.status = collectionParameters.filters.status;
    }

    if (collectionParameters.sort) {
        queryData.sort = collectionParameters.sort;
    }

    queryData['_output'] = outputType;

    return queryData;
};

const RentalMatchesCard = forwardRef((props, ref) => {
	const { t } = useTranslation('common');
    const {activeAccount} = useAccounts();

    const [collectionParameters, setCollectionParameters] = useState({
        page: 1,
        limit: 10,
        searchQuery: null,
        filters: {
            status: null,
            createdAfter: moment().subtract(1, 'month'),
            createdBefore: moment(),
        },
        sort: null
    });

    const { data: rentalMatchesCollection, isLoading: rentalMatchesCollectionIsLoading } = useGetRentalMatchCollection(collectionParameters);

    // We store the table data separately, so the table doesn't jump around when loading new data
    const [tableData, setTableData] = useState({
        items: [],
        total: 0,
    });
    useEffect(() => {
        if (!rentalMatchesCollection) {
            return;
        }
        setTableData({
            items: rentalMatchesCollection.items,
            total: rentalMatchesCollection.total,
        });
    }, [rentalMatchesCollection]);

    const onFilter = (filters) => {
        const searchQuery = filters.searchQuery === '' ? null : filters.searchQuery;
        const status = filters.status === '' ? null : filters.status;
        const collectionFilters = {
            status: status,
            createdAfter: filters.startDate,
            createdBefore: filters.endDate,
        };

        setCollectionParameters(prevState => {
            return {...prevState, searchQuery: searchQuery, filters: collectionFilters}
        });
    };

    const onChangeTable = (pagination, f, sorter) => {
        let sort = null;
        if (sorter.columnKey && sorter.order) {
            sort = (sorter.order === 'descend' ? '-' : '') + sorter.columnKey;
        }
        setCollectionParameters(prevState => {
            return {... prevState, page: pagination.current, limit: pagination.pageSize, sort: sort}
        })
    };

    const filters = {
        status: collectionParameters.filters.status ?? '',
        startDate: collectionParameters.filters.createdAfter,
        endDate: collectionParameters.filters.createdBefore,
        searchQuery: collectionParameters.searchQuery ?? '',
    };

    useImperativeHandle(ref, () => ({
        downloadClicked(type) {
            download(type);
        }
    }));

    const download = (type) => {
        const contentType = type === 'pdf' ? 'application/pdf;charset=utf-8' : 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        const exportQueryData = createExportQueryData(collectionParameters, type);

        client.get('/api/v2/rental-matches?' + createQueryString(exportQueryData)).then(res => {
            let blob = b64toBlob(res.data.file, contentType);
            saveAs(blob, res.data.filename);
        });
    }

	return <>
        <Card title={t('tables.rental-matches.title')}>
            <RentalMatchesFilters onChange={onFilter} filters={filters} />
            <RentalMatchesTable
                loading={rentalMatchesCollectionIsLoading}
                rentalMatches={tableData.items}
                filters={filters}
                onChange={onChangeTable}
                total={tableData.total}
            />
        </Card>
    </>;
});

export default RentalMatchesCard;