import {useGlobalStore} from 'store';

import bindActions from 'store/bindActions';
import testOverlap from 'store/testOverlap';
import chatReducer from 'store/chat';

const { Actions } = chatReducer;

const useChat = () => {
    const {state, dispatch} = useGlobalStore();

    const {chat} = state;

    // Test if there's an overlapping between the actions and store
    testOverlap(chat, Actions);

    return {
        ...bindActions(Actions, dispatch),
        ...chat
    };
};

export default useChat;
