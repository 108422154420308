import React, {useEffect, useRef} from 'react';
import Card from "components/Card";
import {useTranslation} from "react-i18next";
import OriginalTextSkeleton from "components/TextSkeleton";
import {ContactForm} from "components/ContactForm";
import {useAccounts, usePaymentDetails} from "hooks";
import {Button, Descriptions} from "antd";
import {EditOutlined} from "@ant-design/icons";
import PaymentDetailsForm from "./PaymentDetailsForm";

const TextSkeleton = props => {
    const {
        isDataLoaded,
    } = usePaymentDetails();
    return <OriginalTextSkeleton {...props} loading={!isDataLoaded}/>
}

const PaymentDetailsCard = () => {
    const {t} = useTranslation('common');
    const { activeAccount } = useAccounts();
    const { openEditForm } = usePaymentDetails();

    const {
        getCompanyPaymentDetails,
        isDataLoaded,
        payload: {
            iban,
            account_name,
            bank,
            bank_city,
            authorisation,
            mandate_id,
            mandate_date
        }
    } = usePaymentDetails();

    useEffect(() => {
        getCompanyPaymentDetails(activeAccount.companyId)
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const contactFormRef = useRef();
    const openContactForm = () => {
        contactFormRef.current.openForm();
    }

    return <>
        <ContactForm ref={contactFormRef} subject={t('forms.contact-form.subject.payment-details')} title={t('forms.contact-form.title.contact')} type="contact" />
        <Card
            className="company-payment-details-card"
            title={t('pages.company.payment-details-card.title')}>
            <PaymentDetailsForm />
            { authorisation ?
                <>
                    <Descriptions column={1} className="label-50-percent">
                        <Descriptions.Item key={1} label={t('pages.company.payment-details-card.label.iban.label')}>
                            <TextSkeleton>{iban}</TextSkeleton>
                        </Descriptions.Item>
                        <Descriptions.Item key={2} label={t('pages.company.payment-details-card.label.account-name.label')}>
                            <TextSkeleton>{account_name}</TextSkeleton>
                        </Descriptions.Item>
                        <Descriptions.Item key={3} label={t('pages.company.payment-details-card.label.bank.label')}>
                            <TextSkeleton>{bank}</TextSkeleton>
                        </Descriptions.Item>
                        <Descriptions.Item key={4} label={t('pages.company.payment-details-card.label.bank-city.label')}>
                            <TextSkeleton>{bank_city}</TextSkeleton>
                        </Descriptions.Item>
                        <Descriptions.Item key={5} label={t('pages.company.payment-details-card.label.authorisation.label')}>
                            <TextSkeleton>{authorisation ? t('yes-no.yes') : t('yes-no.no')}</TextSkeleton>
                        </Descriptions.Item>
                        <Descriptions.Item key={6} label={t('pages.company.payment-details-card.label.mandate-id.label')}>
                            <TextSkeleton>{mandate_id}</TextSkeleton>
                        </Descriptions.Item>
                        <Descriptions.Item key={7} label={t('pages.company.payment-details-card.label.mandate-date.label')}>
                            <TextSkeleton>{mandate_date}</TextSkeleton>
                        </Descriptions.Item>
                    </Descriptions>
                    <div style={{textAlign: 'center'}}>
                        <Button onClick={openContactForm}>
                            <EditOutlined />
                            {t('button.text.edit')}
                        </Button>
                    </div>
                </>
                :
                <>
                    <TextSkeleton>{t('pages.company.payment-details-card.no-authorisation')}</TextSkeleton>
                    <div style={{textAlign: 'center'}}>
                        <Button onClick={() => openEditForm()}>
                        <EditOutlined />
                            {t('button.text.setup-automatic-payments')}
                        </Button>
                    </div>
                </>
                }

        </Card>
    </>;
};

export default PaymentDetailsCard;
