import React, {useEffect, useRef} from 'react';
import {Button, Descriptions} from "antd";
import Card from "components/Card";
import {useTranslation} from "react-i18next";
import OriginalTextSkeleton from "components/TextSkeleton";
import {useAccounts, useInvoiceDetails} from "hooks";
import {EditOutlined} from "@ant-design/icons";
import {ContactForm} from "components/ContactForm";

const TextSkeleton = props => {
    const {
        isDataLoaded,
    } = useInvoiceDetails();
    return <OriginalTextSkeleton {...props} loading={!isDataLoaded}/>;
}

const InvoiceDetailsCard = () => {
    const {t} = useTranslation('common');
    const { activeAccount } = useAccounts();

    const {
        getCompanyInvoiceDetails,
        isDataLoaded,
        payload: {
            name,
            contact_name,
            address,
            post_code,
            city,
            country,
            phone,
            emails,
            chamber_of_commerce_number,
            vat_number,
            invoice_reference
        }
    } = useInvoiceDetails();

    useEffect(() => {
        getCompanyInvoiceDetails(activeAccount.companyId)
    }, []); // eslint-disable-line react-hooks/exhaustive-deps



    const contactFormRef = useRef();
    const openContactForm = () => {
        contactFormRef.current.openForm();
    }

    return <>
        <ContactForm ref={contactFormRef} subject={t('forms.contact-form.subject.invoice-details')} title={t('forms.contact-form.title.contact')} type="contact" />
        <Card
            className="company-invoice-details-card"
            title={t('pages.company.invoice-details-card.title')}
        >
            <Descriptions column={1} className="label-50-percent">
                <Descriptions.Item key={1} label={t('pages.company.invoice-details-card.label.company-name.label')}>
                    <TextSkeleton>{name}</TextSkeleton>
                </Descriptions.Item>
                <Descriptions.Item key={2} label={t('pages.company.invoice-details-card.label.contact-name.label')}>
                    <TextSkeleton>{contact_name}</TextSkeleton>
                </Descriptions.Item>
                <Descriptions.Item key={3} label={t('pages.company.invoice-details-card.label.address.label')}>
                    <TextSkeleton>{address}</TextSkeleton>
                </Descriptions.Item>
                <Descriptions.Item key={4} label={t('pages.company.invoice-details-card.label.post-code-city.label')}>
                    <TextSkeleton>{post_code} {city}</TextSkeleton>
                </Descriptions.Item>
                <Descriptions.Item key={5} label={t('pages.company.invoice-details-card.label.country.label')}>
                    <TextSkeleton>{country}</TextSkeleton>
                </Descriptions.Item>
                <Descriptions.Item key={6} label={t('pages.company.invoice-details-card.label.phone.label')}>
                    <TextSkeleton>{phone}</TextSkeleton>
                </Descriptions.Item>
                <Descriptions.Item key={7} label={t('pages.company.invoice-details-card.label.email-contacts.label')}>
                    <TextSkeleton>{emails}</TextSkeleton>
                </Descriptions.Item>
                <Descriptions.Item key={8} label={t('pages.company.invoice-details-card.label.chamber-commerce-number.label')}>
                    <TextSkeleton>{chamber_of_commerce_number}</TextSkeleton>
                </Descriptions.Item>
                <Descriptions.Item key={9} label={t('pages.company.invoice-details-card.label.vat-number.label')}>
                    <TextSkeleton>{vat_number}</TextSkeleton>
                </Descriptions.Item>
                <Descriptions.Item key={10} label={t('pages.company.invoice-details-card.label.invoice-reference.label')}>
                    <TextSkeleton>{invoice_reference}</TextSkeleton>
                </Descriptions.Item>
            </Descriptions>
            <div style={{textAlign: 'center'}}>
                <Button onClick={openContactForm}>
                    <EditOutlined />
                    {t('button.text.edit')}
                </Button>
            </div>
        </Card>
    </>;
};

export default InvoiceDetailsCard;
