import React, {useEffect, useState} from 'react';
import LoginLayout from "layout/LoginLayout";
import {useTranslation} from "react-i18next";
import {Button, Card, Col, Divider, Row, Select} from "antd";
import {navigate} from "@reach/router";
import {useAccounts, useAuth} from "hooks";
import {MailOutlined,PhoneOutlined} from "@ant-design/icons";
import TokenStorage from "services/TokenStorage";
import client from "services/HurenApiClient";
import { useLocation } from "@reach/router"
const crypto= require('crypto');

const AccountSelector = () => {
    const { t } = useTranslation('common');
    const {activeAccount} = useAuth();
    const [selectedAccount, setSelectedAccount] = useState(null);

    const location = useLocation();
    const search = new URLSearchParams(location.search);
    let returnTo = search.get('returnTo');
    if (!returnTo || returnTo === '') {
        returnTo = '/';
    }

    const {
        getAccounts,
        chooseAccount,
        accountChosen,
        isDataLoaded,
        userAccounts,
    } = useAccounts();

    useEffect(() => {
        getAccounts()
    }, [activeAccount]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (userAccounts.length === 1) {
            accountPicked(userAccounts[0].company.id);
        }
    }, [userAccounts.length === 1]); // eslint-disable-line react-hooks/exhaustive-deps

    const accountPicked = companyId => {
        let account = userAccounts.find(account => {
            return account.company.id === companyId && account.id !== null;
        });

        if (account !== undefined) {
            chooseAccount(account.id, companyId).then(res => {
                TokenStorage.storeToken(res.data.token);

                window.Intercom('boot', {
                    app_id: window.INTERCOM_APP_ID,
                    email: account.email,
                    name: account.first_name + ' ' + account.last_name,
                    user_id: account.user_id,
                    user_hash: crypto.createHmac('sha256', process.env.REACT_APP_INTERCOM_VERIFICATION_ID.toString()).update(account.user_id.toString()).digest('hex'),
                    company: {
                        id: account.company.id,
                        name: account.company.name,
                    }
                });

                client.get('/api/v2/me/permissions').then(res => {
                    const permissions = res.data;
                    accountChosen(
                        {
                            accountId: account.id,
                            email: account.email,
                            firstName: account.first_name,
                            lastName: account.last_name,
                            gender: account.gender,
                            phoneNumber: account.phone_number,
                            companyName: account.company.name,
                            companyId: account.company.id,
                            hasBudget: account.company.permissions.has_budget,
                            permissions: permissions
                        }
                    );

                    navigate(returnTo);
                });
            });

        } else {
            chooseAccount(null, companyId).then(res => {
                TokenStorage.storeToken(res.data.token);
                let account = userAccounts.find(account => {
                    return account.company.id === companyId;
                });
                account.id = res.data.account_id;

                window.Intercom('boot', {
                    app_id: window.INTERCOM_APP_ID,
                    email: account.email,
                    name: account.first_name + ' ' + account.last_name,
                    user_id: account.user_id,
                    user_hash: crypto.createHmac('sha256', process.env.REACT_APP_INTERCOM_VERIFICATION_ID.toString()).update(account.user_id.toString()).digest('hex'),
                    company: {
                        id: account.company.id,
                        name: account.company.name,
                    }
                });

                client.get('/api/v2/me/permissions').then(res => {
                    const permissions = res.data;
                    accountChosen(
                        {
                            accountId: account.id,
                            email: account.email,
                            firstName: account.first_name,
                            lastName: account.last_name,
                            gender: account.gender,
                            phoneNumber: account.phone_number,
                            companyName: account.company.name,
                            companyId: account.company.id,
                            hasBudget: account.company.permissions.has_budget,
                            permissions: permissions
                        }
                    );

                    navigate(returnTo);
                });
            });
        }
    };

    function handleChange(value) {
        setSelectedAccount(value);
    }

    return <LoginLayout pageTitle={t('pages.account.choose.title')}>
        <Row type="flex" justify="center" align="middle" style={{minHeight: '90vh'}}>
            <Col sm={10} md={8} xl={6}>
                    <Card loading={!isDataLoaded} className="account-chooser-card" title={t((userAccounts.length === 0 && isDataLoaded)?'pages.account.empty.title':'pages.account.choose.title')} >
                {userAccounts.length === 0 ?
                    <Row gutter={[16,16]}>
                        <Col sm={24}>
                            {t('pages.account.empty.line1')}
                        </Col>
                        <Col sm={24}>
                            <Button type="primary" block size="large" href='https://www.huren.nl/contact-bedrijven' target="_blank" icon={<MailOutlined/>}>
                                {t('pages.account.empty.button.contact')}
                            </Button>
                        </Col>
                        <Col sm={24}>
                            {t('pages.account.empty.line2')}
                        </Col>
                        <Col sm={24}>
                            <Button type="primary" block size="large" href='tel:030-2270035' icon={<PhoneOutlined/>}>
                                {t('pages.account.empty.button.phone')}
                            </Button>
                        </Col>
                    </Row>
                :
                    userAccounts.length > 10 ?
                        <>
                            <Row gutter={[16,16]}>
                                <Select
                                    id='accountSelector'
                                    showSearch
                                    style={{ width: '100%' }}
                                    placeholder="Select a company to manage"
                                    onChange={handleChange}
                                    optionFilterProp="label"
                                    options={
                                        userAccounts.map((account, i) => {
                                            return {
                                                label: account.company.name,
                                                value: account.company.id
                                            }
                                        })
                                    }
                                />
                            </Row>
                            <Row>
                                <Button type="primary" block size="large" disabled={selectedAccount === null} onClick={() => accountPicked(selectedAccount)}>
                                    Login
                                </Button>
                            </Row>
                        </> :
                        userAccounts.map((account, i) => {
                            return (
                                <div key={account.id}>
                                    <Button type="primary" block size="large"
                                            onClick={() => accountPicked(account.company.id)}>
                                        {account.company.name}
                                    </Button>
                                    <Divider/>
                                </div>
                            )
                        })
                    }
                </Card>
            </Col>
        </Row>
    </LoginLayout>;
};

export default AccountSelector;
