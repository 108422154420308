import {useGlobalStore} from 'store';

import bindActions from 'store/bindActions';
import testOverlap from 'store/testOverlap';
import localeReducer from 'store/locale';

const { Actions } = localeReducer;

const useLocale = () => {
    const {state, dispatch} = useGlobalStore();

    const {locale} = state;

    // Test if there's an overlapping between the actions and store
    testOverlap(locale, Actions);

    return {
        ...bindActions(Actions, dispatch),
        ...locale,
    };
};

export default useLocale;
