import React, {useEffect} from 'react';
import {renderToStaticMarkup} from 'react-dom/server';
import LoggedInLayout from "layout/LoggedInLayout";
import Card from "components/Card";
import {Trans, useTranslation} from "react-i18next";
import {Col, Row, Skeleton, Button, Typography} from "antd";
import {DownloadOutlined} from '@ant-design/icons';
import {usePromo, useAccounts, useSmallDeviceSize} from "hooks";

const {Text} = Typography;

const CardWrapper = (props) => {
    return <Card {...props}>
        {props.loading ? <Skeleton active={true}/> : props.children}
    </Card>
};

const Promo = () => {

    const { t } = useTranslation('common');

    const { 
        getPromoInfo, 
        isDataLoaded, 
        payload: {
            company_profile_url,
            company_widget_url,
            huurtopper_kit_download_url
        }
    } = usePromo();

    const { activeAccount } = useAccounts();
    useEffect(() => {
        getPromoInfo(activeAccount.companyId);
    }, [activeAccount]); // eslint-disable-line react-hooks/exhaustive-deps

    const getWidgetExample = () => {
        return <a href={company_profile_url} style={{display: "block", width: "160px", height: "160px", background: `transparent url(${company_widget_url}) 0 0 no-repeat`, textIndent: "-3000em", position: "relative"}}>{`Beoordeel ` + activeAccount.companyName}</a>;
    }

    const isSmallDevice = useSmallDeviceSize();
    let widgetExampleWidth = '160px';
    let widgetCodeWidth = 'calc(100% - 180px)';
    if (isSmallDevice) {
        widgetExampleWidth = '100%';
        widgetCodeWidth = '100%';
    }

    return <LoggedInLayout pageTitle={t('pages.help.promo.title')}>
        <CardWrapper loading={!isDataLoaded} title={t('pages.help.promo-kit-card.title')}>
            <Row>
                <Col sm={24} md={20}>
                    <Text>
                        {<Trans t={t} ns="common" i18nKey="pages.help.promo-kit-card.content">
                            For the decision-making process of consumers and companies, it is important to be able to show how others have experienced your company and service. You quickly create trust for potential customers, which in turn will lead to more sales. Show your visitors what appreciation your rental company has received. Reviews are very important for all visitors to make the right choice. This therefore improves your number of reservations.
                            <br/><br/>
                            Huurtoppers 2020, are you already participating? Huurtoppers 2020 are coming! After the successful editions of the Huurtoppers 2018 and 2019, the Huurtoppers awards will also be presented for 2020. The Huurtoppers awards are presented to the `best rated rental companies in the Netherlands! 'Read all about the Huurtoppers here and download the promotional kit now to help you collect reviews.
                        </Trans>}
                    </Text>
                </Col>
                <Col sm={24} md={4}>
                    <Button href={huurtopper_kit_download_url} style={{float: 'right'}}>
                        <DownloadOutlined />
                        {t('pages.help.promo-kit-card.download-button.text')}
                    </Button>
                </Col>
            </Row>
        </CardWrapper>
        
        <br/><br/>

        <CardWrapper loading={!isDataLoaded} title={t('pages.help.widget-card.title')}>
            <Row>
                <Col style={{width:widgetExampleWidth}}>
                    {getWidgetExample()}
                </Col>
                <Col style={{marginLeft: 20, width: widgetCodeWidth}}>
                    <Text>
                        {<Trans t={t} ns="common" i18nKey="pages.help.widget-card.content">
                            Place the widget below on your own website and collect independent reviews via Huren.nl. Also show the visitors what your average rating is. To place the widget on your website, you just need to copy and paste the HMTL code below into your own HTML code. Do you not manage your website yourself? Then ask your webmaster to place the widget. The widget will be on your website in just a few minutes.
                            <br/><br/>
                            <strong>Place this HTML-code on your website:</strong>
                        </Trans>}
                    </Text>
                    <br/>
                    <div style={{display: 'inline-block', fontFamily: 'Courier New, monospace', padding: '1em', overflow: 'auto', width: '100%'}}>
                        {renderToStaticMarkup(getWidgetExample())}
                    </div>
                </Col>
            </Row>
        </CardWrapper>

        <br/><br/>

    </LoggedInLayout>;
};

export default Promo;
