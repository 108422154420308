import React, { useState, useEffect } from "react";
import {Card, Descriptions, Row, Col, Skeleton, notification, Button, Space} from "antd";
import {DownloadOutlined } from "@ant-design/icons";
import {OfferItemsTable} from "./OfferItemsTable";
import {useTranslation} from "react-i18next";
import {OfferType} from "../../../types";
import client from "services/HurenApiClient";
import {saveAs} from "file-saver";

import useFormatting from "../../../hooks/useFormatting";
import {useGetOfferItems} from "../../../queries/offers";

export const OfferDetails = ({offer}) => {

    const { t } = useTranslation('common');
    const { formatDate } = useFormatting();

    const [attachmentsCollection, setAttachmentsCollection] = useState(null);

    const { data: offerItems, isLoading: offerItemsIsLoading } = useGetOfferItems(offer.id, {
        enabled: offer.type === OfferType.DETAILED
    });

    useEffect(() => {
        client.get(`/api/v2/offers/${offer.id}/attachments`).then(res => {
            setAttachmentsCollection(res.data);
        });
    }, [offer])

    const downloadAttachment = (attachment) => {
        client.get(`/api/v2/offer-attachments/${attachment.id}/download`, { responseType: 'blob'}).then(res => {
            saveAs(res.data, attachment.name);
        }).catch(e => {
            notification.open({
                message: t('components:offer-details.attachment-download-failed.title'),
                description: t('components:offer-details.attachment-download-failed.description'),
            });
        });
    }

    return (
        <>
            <Row gutter={[16, 16]}>
                <Col md={24}>
                    <Card title={t('components:offer-details.details.title')}>
                        <Descriptions
                            column={1}
                            layout="horizontal"
                            className="label-50-percent"
                        >
                            <Descriptions.Item label={t('entities:offer.properties.id')}>
                                {offer.id}
                            </Descriptions.Item>
                            {offer.expires_at !== null ?
                                <Descriptions.Item label={t('entities:offer.properties.expires-at')}>
                                    {formatDate(offer.expires_at)} ({t('entities:offer.validity-periods.' + offer.validity_period)})
                                </Descriptions.Item>
                                :
                                <Descriptions.Item label={t('entities:offer.properties.validity-period')}>
                                    {t('entities:offer.validity-periods.' + offer.validity_period)}
                                </Descriptions.Item>
                            }
                            <Descriptions.Item label={t('entities:offer.properties.attachments')}>
                                {attachmentsCollection === null && <Skeleton.Button size="small" active={true} loading={true}/>}
                                {attachmentsCollection !== null && attachmentsCollection.total > 0 &&
                                    <Space direction="vertical">
                                        {attachmentsCollection.items.map(attachment => {
                                            return <Button key={attachment.id} onClick={() => downloadAttachment(attachment)} size="small">
                                                {attachment.name} <DownloadOutlined/>
                                            </Button>
                                        })}
                                    </Space>
                                }
                                {attachmentsCollection !== null && attachmentsCollection.total === 0 && <>{t('components:offer-details.details.no-attachments')}</>}
                            </Descriptions.Item>
                        </Descriptions>
                    </Card>
                </Col>
            </Row>
            {offer.status !== 'open' ?
                <Row gutter={[16, 16]}>
                    <Col md={24}>
                        <Card title={t('components:offer-details.status.title')}>
                            <Descriptions
                                column={1}
                                layout="horizontal"
                                className="label-50-percent"
                            >
                                <Descriptions.Item label={t('entities:offer.properties.status')}>
                                    {t(`entities:offer.status.${offer.status}`)}
                                </Descriptions.Item>
                                { offer.status_detail ?
                                <Descriptions.Item label={t('entities:offer.properties.status-detail')}>
                                    {t(`entities:offer.status-detail.${offer.status_detail}`)}
                                </Descriptions.Item>
                                : <></> }
                                { offer.status_description ? 
                                <Descriptions.Item label={t('entities:offer.properties.status-description')}>
                                    {offer.status_description}
                                </Descriptions.Item>
                                : <></> }
                            </Descriptions>
                        </Card>
                    </Col>
                </Row>
                : <></>
            }
            {offer.type === OfferType.DETAILED ?
                <Row gutter={[16, 16]}>
                    <Col md={24}>
                        <Card title={t('components:offer-details.products.title')} bodyStyle={{'padding': 0}}>
                            <OfferItemsTable loading={offerItemsIsLoading} offerItems={offerItems} />
                        </Card>
                    </Col>
                </Row> :
                <></>
            }
        </>
    );
};