import React from 'react';
import {useGetSubscriptionFeatures, useGetSubscriptionMetrics} from "../../queries/subscription";
import {useTranslation} from "react-i18next";

const ActiveLocationsStatus = () => {
    const { data: subscriptionMetrics } = useGetSubscriptionMetrics();
    const { data: subscriptionFeatures } = useGetSubscriptionFeatures();
    const { t } = useTranslation();

    const numberOfActiveLocations = subscriptionMetrics?.active_locations;
    const maxActiveLocations = subscriptionFeatures?.max_active_locations;

    if (!subscriptionMetrics || !subscriptionFeatures) {
        return <span></span>
    }

    return <span className={numberOfActiveLocations >= maxActiveLocations ? 'error-text': ''}>
        {t('components:subscription-active-locations-status.text', {'count': numberOfActiveLocations, 'max': maxActiveLocations})}
    </span>
}

export default ActiveLocationsStatus;