import {useQuery} from "react-query";
import client from "services/HurenApiClient";

const fetchStatus = async () => {
    const res = await client.get('/api/v2/me/status');
    return res.data;
};

export const useFetchStatus = () => useQuery('me-status', fetchStatus, {
    refetchInterval: 30 * 1000
});

const fetchAuthenticationStatus = () => async () => {
    const res = await client.get(`/api/v2/me`);
    return res.data;
};

export const useFetchAuthenticationStatus = () => {
    return useQuery(['me-authentication-status'], fetchAuthenticationStatus());
};