import axios from "axios";
import TokenStorage from "./TokenStorage";
import {notification} from "antd";
import i18n from "../translations/i18n";

const apiClient = axios.create({
    baseURL: process.env.REACT_APP_MERCHANT_API_URL,
    responseType: "json",
    withCredentials: true
});

 apiClient.interceptors.request.use(request => {
     if (request.url.startsWith('/api/v2')) {
         request.baseURL = process.env.REACT_APP_API_BASE_URL;
     }
    if (process.env.NODE_ENV === 'development') {
        console.debug('Starting Request', request);
    }
    if (TokenStorage.isAuthenticated()) {
        request.headers = TokenStorage.getAuthentication();
    }
    return request
})

apiClient.interceptors.response.use( (response) => {
    if (process.env.NODE_ENV === 'development') {
        console.debug('Response:', response);
    }

    // Return a successful response back to the calling service
    return response;
}, (error) => {
    if (process.env.NODE_ENV === 'development') {
        console.debug('Error response:', error.response);
    }

    if (error && error.response && error.response.status === 401) {
        console.log('Token invalid, logging out');
        TokenStorage.clear();
        window.location.replace(process.env.REACT_APP_WEBSITE_LOGIN_URL)
    }

    if (error && error.response && error.response.status >= 400 && error.response.status < 600) {
        notification.error({
            message: i18n.t('common:api-client.request-failed-message')
        });
    }

    // Logout user if token refresh didn't work
    if (error && error.response && error.config.url.includes('/api/merchants/token')) {
        console.log('Refresh token invalid, logging out');
        TokenStorage.clear();
        window.location.replace(process.env.REACT_APP_WEBSITE_LOGIN_URL)
    }

    return Promise.reject(error);
});

export default apiClient;