import React from 'react';
import {Space, Tag} from "antd";
import {CheckOutlined, CloseOutlined, EuroOutlined} from "@ant-design/icons";
import {useTranslation} from "react-i18next";

export const OrderStatusTag = ({ order, small = false }) => {
    const { t } = useTranslation('common');

    const colors = {
        'completed': '#75b118',
        'canceled': '#ff4d4f',
        'awaiting_payment': '#ff7800'
    };

    const icons = {
        'completed': <CheckOutlined />,
        'canceled': <CloseOutlined />,
        'awaiting_payment': <EuroOutlined />
    };

    const color = colors[order.state];
    const icon = icons[order.state];

    return <Tag color={color}>
        <Space size="small">
            {icon}
            {!small && t(`entities:order.state.${order.state}`)}
        </Space>
    </Tag>;
}