import client from "services/HurenApiClient";
import * as Types from "./Types";

export const setIsDataLoaded = (loaded) => {
  return {
    type: Types.SET_IS_DATA_LOADED,
    isDataLoaded: loaded,
  };
};

export const openEditForm = () => {
  return {
    type: Types.EDIT_FORM_OPEN,
  };
};

export const closeEditForm = () => {
  return {
    type: Types.EDIT_FORM_CLOSE,
  };
};

export const setData = (payload) => {
  return {
    type: Types.SET_DATA,
    payload: payload,
  };
};

export const getCompanyFeatures = (activeAccount) => {
  return async function (dispatch) {
    dispatch(setIsDataLoaded(false));
    return client
      .get("/company/" + activeAccount.companyId + "/features")
      .then((res) => {
        dispatch(setData(res.data));
      });
  };
};

export const putCompanyFeatures = (activeAccount, data) => {
  return async function (dispatch) {
    return client
      .put("/company/" + activeAccount.companyId + "/features", {
        company_features: {
          selected_features: data,
        },
      })
      .then((res) => {
        dispatch(setData(res.data.features));
      });
  };
};
