import React, { useState, useEffect } from "react";
import {Col, DatePicker, Input, Row, Select} from "antd";
import moment from "moment";
import {useTranslation} from "react-i18next";
const {RangePicker} = DatePicker;
const { Search } = Input;

export const OrdersFilters = ({filters: filtersProp, onChange, disabled = false}) => {

    const { t } = useTranslation('common');
    const [filters, setFilters] = useState(filtersProp);
    const [searchQuery, setSearchQuery] = useState(filters.searchQuery);

    useEffect(() => {
        if (onChange) {
            onChange(filters);
        }
    }, [filters])

    const onChangeDateRange = (dates) => {
        if (dates != null) {
            setFilters(prevState => ({...prevState,
                createdAfter: dates[0],
                createdBefore: dates[1]}));
        } else { // Reset date constraints when clearing range picker
            setFilters(prevState => ({...prevState,
                createdAfter: null,
                createdBefore: null
            }))
        }
    }

    // Changes to the search query will be propagated after 500ms, so as not to do any updates while the user is typing
    let searchTimeout = null;
    useEffect(() => {
        clearTimeout(searchTimeout);
        searchTimeout = setTimeout(() => {
            setFilters(prevState => ({ ...prevState, searchQuery: searchQuery }));
        }, 500);
        return () => {
            clearTimeout(searchTimeout);
        };
    }, [searchQuery]);
    const onSearch = (e) => {
        setSearchQuery(e.target.value);
    }

    const onChangeState = value => {
        setFilters(prevState => ({ ...prevState, state: value }));
    };

    return (
        <Row
            gutter={[16, 16]}
            type="flex"
            justify="space-around"
            align="middle"
            style={{borderBottom: '1px solid #e8e8e8', paddingBottom: '16px', marginBottom: '16px'}}
        >
            <Col xs={{span: 24}} sm={{ span: 16 }} md={{span: 14}}>
                <Search
                    style={{ width: '100%' }}
                    placeholder={t('components:orders-filters.search.placeholder')}
                    disabled={disabled}
                    onChange={onSearch}
                />
            </Col>

            <Col xs={{span: 24}} sm={{ span: 8 }} md={{span: 4}}>
                { !disabled ?
                    <Select onChange={onChangeState} defaultValue="" style={{ width: '100%' }}>
                        <Select.Option value="">{ t('entities:order.state.all') }</Select.Option>
                        <Select.Option value="completed">{ t('entities:order.state.completed') }</Select.Option>
                        <Select.Option value="awaiting_payment">{ t('entities:order.state.awaiting_payment') }</Select.Option>
                        <Select.Option value="canceled">{ t('entities:order.state.canceled') }</Select.Option>
                    </Select> : <></>
                }
            </Col>

            <Col xs={{span: 24}} md={{span: 6}}>
                <RangePicker
                    format="DD-MM-YYYY"
                    inputReadOnly={true}
                    defaultValue={[
                        null,
                        null
                    ]}
                    onChange={onChangeDateRange}
                    style={{ width: '100%' }}
                    ranges={
                        {
                            [t('ranges.dates.today')] : [moment().startOf('day'), moment().endOf('day')],
                            [t('ranges.dates.this-month')] : [moment().startOf('month'), moment().endOf('month')],
                            [t('ranges.dates.previous-month')]: [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                            [t('ranges.dates.this-year')]: [moment().startOf('year'), moment().endOf('year')],
                            [t('ranges.dates.previous-year')]: [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')],
                        }
                    }
                />
            </Col>
        </Row>
    )
};
