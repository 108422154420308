import React, {useState, useEffect} from 'react';
import {Trans, useTranslation} from "react-i18next";
import LoggedInLayout from "layout/LoggedInLayout";
import {PremiumInfo} from "components/Premium";
import {Alert, Form, InputNumber, Row, Col, notification, Skeleton} from "antd";
import {navigate, useParams} from "@reach/router";
import {hasPermission} from "../../helpers";
import {useAccounts} from "hooks";
import {useGetAdvertisement, useUpdateAdvertisement} from "../../queries/advertisements";
import {Money} from "components/Money";
import SubmitButton from "components/SubmitButton";
import ClickPriceRulesTable from "../../components/ClickPriceRulesTable";
import styled from "styled-components";
import Card from "../../components/Card";
import TextSkeleton from "../../components/TextSkeleton";

const StyledCol = styled(Col)`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const ActionsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const AdvertisementBidding = () => {
    const { t } = useTranslation('common');
    const {activeAccount} = useAccounts();

    const [form] = Form.useForm();

    const { id: advertisementId } = useParams();
    const { data: advertisement, isLoading: advertisementIsLoading } = useGetAdvertisement(advertisementId);
    const updateAdvertisement = useUpdateAdvertisement();
    const [isSaving, setIsSaving] = useState(false);

    useEffect(() => {
        form.resetFields();
    }, [advertisement]);

    if (!hasPermission(activeAccount, 'manage_click_prices', 'edit')) {
        navigate('/advertisements');
        return;
    }

    const onFinish = async (values) => {
        setIsSaving(true);
        const payload = {
            advertisement_form: {
                clickOutRate: values.clickOutRate,
                clickPriceRules: values.clickPriceRules.map(clickPriceRule => {
                    return {
                        geoLocationId: clickPriceRule.geo_location.id,
                        priceFormula: clickPriceRule.price_formula,
                    };
                })
            },
        };

        await updateAdvertisement.mutateAsync({
            companyId: activeAccount.companyId,
            advertisementId: advertisement.id,
            advertisement: payload,
        });

        notification.success({
            message: t('pages:advertisement-bidding.notifications.saved.title'),
            description: t('pages:advertisement-bidding.notifications.saved.description'),
        });

        setIsSaving(false);
    };

    const clickPriceRules = [];
    if (advertisement) {
        for (var i = 0; i < advertisement.click_price_rules.length; i++) {
            clickPriceRules.push({
                key: i,
                geo_location: {
                    id: advertisement.click_price_rules[i].geo_location.id,
                    name: advertisement.click_price_rules[i].geo_location.name,
                },
                price_formula: advertisement.click_price_rules[i].price_formula,
            });
        }
    }

    const pageTitle = (
        <TextSkeleton loading={advertisementIsLoading}>
            <PremiumInfo/>
            {t('pages:advertisement-bidding.title', { categoryTitle: advertisement?.category.title})}
        </TextSkeleton>
    );

    return <LoggedInLayout pageTitle={pageTitle}>
        <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            initialValues={{
                clickOutRate: advertisement?.click_out_rate,
                clickPriceRules: clickPriceRules
            }}
        >
            <Row gutter={[16, 16]}>
                <StyledCol span={24}>
                    <Card title={t('pages:advertisement-bidding.main_click_price_card.title')}>
                        <Skeleton active={true} loading={advertisementIsLoading} paragraph={{ rows: 4 }}>
                            <Form.Item
                                name="clickOutRate"
                                label={t('pages.advertisements.advertisements-form.items.click-out-rate.label')}
                                extra={t('pages.advertisements.advertisements-form.items.click-out-rate.help')}

                                rules={[
                                    {required: true, message: t('form.errors.required')},
                                    {
                                        type: "number",
                                        min: advertisement?.category.click_out_rate,
                                        message: (<Trans t={t}
                                                         i18nKey={'pages.advertisements.advertisements-form.items.click-out-rate.validation.min'}>The
                                            clickprice cannot be less than <Money
                                                value={advertisement?.category.click_out_rate}/></Trans>)
                                    },
                                    {
                                        type: "number",
                                        max: 3,
                                        message: (<Trans t={t}
                                                         i18nKey={'pages.advertisements.advertisements-form.items.click-out-rate.validation.max'}>The
                                            clickprice cannot be more than <Money value={3}/></Trans>)
                                    }
                                ]}
                            >
                                <InputNumber
                                    decimalSeparator="," precision={2}
                                    formatter={value => `€ ${value}`.replace(/^€\d+(,\d{2})$/g, ',')}
                                    parser={value => value.replace(/€\s?/g, '')}
                                    min={advertisement?.category.click_out_rate}
                                    max="3"
                                    step="0.01"/>
                            </Form.Item>

                            <Alert
                                style={{marginTop: '10px', marginBottom: '6px'}}
                                type="warning"
                                showIcon
                                message={(
                                    <Trans t={t} i18nKey="pages.advertisements.advertisements-form.items.click-out-rate.warning">
                                        Within your subscription there's a minimum clickprice of <Money
                                        value={advertisement?.category.click_out_rate}/>. It is not possible to configure a lower price.
                                    </Trans>)}
                            />
                        </Skeleton>
                    </Card>

                    <Card title={t('pages:advertisement-bidding.click_price_rules_card.title')}>
                        <Form.Item
                            name="clickPriceRules"
                        >
                            <ClickPriceRulesTable loading={advertisementIsLoading} advertisement={advertisement} />
                        </Form.Item>
                    </Card>

                    <Card>
                        <ActionsContainer>
                            <SubmitButton disabled={isSaving}>
                                {t('button.text.save')}
                            </SubmitButton>
                        </ActionsContainer>
                    </Card>
                </StyledCol>
            </Row>
        </Form>
    </LoggedInLayout>;
};

export default AdvertisementBidding;
