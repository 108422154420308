import * as Types from "./Types";

export const initialState = {
    isDataLoaded: false,
    isGraphDataLoaded: false,
    isGraphDataError: false,
    isTotalsDataLoaded: false,
    payload: {},
    graphData: {},
    totals: {}}
;

export default function (state, action) {
    switch (action.type) {
        case Types.SET_IS_DATA_LOADED:
            return {
                ...state,
                isDataLoaded: action.isDataLoaded
            };
        case Types.SET_IS_GRAPH_DATA_LOADED:
            return {
                ...state,
                isGraphDataLoaded: action.isGraphDataLoaded
            };
        case Types.SET_IS_GRAPH_DATA_ERROR:
            return {
                ...state,
                isGraphDataLoaded: true,
                isGraphDataError: action.isGraphDataError
            };
        case Types.SET_IS_TOTALS_DATA_LOADED:
            return {
                ...state,
                isTotalsDataLoaded: action.isTotalsDataLoaded
            };
        case Types.SET_DATA:
            return {
                ...state,
                payload: action.payload,
                isDataLoaded: true
            };
        case Types.SET_GRAPH_DATA:
            return {
                ...state,
                graphData: action.payload,
                isGraphDataLoaded: true
            };
        case Types.SET_TOTALS_DATA:
            return {
                ...state,
                totals: action.payload,
                isTotalsDataLoaded: true
            };
        default:
            return state;
    }
};
