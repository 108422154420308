import {useMutation, useQuery, useQueryClient} from "react-query";
import client from "services/HurenApiClient.js";
import {useAccounts} from "../hooks";

const fetchAdvertisement = (companyId, advertisementId) => async () => {
    const res = await client.get(`/company/${companyId}/advertisements/${advertisementId}`);
    return res.data;
};

export const useGetAdvertisement = (advertisementId) => {
    const {activeAccount} = useAccounts();
    return useQuery(['advertisements', parseInt(advertisementId)], fetchAdvertisement(activeAccount.companyId, advertisementId));
}

const updateAdvertisement = async ({companyId, advertisementId, advertisement}) => {
    return client.put(`/company/${companyId}/advertisements/${advertisementId}`, advertisement);
};
export const useUpdateAdvertisement = () => {
    const queryClient = useQueryClient();
    return useMutation(updateAdvertisement, {
        onSuccess: async (res, {companyId, advertisementId, advertisement}) => {
            await queryClient.invalidateQueries(['advertisements', parseInt(advertisementId)]);
        }
    });
};