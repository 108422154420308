import React, { useState, useEffect } from "react";
import {Col, DatePicker, Input, Row, Select} from "antd";
import moment from "moment";
import {useSmallDeviceSize} from "../../../hooks";
import {useTranslation} from "react-i18next";
const {RangePicker} = DatePicker;
const { Search } = Input;

export const OffersFilters = ({filters: filtersProp, onChange, disabled = false}) => {

    const { t } = useTranslation('common');
    const { isSmallDevice } = useSmallDeviceSize();

    let searchInputWidth = '80%';
    if (isSmallDevice) {
        searchInputWidth = '100%';
    }

    const [filters, setFilters] = useState(filtersProp);
    const [searchQuery, setSearchQuery] = useState(filters.searchQuery);

    useEffect(() => {
        if (onChange) {
            onChange(filters);
        }
    }, [filters])

    const onChangeDateRange = (dates) => {
        if (dates != null) {
            setFilters(prevState => ({...prevState, startDate: dates[0], endDate: dates[1]}));
        }
    }

    // Changes to the search query will be propagated after 500ms, so as not to do any updates while the user is typing
    let searchTimeout = null;
    useEffect(() => {
        clearTimeout(searchTimeout);
        searchTimeout = setTimeout(() => {
            setFilters(prevState => ({ ...prevState, searchQuery: searchQuery }));
        }, 500);
        return () => {
            clearTimeout(searchTimeout);
        };
    }, [searchQuery]);
    const onSearch = (e) => {
        setSearchQuery(e.target.value);
    }

    const onChangeStatus = value => {
        setFilters(prevState => ({ ...prevState, status: value }));
    };

    return (
        <Row gutter={[16, 16]} type="flex" justify="space-around" align="middle" style={{borderBottom: '1px solid #e8e8e8', paddingBottom: '16px', marginBottom: '16px'}}>
            <Col sm={{span: 24}} md={{span: 14}}>
                <Search
                    style={{ width: searchInputWidth }}
                    placeholder={t('tables.rental-matches.search.placeholder')}
                    disabled={disabled}
                    onChange={onSearch}
                />
            </Col>

            <Col sm={{span: 24}} md={{span: 4}}>
                { !disabled ?
                    <Select onChange={onChangeStatus} defaultValue="" style={{ width: '100%' }}>
                        <Select.Option value="">{ t('entities:offer.status.all') }</Select.Option>
                        <Select.Option value="concept">{ t('entities:offer.status.concept') }</Select.Option>
                        <Select.Option value="open">{ t('entities:offer.status.open') }</Select.Option>
                        <Select.Option value="accepted">{ t('entities:offer.status.accepted') }</Select.Option>
                        <Select.Option value="rejected">{ t('entities:offer.status.rejected') }</Select.Option>
                        <Select.Option value="withdrawn">{ t('entities:offer.status.withdrawn') }</Select.Option>
                        <Select.Option value="expired">{ t('entities:offer.status.expired') }</Select.Option>
                    </Select> : <></>
                }
            </Col>

            <Col sm={{span: 24}} md={{span: 6}}>
                <RangePicker format="DD-MM-YYYY" inputReadOnly={true} defaultValue={[filters.startDate, filters.endDate]} onChange={onChangeDateRange} style={{ width: '100%' }} ranges={
                    {
                        [t('ranges.dates.today')] : [moment().startOf('day'), moment().endOf('day')],
                        [t('ranges.dates.this-month')] : [moment().startOf('month'), moment().endOf('month')],
                        [t('ranges.dates.previous-month')]: [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                        [t('ranges.dates.this-year')]: [moment().startOf('year'), moment().endOf('year')],
                        [t('ranges.dates.previous-year')]: [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')],
                    }
                } />
            </Col>
        </Row>
    )
};