import React from "react";
import {Button, Result} from "antd";
import {Link} from "@reach/router";
import {HomeOutlined} from "@ant-design/icons";
import client from "services/HurenApiClient";

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, info) {
        // Log the error to the application.
        client.post('/error', {
            "message": error.message,
            "info": info.componentStack
        }).catch((error) => {
            // Do nothing, since the server seems to be in trouble too.
        })
    }

    render() {
        const { t } = this.props;
        if (this.state.hasError) {
            return <Result
                title={t('pages.error.title')}
                subTitle={t('pages.error.sub-title')}
                extra={<Link to="/"><Button icon={<HomeOutlined />}> {t('pages.error.link')} </Button></Link>}
            />;
        }
        return this.props.children;
    }
}

export default ErrorBoundary;