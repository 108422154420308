import React, {useRef} from 'react';
import LoggedInLayout from "layout/LoggedInLayout";
import RentalMatchesCard from "./components/RentalMatchesCard";
import {useTranslation} from "react-i18next";
import {Button, Dropdown, Menu} from "antd";
import {DownloadOutlined, DownOutlined, FileExcelOutlined, FilePdfOutlined} from "@ant-design/icons";

const RentalMatches = () => {
	const { t } = useTranslation('common');
    const rentalMatchesCardRef = useRef();

    const exportDropdownMenu = {
        items: [
            {
                key: '1',
                label: (
                    <a onClick={() => rentalMatchesCardRef.current.downloadClicked('pdf')}>
                        <FilePdfOutlined style={{marginRight: '5px'}}/>
                        PDF document
                    </a>
                )
            },
            {
                key: '2',
                label: (
                    <a onClick={() => rentalMatchesCardRef.current.downloadClicked('xlsx')}>
                        <FileExcelOutlined style={{marginRight: '5px'}}/>
                        Excelsheet
                    </a>
                )
            }
        ]
    };

    return <LoggedInLayout pageTitle={t('pages.rental-matches.title')}
                           headerExtras={
        [
            <Dropdown key="export" menu={exportDropdownMenu}>
                <Button>
                    <DownloadOutlined />
                    Exporteer
                    <DownOutlined />
                </Button>
            </Dropdown>

        ]
    }
    >
        <RentalMatchesCard ref={rentalMatchesCardRef} />
    </LoggedInLayout>;
};

export default RentalMatches;
