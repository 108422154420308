import React from "react";
import LoggedInLayout from "layout/LoggedInLayout";
import InboxCard from "./components/InboxCard";
import { useTranslation } from "react-i18next";

const Inbox = () => {
  const { t } = useTranslation("common");

  return (
    <LoggedInLayout pageTitle={t("pages.inbox.title")}>
      <InboxCard />
    </LoggedInLayout>
  );
};

export default Inbox;
