import React from 'react';
import {useTranslation} from "react-i18next";
import LoggedInLayout from "layout/LoggedInLayout";
import {PremiumInfo} from "components/Premium";
import {AdvertisementsCard} from "./components/AdvertisementsCard";
import {Button} from "antd";
import {PlusOutlined} from "@ant-design/icons";
import {navigate} from "@reach/router";
import { useSmallDeviceSize } from "hooks";
import {hasPermission} from "../../helpers";
import {useAccounts} from "hooks";

const AdvertisementOverview = () => {
    const { t } = useTranslation('common');
    const isSmallDevice = useSmallDeviceSize();
    const {activeAccount} = useAccounts();

    return <LoggedInLayout pageTitle={<><PremiumInfo/> {t('pages.advertisements.title')}</>} headerExtras={
        [
            <Button key="1" disabled={!hasPermission(activeAccount, 'manage_ads' ,'create')} hidden={isSmallDevice} type="primary" onClick={() => navigate('/advertisements/create')}>
                <PlusOutlined />
                {t('pages.advertisements.new-advertisement-button')}
            </Button>,
        ]
    }>
        <AdvertisementsCard />
    </LoggedInLayout>;
};

export default AdvertisementOverview;
