import React from 'react';
import {
    render
} from 'react-dom';
import Routes from "./Routes";
import CacheBuster from "./components/CacheBuster";
import registerServiceWorker from './registerServiceWorker'
import Provider from "./store";
import gtag, { install } from 'ga-gtag';

import "./translations/i18n";

install(process.env.REACT_APP_GTAG_KEY); 

render(
    <CacheBuster>
        {({ loading, isLatestVersion, refreshCacheAndReload }) => {
            if (loading) return null;
            if (!loading && !isLatestVersion) {
                refreshCacheAndReload();
            }
            return (
                <Provider>
                    <Routes />
                </Provider>
            );
        }}
    </CacheBuster>,
    document.getElementById('root')
);
registerServiceWorker({immediate: true});
