import React from 'react';
import {
    Statistic,
    Divider
} from "antd";
import {useTranslation} from "react-i18next";

const CostStats  = ({costs_this_month, costs_last_month}) => {
    const { t } = useTranslation('common');

    return <>
            <Statistic
                title={t('pages.dashboard.stats.payment.costs.this-month')}
                value={costs_this_month}
                precision={2}
                prefix="&euro;"
                decimalSeparator=","
                groupSeparator="."
            />
            <Divider/>
            <Statistic
                title={t('pages.dashboard.stats.payment.costs.previous-month')}
                value={costs_last_month}
                precision={2}
                prefix="&euro;"
                decimalSeparator=","
                groupSeparator="."
            />
        </>;
};

export default CostStats;
