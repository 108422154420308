import {calculatePriceIncVat} from "./taxes";
import {OfferStatus, OfferType} from "../types";
import moment from "moment";

export const canEditOffer = (offer) => {
    return offer.status === OfferStatus.CONCEPT;
};

export const canDeleteOffer = (offer) => {
    return offer.status === OfferStatus.CONCEPT;
};

export const transformOfferToValues = (offer) => {
    const values = {type: OfferType.DETAILED, ...offer, offer_items: []};

    const fileList = [];
    if (offer.attachments) {
        offer.attachments.forEach((offerAttachment) => {
            fileList.push({
                uid: offerAttachment.id,
                name: offerAttachment.name,
                status: 'done',
            });
        });
    }

    values.attachments = {
        fileList: fileList,
    };

    if (offer.offer_items) {
        offer.offer_items.forEach((offerItem) => {
            const valuesOfferItem = {...offerItem};
            valuesOfferItem.date_from = moment(offerItem.date_from);
            valuesOfferItem.date_until = offerItem.date_until ? moment(offerItem.date_until) : null;
            values.offer_items.push(valuesOfferItem);
        });
    }

    values.date_from = offer.date_from ? moment(offer.date_from) : null;
    values.date_until = offer.date_until ? moment(offer.date_until) : null;

    if (offer.price_incl) {
        values.price_incl = offer.price_incl / 100;
    }
    if (offer.price_excl) {
        values.price_excl = offer.price_excl / 100;
    }

    return values;
};

export const getDefaultTotals = () => {
    return {
        exVat: 0,
        vat: 0,
        incVat: 0
    };
}

const getDetailedTotals = (offer) => {
    let newTotals = getDefaultTotals();
    offer.offer_items.forEach(offerItem => {
        const exVat = offerItem.price;
        const incVat = calculatePriceIncVat(exVat, offerItem.tax_rate.percentage);
        const vat = incVat - exVat;
        newTotals.exVat += exVat;
        newTotals.vat += vat;
        newTotals.incVat += incVat;
    });

    return newTotals;
};

const getSimpleTotals = (offer) => {
    if (!offer.price_excl || !offer.price_incl) {
        return getDefaultTotals();
    }

    const exVat = offer.price_excl;
    const incVat = offer.price_incl;
    const vat = incVat - exVat;

    return {
        exVat: exVat,
        incVat: incVat,
        vat: vat,
    };
};

export const getOfferTotals = (offer) => {
    let totals = getDefaultTotals();

    if (offer.type === OfferType.SIMPLE) {
        totals = getSimpleTotals(offer);
    }
    if (offer.type === OfferType.DETAILED) {
        totals = getDetailedTotals(offer);
    }

    return totals;
};

export const isInTerminalState = (offer) => {
    const terminalStates = [OfferStatus.WITHDRAWN, OfferStatus.REJECTED, OfferStatus.ACCEPTED];
    return terminalStates.includes(offer.status);
};