import client from "services/HurenApiClient";
import * as Types from "./Types";

export const setIsDataLoaded = (loaded) => {
    return {
        type: Types.SET_IS_DATA_LOADED,
        isDataLoaded: loaded
    };
};

export const setData = (payload) => {
    return {
        type: Types.SET_DATA,
        payload: payload
    };
};

export const getRentalItem = (companyId, rentalItemId) => {
    return async function(dispatch){
        dispatch(setIsDataLoaded(false));

        return client.get('/rental_items/' + companyId + '/' + rentalItemId).then(res => {
            dispatch(setData(res.data));
            return res;
        });
    };
};

export const putRentalItemPricing = (companyId, rentalItemId, prices, extraPriceText, showByDefaultPriceIncludingVAT, pricingModelName) => {
    return async function(dispatch) {
        return client.put('/rental_items/' + companyId + '/' + rentalItemId + '/pricing', {
            "rental_item_pricing_form" : {
                "extraPriceText": extraPriceText,
                "showByDefaultPriceIncludingVAT": showByDefaultPriceIncludingVAT,
                "rentalPrices": prices,
                "pricingModelName": pricingModelName,
            }
        }).then(res => {
            dispatch(setData(res.data));
        });
    }
};

export const putDetails = (companyId, rentalItemId, active, name) => {
    return async function(dispatch) {
        return client.put('/rental_items/' + companyId + '/' + rentalItemId + '/details', {
            "rental_item_details_form" : {
                "active": active,
                "name": name
            }
        }).then(res => {
            dispatch(setData(res.data));
        });
    }
};

export const putContentBlocks = (companyId, rentalItemId, active, name, contentBlocks) => {
    return async function(dispatch) {
        return client.put('/rental_items/' + companyId + '/' + rentalItemId + '/content', {
            "rental_item_content_form" : {
                "active": active,
                "name": name,
                "content_blocks": contentBlocks
            }
        }).then(res => {
            if (res.data.code === 400){
                throw res.data;
            }
            dispatch(setData(res.data));
        });
    }
};

export const putRentalItemImage = (companyId, rentalItemId, image, fileName) => {
    return async function(dispatch) {
        return client.put('/rental_items/' + companyId + '/' + rentalItemId + '/image', {
            "rental_item_image_form" : {
                "image": image,
                "filename": fileName
            }
        }).then(res => {
            dispatch(setData(res.data));
        });
    }
};

export const removeRentalItemImage = (companyId, rentalItemId, imageId) => {
    return async function(dispatch) {
        return client.delete('/rental_items/' + companyId + '/' + rentalItemId + '/image/' + imageId)
            .then(res => {
                dispatch(setData(res.data));
            });
    };
};

export const rearrangeRentalItemImages = (companyId, rentalItemId, imagesOrder) => {
    return async function(dispatch) {
        return client.post('/rental_items/' + companyId + '/' + rentalItemId + '/image-rearrange', {
            "images": [
                ...imagesOrder
            ]
        }).then(res => {
            dispatch(setData(res.data));
        });
    };
};

export class Feature {
  constructor(key, feature , value, type, valueType = 'string', multipleOptions = false, options = [], canonicalName = '', prefix = '', suffix = '') {
    this.key = key;
    this.feature = feature;
    this.value = value;
    this.type = type;
    this.valueType = valueType;
    this.multipleOptions = multipleOptions;
    this.options = options;
    this.canonicalName = canonicalName;
    this.prefix = prefix;
    this.suffix = suffix;
  }
}

export const getRentalItemFeatureDetails = (companyId, rentalItemId, type) => {
    return async function(dispatch){
        return client.get('/rental_items/' + companyId + '/' + rentalItemId + '/features/' + type).then((res) => {
            let keyIndex = 0;


            let features = res.data.features.map((rawFeature) => {
                keyIndex++;
                let prefix = '';
                let suffix = '';
                if (rawFeature['display_unit_class_name'] !== '') {
                    let filteredUnits = res.data.units.filter(u => u.class_name === rawFeature['display_unit_class_name']);
                    if (filteredUnits.length) {
                        let unit = filteredUnits[0];
                        if (unit) {
                            prefix = unit.prefix;
                            suffix = unit.suffix;
                        }
                    }
                }

                let value = rawFeature['value'];
                if (value !== '' && rawFeature['value_type'] === 'string' && rawFeature['enum_multiple'] && rawFeature['enum_options'].length > 0) {
                    try {
                        value = JSON.parse(value);
                    } catch(e) {}
                }

                return new Feature(keyIndex, rawFeature['label'], value, 'system', rawFeature['value_type'], rawFeature['enum_multiple'], rawFeature['enum_options'], rawFeature['canonical_name'], prefix, suffix);
            });

            let userGeneratedFeatures = res.data.user_generated_features.map((rawFeature) => {
                keyIndex++;
                return new Feature(keyIndex, rawFeature['key'], rawFeature['value'], 'user_generated', 'string', false, [], '', rawFeature['prefix'], rawFeature['suffix']);
            });

            res.data = {
                'features': features.filter(f => f.value !== ''),
                'systemFeatures': features,
                'userGeneratedFeatures': userGeneratedFeatures
            };

            return Promise.resolve(res);
        });
    };
};

export const putRentalItemFeatures = (companyId, rentalItemId, type, features, userGeneratedFeatures) => {
    let userGeneratedFeaturesObject = {};
    userGeneratedFeatures.map(f => {
        userGeneratedFeaturesObject[f.feature] = f.value;
    });
    
    let featuresObject = {};
    features.map(f => {
        featuresObject[f.canonicalName] = f.value;
    });

    return async function(dispatch) {
        return client.put('/rental_items/' + companyId + '/' + rentalItemId + '/features/' + type, {
            "features": featuresObject, 
            "user_generated_features": userGeneratedFeaturesObject
        }).then(res => {
            dispatch(setData(res.data));
        });
    }
};