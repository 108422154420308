import React, {useEffect, useState} from 'react';
import {Layout, PageHeader, ConfigProvider, Drawer} from "antd";
import Header from "./Header"
import SidebarMenu from "./SidebarMenu";
import "../assets/style/style.less";
import styled from 'styled-components';
import usePersistedState from "../services/usePersistedState";
import {useLocale, useSmallDeviceSize, useChat} from '../hooks';
import ErrorBoundary from "components/Errors/ErrorBoundary";
import nlNL from "antd/es/locale/nl_NL";
import enGB from "antd/es/locale/en_GB";
import {useTranslation} from "react-i18next";
import Chat from "../components/ConversationCard/Chat";
import useMatchBreakpoint from "../hooks/useMatchBreakpoint";

const {Content, Sider} = Layout;

const FullHeightLayout = styled(Layout)`
    padding-top: 64px;
    box-sizing: border-box; 
    height: 100vh;
`;

const ContentLayout = styled(Layout)`
    transition: all 0.2s ease 0s;
`;

const StyledSider = styled(Sider)`
    background-color: #fff;
    line-height: 64px;
    overflow: auto;
    height: calc(100vh -64px);
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    margin-top: 64px;
`;

const StyledContent = styled(Content)`
    padding: 0 24px 24px;
`;

const LoggedInLayout = ({
    pageTitle,
    headerExtras,
    children
}) => {
    const isSmallDeviceSize = useSmallDeviceSize();
    const matchBreakpoint = useMatchBreakpoint();

    const [siderCollapsed, setSiderCollapsed] =  usePersistedState('siderCollapsed', isSmallDeviceSize);

    const { chat, doCloseChat } = useChat();

    let siderWidth = 230;
    let contentMarginLeft = siderWidth;

    let collapsedSiderWidth = 80;

    if (siderCollapsed) {
        contentMarginLeft = collapsedSiderWidth;
    }

    if (isSmallDeviceSize) {
        siderWidth = '100%';
        collapsedSiderWidth = 0;
        contentMarginLeft = 0;
    }

    // I18n for antd components.
    const {t} = useTranslation('common');
    const {locale} = useLocale();
    const [antLocale, setAntLocale] = useState({});
    useEffect(() => {
        if (locale === 'nl') {
            setAntLocale(nlNL);
        } else {
            setAntLocale(enGB);
        }
    }, [locale]); // eslint-disable-line react-hooks/exhaustive-deps


    return (
        <>
            <Layout hasSider>
                <Drawer
                    title={"Berichten"}
                    width={matchBreakpoint({ sm: '100%', md: 560})}
                    open={chat.open}
                    onClose={() => doCloseChat()}
                >
                    {chat.conversationId && <Chat id={chat.conversationId}/>}
                </Drawer>
                <Header 
                    loggedIn={true} 
                    onMenuButtonClicked={() => {
                        setSiderCollapsed(!siderCollapsed)
                    }} 
                />
                <FullHeightLayout>
                    <StyledSider
                        id='menu-sider'
                        collapsed={siderCollapsed}
                        width={siderWidth}
                        collapsedWidth={collapsedSiderWidth}
                        onCollapse={(collapsed) => {
                            setSiderCollapsed(collapsed);
                        }}
                        collapsible
                        trigger={null}
                    >
                        <SidebarMenu collapsed={siderCollapsed} onMenuItemSelect={() => {
                            if (isSmallDeviceSize) {
                                setSiderCollapsed(true)
                            }
                        }} />
                    </StyledSider>
                    <ContentLayout style={{ marginLeft: contentMarginLeft }} hidden={!siderCollapsed && isSmallDeviceSize}>
                        {pageTitle && (<PageHeader title={pageTitle} extra={headerExtras}
                            />)}
                        <StyledContent>
                            <ConfigProvider locale={antLocale}>
                                <ErrorBoundary t={t}>
                                    {children}
                                </ErrorBoundary>
                            </ConfigProvider>
                        </StyledContent>
                    </ContentLayout>
                </FullHeightLayout>
            </Layout>
        </>
    );
};

export default LoggedInLayout;
