import React, { useRef } from "react";
import {Button} from "antd";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import {useGetEnabledTransitions, useCreateOfferTransition, useGetOffer} from "../../queries/offers";
import { useTranslation } from "react-i18next";

const MessageOfferAttachment = ({
  offerId,
  onScreen
}) => {
  // Translations
  const { t } = useTranslation("common");
  const ref = useRef();

  const { data: offer, isLoading: offerIsLoading } = useGetOffer(offerId, {
    enabled: !!onScreen,
  });

  const { data: offerTransitions, isLoading: offerTransitionsIsLoading } = useGetEnabledTransitions(offerId, {
    enabled: !!onScreen,
  });

  const createOfferTransition = useCreateOfferTransition();

  const transitionOffer = async (transition) => {
    await createOfferTransition.mutateAsync({
      rentalMatchId: offer.rental_match.id,
      offerId: offerId,
      transition: {"transition_name":transition},
    });
  }

  let buttons = [];
  const acceptButton = <Button
    key={'accept'}
    type="primary"
    icon={<CheckOutlined />}
    onClick={ () => transitionOffer('accept')}
    style={{marginRight: '8px', verticalAlign: 'middle'}}
  >
    { t('entities:offer.transition.accept')  }
  </Button>;

  const withdrawButton = <Button
    key={'withdraw'}
    htmlType="submit"
    type="secondary"
    icon={<CloseOutlined />}
    onClick={ () => transitionOffer('withdraw')}
    style={{verticalAlign: 'middle'}}
  >
    { t('entities:offer.transition.withdraw') }
  </Button>;

  if (offerTransitions) {
    buttons = offerTransitions.map(transition => {
      if (transition.name === 'accept') {
        return acceptButton
      } else if (transition.name === 'withdraw') {
        return withdrawButton
      } else {
        return <Button
          key={transition.name}
          htmlType="submit"
          type="secondary"
          onClick={ () => transitionOffer(transition.name)}
          style={{verticalAlign: 'middle'}}
        >
          { t('entities:offer.transition.' + transition.name) }
        </Button>
      }
    });
  }

  return (
    <div className="offer" ref={ref}>
      { t("pages.conversation.message.offer.body", {id: offerId}) }
      <div style={{
        marginTop: '10px',
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%'
      }}>
        {buttons}
      </div>
    </div>
  );
};

export default MessageOfferAttachment;
