import React, {useEffect, useState} from "react";
import {Form, Select, Input, Button, Radio, DatePicker, Checkbox, InputNumber} from "antd";
import {useTranslation} from "react-i18next";
import client from "services/HurenApiClient";
import {CheckOutlined} from "@ant-design/icons";
import {OfferItemType} from "../../types";
import useGetTaxRates from "../../queries/taxRates";
import {getTaxRateById, getActiveTaxRateByCode} from "../../helpers/taxes";

const OfferItemForm = ({ offerItem, onFinish, onFinishFailed, mode }) => {

    // TODO: Clean this crap up
    if (offerItem) {
        if (offerItem.rental_item) {
            offerItem.rental_item_id = offerItem.rental_item.id;
        }
        if (offerItem.tax_rate) {
            offerItem.tax_rate_id = offerItem.tax_rate.id;
        }
    }

    const { t } = useTranslation('common');
    const { data: taxRates } = useGetTaxRates();

    const [form] = Form.useForm();

    const [rentalItems, setRentalItems] = useState([]);
    const [type, setType] = useState(null);
    const [autoCalculatePrice, setAutoCalculatePrice] = useState(true);
    const [selectedRentalItem, setSelectedRentalItem] = useState(null);

    const dateUntilRequired = type === OfferItemType.RENTAL_ITEM && selectedRentalItem && selectedRentalItem.pricing_model_name !== 'fixed';
    const hideDateUntil = type === OfferItemType.RENTAL_ITEM && selectedRentalItem && selectedRentalItem.pricing_model_name === 'fixed';

    const typeOptions = [
        {
            label: t('entities:offer-item.types.rental-item'),
            value: OfferItemType.RENTAL_ITEM
        },
        {
            label: t('entities:offer-item.types.other'),
            value: OfferItemType.OTHER
        }
    ];

    useEffect(() => {
        form.resetFields();
        setType(offerItem === null ? null : offerItem.type);
        setSelectedRentalItem(offerItem === null ? null : offerItem.rental_item);
    }, [offerItem]);

    useEffect(() => {
        determineAutoCalculatePrice().then(autoCalc => {
            setAutoCalculatePrice(autoCalc);
        });
    }, [offerItem, type]);

    useEffect(() => {
        calculatePrice()
    }, [autoCalculatePrice, selectedRentalItem]);

    useEffect(() => {
        client.get('/api/v2/rental-items').then(res => {
            setRentalItems(res.data.items);
        });
    }, []);

    const transformToValues = (offerItem) => {
        if (!offerItem) {
            return {};
        }
        const values = {...offerItem};

        if (offerItem.price) {
            values.price = offerItem.price / 100;
        }

        return values;
    };
    const initialValues = transformToValues(offerItem);

    /**
     * Determines tax percentage, moves properties from the rental item to the offer item and then bubbles up
     * the result
     */
    const onInnerFinish = (values) => {
        if (values.type === OfferItemType.RENTAL_ITEM) {
            // Decorate form values with other rental item values
            const rentalItem = rentalItems.find(item => item.id === values.rental_item_id);
            values.rental_item = rentalItem;
            values.name = rentalItem.name;
            values.tax_rate = getActiveTaxRateByCode(taxRates, rentalItem.rental_price_tax_rate);
            delete values.rental_item_id;
        } else if (values.type === OfferItemType.OTHER) {
            values.tax_rate = getTaxRateById(taxRates, values.tax_rate_id);
            delete values.tax_rate_id;
        }

        if (values.price) {
            values.price = values.price * 100;
        }

        if (hideDateUntil) {
            values.date_until = null;
        }

        if (onFinish) {
            onFinish(values);
        }
    };

    const onChangeType = (e) => {
        setType(e.target.value);
    }

    /**
     * @returns {Promise<float>}
     */
    const calculateRentalItemPrice = (rentalItemId, dateFrom, dateUntil, quantity) => {
        quantity = quantity || 1;

        let url = `/api/v2/rental-items/${rentalItemId}/price?start=${dateFrom.toISOString()}&quantity=${quantity}`;

        if (dateUntil) {
            url += `&end=${dateUntil.toISOString()}`;
        }

        return new Promise((resolve, reject) => {
            client
                .get(url)
                .then(res => {
                    resolve(res.data.total);
                });
        });
    };

    /**
     * Determines whether auto price calculation is enabled for an existing offer item. It does this by calculating
     * the price through the API and comparing it with the existing price
     */
    const determineAutoCalculatePrice = async () => {
        if (mode === 'new') {
            return true;
        }
        if (type !== OfferItemType.RENTAL_ITEM) {
            return false;
        }

        const formValues = form.getFieldsValue();
        if (!formValues.rental_item_id || !formValues.date_from || !formValues.date_until || !formValues.quantity) {
            return true;
        }

        const calculatedPrice = await calculateRentalItemPrice(
            formValues.rental_item_id,
            formValues.date_from,
            formValues.date_until,
            formValues.quantity
        );

        return calculatedPrice === offerItem.price;
    };

    const calculatePrice = () => {
        if (!autoCalculatePrice) {
            return;
        }

        if (type !== OfferItemType.RENTAL_ITEM) {
            return;
        }

        const formValues = form.getFieldsValue();

        if (!formValues.rental_item_id || !formValues.date_from || !formValues.quantity) {
            return;
        }

        if (dateUntilRequired) {
            if (!formValues.date_until) {
                return;
            }
            if (formValues.date_until < formValues.date_from) {
                return;
            }
        }

        calculateRentalItemPrice(
            formValues.rental_item_id,
            formValues.date_from,
            formValues.date_until,
            formValues.quantity
        ).then((price) => {
            form.setFieldsValue({
                price: price
            });

            if (price <= 0) {
                setAutoCalculatePrice(false);
            }
        });
    };

    const onChangeRentalItem = (rentalItemId) => {
        const rentalItem = rentalItems.find(rentalItem => rentalItem.id === rentalItemId);
        setSelectedRentalItem(rentalItem);
    };

    return (
        <Form
            form={form}
            layout="vertical"
            onFinish={onInnerFinish}
            onFinishFailed={onFinishFailed}
            initialValues={initialValues}
        >
            <Form.Item
                label={t('entities:offer-item.properties.type')}
                name="type"
            >
                <Radio.Group options={typeOptions} onChange={onChangeType} optionType="button" buttonStyle="solid" />
            </Form.Item>

            {type === OfferItemType.RENTAL_ITEM ?
                <>
                    <Form.Item
                        label={t('entities:offer-item.properties.rental-item')}
                        name="rental_item_id"
                        rules={[
                            {required: true, message: t('form.errors.required')}
                        ]}
                    >
                        <Select
                            onChange={onChangeRentalItem}
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {rentalItems.map(rentalItem => {
                                return <Select.Option key={rentalItem.id} value={rentalItem.id}>
                                    {rentalItem.name}
                                </Select.Option>
                            })}
                        </Select>
                    </Form.Item>
                </>
                : <></>}
            {type === OfferItemType.OTHER ?
                <>
                    <Form.Item
                        label={t('entities:offer-item.properties.name')}
                        name="name"
                        rules={[
                            {required: true, message: t('form.errors.required')}
                        ]}
                    >
                        <Input/>
                    </Form.Item>

                    <Form.Item
                        label={t('entities:offer-item.properties.tax-rate')}
                        name="tax_rate_id"
                        rules={[
                            {required: true, message: t('form.errors.required')}
                        ]}
                    >
                        <Select>
                            {taxRates?.map(taxRate => {
                                return (
                                    <Select.Option key={taxRate.id} value={taxRate.id}>
                                        {t('tax-rates.' + taxRate.code, {'rate': taxRate.percentage})}
                                    </Select.Option>
                                )
                            })}
                        </Select>
                    </Form.Item>
                </>
                : <></>}

            {type !== null ?
                <>
                    <Form.Item
                        label={t('entities:offer-item.properties.date-from')}
                        name="date_from"
                        rules={[
                            {required: true, message: t('form.errors.required')}
                        ]}
                    >
                        <DatePicker
                            format="DD-MM-YYYY HH:mm"
                            showTime={true}
                            placeholder={t('forms.cancellation-form.items.cancellation-date.placeholder')}
                            onChange={calculatePrice}
                        />
                    </Form.Item>
                    {!hideDateUntil &&
                        <Form.Item
                            label={t('entities:offer-item.properties.date-until')}
                            name="date_until"
                            rules={[
                                {required: dateUntilRequired, message: t('form.errors.required')},
                                ({ getFieldValue }) => ({
                                    validator(rule, value) {
                                        if (value !== null && value < getFieldValue('date_from')) {
                                            return Promise.reject(
                                                t('components:offer-item-form.fields.date-until.errors.must-be-greater-than-date-from')
                                            );
                                        }
                                        return Promise.resolve();
                                    }
                                })
                            ]}
                        >
                            <DatePicker
                                format="DD-MM-YYYY HH:mm"
                                showTime={true}
                                placeholder={t('forms.cancellation-form.items.cancellation-date.placeholder')}
                                onChange={calculatePrice}
                            />
                        </Form.Item>
                    }
                    <Form.Item
                        label={t('entities:offer-item.properties.quantity')}
                        name="quantity"
                        rules={[
                            {required: true, message: t('form.errors.required')}
                        ]}
                    >
                        <InputNumber 
                            min={0}
                            onChange={calculatePrice}
                        />
                    </Form.Item>

                    <Form.Item
                        label={t('entities:offer-item.properties.price')}
                        name="price"
                        rules={[
                            {required: true, message: t('form.errors.required')}
                        ]}
                        normalize={(value) => parseFloat(value)}
                    >
                        <InputNumber
                            min={0}
                            disabled={type === OfferItemType.RENTAL_ITEM && autoCalculatePrice}
                            decimalSeparator=","
                        />
                    </Form.Item>
                    {type === OfferItemType.RENTAL_ITEM ?
                        <>
                            <Checkbox checked={autoCalculatePrice} onChange={() => setAutoCalculatePrice(!autoCalculatePrice)}>
                                {t('components:offer-item-form.auto-calculate-price')}
                            </Checkbox>
                        </> :
                        <></>
                    }
                    <br/><br/>
                </>:
                <></>
            }

            <div className="actions">
                <Button htmlType="submit" type="primary">
                    <CheckOutlined/>
                    {t('button.text.save')}
                </Button>
            </div>
        </Form>
    )
}

export default OfferItemForm;