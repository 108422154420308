import React from 'react';
import LoggedInLayout from "layout/LoggedInLayout";
import Stats from "./components/stats/Stats";
import ClickGraphCard from "./components/chart/ClickGraphCard";
import IntroCard from "./components/IntroCard";

const Dashboard = () => {
    return <LoggedInLayout>
        <br/>
        <IntroCard />

        <br/>
        <Stats />

        <br/>
        <ClickGraphCard />

        <br/>
    </LoggedInLayout>;
};

export default Dashboard;
