import Media from "react-media";
import React from "react";
import {Drawer as AntDrawer} from "antd";

const Drawer = (props) => {
    let {width} = props;

    if(typeof width === 'undefined'){
        width = 600;
    }

    return <Media query={"(min-width: "+width+"px)"}>
        {matches => {
            return <AntDrawer
                placement="right"
                {...props}
                width={matches ? width : '100%'}
            />
        }}
    </Media>
};

export default Drawer;
