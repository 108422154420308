import React from 'react';
import {useTranslation} from "react-i18next";
import LoggedInLayout from "layout/LoggedInLayout";
import {Col, Row} from "antd";
import {PremiumInfo} from "components/Premium";
import {BudgetCard} from "./components/BudgetCard";
import {SubscriptionCard} from "./components/SubscriptionCard";
import {useAccounts} from "hooks";
import {hasPermission} from "../../helpers";

const Budget = () => {
    const { t } = useTranslation('common');
    const { activeAccount } = useAccounts();

    return <LoggedInLayout pageTitle={<><PremiumInfo/> {t('pages.advertisements.budget.title')}</>}>
        <Row gutter={[16,16]}>
            <Col style={{width: '100%'}} md={{span: 24}} lg={{span: 12}}>
                { hasPermission(activeAccount, 'manage_daily_budget', 'view') ? <BudgetCard /> : <></> }
            </Col>
        </Row>
        <Row gutter={[16,16]}>
            <Col style={{width: '100%'}} md={{span: 24}} lg={{span: 12}}>
                { hasPermission(activeAccount, 'manage_subscription', 'view') ? <SubscriptionCard /> : <></> }
            </Col>
        </Row>
    </LoggedInLayout>;
};

export default Budget;
