import { useGlobalStore } from "store";

import bindActions from "store/bindActions";
import testOverlap from "store/testOverlap";
import FeaturesReducer from "store/features";

const { Actions } = FeaturesReducer;

const useFeatures = () => {
  const { state, dispatch } = useGlobalStore();

  const { features } = state;

  // Test if there's an overlapping between the actions and store
  testOverlap(features, Actions);

  return {
    ...bindActions(Actions, dispatch),
    ...features,
  };
};

export default useFeatures;
