import React from "react";
import {useTranslation} from "react-i18next";
import {Card, Descriptions} from "antd";
import {useAccounts} from "hooks";

const AccountInfoCard = () => {
    const {t} = useTranslation('common');
    const { activeAccount } = useAccounts();

    // See User entity for possible values.
    const genders = {
        0: t('gender.unknown'),
        1: t('gender.male'),
        2: t('gender.female'),
        9: t('gender.not-applicable'),
    };

    return <Card
            size="small"
            title={t('pages.account.profile-card.title')}
        >
            <Descriptions
                column={1}
                layout="horizontal"
            >
                <Descriptions.Item label={t('pages.account.profile-card.label.name')}>
                    {activeAccount.firstName} {activeAccount.lastName}
                </Descriptions.Item>
                <Descriptions.Item label={t('pages.account.profile-card.label.email')}>
                    {activeAccount.email}
                </Descriptions.Item>
                <Descriptions.Item label={t('pages.account.profile-card.label.gender')}>
                    {genders[activeAccount.gender]}
                </Descriptions.Item>
                <Descriptions.Item label={t('pages.account.profile-card.label.phone')}>
                    {activeAccount.phoneNumber}
                </Descriptions.Item>
            </Descriptions>
        </Card>;
}

export default AccountInfoCard;