import React from "react";

const Money = ({value, digits = 2, postFix = ''}) => {
    // Make sure we don't call toFixed on null.
    const printValue = value == null ? 0 : parseFloat(value);

    return <React.Fragment>
            &euro; {printValue.toFixed(digits).replace('.', ',')} {postFix}
        </React.Fragment>
};

export { Money };
