import * as Types from "./Types";

export const initialState = {
  chat: {
    conversationId: null,
    open: false,
  }
};

export default function (state, action) {
  switch (action.type) {
    case Types.OPEN_CHAT:
      return {
        ...state,
        chat: {
          conversationId: action.payload.conversationId,
          open: action.payload.open,
        }
      };
    default:
      return state;
  }
}