import React, {useState} from 'react';
import LoggedInLayout from "layout/LoggedInLayout";
import {MessageOutlined, PhoneOutlined, MailOutlined} from "@ant-design/icons";
import {useParams} from "@reach/router";
import {
    Drawer,
    Button,
    Col,
    Row,
    Badge,
    Descriptions,
} from "antd";
import Card from "components/Card";
import {useChat} from "hooks";
import useMatchBreakpoint from "../../hooks/useMatchBreakpoint";
import {useTranslation} from "react-i18next";
import moment from "moment/moment";
import useFormatting from "../../hooks/useFormatting";
import {useGetEnabledTransitions} from "../../queries/orders";
import {useFetchStatus} from "../../queries/me";
import {useGetOrder} from "../../queries/orders";
import {OrderStatusTag} from "../../components/Order/OrderStatusTag";
import TextSkeleton from "components/TextSkeleton";
import {OrderExtraFormValuesCard} from "./components/OrderExtraFormValuesCard";
import {OrderSummary} from "./components/OrderSummary";
import OrderCancelForm from "../../components/Order/OrderCancelForm";
import {OrderPaymentTransferCard} from "./components/OrderPaymentTransferCard";

const OrderDetail = () => {
    const { orderId } = useParams();
    const matchBreakpoint = useMatchBreakpoint();
    const { data: status } = useFetchStatus();
    const { doOpenChat } = useChat();
    const [isCancelDrawerOpen, setIsCancelDrawerOpen] = useState(false);

    const { t } = useTranslation('common');
    const { humanizer, formatDateTime, formatDate } = useFormatting();

    const { data: order, isLoading: isLoading } = useGetOrder(orderId);
    const { data: orderTransitions} = useGetEnabledTransitions(orderId);
    const cancelButtonEnabled = orderTransitions?.filter(t => t.name === 'cancel').length > 0;

    const genders = {
        0: t('gender-short.unknown'),
        1: t('gender-short.male'),
        2: t('gender-short.female'),
        9: t('gender-short.not-applicable'),
    };

    const customerTypes = {
        0: t('customer-type.private'),
        1: t('customer-type.business')
    };
    let unreadMessagesCount = 0;
    if (status && status.orders) {
        let orderStatus = status.orders.find(obj => {
            return obj.id === parseInt(orderId);
        });
        if (orderStatus) {
            unreadMessagesCount = orderStatus.unread_messages_count;
        }
    }

    let formattedDateFrom = '';
    let formattedDateUntil = '';
    if (order) {
        if (order.date_from) {
            if (order.rental_item?.allow_time_selection_in_quotation_form) {
                formattedDateFrom = formatDateTime(order?.date_from);
            } else {
                formattedDateFrom = formatDate(order?.date_from);
            }
        }
        if (order.date_until) {
            if (order.rental_item?.allow_time_selection_in_quotation_form) {
                formattedDateUntil = formatDateTime(order?.date_until);
            } else {
                formattedDateUntil = formatDate(order?.date_until);
            }
        }
    }

    return (
        <LoggedInLayout
            pageTitle={t('pages:order.title', {"orderId": orderId})}
            headerExtras={<><Button disabled={!cancelButtonEnabled} onClick={() => setIsCancelDrawerOpen(true)}>{t('button.text.cancel')}</Button></>}
        >
            <Drawer
                title={t('entities:order.transition.cancel')}
                width={matchBreakpoint({ sm: '100%', md: 520})}
                open={isCancelDrawerOpen}
                onClose={() => setIsCancelDrawerOpen(false)}
            >
                {order && <OrderCancelForm onFinish={() => setIsCancelDrawerOpen(false)} order={order}/>}
            </Drawer>
            <Row gutter={[16, 16]}>
                <Col lg={16} md={24}>
                    <Card
                        className={'order-card'}
                        title={t('components:order-details.overview.title')}
                        extra={order && <OrderStatusTag order={order} small={matchBreakpoint({ xs: true, md: false})} />}
                    >
                        <OrderSummary order={order} />
                    </Card>
                    <Card
                        className={'order-card'}
                        title={t('components:order-details.general-information.title')}
                    >
                        <Descriptions column={1} bordered={true}>
                            <Descriptions.Item label={t('components:order-details.general-information.table.requested')}>
                                <TextSkeleton loading={isLoading}>
                                    {order?.quantity}x {order?.rental_item ?
                                        <a href={order?.rental_item._links.html.href} target="_blank" rel="noopener noreferrer">{order?.rental_item.name}</a> :
                                        <a href={order?.clickbox_category._links.html.href} target="_blank" rel="noopener noreferrer">{order?.clickbox_category.title}</a>
                                    }<br/>
                                    {order?.date_from && order?.date_until &&
                                        t(
                                            'components:order-details.general-information.requested.full', {
                                                'date_from': formattedDateFrom,
                                                'date_until': formattedDateUntil,
                                                'duration': humanizer(moment(order?.date_from).diff(moment(order?.date_until)))}
                                        )
                                    }
                                    {order?.date_from && !order?.date_until &&
                                        t(
                                            'components:order-details.general-information.requested.simple', {
                                                'date_from': formattedDateFrom
                                            }
                                        )
                                    }
                                </TextSkeleton>
                            </Descriptions.Item>
                            <Descriptions.Item label={t('components:order-details.general-information.table.reservation_date')}>
                                <TextSkeleton loading={isLoading}>{formatDateTime(order?.created_at)}</TextSkeleton>
                            </Descriptions.Item>
                            {!order?.shipment &&
                                <>
                                    <Descriptions.Item
                                        label={t('components:order-details.general-information.table.reservation_location')}>
                                        <TextSkeleton loading={isLoading}>
                                            <a
                                                href={order?.rental_service_provider_location._links.html.href}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                {order?.rental_service_provider_location.name}
                                            </a>
                                        </TextSkeleton>
                                    </Descriptions.Item>
                                    <Descriptions.Item
                                        label={t('components:order-details.general-information.table.where')}>
                                        <TextSkeleton
                                            loading={isLoading}>{order?.rental_service_provider_location.city || ""}</TextSkeleton>
                                    </Descriptions.Item>
                                </>
                            }

                            {order?.shipment &&
                                <>
                                    <Descriptions.Item
                                        label={t('components:order-details.general-information.table.pick_up_location')}>
                                        <TextSkeleton loading={isLoading}>
                                            <a
                                                href={order?.shipment.pick_up_location._links.html.href}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                {order?.shipment.pick_up_location.full_address || order?.shipment.pick_up_location.name}
                                            </a>
                                        </TextSkeleton>
                                    </Descriptions.Item>
                                    <Descriptions.Item
                                        label={t('components:order-details.general-information.table.return_location')}>
                                        <TextSkeleton loading={isLoading}>
                                            <a
                                                href={order?.shipment.return_location._links.html.href}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                {order?.shipment.return_location.full_address || order?.shipment.return_location.name}
                                            </a>
                                        </TextSkeleton>
                                    </Descriptions.Item>
                                </>
                            }
                            <Descriptions.Item label={t('components:order-details.general-information.table.customer_type')}>
                                <TextSkeleton loading={isLoading}>{customerTypes[order?.customer_type]}</TextSkeleton>
                            </Descriptions.Item>
                            <Descriptions.Item label={t('components:order-details.general-information.table.remark')}>
                                <TextSkeleton loading={isLoading}>{order?.extra}</TextSkeleton>
                            </Descriptions.Item>
                        </Descriptions>
                    </Card>
                    <OrderExtraFormValuesCard orderId={orderId} />
                </Col>
                <Col lg={8} md={24}>
                    <Card
                        className={'order-card'}
                        title={t('components:order-details.customer-details.title')}
                        actions={[
                            <div onClick={e => doOpenChat(order?.conversation.id)}>
                                <Badge size="small" count={unreadMessagesCount}><MessageOutlined key="message" /></Badge>
                            </div>,
                            <a href={"mailto:"+order?.email}>
                                <MailOutlined />
                            </a>,
                            <a href={"phone:"+order?.billing_address.phone_number}>
                                <PhoneOutlined />
                            </a>,
                        ]}
                    >
                        <Descriptions column={1} bordered={true}>
                            <Descriptions.Item label={t('components:order-details.customer-details.table.gender')}>
                                <TextSkeleton loading={isLoading}>{genders[order?.billing_address.gender]}</TextSkeleton>
                            </Descriptions.Item>
                            <Descriptions.Item label={t('components:order-details.customer-details.table.name')}>
                                <TextSkeleton loading={isLoading}>{order?.billing_address.first_name} {order?.billing_address.last_name}</TextSkeleton>
                            </Descriptions.Item>
                            <Descriptions.Item label={t('components:order-details.customer-details.table.email')}>
                                <TextSkeleton loading={isLoading}>{order?.email}</TextSkeleton>
                            </Descriptions.Item>
                            <Descriptions.Item label={t('components:order-details.customer-details.table.phone')}>
                                <TextSkeleton loading={isLoading}>{order?.billing_address.phone_number}</TextSkeleton>
                            </Descriptions.Item>
                        </Descriptions>
                    </Card>
                    <Card
                        className={'order-card'}
                        title={t('components:order-details.address-information.title')}
                    >
                        <Descriptions column={1} bordered={true}>
                            <Descriptions.Item label={t('components:order-details.address-information.table.street')}>
                                <TextSkeleton loading={isLoading}>{order?.billing_address.street} {order?.billing_address.house_number}{order?.billing_address.house_number_addition ? '-' + order?.billing_address.house_number_addition : ''}</TextSkeleton>
                            </Descriptions.Item>
                            <Descriptions.Item label={t('components:order-details.address-information.table.postcode')}>
                                <TextSkeleton loading={isLoading}>{order?.billing_address.postcode}</TextSkeleton>
                            </Descriptions.Item>
                            <Descriptions.Item label={t('components:order-details.address-information.table.city')}>
                                <TextSkeleton loading={isLoading}>{order?.billing_address.city}</TextSkeleton>
                            </Descriptions.Item>
                        </Descriptions>
                    </Card>
                    {order?.shipping_address && <Card
                        className={'order-card'}
                        title={t('components:order-details.shipping-address.title')}
                    >
                        <Descriptions column={1} bordered={true}>
                            <Descriptions.Item label={t('components:order-details.shipping-address.table.street')}>
                                <TextSkeleton loading={isLoading}>{order?.shipping_address.street} {order?.shipping_address.house_number}{order?.shipping_address.house_number_addition ? '-' + order?.shipping_address.house_number_addition : ''}</TextSkeleton>
                            </Descriptions.Item>
                            <Descriptions.Item label={t('components:order-details.shipping-address.table.postcode')}>
                                <TextSkeleton loading={isLoading}>{order?.shipping_address.postcode}</TextSkeleton>
                            </Descriptions.Item>
                            <Descriptions.Item label={t('components:order-details.shipping-address.table.city')}>
                                <TextSkeleton loading={isLoading}>{order?.shipping_address.city}</TextSkeleton>
                            </Descriptions.Item>
                        </Descriptions>
                    </Card>}

                    {!order?.shipping_address && <Card
                        className={'order-card'}
                        title={t('components:order-details.shipping-address.title')}
                    >
                        <Descriptions column={1} bordered={true}>
                            <Descriptions.Item label={t('components:order-details.shipping-address.table.street')}>
                                <TextSkeleton loading={isLoading}>{order?.billing_address.street} {order?.billing_address.house_number}{order?.billing_address.house_number_addition ? '-' + order?.billing_address.house_number_addition : ''}</TextSkeleton>
                            </Descriptions.Item>
                            <Descriptions.Item label={t('components:order-details.shipping-address.table.postcode')}>
                                <TextSkeleton loading={isLoading}>{order?.billing_address.postcode}</TextSkeleton>
                            </Descriptions.Item>
                            <Descriptions.Item label={t('components:order-details.shipping-address.table.city')}>
                                <TextSkeleton loading={isLoading}>{order?.billing_address.city}</TextSkeleton>
                            </Descriptions.Item>
                        </Descriptions>
                    </Card>}
                    {order?.payment?.payment_transfer && <OrderPaymentTransferCard order={order} isLoading={isLoading} />}
                </Col>
            </Row>
        </LoggedInLayout>
    );
};

export default OrderDetail;
