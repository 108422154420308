import {useGlobalStore} from 'store';

import bindActions from 'store/bindActions';
import testOverlap from 'store/testOverlap';
import advertisementBudgetReducer from 'store/advertisementBudget';

const { Actions } = advertisementBudgetReducer;

const useAdvertisementBudget = () => {
    const {state, dispatch} = useGlobalStore();

    const {advertisementBudget} = state;

    // Test if there's an overlapping between the actions and store
    testOverlap(advertisementBudget, Actions);

    return {
        ...bindActions(Actions, dispatch),
        ...advertisementBudget,
    };
};

export default useAdvertisementBudget;
