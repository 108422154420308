import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import LoggedInLayout from "layout/LoggedInLayout";
import {PremiumInfo} from "components/Premium";
import {RentalItemCard} from "./components/RentalItemCard";
import {Button} from "antd";
import {PlusOutlined} from "@ant-design/icons";
import {navigate} from "@reach/router";
import { useSmallDeviceSize, useRentalItem, useAccounts } from "hooks";
import {hasPermission} from "../../helpers";

const RentalItem = () => {
    const { t } = useTranslation('common');
    const isSmallDevice = useSmallDeviceSize();
    const {activeAccount} = useAccounts();

    const {
        isDataLoaded,
        getRentalItem,
        payload: {
        	url
        }
    } = useRentalItem();

    useEffect(() => {
        let params = new URLSearchParams(location.search); // eslint-disable-line
        getRentalItem(activeAccount.companyId, params.get('id'));
    }, [activeAccount]); // eslint-disable-line react-hooks/exhaustive-deps

    return <LoggedInLayout pageTitle={<><PremiumInfo/> {t('pages.rentalitems.title')}</>} headerExtras={
        [
            <Button key="1" type="secondary" disabled={!hasPermission(activeAccount, 'manage_rental_items' ,'view') || !isDataLoaded} hidden={isSmallDevice} href={url} target="_blank">
                {t('pages.rentalitems.view-rental-item')}
            </Button>,
        ]
    }>
	    <RentalItemCard />
	</LoggedInLayout>
    ;
};

export default RentalItem;
