import React from "react";
import {Descriptions, Skeleton} from "antd";
import Card from "components/Card.js";
import {useTranslation} from "react-i18next";
import useFormatting from "../../hooks/useFormatting";
import useGetQuotationExtraValues from "../../queries/quotations";

export const QuotationExtraInformationCard = (props) => {

    const { t } = useTranslation('common');
    const { formatDateTime } = useFormatting();

    const quotation = props.quotation;
    const {data: items, isFetched: isFetched} = useGetQuotationExtraValues(quotation.id);

    if (!items || items.length <= 0){
        return null
    }

    return (
        <Card title={t('components:quotation-extra-information-card.title')}>
            <Skeleton active={true} loading={false}/>
                <Descriptions
                    column={1}
                    layout="horizontal"
                    className="label-50-percent"
                >
                    {
                        items.map((item, key) => {
                            let value = item.value;
                            if (value === "no") {
                                value = t('entities:quotation.delivery_question_answer.delivery_no')
                            } else if (value === "yes") {
                                value = t('entities:quotation.delivery_question_answer.delivery_yes')
                            }

                            return <Descriptions.Item key={key}
                                                      label={t('entities:quotation.extra_form_values.' + item.name)}>
                                {value}
                            </Descriptions.Item>
                        })
                    }

                </Descriptions>
        </Card>
    );
}