import {useGlobalStore} from 'store';

import bindActions from 'store/bindActions';
import testOverlap from 'store/testOverlap';
import invoicesReducer from 'store/invoices';

const { Actions } = invoicesReducer;

const useInvoices = () => {
    const {state, dispatch} = useGlobalStore();

    const {invoices} = state;

    // Test if there's an overlapping between the actions and store
    testOverlap(invoices, Actions);

    return {
        ...bindActions(Actions, dispatch),
        ...invoices,
    };
};

export default useInvoices;
