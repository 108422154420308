import React, { useState, useEffect } from "react";
import {useTranslation} from "react-i18next";
import LoggedInLayout from "../../layout/LoggedInLayout";
import Card from "components/Card";
import {OffersFilters} from "./components/OffersFilters";
import {OffersTable} from "./components/OffersTable";
import moment from "moment";
import {useGetOfferCollection} from "../../queries/offers";

export const Offers = () => {
    const { t } = useTranslation('common');

    const [collectionParameters, setCollectionParameters] = useState({
        page: 1,
        limit: 10,
        searchQuery: null,
        filters: {
            status: null,
            createdAfter: moment().subtract(1, 'month'),
            createdBefore: moment(),
        },
        sort: null
    });

    const { data: offerCollection, isLoading: offerCollectionIsLoading } = useGetOfferCollection(collectionParameters);

    // We store the table data separately, so the table doesn't jump around when loading new data
    const [tableData, setTableData] = useState({
        items: [],
        total: 0,
    });
    useEffect(() => {
        if (!offerCollection) {
            return;
        }
        setTableData({
            items: offerCollection.items,
            total: offerCollection.total,
        });
    }, [offerCollection]);

    const onFilter = (filters) => {
        const searchQuery = filters.searchQuery === '' ? null : filters.searchQuery;
        const status = filters.status === '' ? null : filters.status;
        const collectionFilters = {
            status: status,
            createdAfter: filters.startDate,
            createdBefore: filters.endDate,
        };

        setCollectionParameters(prevState => {
            return {...prevState, searchQuery: searchQuery, filters: collectionFilters}
        });
    };

    const onChangeTable = (pagination, f, sorter) => {
        let sort = null;
        if (sorter.columnKey && sorter.order) {
            sort = (sorter.order === 'descend' ? '-' : '') + sorter.columnKey;
        }
        setCollectionParameters(prevState => {
            return {... prevState, page: pagination.current, limit: pagination.pageSize, sort: sort}
        })
    };

    const filters = {
        status: collectionParameters.filters.status ?? '',
        startDate: collectionParameters.filters.createdAfter,
        endDate: collectionParameters.filters.createdBefore,
        searchQuery: collectionParameters.searchQuery ?? '',
    };

    return (
        <LoggedInLayout
            pageTitle={t('pages:offers.title')}
        >
            <Card title={t('pages:offers.card-title')}>
                <OffersFilters onChange={onFilter} filters={filters} />
                <OffersTable
                    loading={offerCollectionIsLoading}
                    onChange={onChangeTable}
                    offers={tableData.items}
                    total={tableData.total}
                />
            </Card>
        </LoggedInLayout>
    )
};
