import humanizeDuration from "humanize-duration";
import {useTranslation} from "react-i18next";

import moment from "moment";

const useFormatting = () => {

    const { i18n } = useTranslation();

    const humanizer = humanizeDuration.humanizer({
        language: i18n.language,
        serialComma: false,
        conjunction: i18n.language === "nl" ? " en " : " and "
    });

    const dateTimeFormat = 'DD-MM-YYYY HH:mm';
    const dateFormat = 'DD-MM-YYYY';

    const formatDate = (date) => {
        return moment(date).format(dateFormat)
    }

    const formatDateTime = (date) => {
        return moment(date).format(dateTimeFormat)
    }

    const formatPrice = (price) => {
        return '€ ' + parseFloat(price).toFixed(2).replace('.', ',');
    }

    const formatCents = (cents) => {
        return formatPrice(cents / 100);
    };

    return {
        humanizer,
        dateTimeFormat,
        formatDateTime,
        formatDate,
        formatPrice,
        formatCents,
    };
}

export default useFormatting;