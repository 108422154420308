
export const OfferItemType = {
    RENTAL_ITEM: 'rental_item',
    OTHER: 'other'
};

export const OfferType = {
    DETAILED: 'detailed',
    SIMPLE: 'simple'
};

export const OfferStatus = {
    CONCEPT: 'concept',
    OPEN: 'open',
    ACCEPTED: 'accepted',
    REJECTED: 'rejected',
    WITHDRAWN: 'withdrawn',
    EXPIRED: 'expired',
};

export const OfferTransition = {
    ACCEPT: 'accept',
    REJECT: 'reject'
};

export const QuotationStatus = {
    OPEN: 'open',
    IN_PROGRESS: 'in_progress',
    MATCHED: 'matched',
    REJECTED: 'rejected',
    WITHDRAWN: 'withdrawn',
    COMPLETED: 'completed',
};

export const OFFER_VALIDITY_PERIODS = ['P30D', 'P14D'];

export const TransitionStatusDetails = {
    PRODUCT_NOT_IN_STOCK: 'product_not_in_stock',
    NO_RENTAL_TO_PRIVATE_PERSON: 'no_rental_to_private_person',
    ELSE: 'else',
};

export const OrderCancellationReasons = {
    ELSE: 'else',
};