import React from 'react';
import {useTranslation} from "react-i18next";
import LoggedInLayout from "layout/LoggedInLayout";
import {Col, Row} from "antd";
import {InvoicesCard} from "./components/InvoicesCard";
import {InvoiceDetailsCard} from "./components/InvoiceDetailsCard";
import {PaymentDetailsCard} from "./components/PaymentDetailsCard";
import {hasPermission} from "../../helpers";
import {useAccounts} from "../../hooks";
import PaymentTransfersCard from "../../components/PaymentTransfersCard";

const Invoicing = () => {
    const { t } = useTranslation('common');
    const {activeAccount} = useAccounts();

    return <LoggedInLayout pageTitle={t('pages.company.invoicing.title')}>
        <Row gutter={[16,16]}>
            <Col sm={{span: 24}} md={{span: 12}}>
                <InvoiceDetailsCard />
            </Col>
            <Col xs={{span: 24}} md={{span: 12}}>
                <PaymentDetailsCard />
            </Col>
        </Row>
        <Row gutter={[16,16]}>
            <Col span={24}>
                <InvoicesCard />
            </Col>
        </Row>
        {hasPermission(activeAccount, 'manage_payment_transfers', 'view') && (
            <Row gutter={[16,16]}>
                <Col span={24}>
                    <PaymentTransfersCard />
                </Col>
            </Row>
        )}

    </LoggedInLayout>;
};

export default Invoicing;
