import {
    Input
} from "antd";
import React, {forwardRef} from "react";
import {useTranslation} from "react-i18next";

const {TextArea} = Input;

const TextareaCharacterCount = (props, ref) => {
    const {t} = useTranslation('common');
    const {max, value} = props;

    const count = value ? (max - value.length) : max;

    return <div ref={ref}>
        <TextArea
            maxLength={max}
            {...props}
        />
        {count}/{max} {t('components.textarea-character-count.characters-left')}
    </div>;
};

export default forwardRef(TextareaCharacterCount);
