import * as Types from "./Types";

export const initialState = {
  isDataLoaded: false,
  payload: {},
  attachments: [],
};

export default function (state, action) {
  switch (action.type) {
    case Types.SET_IS_DATA_LOADED:
      return {
        ...state,
        isDataLoaded: action.isDataLoaded,
      };
    case Types.SET_DATA:
      return {
        ...state,
        payload: action.payload,
        isDataLoaded: true,
      };
    case Types.ADD_MESSAGE:
      return {
        ...state,
        payload: {
          ...state.payload,
          messages: [action.payload, ...state.payload.messages],
        },
        attachments: [],
        isDataLoaded: true,
      };
    case Types.ADD_MESSAGES:
      return {
        ...state,
        payload: {
          ...state.payload,
          messages: [...action.payload, ...state.payload.messages],
        },
        isDataLoaded: true,
      };
    case Types.ADD_FILE:
      return {
        ...state,
        isDataLoaded: true,
        attachments: [...state.attachments, action.payload],
      };
    case Types.REMOVE_FILE:
      return {
        ...state,
        isDataLoaded: true,
        attachments: [...state.attachments].filter(
          (attachment) => attachment.id !== action.payload
        ),
      };
    default:
      return state;
  }
}
