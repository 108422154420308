import React, {useState, useEffect} from "react";
import {Trans, useTranslation} from 'react-i18next';
import {Button, Form, notification, Switch} from "antd";
import Drawer from "components/Drawer";
import SubmitButton from "components/SubmitButton";
import {useAccounts, useRentalItem} from "hooks";
import InputCharacterCount from "../../../../components/InputCharacterCount";
import {hasPermission} from "../../../../helpers";
import {useGetSubscriptionFeatures, useGetSubscriptionMetrics} from "../../../../queries/subscription";
import ActiveRentalItemsStatus from "../../../../components/Subscription/ActiveRentalItemsStatus";
import {useMutation, useQueryClient} from "react-query";

const { forwardRef, useImperativeHandle } = React;

const RentalItemDetailsForm = forwardRef((props, ref) => {
    const [form] = Form.useForm();
    const [formOpen, setFormOpen] = useState(false);
    const [rentalItem, setRentalItem] = useState({id: null, active: false, name: null});

    const queryClient = useQueryClient();
    const [initiallyActive, setInitiallyActive] = useState(false)

    const {t} = useTranslation('common');
    const {activeAccount} = useAccounts();

    const { data: subscriptionMetrics } = useGetSubscriptionMetrics();
    const { data: subscriptionFeatures } = useGetSubscriptionFeatures();

    const numberOfActiveRentalItems = subscriptionMetrics?.active_rental_items;
    const maxActiveRentalItems = subscriptionFeatures?.max_active_rental_items;

    const {
        putDetails
    } = useRentalItem();

    // Allows use of the openForm function from the parent component.
    useImperativeHandle(ref, () => ({
        openForm(id, active, name) {
            setInitiallyActive(active)
            form.setFieldsValue({
                active: active,
                name: name
            });
            setRentalItem({id: id, active: active, name: name});
            setFormOpen(true);
        }
    }));

    const onChangeActive = (checked) => {
        setRentalItem({...rentalItem, active: checked});
    }

    const onChangeName = (e) => {
        setRentalItem({...rentalItem, name: e.target.value});
    }

    const closeForm = () => {
        setFormOpen(false);
        setRentalItem({});
    };

    const onFinish = (values) => {
        putDetails(activeAccount.companyId, rentalItem.id, rentalItem.active, rentalItem.name).then(async (res) => {
            notification.open({
                message: t('notification.messages.saved.title'),
                description: t('notification.messages.saved.description'),
            });

            await queryClient.invalidateQueries('subscription-metrics');

            closeForm();
        }).catch(errorInfo => {
            console.log(errorInfo);
            notification.open({
                message: t('notification.messages.error.title'),
                description: t('notification.messages.error.description'),
            });
            closeForm();
        });
    }

    return (
        <Drawer
            form={form}
            title={t('pages.rentalitems.rental-item.activation-form.title')}
            placement="right"
            onClose={() => closeForm()}
            open={formOpen}
            width={520}
        >
            <Form
                form={form}
                layout="vertical"
                onFinish={onFinish}
                initialValues={{
                    active: rentalItem.active,
                    name: rentalItem.name
                }}
            >
                <Form.Item
                    name="name"
                    label={t('pages.rentalitems.rental-item.activation-form.items.name.label')}
                    rules={[
                        {required: true, message: t('form.errors.required')}
                    ]}
                >
                    <InputCharacterCount onChange={onChangeName} max={50} placeholder={t('pages.rentalitems.rental-item.activation-form.items.name.label')}/>
                </Form.Item>

                <Form.Item
                    name="active"
                    valuePropName="checked"
                    label={
                        <span>
                        {t('pages.rentalitems.rental-item.activation-form.items.active.label')}<br/>
                        <ActiveRentalItemsStatus />
                </span>
                }
                    style={{marginBottom: '40px'}}
                >
                    <Switch onChange={onChangeActive} disabled={
                        !hasPermission(activeAccount, 'manage_rental_item_activation', 'edit') || (!initiallyActive && numberOfActiveRentalItems >= maxActiveRentalItems)
                    } />
                </Form.Item>


                <div className="actions">
                    <Button onClick={() => closeForm()} style={{marginRight: '8px'}}>
                        {t('button.text.cancel')}
                    </Button>
                    <SubmitButton>
                        {t('button.text.save')}
                    </SubmitButton>
                </div>
            </Form>
        </Drawer>
    );
});

export default RentalItemDetailsForm;