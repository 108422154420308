import React, { useState, useEffect } from "react";
import {Col, Input, Row, Select} from "antd";
import {useTranslation} from "react-i18next";
const { Search } = Input;

export const PaymentTransfersFilters = ({filters: filtersProp, onChange, disabled = false}) => {

    const { t } = useTranslation('common');
    const [filters, setFilters] = useState(filtersProp);
    const [searchQuery, setSearchQuery] = useState(filters.searchQuery);

    useEffect(() => {
        if (onChange) {
            onChange(filters);
        }
    }, [filters])

    // Changes to the search query will be propagated after 500ms, so as not to do any updates while the user is typing
    let searchTimeout = null;
    useEffect(() => {
        clearTimeout(searchTimeout);
        searchTimeout = setTimeout(() => {
            setFilters(prevState => ({ ...prevState, searchQuery: searchQuery }));
        }, 500);
        return () => {
            clearTimeout(searchTimeout);
        };
    }, [searchQuery]);
    const onSearch = (e) => {
        setSearchQuery(e.target.value);
    }

    const onChangeState = value => {
        setFilters(prevState => ({ ...prevState, state: value }));
    };

    return (
        <Row
            gutter={[16, 16]}
            type="flex"
            justify="space-around"
            align="middle"
            style={{borderBottom: '1px solid #e8e8e8', paddingBottom: '16px', marginBottom: '16px'}}
        >
            <Col xs={{span: 24}} sm={{ span: 16 }} md={{span: 16}}>
                <Search
                    style={{ width: '100%' }}
                    placeholder={t('components:payment-transfers-filters.search.placeholder')}
                    disabled={disabled}
                    onChange={onSearch}
                />
            </Col>

            <Col xs={{span: 24}} sm={{ span: 8 }} md={{span: 8}}>
                { !disabled ?
                    <Select onChange={onChangeState} defaultValue="" style={{ width: '100%' }}>
                        <Select.Option value="">{ t('entities:payment-transfer.state.all') }</Select.Option>
                        <Select.Option value="pending">{ t('entities:payment-transfer.state.pending') }</Select.Option>
                        <Select.Option value="transferred">{ t('entities:payment-transfer.state.transferred') }</Select.Option>
                        <Select.Option value="canceled">{ t('entities:order.state.canceled') }</Select.Option>
                    </Select> : <></>
                }
            </Col>
        </Row>
    )
};
