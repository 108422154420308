import apiClient from "services/HurenApiClient";
import * as Types from "./Types";
import TokenStorage from "services/TokenStorage";

export const getToken = () => {
    return async function(dispatch) {
        if (TokenStorage.isAuthenticated()) {
            dispatch(loginPerformed());
            return;
        }
        return apiClient.post('/token').then(res => {
            if (res.data) {
                if (res.data.token === '') {
                    TokenStorage.clear();
                } else {
                    TokenStorage.storeToken(res.data.token);
                    TokenStorage.storeRefreshToken(res.data.refresh_token);

                    dispatch(loginPerformed());
                }
            }
        }).catch(e => {
            TokenStorage.clear();
            localStorage.clear();
            console.log(e);
        });
    };
};

export const logout = () => {
    return function(dispatch) {
        TokenStorage.clear();
        localStorage.clear();
        dispatch(logoutPerformed());
        window.location.replace(process.env.REACT_APP_WEBSITE_LOGOUT_URL);
    }
};

export const logoutPerformed = () => {
    return {
        type: Types.LOGOUT,
    };
};

export const loginPerformed = (token) => {
    return {
        type: Types.LOGIN
    };
};