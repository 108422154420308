import React, {useRef} from 'react';
import LoggedInLayout from "layout/LoggedInLayout";
import Card from "components/Card";
import {Trans, useTranslation} from "react-i18next";
import {Col, Row, Button, Typography } from "antd";
import {PhoneOutlined, MailOutlined, UnorderedListOutlined} from '@ant-design/icons';
import {Link} from "@reach/router";
import {ContactForm} from "components/ContactForm";
import {useSmallDeviceSize} from "hooks";

const {Text} = Typography;

const Contact = () => {
    const { t } = useTranslation('common');

    const contactFormRef = useRef();

    const openContactForm = () => {
        contactFormRef.current.openForm();
    }

    const isSmallDeviceSize = useSmallDeviceSize();

    return <LoggedInLayout pageTitle={t('pages.help.contact.title')}>
        <ContactForm ref={contactFormRef} title={t('forms.contact-form.title.contact')} type="contact" />
        <Card title={t('pages.help.contact-card.title')} className="contact-page">
            <Text>
                {<Trans t={t} ns="common" i18nKey="pages.help.contact-card.content">
                    We would like to help you improve your online campaigns!
                    (Did you read our <Link to="/help/ebooks">e-books</Link>?)
                    Our frequently asked questions can help you find answers more quickly.
                    Can't find your answer or you have some feedback? Please contact us!
                    There are various ways, find the easiest methods below.
                </Trans>}
            </Text>

            <br/><br/>
            <Row gutter={[16,16]}>
                <Col sm={24} md={8}>
                    <strong>{t('pages.help.contact-card.methods.faq.title')}</strong><br/>
                    {t('pages.help.contact-card.methods.faq.subtitle')}<br/>
                    <Button href="https://www.huren.nl/veelgestelde-vragen-bedrijven" target="_blank" hidden={!isSmallDeviceSize}>
                        <UnorderedListOutlined />
                        {t('pages.help.contact-card.methods.faq.button')}
                    </Button>
                </Col>
                <Col sm={24} md={8}>
                    <strong>{t('pages.help.contact-card.methods.mail.title')}</strong><br/>
                    {t('pages.help.contact-card.methods.mail.subtitle')}<br/>
                    <Button onClick={openContactForm} hidden={!isSmallDeviceSize}>
                        <MailOutlined />
                        {t('pages.help.contact-card.methods.mail.button')}
                    </Button>
                </Col>
                <Col sm={24} md={8}>
                    <strong>{t('pages.help.contact-card.methods.call.title')}</strong><br/>
                    {t('pages.help.contact-card.methods.call.subtitle')}<br/>
                    <Button href={ "tel:" + t('pages.help.contact-card.methods.call.phone')} hidden={!isSmallDeviceSize}>
                        <PhoneOutlined />
                        {t('pages.help.contact-card.methods.call.button')}
                    </Button>
                </Col>
            </Row>
            <Row gutter={[16,16]} hidden={isSmallDeviceSize}>
                <Col span={8}>
                    <Button href="https://www.huren.nl/veelgestelde-vragen-bedrijven" target="_blank">
                        <UnorderedListOutlined />
                        {t('pages.help.contact-card.methods.faq.button')}
                    </Button>
                </Col>
                <Col span={8}>
                    <Button onClick={openContactForm}>
                        <MailOutlined />
                        {t('pages.help.contact-card.methods.mail.button')}
                    </Button>
                </Col>
                <Col span={8}>
                    <Button href={ "tel:" + t('pages.help.contact-card.methods.call.phone')}>
                        <PhoneOutlined />
                        {t('pages.help.contact-card.methods.call.button')}
                    </Button>
                </Col>
            </Row>
        </Card>

        <br/>
    </LoggedInLayout>;
};

export default Contact;
