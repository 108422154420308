import React, {useEffect} from 'react';
import {EditOutlined} from '@ant-design/icons';
import {Alert, Button, Descriptions} from "antd";
import Card from "components/Card";
import {useTranslation} from "react-i18next";
import {PremiumInfo} from "components/Premium";
import OriginalTextSkeleton from "components/TextSkeleton";
import moment from 'moment';
import {useOpeningHours} from "hooks";
import { useAccounts } from "hooks";
import {hasPermission} from "../../../../helpers";
import OpeningHoursDrawer from "./OpeningHoursDrawer";

const timeFormat = 'HH:mm';
const weekdays = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];

const TextSkeleton = (props) => {
    const {
        isDataLoaded,
    } = useOpeningHours();

    return <OriginalTextSkeleton {...props} loading={!isDataLoaded}/>
};

const OpeningHoursCard = () => {
    const {t} = useTranslation('common');
    const { activeAccount } = useAccounts();

    const {
        getOpeningHours,
        isDataLoaded,
        openEditForm,
        payload: {
            openingHours
        }
    } = useOpeningHours();

    useEffect(() => {
        getOpeningHours(activeAccount.companyId);
    }, [activeAccount]); // eslint-disable-line react-hooks/exhaustive-deps

    const getOpeningHoursByDay = (weekday) => {
        if (isDataLoaded) {
            return {
                timeFrom: openingHours['opening_time_' + weekday],
                timeTo: openingHours['closing_time_' + weekday],
            };
        }
    };

    return <>
        <OpeningHoursDrawer/>
        <Card
            loading={!isDataLoaded}
            className="company-opening-hours-card"
            title={<><PremiumInfo/> {t('pages.company.opening-hours-card.title')}</>}
            actions={[
                <Button disabled={!hasPermission(activeAccount, 'manage_opening_hours', 'edit')} onClick={() => openEditForm()}>
                    <EditOutlined/> {t('button.text.edit')}
                </Button>
            ]}
        >
            <Alert
                style={{marginBottom: '14px'}}
                type="warning"
                showIcon
                message={t("pages.company.opening-hours-card.alert-opening-hours")} />

            <Descriptions column={1} bordered={true}>

                {weekdays.map((weekday, index) => {
                    const data = getOpeningHoursByDay(weekday);

                    let text = t('pages.company.opening-hours-card.closed');
                    if (data && data.timeFrom != null && data.timeTo !== null) {
                        text = moment(data.timeFrom, timeFormat).format(timeFormat)
                            + " - " +
                            moment(data.timeTo, timeFormat).format(timeFormat);
                    }

                    const label = t('days.' + weekday + '.long');

                    return <Descriptions.Item label={label} key={index}>
                        <TextSkeleton>{text}</TextSkeleton>
                    </Descriptions.Item>;
                })}
            </Descriptions>
            
            <Descriptions column={1} style={{marginBottom: '16px'}}>
                <Descriptions.Item label={t('pages.company.opening-hours-card.label.additional-text.label')}>
                    {isDataLoaded ? openingHours.additional_text : ""}
                </Descriptions.Item>
            </Descriptions>
        </Card>
    </>;
};

export default OpeningHoursCard;
