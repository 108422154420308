import React, {useEffect, useState} from 'react';
import LoggedInLayout from "layout/LoggedInLayout";
import {useTranslation} from "react-i18next";
import {navigate, Redirect, useParams} from "@reach/router";
import client from "services/HurenApiClient";
import Card from "components/Card";
import {Col, Row, notification, Spin} from "antd";
import {QuotationCard} from "../../components/QuotationCard/QuotationCard";
import OfferForm from "../../components/OfferForm/OfferForm";
import {useGetRentalMatch} from "../../queries/rentalMatches";
import {useUpdateOffer} from "../../queries/offers";
import {canEditOffer} from "../../helpers/offers";

const EditOffer = () => {
	const { t } = useTranslation('common');
    const { rentalMatchId, offerId } = useParams();

    const { data: rentalMatch } = useGetRentalMatch(rentalMatchId);
    const [offerFormDisabled, setOfferFormDisabled] = useState(false);
    const [offer, setOffer] = useState(null);
    const updateOffer = useUpdateOffer();

    const loadData = async () => {
        client.get(`/api/v2/offers/${offerId}`).then(res => {
            setOffer(res.data);
        });
    }

    useEffect(() => {
        if (!rentalMatch) {
            return;
        }
        loadData();
    }, [rentalMatch, offerId]);

    const onFinish = async (updatedOffer) => {
        setOfferFormDisabled(true);
        await updateOffer.mutateAsync({
            offerId: offerId,
            offer: updatedOffer
        });
        await navigate(`/rental-matches/${rentalMatchId}#offers`);
        notification.success({
            message: t('notifications:edit-offer.success.message'),
            description: t('notifications:edit-offer.success.description'),
        });
    };

    const returnUrl = `/rental-matches/${rentalMatchId}#offers`

    const onCancel = () => {
        navigate(returnUrl);
    };

    if (offer && !canEditOffer(offer)) {
        return <Redirect to={returnUrl} noThrow/>
    }

    return (
        <LoggedInLayout
            pageTitle={t('pages:edit-offer.title')}
        >
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <QuotationCard quotation={rentalMatch?.quotation} rentalMatch={rentalMatch} />
                </Col>
            </Row>

            <Card title={t('pages:edit-offer.offer-form-card.title')}>
                <Spin spinning={offer === null}>
                    <OfferForm
                        mode="edit"
                        disabled={offerFormDisabled}
                        rentalMatch={rentalMatch}
                        offer={offer}
                        onFinish={onFinish}
                        onCancel={onCancel}
                    />
                </Spin>
            </Card>
        </LoggedInLayout>
    );
};

export default EditOffer;
