import React, {useEffect, useState} from 'react';
import {EditOutlined} from "@ant-design/icons";
import {Trans, useTranslation} from "react-i18next";
import {Badge, Button, Col, Input, Radio, Row, Table} from "antd";
import {Link} from "@reach/router";
import Card from "components/Card";
import StatusInfo from "components/StatusInfo";
import RentalPrice from "components/RentalPrice";
import {useAccounts, useSmallDeviceSize, useRentalItems} from "hooks";
import Media from "react-media";
import {Money} from "components/Money";
import {hasPermission} from "../../../../helpers";
import {useGetSubscriptionFeatures, useGetSubscriptionMetrics} from "../../../../queries/subscription";
import ActiveRentalItemsStatus from "../../../../components/Subscription/ActiveRentalItemsStatus";

const RentalItemsOverviewCard = () => {
    const {t} = useTranslation('common');
    const { Search } = Input;
    const [rentalItemsData, setRentalItemsData] = useState([]);
    const [filters, setFilters] = useState({active: '', rentalItemName: ''});

    const { data: subscriptionMetrics } = useGetSubscriptionMetrics();
    const { data: subscriptionFeatures } = useGetSubscriptionFeatures();

    const {activeAccount} = useAccounts();
    
    const {
        isDataLoaded,
        getRentalItems,
        viewRentalItem,
        payload: {
            rentalItems,
            total
        }
    } = useRentalItems();

    const filterRentalItems = () => {
        const activeFilter = filters.active;
        const rentalItemNameFilter = filters.rentalItemName;

        let results;
        if (rentalItemNameFilter && rentalItemNameFilter !== "") {
            results = rentalItems.filter(rentalItem => {
                const searchName = rentalItem.name.toLowerCase();
                return (searchName.indexOf(rentalItemNameFilter.toLowerCase()) >= 0);
            });
        } else {
            results = rentalItems;
        }

        if (activeFilter && activeFilter !== "") {
            results = results.filter(rentalItem => {
                if (activeFilter === "1") {
                    return rentalItem.active === true;
                } else if (activeFilter === "0") {
                    return rentalItem.active === false;
                } else {
                    return false;
                }
            });
        }

        setRentalItemsData(results);
    };

    const onChange = event => {
        const target = event.target;
        const value = target.value;
        setFilters(prevState => ({ ...prevState, rentalItemName: value }));
    };

    const onFilterActive = event => {
        setFilters(prevState => ({ ...prevState, active: event.target.value }));
    };

    useEffect(() => {
        getRentalItems(activeAccount.companyId);
    }, [activeAccount]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setRentalItemsData(rentalItems);
    }, [rentalItems]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        filterRentalItems();
    }, [filters]); // eslint-disable-line react-hooks/exhaustive-deps

    const isSmallDevice = useSmallDeviceSize();

    const tableColumns = [
        {
            title: t('pages.rentalitems.rental-items-card.table.name'),
            dataIndex: 'name',
            key: 'name',
            ellipsis: false,
            sorter: (a, b) => ('' + a.title).localeCompare(b.category.title),
            render: (text, record) => <Row type="flex" className="name-cell" style={{alignItems: 'center'}}>
            { (record.images.length > 0) ? 
                 (!isSmallDevice) ?
                    <Col span={10}>
                        <img width={64} height={47} src={record.images[0].image_url}
                               style={{marginRight: '1em', marginBottom: '1px', boxSizing: 'content-box', border: '1px solid #d9d9d9'}}/>
                    </Col> : 
                    <Col span={10}>
                        <img width={64} height={47} src={record.images[0].image_url}
                               style={{marginBottom: '1px', boxSizing: 'content-box', border: '1px solid #d9d9d9'}}/>
                    </Col>
                 : ""
            }
                <Col span={14}>
                    <Row className="name-cell">
                        <a href={record.url} target="_blank">
                            <h4 style={{color: 'rgba(0, 0, 0, 0.65)'}}>
                                {record.name}
                            </h4>
                        </a>
                    </Row>
                </Col>
             </Row>
        },
        {
            title: t('pages.rentalitems.rental-items-card.table.category'),
            dataIndex: 'category',
            key: 'category',
            ellipsis: false,
            sorter: (a, b) => ('' + a.category.title).localeCompare(b.category.title),
            render: (text, record) => <Row>
                <Row className="category-cell">
                    <a href={record.category.url} target="_blank" rel="noopener noreferrer">
                        {record.category.title}
                    </a>
                </Row>
            </Row>
        },
        {
            title: t('pages.rentalitems.rental-items-card.table.price'),
            dataIndex: 'price',
            key: 'price',
            ellipsis: false,
            render: (text, record) => record.price ? <RentalPrice value={record.price} /> : ""
        },
        {
            title: <>
                {t('pages.rentalitems.rental-items-card.table.status')} &nbsp;
                <StatusInfo />
            </>,
            className: 'status-state-cell',
            dataIndex: 'active',
            key: 'active',
            ellipsis: true,
            hideOnResponsive: false,
            align: (isSmallDevice) ? 'right' : 'left',
            width: '15%',
            sorter: (a, b) => a.active - b.active,
            render: (text, record) => {
                return record.active ? <Badge color="green" text={t('badges.active')}/> : <Badge color="red" text={t('badges.inactive')}/>
            }
        },
        {
            align: 'right',
            title: t('pages.rentalitems.rental-items-card.table.actions'),
            dataIndex: 'actions',
            key: 'actions',
            ellipsis: false,
            width: '20%',
            render: (text, record) => {
                return <Link disabled={!hasPermission(activeAccount, 'manage_rental_items', 'view')} to={"/rentalitem?id="+record.id}>{t('pages.rentalitems.rental-items-card.table.view')}</Link>
            }
        }
    ];

    let searchInputWidth = '80%';
    if (isSmallDevice) {
        searchInputWidth = '100%';
    }

    return <>
        <Card
            size="small"
            className="company-advertisements-card"
            title={t('pages.rentalitems.rental-items-card.title')}
        >
            <Row gutter={[16, 16]} type="flex" justify="space-around" align="middle" style={{borderBottom: '1px solid #e8e8e8', paddingBottom: '16px', marginBottom: '16px'}}>
                <Col sm={{span: 24}} md={{span: 12}}>
                    <Search
                        style={{ width: searchInputWidth }}
                        placeholder={t('pages.rentalitems.rental-items-card.search.placeholder')}
                        disabled={!isDataLoaded}
                        onChange={onChange}
                    />
                </Col>
                <Col sm={24} md={12}>
                    { isDataLoaded ?
                        <Radio.Group onChange={onFilterActive} defaultValue="none">
                            <Radio.Button value="1"><Badge color="green" text={t('badges.active')} /></Radio.Button>
                            <Radio.Button value="0"><Badge color="red" text={t('badges.inactive')} /></Radio.Button>
                            <Radio.Button value="">{t('filters.none')}</Radio.Button>
                        </Radio.Group> : <></>}
                </Col>
            </Row>

            <Row gutter={[16, 16]}>
                <Col align="left">
                    <strong>
                    { rentalItemsData !== undefined &&
                        <span>{ rentalItemsData.length + ' ' + t('pages.rentalitems.rental-items-card.items') }</span>
                    }
                    {' | '}
                    <ActiveRentalItemsStatus />
                    </strong>
                </Col>
            </Row>
            <Media query="(min-width: 950px)">
                {matches => {

                    let filteredColumns = tableColumns.filter((column) => {
                        return matches || column.hideOnResponsive !== true;
                    });

                    return <Table
                        tableLayout="fixed"
                        dataSource={rentalItemsData}
                        columns={filteredColumns}
                        loading={!isDataLoaded}
                        rowKey="id"
                        pagination={{
                            total:total,
                            defaultPageSize:10,
                            showSizeChanger:true,
                            showLessItems:true
                        }}
                    />
                }}
            </Media>
        </Card>
    </>
};
export default RentalItemsOverviewCard;