export const isTransitionEnabled = (enabledTransitions, transitionName) => {
    if (!enabledTransitions) {
        return false;
    }

    for (var i = 0; i < enabledTransitions.length; i++) {
        const enabledTransition = enabledTransitions[i];
        if (enabledTransition.name === transitionName) {
            return true;
        }
    }

    return false;
};