import React, {useEffect} from 'react';
import LoginLayout from "layout/LoginLayout";
import {useTranslation} from "react-i18next";
import {Card, Col, Row, Spin} from "antd";
import {useAuth, useAccounts } from "hooks";
import Text from "antd/es/typography/Text";
import TokenStorage from "services/TokenStorage";
import {navigate} from "@reach/router";
import client from "services/HurenApiClient";
const crypto = require('crypto');

const redirectToLogin = (returnPath) => {
    window.location.replace(
        process.env.REACT_APP_WEBSITE_LOGIN_URL + '?returnTo=' + process.env.REACT_APP_HOME_URL + returnPath
    );
}

const TokenCheck = () => {
    const { t } = useTranslation('common');

    const {
        getToken
    } = useAuth();

    const {
        getAccounts,
        accountChosen,
    } = useAccounts();

    useEffect(() => {
        getToken().then(r => {

            const currentPath = window.location.pathname + window.location.search;

            if (!TokenStorage.isAuthenticated()) {
                redirectToLogin(currentPath);
            } else {
                client.get('/api/v2/me').then(res => {
                    const user = res.data;

                    if (!user.selected_account) {
                        navigate('/accounts?returnTo=' + currentPath);
                        return;
                    }

                    // Load accounts but ignore output because api is slow but still needed
                    // to enable/disable the account switcher button in the top menu
                    getAccounts();

                    window.Intercom('boot', {
                        app_id: window.INTERCOM_APP_ID,
                        email: user.email,
                        name: user.first_name + ' ' + user.last_name,
                        user_id: user.id,
                        user_hash: crypto.createHmac('sha256', process.env.REACT_APP_INTERCOM_VERIFICATION_ID.toString()).update(user.id.toString()).digest('hex'),
                        company: {
                            id: user.selected_account.rental_service_provider.id,
                            name: user.selected_account.rental_service_provider.name,
                        }
                    });

                    client.get('/api/v2/me/permissions').then(res => {
                        const permissions = res.data;
                        accountChosen(
                            {
                                accountId: user.selected_account.id,
                                email: user.email,
                                firstName: user.first_name,
                                lastName: user.last_name,
                                gender: user.gender,
                                phoneNumber: user.phone_number,
                                companyName: user.selected_account.rental_service_provider.name,
                                companyId: user.selected_account.rental_service_provider.id,
                                hasBudget: user.selected_account.rental_service_provider.has_budget,
                                permissions: permissions
                            }
                        );
                    });
                });
            }
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return <LoginLayout pageTitle={t('pages.auth.token-check.title')}>
        <Row type="flex" justify="center" align="middle" style={{minHeight: '90vh'}}>
            <Col sm={10} md={8} xl={6}>
                <Card className="token-check-card" title={t('pages.account.token-check.title')} >
                    <Text>
                        {t('pages.account.token-check.text')}
                    </Text>
                    <Spin spinning={true} />
                </Card>
            </Col>
        </Row>
    </LoginLayout>;
};

export default TokenCheck;
