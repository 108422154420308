import React from "react";
import {useTranslation} from "react-i18next";
import {useAccounts, useSubscription, useCancellation, useSmallDeviceSize} from "hooks";
import {Button, DatePicker, Drawer, Form, notification} from "antd";
import gtag from 'ga-gtag';
import moment from "moment";
import {CheckOutlined} from "@ant-design/icons";

const CancellationForm = () => {
    const [form] = Form.useForm();
    const {getFieldValue} = form;

    const {t} = useTranslation('common');
    const isSmallDevice = useSmallDeviceSize();

    const {activeAccount} = useAccounts();

    const {
        payload: {
            next_date,
            termination_date
        }
    } = useSubscription();

    const {
        closeCancellationForm,
        isCancellationFormOpen,
        updateCancellation,
    } = useCancellation();

    let drawerWidth = 520;
    if (isSmallDevice) {
        drawerWidth = '100%';
    }

    const onFinish = (values) => {
        updateCancellation(activeAccount.companyId, values)
            .then((res) => {
                notification.open({
                    message: t('notification.messages.saved.title'),
                    description: t('notification.messages.saved.description'),
                });

                gtag('event', 'changed_subscription', {
                    event_category: 'ma_budget',
                    event_label: 'cancelled'
                });

                closeCancellationForm();
            })
            .catch(e => {
                console.log(e);
                notification.open({
                    message: t('notification.messages.error.title'),
                    description: t('notification.messages.error.description'),
                });
                closeCancellationForm();
            });
    };

    const disabledDate = (current) => {
        // Can not select days before today and today
        return current && current < moment().endOf('day');
    }

    const validateDate = (value) => {
        // Take in mind the 1 month cancellation policy.
        return value && (
            value < moment(next_date).subtract(30, 'days') ||
            value > moment(next_date)
        );
    }

    return (
        <Drawer
            title={t('forms.cancellation-form.title')}
            placement="right"
            onClose={() => closeCancellationForm()}
            open={isCancellationFormOpen}
            width={drawerWidth}
        >
            <Form
                form={form}
                layout="vertical"
                style={{marginBottom: '3em'}}
                onFinish={onFinish}
            >
                { termination_date === null ?
                <Form.Item
                    name="cancellationDate"
                    label={t('forms.cancellation-form.items.cancellation-date.label')}
                    rules={[
                        {required: true, message: t('form.errors.required')},
                        ({ getFieldValue }) => ({
                            validator(rule, value) {
                            if (!value || validateDate(value)) {
                                return Promise.resolve();
                            }
                                return Promise.reject(t('form.errors.invalid-cancellation-date'));
                            },
                        }),
                    ]}
                >
                    <DatePicker
                        format="DD-MM-YYYY"
                        showTime={false}
                        disabledDate={disabledDate}
                        placeholder={t('forms.cancellation-form.items.cancellation-date.placeholder')} />
                </Form.Item> : <></> }

                <div className="actions">
                    <Button onClick={() => closeCancellationForm()} style={{marginRight: '8px'}}>
                        {t('button.text.cancel')}
                    </Button>
                    <Button htmlType="submit" type="primary">
                        <CheckOutlined/>
                        {t('button.text.save')}
                    </Button>
                </div>
            </Form>
        </Drawer>
    );
}

export default CancellationForm;