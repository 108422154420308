import client from "services/HurenApiClient";
import * as Types from "./Types";

export const setIsDataLoaded = (loaded) => {
  return {
    type: Types.SET_IS_DATA_LOADED,
    isDataLoaded: loaded,
  };
};

export const setData = (payload) => {
  return {
    type: Types.SET_DATA,
    payload,
  };
};

export const addMessage = (payload) => {
  return {
    type: Types.ADD_MESSAGE,
    payload,
  };
};

export const addFile = (payload) => {
  return {
    type: Types.ADD_FILE,
    payload,
  };
};

export const removeFile = (payload) => {
  return {
    type: Types.REMOVE_FILE,
    payload,
  };
};

export const addMessages = (payload) => {
  return {
    type: Types.ADD_MESSAGES,
    payload: payload,
  };
};

export const getConversation = (
  conversationId,
  rentalServiceProviderId,
  accountId
) => {
  return async function (dispatch) {
    dispatch(setIsDataLoaded(false));

    return client
      .get(`/inbox/${conversationId}/conversation`, {
        params: { rentalServiceProviderId, accountId },
      })
      .then((res) => {
        dispatch(setData(res.data));
      });
  };
};

export const getConversationMessages = (
  conversationId,
  rentalServiceProviderId,
  timestamp,
  accountId
) => {
  return async function (dispatch) {
    dispatch(setIsDataLoaded(false));

    return client
      .get(`/inbox/${conversationId}/conversation-messages`, {
        params: { rentalServiceProviderId, timestamp, accountId },
      })
      .then((res) => {
        dispatch(addMessages(res.data));
      });
  };
};

export const writeMessage = (
  values,
  conversationId,
  rentalServiceProviderId,
  accountId
) => {
  return async function (dispatch) {
    return client
      .post(
        `/inbox/${conversationId}/post-message`,
        {
          message: values,
        },
        {
          params: {
            rentalServiceProviderId,
            accountId,
          },
        }
      )
      .then((res) => {
        dispatch(addMessage(res.data));
      });
  };
};

export const uploadFile = (file) => {
  return async function (dispatch) {
    const form = new FormData();
    form.append("file", file || "");
    client.post(`/inbox/upload-attachment`, form).then((res) => {
      dispatch(addFile(res.data));
    });
  };
};

export const deleteAttachment = (id) => (dispatch) =>
  dispatch(removeFile(id));
