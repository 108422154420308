import React from "react";
import {useTranslation} from "react-i18next";
import {Tag} from "antd";

const RentalMatchStatus = (props) => {
    const { t, i18n } = useTranslation('common');
    let {status} = props;

    var color = '#ff7800'; // orange
    if (status == 'accepted') {
        color = '#75b118'; // green
    } else if (status == 'rejected') {
        color = '#ff4d4f'; // red
    }

    const statuses = {
        'open': (t('entities:rental-match.status.open')),
        'accepted': (t('entities:rental-match.status.accepted')),
        'rejected': (t('entities:rental-match.status.rejected')),
    };

    return <Tag color={color}>
        {statuses[status] ?? status}
    </Tag>
};

export default RentalMatchStatus;
