import {useMutation, useQuery, useQueryClient} from "react-query";
import client from "services/HurenApiClient";
import {createQueryString} from "../helpers/queryString";

const fetchRentalMatchOffers = (id) => async () => {
    const res = await client.get(`/api/v2/rental-matches/${id}/offers`);
    return res.data.items;
};
export const useGetRentalMatchOffers = (id, options) => {
    return useQuery(['rental-matches', parseInt(id), 'offers'], fetchRentalMatchOffers(id), options);
};

const fetchRentalMatch = (id) => async () => {
    const res = await client.get(`/api/v2/rental-matches/${id}`);
    return res.data;
};
export const useGetRentalMatch = (id) => {
    return useQuery(['rental-matches', parseInt(id)], fetchRentalMatch(id));
};

const fetchRentalMatches = async () => {
    const res = await client.get(`/api/v2/rental-matches`);
    return res.data.items;
};
export const useGetRentalMatches = () => {
    return useQuery(['rental-matches'], fetchRentalMatches);
};

const createRentalMatchTransition = async ({rentalMatchId, transition}) => {
    return client.post(`/api/v2/rental-matches/${rentalMatchId}/transitions`, transition);
};
export const useCreateRentalMatchTransition = () => {
    return useMutation(createRentalMatchTransition);
};

const fetchRentalMatchCollection = (queryData) => async () => {
    let url = '/api/v2/rental-matches';
    if (queryData) {
        url += '?' + createQueryString(queryData, '');
    }
    const res = await client.get(url);
    return res.data;
};

export const useGetRentalMatchCollection = (collectionParameters, options) => {
    const createQueryData = () => {
        const queryData = {
            page: collectionParameters.page,
            limit: collectionParameters.limit,
        };
        if (collectionParameters.searchQuery) {
            queryData.searchQuery = collectionParameters.searchQuery;
        }
        queryData.filters = {
            createdAfter: collectionParameters.filters.createdAfter.toISOString(),
            createdBefore: collectionParameters.filters.createdBefore.toISOString(),
        };
        if (collectionParameters.filters.status) {
            queryData.filters.status = collectionParameters.filters.status;
        }

        if (collectionParameters.sort) {
            queryData.sort = collectionParameters.sort;
        }

        return queryData;
    };
    const queryData = createQueryData();
    return useQuery(['rental-matches', queryData], fetchRentalMatchCollection(queryData), options);
};