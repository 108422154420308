import React, { useEffect } from "react";
import { EditOutlined } from "@ant-design/icons";
import { Button, Checkbox, Descriptions } from "antd";
import Card from "components/Card";
import { useTranslation } from "react-i18next";
import { PremiumInfo } from "components/Premium";
import OriginalTextSkeleton from "components/TextSkeleton";
import { useAccounts, useFeatures } from "hooks";
import { hasPermission } from "../../../../helpers";
import FeaturesDrawer from "./FeaturesDrawer";

const TextSkeleton = (props) => {
    const {
        isDataLoaded,
    } = useFeatures();
    return <OriginalTextSkeleton {...props} loading={!isDataLoaded} />
}

const FeaturesCard = () => {
  const { t } = useTranslation("common");
  const { activeAccount } = useAccounts();

  const {
    getCompanyFeatures,
    openEditForm,
    payload: { all_features, selected_features },
  } = useFeatures();

  useEffect(() => {
    getCompanyFeatures(activeAccount);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps



  return (
    <>
      <FeaturesDrawer/>
      <Card
        className="company-features-card"
        title={
          <>
            <PremiumInfo /> {t("pages.company.features-card.title")}
          </>
        }
        actions={[
          <Button
            disabled={
              !hasPermission(
                activeAccount,
                "manage_company_features",
                "edit"
              )
            }
            onClick={() => openEditForm()}
          >
            <EditOutlined /> {t("button.text.edit")}
          </Button>,
        ]}
      >
        <Descriptions column={1} className="label-50-percent">
          {Object.entries(all_features?.properties || []).map(
            ([key, value]) => {
              const features = selected_features || {};
              let label;
              switch (value.type) {
                case "array":
                  label = value.items.enum.map((item) => (
                    <div key={item} style={{ display: "flex" }}>
                      <Checkbox
                        style={{ marginRight: 8 }}
                        checked={(features[key] || []).includes(item)}
                        disabled
                      />
                      <p>{item}</p>
                    </div>
                  ));
                  break;
                case "string":
                case "number":
                  label = features[key];
                  break;
                case "boolean":
                  switch (features[key]) {
                    case true:
                      label = t("yes-no.yes");
                      break;
                    case false:
                      label = t("yes-no.no");
                      break;
                    default:
                      label = features[key];
                      break;
                  }
                  break;
                default:
                  break;
              }

              if (label !== 0 && !label) {
                label = t("pages.company.features-card.empty-value");
              }

              return (
                <Descriptions.Item key={key} label={value.title}>
                  <TextSkeleton>{label}</TextSkeleton>
                </Descriptions.Item>
              );
            }
          )}
        </Descriptions>
      </Card>
    </>
  );
};

export default FeaturesCard;
