import {useGlobalStore} from 'store';

import bindActions from 'store/bindActions';
import testOverlap from 'store/testOverlap';
import profileReducer from 'store/profile';

const { Actions } = profileReducer;

const useProfile = () => {
    const {state, dispatch} = useGlobalStore();

    const {profile} = state;

    // Test if there's an overlapping between the actions and store
    testOverlap(profile, Actions);

    return {
        ...bindActions(Actions, dispatch),
        ...profile
    };
};

export default useProfile;
