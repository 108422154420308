import * as Types from "./Types";

export const initialState = {
    isSubscriptionDataLoaded: false,
    payload: {
    }
};

export default function (state, action) {
    switch (action.type) {
        case Types.SET_IS_DATA_LOADED:
            return {
                ...state,
                isSubscriptionDataLoaded: action.isDataLoaded
            };
        case Types.SET_DATA:
            return {
                ...state,
                payload: action.payload,
                isSubscriptionDataLoaded: true
            };
        default:
            return state;
    }
};
