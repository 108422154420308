import {Tag} from "antd";
import React from "react";
import {useTranslation} from "react-i18next";

export const OfferStatusTag = ({status}) => {
    const { t } = useTranslation('common');
    const colorMap = {
        open: '#ff7800',
        concept: 'gray',
        accepted: '#75b118',
        rejected: '#ff4d4f',
        withdrawn: '#FFC409',
        expired: '#FFC409'
    }
    return <Tag color={colorMap[status]}>{t(`entities:offer.status.${status}`)}</Tag>
}