import React, { useEffect, useState } from "react";
import Card from "components/Card";
import {
  Button,
  Col,
  DatePicker,
  Input,
  Row,
  Select,
  Table,
  Tag,
} from "antd";
import { useAccounts, useInbox, useSmallDeviceSize, useChat } from "hooks";
import { useTranslation } from "react-i18next";
import Media from "react-media";
import moment from "moment";
import { hasPermission } from "../../../helpers";
import { MessageOutlined } from "@ant-design/icons";
import { navigate } from "@reach/router";
import useInboxPoll from "../../../hooks/useInboxPoll";
import styled from "styled-components";

const STable = styled(Table)`
  .selected {
    background-color: #e0edf2;

    &:hover td {
      background-color: #b7d5e1 !important;
    }
  }
`;

const dateTimeFormat = "DD-MM-YYYY HH:mm";
const { RangePicker } = DatePicker;
const { Search } = Input;

const InboxCard = () => {
  const { t } = useTranslation("common");
  const { activeAccount } = useAccounts();

  const [inboxData, setInboxData] = useState([]);
  const [filters, setFilters] = useState({
    statusFilter: "",
    searchFilter: "",
    startDate: moment().subtract(1, "month"),
    endDate: moment(),
  });

  const {
    isDataLoaded,
    getInbox,
    payload: { conversations },
  } = useInbox();

  const { payload } = useInboxPoll();
  const { doOpenChat } = useChat();

  useEffect(() => {
    getInbox(activeAccount.companyId);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setInboxData(conversations);
  }, [conversations]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    filterInbox();
  }, [filters, isDataLoaded]); // eslint-disable-line react-hooks/exhaustive-deps

  const filterInbox = () => {
    if (isDataLoaded) {
      const { endDate, searchFilter, startDate, statusFilter } =
        filters;

      let results = conversations;

      // Free text search
      if (searchFilter && searchFilter !== "") {
        results = conversations.filter((inbox) => {
          const searchString = searchFilter.toLowerCase();
          // We search on BookingID, Company name, First and last name, product name and rental location.
          // const bookingID = inbox.quotation.id.toString();
          const renterName = inbox.renter_name.toLowerCase();

          const productName = inbox.subject;
          const locationName =
            inbox.rental_service_provider_location.name.toLowerCase();

          return (
            // bookingID.indexOf(searchString) >= 0 ||
            renterName.indexOf(searchString) >= 0 ||
            productName?.indexOf(searchString) >= 0 ||
            locationName.indexOf(searchString) >= 0
          );
        });
      }

      // Date filtering
      if (startDate && endDate && startDate !== "" && endDate !== "") {
        results = results.filter((inbox) => {
          if (inbox?.messages[0]?.send_at) {
            const inboxDate = moment(inbox.messages[0].send_at);
            return inboxDate.isBetween(
              moment(startDate).startOf("day"),
              moment(endDate).endOf("day")
            );
          } else return true;
        });
      }

      // Status filter
      if (
        statusFilter &&
        statusFilter !== "" &&
        statusFilter !== "all"
      ) {
        results = results.filter((inbox) => {
          return inbox.status === statusFilter;
        });
      }

      setInboxData(results);
    }
  };

  const changeDateRange = (dates) => {
    if (dates !== null) {
      setFilters((prevState) => ({
        ...prevState,
        startDate: dates[0],
        endDate: dates[1],
      }));
    }
  };

  const onChange = (event) =>
    setFilters((prevState) => ({
      ...prevState,
      searchFilter: event?.target?.value,
    }));

  const onFilter = (value) =>
    setFilters((prevState) => ({ ...prevState, statusFilter: value }));

  const isSmallDevice = useSmallDeviceSize();

  const getQuotationProductName = (quotation) => {
    let productName = quotation.clickbox_category?.title;
    if (quotation.rental_item) {
      productName = `${productName} | ${quotation.rental_item.name}`;
    }
    return productName;
  };

  const tableColumns = [
    {
      title: t("pages.inbox.table.column.name"),
      dataIndex: ["quotation", "first_name"],
      key: "quotation.first_name",
      hideOnResponsive: true,
      render: (_, record) => {
        return record.renter_name;
      },
    },
    // {
    //   title: t("pages.inbox.table.column.quotation-id"),
    //   dataIndex: ["quotation", "id"],
    //   key: "quotation.id",
    //   hideOnResponsive: true,
    //   sorter: (a, b) =>
    //     ("" + a.quotation.quotation_id).localeCompare(b.quotation.quotation_id),
    // },
    {
      title: t("pages.inbox.table.column.product"),
      dataIndex: "product_name",
      key: "product_name",
      render: (text, record) => record.subject
    },
    {
      title: t("pages.inbox.table.column.rental-date"),
      dataIndex: ["quotation", "date_from"],
      key: "quotation.date_from",
      hideOnResponsive: true,
      sorter: (a, b) =>
        ("" + a.date_from).localeCompare(
          b.date_from
        ),
      render: (_, record) =>
        record.date_from
          ? moment(record.date_from).format(
              dateTimeFormat
            )
          : null,
    },
    {
      title: t("pages.inbox.table.column.last-message"),
      dataIndex: ["messages[0]", "send_at"],
      key: "last_message_date",
      ellipsis: true,
      sorter: (a, b) =>
        ("" + a.messages[0]?.send_at).localeCompare(
          b.messages[0]?.send_at
        ),
      render: (_, record) => (
        <p>
          {record.messages[0]?.send_at
            ? moment(record.messages[0].send_at).format(dateTimeFormat)
            : null}
          <Tag
            style={{ marginLeft: "0.5rem" }}
            color="#FF7800"
            visible={payload[record.id]?.unread_messages.length}
          >
            {t("pages.inbox.table.unread-count", {
              count: payload[record.id]?.unread_messages.length,
            })}
          </Tag>
        </p>
      ),
    },
    {
      align: "right",
      title: t("pages.inbox.table.column.actions"),
      dataIndex: "actions",
      key: "actions",
      render: (_, record) => (
        <Button
          onClick={(e) => {
            doOpenChat(record.id);
          }}
          className={isSmallDevice ? "small-button" : undefined}
          disabled={
            !hasPermission(activeAccount, "manage_offers", "edit")
          }
          icon={<MessageOutlined />}
        >
          {t("button.text.message")}
        </Button>
      ),
    },
  ];

  let searchInputWidth = "80%";
  if (isSmallDevice) {
    searchInputWidth = "100%";
  }

  return (
    <>
      <Card title={t("pages.inbox.table.title")}>
        <Row
          gutter={[16, 16]}
          type="flex"
          justify="space-around"
          align="middle"
          style={{
            borderBottom: "1px solid #e8e8e8",
            paddingBottom: "16px",
            marginBottom: "16px",
          }}
        >
          <Col sm={{ span: 24 }} md={{ span: 14 }}>
            <Search
              style={{ width: searchInputWidth }}
              placeholder={t("pages.inbox.search.placeholder")}
              disabled={!isDataLoaded}
              onChange={onChange}
            />
          </Col>

          <Col sm={{ span: 24 }} md={{ span: 4 }}>
            {isDataLoaded ? (
              <Select
                onChange={onFilter}
                defaultValue="all"
                style={{ width: "100%" }}
              >
                <Select.Option value="all">
                  {t("pages.inbox.statuses.all")}
                </Select.Option>
                <Select.Option value="open">
                  {t("pages.inbox.statuses.open")}
                </Select.Option>
                <Select.Option value="in_progress">
                  {t("pages.inbox.statuses.in_progress")}
                </Select.Option>
                <Select.Option value="reserved">
                  {t("pages.inbox.statuses.reserved")}
                </Select.Option>
                <Select.Option value="rejected">
                  {t("pages.inbox.statuses.rejected")}
                </Select.Option>
                <Select.Option value="withdrawn">
                  {t("pages.inbox.statuses.withdrawn")}
                </Select.Option>
                <Select.Option value="expired">
                  {t("pages.inbox.statuses.expired")}
                </Select.Option>
              </Select>
            ) : (
              <></>
            )}
          </Col>

          <Col sm={{ span: 24 }} md={{ span: 6 }}>
            <RangePicker
              format="DD-MM-YYYY"
              inputReadOnly={true}
              defaultValue={[filters.startDate, filters.endDate]}
              onChange={changeDateRange}
              style={{ width: "100%" }}
              ranges={{
                [t("ranges.dates.today")]: [
                  moment().startOf("day"),
                  moment().endOf("day"),
                ],
                [t("ranges.dates.this-month")]: [
                  moment().startOf("month"),
                  moment().endOf("month"),
                ],
                [t("ranges.dates.previous-month")]: [
                  moment().subtract(1, "month").startOf("month"),
                  moment().subtract(1, "month").endOf("month"),
                ],
                [t("ranges.dates.this-year")]: [
                  moment().startOf("year"),
                  moment().endOf("year"),
                ],
                [t("ranges.dates.previous-year")]: [
                  moment().subtract(1, "year").startOf("year"),
                  moment().subtract(1, "year").endOf("year"),
                ],
              }}
            />
          </Col>
        </Row>

        <Media query="(min-width: 950px)">
          {(matches) => {
            let filteredColumns = tableColumns.filter((column) => {
              return matches || column.hideOnResponsive !== true;
            });

            return (
              <STable
                dataSource={inboxData}
                columns={filteredColumns}
                loading={!isDataLoaded}
                rowKey="id"
                pagination={{
                  showLessItems: true,
                  total: inboxData?.length || 1,
                  showSizeChanger: true,
                }}
                rowClassName={(record) =>
                  payload[record.id]?.unread_messages.length > 0
                    ? "selected"
                    : undefined
                }
              />
            );
          }}
        </Media>
      </Card>
    </>
  );
};

export default InboxCard;
