import React from "react";
import {CheckOutlined, MinusCircleOutlined, PlusOutlined} from '@ant-design/icons';
import {Button, notification, Form, Input} from "antd";
import TextareaCharacterCount from "components/TextareaCharacterCount";
import {useTranslation} from "react-i18next";
import {useProfile, useAccounts} from "hooks";
import InputCharacterCount from "components/InputCharacterCount";
import ReactQuill from "react-quill";

// Fixes updating Quill after deleting block
let preventOnChange = false;
class ReactQuillFix extends ReactQuill {
	componentWillReceiveProps(nextProps, nextState) {
        preventOnChange = true;
        try {
            super.componentWillReceiveProps(nextProps, nextState)
        } finally {
            preventOnChange = false;
        }
    }
}

const ProfileForm = () => {
    const {t} = useTranslation('common');
    const {activeAccount} = useAccounts();
    const [form] = Form.useForm();

    const {
        putCompanyProfile,
        closeEditForm,
        payload: {
            name,
            profile_description,
            delivery_costs_description,
            shipping_details_description,
            unique_selling_points
        }
    } = useProfile();

    const onFinish = (values) => {
        putCompanyProfile(activeAccount, values)
            .then(() => {
                notification.open({
                    message: t('notification.messages.saved.title'),
                    description: t('notification.messages.saved.description'),
                });

                closeEditForm();
            })
            .catch(e => {
                console.log(e);
                notification.open({
                    message: t('notification.messages.error.title'),
                    description: t('notification.messages.error.description'),
                });
            });
    };

    return (
        <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            initialValues={{
                name: name,
                profileDescription: profile_description,
                deliveryCostsDescription: delivery_costs_description,
                shippingDetailsDescription: shipping_details_description,
                uniqueSellingPoints: unique_selling_points
            }}
        >
            <Form.Item
                name="name"
                label={t('pages.company.profile-form.items.company-name.label')}
                rules={[
                    {required: true, message: t('form.errors.required')},
                    ({ getFieldValue }) => ({
                        validator(rule, value) {

                            let emailInstances = value.toString().match('(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))') ?? [];
                            let phoneInstances = value.toString().match('((\\+|00(\\s|\\s?\\-\\s?)?)31(\\s|\\s?\\-\\s?)?(\\(0\\)[\\-\\s]?)?|0)[1-9]((\\s|\\s?\\-\\s?)?[0-9])((\\s|\\s?-\\s?)?[0-9])((\\s|\\s?-\\s?)?[0-9])\\s?[0-9]\\s?[0-9]\\s?[0-9]\\s?[0-9]\\s?[0-9]') ?? [];
                            let urlInstances = value.toString().match('https?:\\/\\/(www\\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\\.[a-zA-Z0-9()]{1,6}\\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)') ?? [];

                            if (emailInstances.length < 1 && phoneInstances.length < 1 && urlInstances.length < 1) {
                                return Promise.resolve();
                            }

                            return Promise.reject(t('form.errors.contact-details'));
                        },
                    }),
                ]}
            >
                <InputCharacterCount max={50} placeholder={t('pages.company.profile-form.items.company-name.placeholder')} />
            </Form.Item>

            <Form.Item
                name="profileDescription"
                label={t('pages.company.profile-form.items.profile-text.label')}
                rules={[
                    {type: 'string', max: 1500, message: t('form.errors.max-length')},
                    ({ getFieldValue }) => ({
                        validator(rule, value) {
                            if (value === null) {
                                return Promise.resolve();
                            }

                            let emailInstances = value.toString().match('(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))') ?? [];
                            let phoneInstances = value.toString().match('((\\+|00(\\s|\\s?\\-\\s?)?)31(\\s|\\s?\\-\\s?)?(\\(0\\)[\\-\\s]?)?|0)[1-9]((\\s|\\s?\\-\\s?)?[0-9])((\\s|\\s?-\\s?)?[0-9])((\\s|\\s?-\\s?)?[0-9])\\s?[0-9]\\s?[0-9]\\s?[0-9]\\s?[0-9]\\s?[0-9]') ?? [];
                            let urlInstances = value.toString().match('https?:\\/\\/(www\\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\\.[a-zA-Z0-9()]{1,6}\\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)') ?? [];

                            if (emailInstances.length < 1 && phoneInstances.length < 1 && urlInstances.length < 1) {
                                return Promise.resolve();
                            }

                            return Promise.reject(t('form.errors.contact-details'));
                        },
                    }),
                ]}
            >
                <ReactQuillFix
                theme="snow"
                formats={['bold', 'italic', 'strike', 'underline', 'list']}
                modules={{
                    toolbar: [
                    ["bold", "italic", "underline"],
                    [{'list': 'ordered'}, {'list': 'bullet'}],
                    ]
                }}
                value={form.getFieldValue('profileDescription')}
                defaultValue={form.getFieldValue('profileDescription')}
                />
            </Form.Item>

            <Form.Item
                name="deliveryCostsDescription"
                label={t('pages.company.profile-form.items.delivery-text.label')}
                rules={[
                    {type: 'string', max: 1000, message: t('form.errors.max-length')},
                    ({ getFieldValue }) => ({
                        validator(rule, value) {
                            if (value === null) {
                                return Promise.resolve();
                            }

                            let emailInstances = value.toString().match('(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))') ?? [];
                            let phoneInstances = value.toString().match('((\\+|00(\\s|\\s?\\-\\s?)?)31(\\s|\\s?\\-\\s?)?(\\(0\\)[\\-\\s]?)?|0)[1-9]((\\s|\\s?\\-\\s?)?[0-9])((\\s|\\s?-\\s?)?[0-9])((\\s|\\s?-\\s?)?[0-9])\\s?[0-9]\\s?[0-9]\\s?[0-9]\\s?[0-9]\\s?[0-9]') ?? [];
                            let urlInstances = value.toString().match('https?:\\/\\/(www\\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\\.[a-zA-Z0-9()]{1,6}\\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)') ?? [];

                            if (emailInstances.length < 1 && phoneInstances.length < 1 && urlInstances.length < 1) {
                                return Promise.resolve();
                            }

                            return Promise.reject(t('form.errors.contact-details'));
                        },
                    }),
                ]}
            >
                <TextareaCharacterCount max={1000} rows={10}/>
            </Form.Item>
            <Form.Item
                name="shippingDetailsDescription"
                label={t('pages.company.profile-form.items.shipping-text.label')}
                rules={[
                    {type: 'string', max: 1000, message: t('form.errors.max-length')},
                    ({ getFieldValue }) => ({
                        validator(rule, value) {
                            if (value === null) {
                                return Promise.resolve();
                            }

                            let emailInstances = value.toString().match('(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))') ?? [];
                            let phoneInstances = value.toString().match('((\\+|00(\\s|\\s?\\-\\s?)?)31(\\s|\\s?\\-\\s?)?(\\(0\\)[\\-\\s]?)?|0)[1-9]((\\s|\\s?\\-\\s?)?[0-9])((\\s|\\s?-\\s?)?[0-9])((\\s|\\s?-\\s?)?[0-9])\\s?[0-9]\\s?[0-9]\\s?[0-9]\\s?[0-9]\\s?[0-9]') ?? [];
                            let urlInstances = value.toString().match('https?:\\/\\/(www\\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\\.[a-zA-Z0-9()]{1,6}\\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)') ?? [];

                            if (emailInstances.length < 1 && phoneInstances.length < 1 && urlInstances.length < 1) {
                                return Promise.resolve();
                            }

                            return Promise.reject(t('form.errors.contact-details'));
                        },
                    }),
                ]}
            >
                <TextareaCharacterCount max={1000} rows={10}/>
            </Form.Item>
            <Form.List
                name="uniqueSellingPoints"
            >
                {(fields, { add, remove }, { errors }) => (
                    <>
                        {t('pages.company.profile-form.items.unique-selling-points.label')}
                        {fields.map((field, index) => (
                            <Form.Item
                                required={false}
                                key={field.key}
                            >
                                <Form.Item
                                    {...field}
                                    validateTrigger={['onChange', 'onBlur']}
                                    rules={[
                                        {
                                            required: true,
                                            whitespace: true,
                                            message: t('pages.company.profile-form.items.unique-selling-points.empty-message'),
                                        },
                                    ]}
                                    noStyle
                                >
                                    <Input
                                        maxLength={30}
                                        placeholder={t('pages.company.profile-form.items.unique-selling-points.placeholder')}
                                        style={{ width: '60%' }}
                                    />
                                </Form.Item>
                                <MinusCircleOutlined
                                    className="dynamic-delete-button"
                                    onClick={() => remove(field.name)}
                                />
                            </Form.Item>
                        ))}
                        <Form.Item>
                            <Button
                                type="dashed"
                                disabled={fields.length >= 5}
                                onClick={() => add()}
                                style={{ width: '60%' }}
                                icon={<PlusOutlined />}
                            >
                                {t('pages.company.profile-form.items.unique-selling-points.add')}
                            </Button>
                            <Form.ErrorList errors={errors} />
                        </Form.Item>
                    </>
                )}
            </Form.List>
            <div className="actions">
                <Button onClick={() => closeEditForm()} style={{marginRight: '8px'}}>
                    {t('button.text.cancel')}
                </Button>
                <Button htmlType="submit" type="primary">
                    <CheckOutlined/>
                    {t('button.text.save')}
                </Button>
            </div>
        </Form>
    );
};

export default ProfileForm;
