import client from "services/HurenApiClient";
import * as Types from "./Types";

export const getAccounts = () => {
    return async function(dispatch) {
        return client.get('/accounts', {
        }).then(res => {
            dispatch(accountsLoaded(res.data));
            return res;
        });
    };
};

export const chooseAccount = (accountId, companyId) => {
    return async function(dispatch) {
        return client.post('/api/v2/auth/provider-account-token', {
            providerId: companyId
        });
    };
}

export const changePassword = (activeAccount, oldPassword, newPassword) => {
    return async function(dispatch) {
        dispatch(setIsEditFormSaving(true));
        return client.put('/account/password', {
            'oldPassword': oldPassword,
            'newPassword': newPassword
        }).then(res => {
            dispatch(setIsEditFormSaving(false));
        });
    };
}

export const setIsEditFormSaving = (saving) => {
    return {
        type: Types.SET_IS_EDIT_FORM_SAVING,
        isEditFormSaving: saving
    };
};

export const openEditForm = () => {
    return {
        type: Types.EDIT_FORM_OPEN
    };
};

export const closeEditForm = () => {
    return {
        type: Types.EDIT_FORM_CLOSE
    };
};

export const accountsLoaded = (accounts) => {
    return {
        type: Types.ACCOUNTS_LOADED,
        userAccounts: accounts,
    }
};

export const accountChosen = (activeAccount) => {
    return {
        type: Types.ACCOUNT_CHOSEN,
        activeAccount: activeAccount
    }
};