import {useGlobalStore} from 'store';

import bindActions from 'store/bindActions';
import testOverlap from 'store/testOverlap';
import inboxReducer from 'store/inbox';

const { Actions } = inboxReducer;

const useInbox = () => {
    const {state, dispatch} = useGlobalStore();

    const {inbox} = state;

    // Test if there's an overlapping between the actions and store
    testOverlap(inbox, Actions);

    return {
        ...bindActions(Actions, dispatch),
        ...inbox
    };
};

export default useInbox;
