import React from "react";
import {useTranslation} from "react-i18next";
import {useAccounts, usePaymentDetails, useSmallDeviceSize} from "hooks";
import {Button, Divider, Drawer, Form, Input, notification, Switch} from "antd";
import {CheckOutlined, CloseOutlined} from "@ant-design/icons";
import Text from "antd/es/typography/Text";
import Iban from "iban";

const PaymentDetailsForm = () => {
    const [form] = Form.useForm();
    const {getFieldValue, setFields} = form;

    const {t} = useTranslation('common');
    const { activeAccount } = useAccounts();
    const {
        closeEditForm,
        isEditFormOpen,
        saveAutomaticPayments,
    } = usePaymentDetails();

    const isSmallDevice = useSmallDeviceSize();

    const onFinish = (values) => {
        saveAutomaticPayments(activeAccount.companyId, values)
            .then((res) => {
                notification.open({
                    message: t('notification.messages.saved.title'),
                    description: t('notification.messages.saved.description'),
                });

                closeEditForm();
            })
            .catch(e => {
                console.log(e);
                notification.open({
                    message: t('notification.messages.error.title'),
                    description: t('notification.messages.error.description'),
                });
                closeEditForm();
            });
    };

    let formWidth = 520;
    if (isSmallDevice) {
        formWidth = '100%';
    }

    return (
        <Drawer
            title={t('pages.company.payment-details-form.title')}
            placement="right"
            onClose={() => closeEditForm()}
            open={isEditFormOpen}
            width={formWidth}
        >
            <Form
                form={form}
                layout="vertical"
                style={{marginBottom: '3em'}}
                onFinish={onFinish}
            >
                <Text>
                    {t('pages.company.payment-details-form.intro')}
                    <dl className="inline" style={{margin: '10px 0'}}>
                        <dt>{t('pages.company.payment-details-form.sepa-details.name.label')}</dt><dd>{t('pages.company.payment-details-form.sepa-details.name.value')}</dd>
                        <dt>{t('pages.company.payment-details-form.sepa-details.address.label')}</dt><dd>{t('pages.company.payment-details-form.sepa-details.address.value')}</dd>
                        <dt>{t('pages.company.payment-details-form.sepa-details.postcode.label')}</dt><dd>{t('pages.company.payment-details-form.sepa-details.postcode.value')}</dd>
                        <dt>{t('pages.company.payment-details-form.sepa-details.city.label')}</dt><dd>{t('pages.company.payment-details-form.sepa-details.city.value')}</dd>
                        <dt>{t('pages.company.payment-details-form.sepa-details.country.label')}</dt><dd>{t('pages.company.payment-details-form.sepa-details.country.value')}</dd>
                        <dt>{t('pages.company.payment-details-form.sepa-details.id.label')}</dt><dd>{t('pages.company.payment-details-form.sepa-details.id.value')}</dd>
                    </dl>
                </Text>

                <Divider />

                <Form.Item
                    name="accountName"
                    label={t('pages.company.payment-details-form.items.name.label')}
                    rules={[
                        {required: true, message: t('form.errors.required')}
                    ]}
                >
                    <Input placeholder={t('pages.company.payment-details-form.items.name.placeholder')}/>
                </Form.Item>

                <Form.Item
                    name="iban"
                    label={t('pages.company.payment-details-form.items.iban.label')}
                    rules={[
                        {required: true, message: t('form.errors.required')},
                        ({ getFieldValue }) => ({
                            validator(rule, value) {
                                if (Iban.isValid(value)) {
                                    return Promise.resolve();
                                }

                                return Promise.reject(t('form.errors.iban-invalid'));
                            },
                        })
                    ]}
                >
                    <Input placeholder={t('pages.company.payment-details-form.items.iban.placeholder')}/>
                </Form.Item>

                <Form.Item
                    name="bank"
                    label={t('pages.company.payment-details-form.items.bank.label')}
                    rules={[
                        {required: true, message: t('form.errors.required')}
                    ]}
                >
                    <Input placeholder={t('pages.company.payment-details-form.items.bank.placeholder')}/>
                </Form.Item>

                <Form.Item
                    name="bankCity"
                    label={t('pages.company.payment-details-form.items.city.label')}
                    rules={[
                        {required: true, message: t('form.errors.required')}
                    ]}
                >
                    <Input placeholder={t('pages.company.payment-details-form.items.city.placeholder')}/>
                </Form.Item>

                <Divider />

                <Form.Item
                    name="authorisation"
                    valuePropName="checked"
                    label={t('pages.company.payment-details-form.items.permission.label')}
                    rules={[
                        {required: true, message: t('form.errors.required')}
                    ]}
                >
                    <Switch
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                    />
                </Form.Item>
                <Text>{t('pages.company.payment-details-form.items.permission.extra')}</Text>

                <div className="actions">
                    <Button onClick={() => closeEditForm()} style={{marginRight: '8px'}}>
                        {t('button.text.cancel')}
                    </Button>
                    <Button htmlType="submit" type="primary">
                        <CheckOutlined/>
                        {t('button.text.save')}
                    </Button>
                </div>
            </Form>
        </Drawer>
    );
}

export default PaymentDetailsForm;