import {useGlobalStore} from 'store';

import bindActions from 'store/bindActions';
import testOverlap from 'store/testOverlap';
import categoriesReducer from 'store/categories';

const { Actions } = categoriesReducer;

const useCategories = () => {
    const {state, dispatch} = useGlobalStore();

    const {categories} = state;

    // Test if there's an overlapping between the actions and store
    testOverlap(categories, Actions);

    return {
        ...bindActions(Actions, dispatch),
        ...categories,
    };
};

export default useCategories;
