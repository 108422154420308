import React from 'react';
import {Alert} from "antd";
import {useTranslation} from "react-i18next";

const BudgetAlert = ({unlimited_budget, budget_used_today, current_daily_limit}) => {
    const {t} = useTranslation('common');

    if (!unlimited_budget) {
        if (current_daily_limit === null) return null;
        const dailyBudgetExceeded = budget_used_today >= current_daily_limit;
        if (dailyBudgetExceeded) {
            return (
                <Alert
                    showIcon
                    style={{marginBottom: '14px'}}
                    type="warning"
                    message={(<>
                        {t('alert.daily-budget-exceeded-offline.title')}&nbsp;
                    </>)}/>
            );
        }
    }
    return null;
};

export default BudgetAlert;
