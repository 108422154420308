import * as Types from "./Types";

export const initialState = {
    currentPage: 1,
    itemsPerPage: 10,
    isDataLoaded: false,
    isEditFormDataLoaded: false,
    isEditFormOpen: false,
    isEditFormSaving: false,
    citiesLoaded: false,
    cities: [],
    payload: {},
    editFormPayload: {}
};

export default function (state, action) {
    switch (action.type) {
        case Types.SET_IS_DATA_LOADED:
            return {
                ...state,
                isDataLoaded: action.isDataLoaded
            };
        case Types.SET_IS_EDIT_FORM_DATA_LOADED:
            return {
                ...state,
                isEditFormDataLoaded: action.isEditFormDataLoaded
            };
        case Types.SET_IS_CITY_DATA_LOADED:
            return {
                ...state,
                isCityDataLoaded: action.isCityDataLoaded
            };
        case Types.SET_IS_EDIT_FORM_SAVING:
            return {
                ...state,
                isEditFormSaving: action.isEditFormSaving
            };
        case Types.EDIT_FORM_OPEN:
            return {
                ...state,
                isEditFormOpen: true,
            };
        case Types.EDIT_FORM_CLOSE:
            return {
                ...state,
                isEditFormOpen: false,
                isEditFormSaving: false
            };
        case Types.SET_DATA:
            return {
                ...state,
                payload: action.payload,
                isDataLoaded: true
            };
        case Types.SET_EDIT_FORM_DATA:
            return {
                ...state,
                editFormPayload: action.payload,
                isEditFormDataLoaded: true
            };
        case Types.SET_CITY_DATA:
            return {
                ...state,
                cities: action.payload,
                isCityDataLoaded: true
            };
        default:
            return state;
    }
};
