import React from 'react';
import LoggedInLayout from "layout/LoggedInLayout";
import Card from "components/Card";
import {useTranslation} from "react-i18next";
import {Button, Col, List} from "antd";
import {DownloadOutlined} from '@ant-design/icons';
import {useAccounts} from "hooks";
import {hasPermission} from "../../helpers";

const Ebooks = () => {
    const { t } = useTranslation('common');
    const {activeAccount} = useAccounts();

    return <LoggedInLayout pageTitle={t('pages.help.ebooks.title')}>
        <Card title={t('pages.help.ebooks.card-title')}>
            <List>
                <List.Item>
                    <Col sm={24} md={20}>
                        <h4>{t('pages.help.ebooks.twelve-steps.title')}</h4>
                        <p>{t('pages.help.ebooks.twelve-steps.content')}</p>
                    </Col>
                    <Col sm={24} md={4}>
                        <Button style={{float: 'right'}} disabled={!hasPermission(activeAccount, 'manage_ebooks', 'view')} href={process.env.REACT_APP_MERCHANT_DOWNLOAD_URL + "uploads/pdf/In-12-stappen-meer-rendement.pdf"} target="_blank">
                            <DownloadOutlined />
                            {t('pages.help.ebooks.download-button-title')}
                        </Button>
                    </Col>
                </List.Item>
                <List.Item>
                    <Col sm={24} md={20}>
                        <h4>{t('pages.help.ebooks.visitor-origin.title')}</h4>
                        <p>{t('pages.help.ebooks.visitor-origin.content')}</p>
                    </Col>
                    <Col sm={24} md={4}>
                        <Button style={{float: 'right'}} disabled={!hasPermission(activeAccount, 'manage_ebooks', 'view')} href={process.env.REACT_APP_MERCHANT_DOWNLOAD_URL + "uploads/pdf/Waar-komen-de-bezoekers-vandaan.pdf"} target="_blank">
                            <DownloadOutlined />
                            {t('pages.help.ebooks.download-button-title')}
                        </Button>
                    </Col>
                </List.Item>
                <List.Item>
                    <Col sm={24} md={20}>
                        <h4>{t('pages.help.ebooks.google-analytics.title')}</h4>
                        <p>{t('pages.help.ebooks.google-analytics.content')}</p>
                    </Col>
                    <Col sm={24} md={4}>
                        <Button style={{float: 'right'}} disabled={!hasPermission(activeAccount, 'manage_ebooks', 'view')} href={process.env.REACT_APP_MERCHANT_DOWNLOAD_URL + "uploads/pdf/Google-Analytics-geinstalleerd-op-jouw-website.pdf"} target="_blank">
                            <DownloadOutlined />
                            {t('pages.help.ebooks.download-button-title')}
                        </Button>
                    </Col>
                </List.Item>
                <List.Item>
                    <Col sm={24} md={20}>
                        <h4>{t('pages.help.ebooks.10-steps-targets.title')}</h4>
                        <p>{t('pages.help.ebooks.10-steps-targets.content')}</p>
                    </Col>
                    <Col sm={24} md={4}>
                        <Button style={{float: 'right'}} disabled={!hasPermission(activeAccount, 'manage_ebooks', 'view')} href={process.env.REACT_APP_MERCHANT_DOWNLOAD_URL + "uploads/pdf/Bestemmingsdoel-instellen-in-Google-Analytics.pdf"} target="_blank">
                            <DownloadOutlined />
                            {t('pages.help.ebooks.download-button-title')}
                        </Button>
                    </Col>
                </List.Item>
            </List>
        </Card>

        <br/>

    </LoggedInLayout>;
};

export default Ebooks;