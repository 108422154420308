import * as Types from "./Types";

export const addItem = (cartItem) => {
    return {
        type: Types.ADD_ITEM,
        cartItem: cartItem
    };
};

export const removeItem = (cartItem) => {
    return {
        type: Types.REMOVE_ITEM,
        cartItem: cartItem
    };
};

export const clearCart = () => {
    return {
        type: Types.CLEAR_CART
    };
};

export const setItems = (cartItems) => {
    return {
        type: Types.SET_ITEMS,
        cartItems: cartItems
    };
};