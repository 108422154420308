import client from "services/HurenApiClient";
import * as Types from "./Types";

const emptyLocation = {
    "id": null,
    "active": false,
    "delivery_location": false,
    "name": "",
    "phone": "",
    "email": "",
    "url": "",
    "address": "",
    "postcode": "",
    "city": "",
    "country": "NL",
    "opening_hours": {
        "opening_time_monday": null,
        "closing_time_monday": null,
        "opening_time_tuesday": null,
        "closing_time_tuesday": null,
        "opening_time_wednesday": null,
        "closing_time_wednesday": null,
        "opening_time_thursday": null,
        "closing_time_thursday": null,
        "opening_time_friday": null,
        "closing_time_friday": null,
        "opening_time_saturday": null,
        "closing_time_saturday": null,
        "opening_time_sunday": null,
        "closing_time_sunday": null,
        "additional_text": null
    }
};

export const setIsDataLoaded = (loaded) => {
    return {
        type: Types.SET_IS_DATA_LOADED,
        isDataLoaded: loaded
    };
};

export const setIsEditFormSaving = (saving) => {
    return {
        type: Types.SET_IS_EDIT_FORM_SAVING,
        isEditFormSaving: saving
    };
};

export const setIsCityDataLoaded = (loaded) => {
    return {
        type: Types.SET_IS_CITY_DATA_LOADED,
        isCityDataLoaded: loaded
    };
};

export const setIsEditFormDataLoaded = (loaded) => {
    return {
        type: Types.SET_IS_EDIT_FORM_DATA_LOADED,
        isEditFormDataLoaded: loaded
    };
};

export const openEditForm = () => {
    return {
        type: Types.EDIT_FORM_OPEN
    };
};

export const closeEditForm = () => {
    return {
        type: Types.EDIT_FORM_CLOSE
    };
};

export const setData = (payload) => {
    return {
        type: Types.SET_DATA,
        payload: payload
    };
};

export const setEditFormData = (payload) => {
    return {
        type: Types.SET_EDIT_FORM_DATA,
        payload: payload
    };
};

export const setCityData = (payload) => {
    return {
        type: Types.SET_CITY_DATA,
        payload: payload
    };
};

export const putLocation = (companyId, locationId, data) => {
    return async function (dispatch) {
        dispatch(setIsEditFormSaving(true));
        return client.put('/company/' + companyId + '/locations/' + locationId, {
            "company_location_form" : data
        }).then(res => {
            dispatch(setIsEditFormSaving(false));
        });
    };
};

export const createLocation = (companyId, data) => {
    return async function (dispatch) {
        dispatch(setIsEditFormSaving(true));
        return client.post('/company/' + companyId + '/locations', {
            "company_location_form" : data
        }).then(res => {
            dispatch(setIsEditFormSaving(false));
        });
    };
};

export const editLocation = (companyId, locationId) => {
    return async function (dispatch) {
        if (locationId !== null) {
            getEditFormData(companyId, locationId)(dispatch);
            dispatch(openEditForm());
        } else {
            // new location
            dispatch(openEditForm());
            dispatch(setEditFormData(emptyLocation));
        }
    };
};

export const getEditFormData = (companyId, locationId) => {
    return async function(dispatch) {
        dispatch(setIsEditFormDataLoaded(false));

        return client.get('/company/' + companyId + '/locations/' + locationId).then(res => {
            dispatch(setEditFormData(res.data));
        });
    }
};

export const getCities = () => {
    return async function (dispatch) {
        dispatch(setIsCityDataLoaded(false));

        return client.get('/cities').then(res => {
            dispatch(setCityData(res.data));
        });
    };
};

export const getLocations = (companyId) => {
    return async function (dispatch) {
        dispatch(setIsDataLoaded(false));

        return client.get('/company/' + companyId + '/locations').then(res => {
            dispatch(setData(res.data));
        });
    };
};
