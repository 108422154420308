import React from "react";
import { InfoCircleOutlined } from '@ant-design/icons';
import { Popover } from "antd";
import styled from 'styled-components';

const StyledInfoIcon = styled(InfoCircleOutlined)`
        color: #0192c3;
        margin: 0 5px;
        cursor: pointer;
`;

const InfoPopover = ({content}) => {
    const PopoverContent = <div style={{display: 'flex'}}>
        <InfoCircleOutlined style={{marginTop: '4px'}} />
        <div style={{marginLeft: '10px'}}>{content}</div>
    </div>;

    return <Popover content={PopoverContent} mouseEnterDelay={1}>
        <StyledInfoIcon type="info-circle" />
    </Popover>
};

export {InfoPopover};
