import React, { useContext, useState, useEffect, useRef } from 'react';
import {Trans, useTranslation} from 'react-i18next';
import { Checkbox, Table, Input, Button, Popconfirm, Form, InputNumber } from 'antd';
import { Money } from './Money';
import { InfoPopover } from './InfoPopover';
const EditableContext = React.createContext(null);

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({
  editable,
  permanentlyEditable,
  required,
  inputType,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
	const {t} = useTranslation('common');
	const [editing, setEditing] = useState(false);
	const inputRef = useRef(null);
	const form = useContext(EditableContext);
	useEffect(() => {
  	if (editing) {
  	  inputRef.current.focus();
  	}
	}, [editing]);

	const toggleEdit = () => {
  	form.setFieldsValue({
  	  [dataIndex]: record[dataIndex],
  	});

    if (permanentlyEditable) {
      setEditing(true);
    } else {
      setEditing(!editing);
    }

    // try opening select dropdown "if (inputType === 'periodSelect') {"
    // When you click on an input field, the focus is on the field straight away, 
    // now you have to click twice for a select item, annoying
	};

  var rules = [];
  if (required) {

    rules.push({
      required: true,
      message: t('pages.rentalitems.rental-item.pricing-form.price-table.validation.required'),
    });
  }

	const save = async () => {
  	try {
  	  const values = await form.validateFields();

      if ('show_in_orange_box' in values && values['show_in_orange_box'] !== undefined) {
        values.show_in_orange_box = true;
      }

      if (!permanentlyEditable) {
        toggleEdit();
      }
  	  handleSave({ ...record, ...values });
  	} catch (errInfo) {
  	  console.log('Save failed:', errInfo);
  	}
	};

	var childNode = children;

  if (permanentlyEditable) {
    editable = true;
  }

	if (editable) {

    var inputElement = <Input ref={inputRef} onPressEnter={save} onBlur={save} />;

    if (inputType === 'money') {
      inputElement = <InputNumber 
            ref={inputRef} onPressEnter={save} onBlur={save}
            decimalSeparator="," precision={4}
             formatter={value => `€ ${value}`.replace(/^€\d+(,\d{2})$/g, ',')}
             parser={value => value.replace(/€\s?/g, '')} />
    } else if (inputType === 'number') {
      inputElement = <InputNumber 
            ref={inputRef} onPressEnter={save} onBlur={save}
            precision={0} decimalSeparator=","
          />
    } else if (inputType === 'periodSelect') {
      var options = [];
      
      var types = [
        'minute',
        'hour',
        'two_hours',
        'half_day',
        'day',
        'two_days',
        'week',
        'weekend',
        'two_weeks',
        'month',
        'quarter',
        'half_year',
        'year'
      ];

      types.forEach((e,idx) => {
        var option = <option key={idx} value={e}>{t('pages.rentalitems.rental-item.pricing-form.price-table.price-period.'+e)}</option>;
        options.push(option);
      })

      inputElement = <select ref={inputRef} onPressEnter={save} onBlur={save}>
        {options}
      </select>;

      children = [null, t('pages.rentalitems.rental-item.pricing-form.price-table.price-period.'+children[1])];
    } else if (inputType === 'defaultPriceSelect') {
      inputElement = <input
        ref={inputRef} onChange={save}
        type="radio"
        checked={record.show_in_orange_box}
      />
    }


  	childNode = (editing || permanentlyEditable) ? (
  	  <Form.Item
  	    style={{
  	      margin: 0,
  	    }}
  	    name={dataIndex}
  	    rules={rules}
  	  >
  	    {inputElement}
  	  </Form.Item>
  	) : (
  	  <div
  	    className="editable-cell-value-wrap"
  	    style={{
  	      paddingRight: 24,
  	    }}
  	    onClick={toggleEdit}
  	  >
  	    {children}
  	  </div>
  	);
	}

	return <td {...restProps}>{childNode}</td>;
};

const RentalPriceTable = (props) => {
  const {data, onDataChange, taxRate} = props;
  
  for (var i = data.length - 1; i >= 0; i--) {
    if (!data[i].hasOwnProperty('key') || data[i].key == undefined) {
      data[i].key = data[i].id;
    }
  }

  const [state, setState] = useState({
    dataSource: data,
    count: data.length,
  });

  const [includeVAT, setIncludeVAT] = useState(props.includeVAT);

  const toggleVAT = () => {
    setIncludeVAT(!includeVAT);

    props.onDataChange({
      type: 'vatStatus',
      data: !includeVAT
    });
  };

  const {t} = useTranslation('common');
  var columns = [{
        title: t('pages.rentalitems.rental-item.pricing-form.price-table.price'),
        dataIndex: 'price',
        editable: true,
        required: true,
        inputType: 'money',
        width: '25%',
        render: (_, record) => 
          <Money value={record.price} />
    },
    {
        title: t('pages.rentalitems.rental-item.pricing-form.price-table.multiplier'),
        dataIndex: 'multiplier',
        editable: true,
        required: true,
        inputType: 'number'
    },
    {
        title: t('pages.rentalitems.rental-item.pricing-form.price-table.period'),
        dataIndex: 'period',
        width: '10%',
        editable: true,
        required: true,
        inputType: 'periodSelect'
    },
    {
        title: t('pages.rentalitems.rental-item.pricing-form.price-table.extra-text'),
        dataIndex: 'extra_text',
        width: '25%',
        editable: true,
    },
    {
        title: t('pages.rentalitems.rental-item.pricing-form.price-table.standard'),
        dataIndex: 'show_in_orange_box',
        inputType: 'defaultPriceSelect',
        permanentlyEditable: true,
    },
    {
        title: t('pages.rentalitems.rental-item.pricing-form.price-table.price-example'),
        render: (_,record) => {
          var money = (record.price*record.multiplier);
          if (includeVAT) {
            money = money*(1+taxRate/100);
          }
          const moneyEl = <Money value={money} />;

          if (record.extra_text) {
            return (<>
                  {moneyEl}
                <InfoPopover content={<>
                  <div>
                      {record.extra_text}
                  </div>
              </>} />
              </>//
            );
          }

          return moneyEl;
        }
    },
    {
      title: ' ',
      dataIndex: 'operation',
      render: (_, record) =>
      state && state.dataSource.length >= 1 ? (
        <Popconfirm title={t('pages.rentalitems.rental-item.pricing-form.price-table.actions.delete.title')} onConfirm={() => handleDelete(record.key)}>
          <a>{t('pages.rentalitems.rental-item.pricing-form.price-table.actions.delete.continue')}</a>
        </Popconfirm>
      ) : null
    }
  ];

  const handleDelete = (key) => {
    const dataSource = [...state.dataSource];

    const removedItems = dataSource.filter((item) => item.key === key);
    const removedItem = removedItems[0];

    const newData = dataSource.filter((item) => item.key !== key);

    if (removedItem.show_in_orange_box) {
      newData[0].show_in_orange_box = true;
    }

    state.dataSource = newData;
    state.count = newData.length;
    setState(state);

    props.onDataChange({
      type: 'rentalPrices',
      data: newData
    });
  };

  const handleAdd = () => {
    const { count, dataSource } = state;

    if (count >= 10) {
      return;
    }

    const newData = {
      key: count+1,
      price: 0.0,
      multiplier: 1,
      period: 'day',
      show_in_orange_box: false,
      extra_text: ''
    };

    if (count <= 0) {
      newData.show_in_orange_box = true;
    }

    setState({
      dataSource: [...dataSource, newData],
      count: count + 1,
    });
  };

  const handleSave = (row) => {
    const newData = [...state.dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, { ...item, ...row });

    if (row.show_in_orange_box === true) {
      newData.forEach((e,i) => {
        if (i === index) return;
        e.show_in_orange_box = false;
      })
    }

    state.dataSource = newData;
    state.count = newData.length;
    setState(state);

    props.onDataChange({
      type: 'rentalPrices',
      data: newData
    });
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };
  
  columns = columns.map((col) => {
    if (!col.editable && !col.permanentlyEditable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => (
        {
          record: record,
          editable: col.editable,
          permanentlyEditable: col.permanentlyEditable,
          required: col.required,
          inputType: col.inputType,
          dataIndex: col.dataIndex,
          handleSave: handleSave,
        }
      ),
    };
  });

  let addButtonDisabled = state.count >= 10;
  if (props.pricingModelName === 'fixed') {
      addButtonDisabled = state.count >= 1;
  }

  return (
    <div>
      <Button
        onClick={handleAdd}
        disabled={addButtonDisabled}
        style={{
          marginBottom: 16,
          marginRight: 10
        }}
      >
        {t('pages.rentalitems.rental-item.pricing-form.price-table.actions.add')}
      </Button>
      <Checkbox defaultChecked={includeVAT} onChange={toggleVAT}>
        {t('pages.rentalitems.rental-item.pricing-form.price-table.actions.toggle-vat')}
      </Checkbox>
      <Table
        components={components}
        rowClassName={() => 'editable-row'}
        pagination={false}
        bordered
        dataSource={state.dataSource}
        columns={columns}
      />
    </div>
  );

}

export default RentalPriceTable;