import React, {useContext, useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {AutoComplete, Button, Form, Input, InputNumber, Popconfirm, Select, Space, Table} from 'antd';
// import Feature from "models";
import {Feature} from "store/rentalItem/Actions";

const EditableContext = React.createContext(null);

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({
  dataIndex,
  children,
  record,
  handleSave,
  systemFeatures,
  usedSystemFeatures,
  ...restProps
}) => {
	const {t} = useTranslation('common');
	const [editing, setEditing] = useState(false);
	const inputRef = useRef(null);
	const form = useContext(EditableContext);
	useEffect(() => {
  	if (editing) {
  	  inputRef.current.focus();
  	}
	}, [editing]);

  var filteredOptions = [];
  var availableSystemFeatures = systemFeatures ? systemFeatures.filter(f => !usedSystemFeatures.includes(f)) : [];

  availableSystemFeatures.forEach(f => {
    var option = {value: f.feature, feature: f, type: 'system'};
    filteredOptions.push(option);
  });

  const [newFeatureOptions, setNewFeatureOptions] = useState(filteredOptions);

	const toggleEdit = () => {
    var value = record.value;
    if (dataIndex == 'feature') {
      value = record.feature;
    }

  	form.setFieldsValue({
  	  [dataIndex+record.key]: value,
  	});
    
    setEditing(!editing);
	};

  var rules = [];
  rules.push({
    required: true,
    message: t('pages.rentalitems.rental-item.pricing-form.price-table.validation.required'),
  });

	const save = async (a,b,c) => {
  	try {
  	  const values = await form.validateFields();
      var value = values[Object.keys(values)[0]];
      record.value = value;
  	  handleSave(record);
      setEditing(false);
  	} catch (errInfo) {
  	  console.log('Save failed:', errInfo);
  	}
	};

  const saveFeature = async (a,b,c) => {
    try {
      const values = await form.validateFields();
      var value = values[Object.keys(values)[0]];
      record.feature = value;
      handleSave(record);
      setEditing(false);
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

	var childNode = children;

  // We want things to be editable if we're editing an existing system value
  var editable = false;
  if (record === undefined) {
    editable = false;
  } else {
    if (record.type == 'system' && dataIndex == 'value') {
      editable = true;
    } else if (record.type == 'user_generated') { // Or when the feature is user generated we want to edit both key and value
      editable = true;
    } else if (record.type == 'new' && dataIndex == 'feature') {
      editable = true;
    }
  }

  // But we never want to edit the delete button
  if (dataIndex == 'operation') {
    editable = false;
  }

	if (editable) {
    var inputElement = null;

    var inputElementPrefix = null;
    var inputElementSuffix = null;

    var elementDefaultValue = record.value;
    if (dataIndex == 'feature') {
      elementDefaultValue = record.feature;
    }

    if (record.type != 'new') {
      if (dataIndex == 'value') {
        if (record.valueType == 'money') {
          inputElement = <InputNumber decimalSeparator=","
                                      formatter={value => `€ ${value}`.replace(/^€\d+(,\d{2})$/g, ',')}
                                      parser={value => value.replace(/€\s?/g, '')}
                                      ref={inputRef} onPressEnter={save} onBlur={save}
          />
        }
        else if (record.valueType == 'number') {
          inputElement = <InputNumber decimalSeparator="," ref={inputRef} onPressEnter={save} onBlur={save} />
        } else if (record.valueType === 'string' && record.options.length > 0) {
          let options = [];
          let types = record.options;

          types.forEach((e, i) => {
            let option = <Select.Option key={i} value={e}>{e}</Select.Option>;
            options.push(option);
          })

          let selectMode = 'default';
          if (record.multipleOptions) {
            selectMode = 'multiple';
          }

          inputElement = <Select
            showSearch 
            mode={selectMode}
            optionFilterProp="children" 
            ref={inputRef} 
            onBlur={save} 
            style={{'min-width': '200px'}}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }>
              {options}
          </Select>;
        } else if (record.valueType === 'boolean') {
          inputElement = <Select style={{'min-width': '200px'}} ref={inputRef} onBlur={save}>
            <Select.Option value="1">{t('yes-no.yes')}</Select.Option>
            <Select.Option value="0">{t('yes-no.no')}</Select.Option>
          </Select>;
        } else {
          inputElement = <Input ref={inputRef} onPressEnter={save} onBlur={save} />;
        }

        if (record.prefix) {
          var prefix = record.prefix;
          if (prefix == '&euro;') {
            prefix = '€';
          }
          inputElementPrefix = prefix;
        }
        if (record.suffix) {
          inputElementSuffix = record.suffix;
        }
      } else if (dataIndex == 'feature') {
        inputElement = <Input ref={inputRef} onPressEnter={saveFeature} onBlur={saveFeature} />;
      }
    } else {
      const handleSearch = (value) => {
        var filteredOptions = [];
        var availableSystemFeatures = systemFeatures ? systemFeatures.filter(f => !usedSystemFeatures.includes(f)) : [];

        availableSystemFeatures.forEach(f => {
          var option = {value: f.feature, feature: f, type: 'system'};
          filteredOptions.push(option);
        });

        filteredOptions = filteredOptions.filter(f => (f.value.toLowerCase().indexOf(value.toLowerCase()) >= 0 || f.value == 'custom'))

        var option = {value: t('pages.rentalitems.rental-item.feature-table.actions.add-custom-feature'), type: 'user_generated', input: value};
        filteredOptions.push(option);

        setNewFeatureOptions(filteredOptions);
      };

      const saveNewFeature = async (_,selectedFeature) => {
        try {
          const values = await form.validateFields();
          var value = values[Object.keys(values)[0]];

          if (selectedFeature.type == 'system') {
            record = selectedFeature.feature;
          } else {
            record.key = Math.floor(Math.random() * 100) + 100+record.key; // Overlapping keys give problems but they don't matter for the api so....
            record.type = 'user_generated';
            record.feature = selectedFeature.input;
          }

          handleSave(record);
          setEditing(false);
        } catch (errInfo) {
          console.log('Save failed:', errInfo);
        }
      };

      inputElement = <AutoComplete
          defaultOpen={true}
          options={newFeatureOptions}
          onSelect={saveNewFeature}
          onSearch={handleSearch}
          ref={inputRef}
        >
          <Input />
      </AutoComplete>;
    }
    
  	childNode = (editing) ? (
      <>
      <Space direction="horizontal">
        {inputElementPrefix}
    	  <Form.Item
    	    style={{
    	      margin: 0,
            display: 'inline'
    	    }}
    	    name={dataIndex+record.key}
    	    rules={rules}
    	  >
          {inputElement}
    	  </Form.Item>
        {inputElementSuffix}
      </Space>
      </>
  	) : (
  	  <div
  	    className="editable-cell-value-wrap"
  	    style={{
  	      paddingRight: 24,
  	    }}
  	    onClick={toggleEdit}
  	  >
  	    {children}
  	  </div>
  	);
	}

	return <td {...restProps}>{childNode}</td>;
};

const RentalFeatureTable = (props) => {
  const {features, userGeneratedFeatures, systemFeatures, onDataChange} = props;

  var data = [];
  for (var i = features.length - 1; i >= 0; i--) {
    var feature = features[i];
    
    if (!feature.value) {
      continue;
    }
    data.push(feature);
  }

  for (var i = userGeneratedFeatures.length - 1; i >= 0; i--) {
    var feature = userGeneratedFeatures[i];
    data.push(feature);
  }

  const [state, setState] = useState({
    dataSource: data,
    count: data.length
  });

  const {t} = useTranslation('common');
  var columns = [{
        title: t('pages.rentalitems.rental-item.feature-table.feature'),
        dataIndex: 'feature'
    },
    {
        title: t('pages.rentalitems.rental-item.feature-table.value'),
        dataIndex: 'value'
    },
    {
      title: ' ',
      dataIndex: 'operation',
      render: (_, record) =>
      state && state.dataSource.length >= 1 ? (
        <Popconfirm title={t('pages.rentalitems.rental-item.feature-table.actions.delete.title')} onConfirm={() => handleDelete(record.key)}>
          <a>{t('pages.rentalitems.rental-item.feature-table.actions.delete.continue')}</a>
        </Popconfirm>
      ) : null
    }
  ];

  const handleDelete = (key) => {
    const dataSource = [...state.dataSource];
    const newData = dataSource.filter((item) => item.key !== key);

    state.dataSource = newData;
    state.count = newData.length;
    setState(state);

    props.onDataChange({
      data: newData
    });
  };

  const handleAdd = () => {
    const { count, dataSource } = state;

    var newData = new Feature(count+1, '', '', 'new');
    
    setState({
      dataSource: [...dataSource, newData],
      count: count + 1,
    });
  };

 const handleSave = (row) => {
    const newData = [...state.dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, row);

    state.dataSource = newData;
    setState(state);

    props.onDataChange({
      data: newData
    });
  };

  const getTypeAwareCellValue = (value, record, col) => {
    var returnValue = '';
    if (record.prefix) {
      var prefix = record.prefix;
      if (prefix == '&euro;') {
        prefix = '€';
      }
      returnValue += prefix+' ';
    }

    if (record.valueType == 'number') {
      value = ('' + value).replace(/\./g, ',');
    }

    if (record.valueType == 'boolean') {
      if (value == '1') {
        value = t('yes-no.yes');
      } else {
        value = t('yes-no.no');
      }
    }
    returnValue += value;

    if (record.suffix) {
      returnValue += ' '+record.suffix;
    }

    return returnValue;
  }

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };
  
  columns = columns.map((col) => {
    var cell = {
      ...col,
      onCell: (record) => (
        {
          dataIndex: col.dataIndex,
          record: record,
          handleSave: handleSave,
          usedSystemFeatures: features,
          systemFeatures: systemFeatures
        }
      ),
    };

    if (col.dataIndex != 'value') {
      return cell;
    }

    cell['render'] = getTypeAwareCellValue;

    return cell;
  });

  return (
    <div>
      <Table
        components={components}
        rowClassName={() => 'editable-row'}
        pagination={false}
        bordered
        dataSource={state.dataSource}
        columns={columns}
      />
      <br />
      <Button
        onClick={handleAdd}
        style={{
          marginBottom: 16,
          marginRight: 10
        }}
      >
        {t('pages.rentalitems.rental-item.feature-table.actions.add')}
      </Button>
    </div>
  );

}

export default RentalFeatureTable;