import React, {
    createContext,
    useContext,
    useReducer,
    useCallback
} from 'react';
import RootReducer, {initialState} from './RootReducer';
import { asyncer } from './middlewares';
import { QueryClient, QueryClientProvider, useQuery } from 'react-query'
const queryClient = new QueryClient()

const GlobalStore = createContext({});

export const useGlobalStore = () => useContext(GlobalStore);


export default function Provider({children}) {
    const [state, dispatchBase] = useReducer(RootReducer, initialState);
    const dispatch = useCallback(asyncer(dispatchBase, state), []);

    return (
        <QueryClientProvider client={queryClient}>
            <GlobalStore.Provider value={{state, dispatch}}>
                {children}
            </GlobalStore.Provider>
        </QueryClientProvider>
    );
}