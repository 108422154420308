import React, {useEffect, useState} from 'react';
import Card from "components/Card";
import StatusInfo from "components/StatusInfo";
import {EditOutlined} from '@ant-design/icons';
import {Badge, Button, Col, Input, Radio, Row, Table} from "antd";
import {useTranslation} from "react-i18next";
import Media from "react-media";
import {useAccounts, useDailyBudget, useLocations, useSmallDeviceSize} from "hooks";
import {hasPermission} from "../../../../helpers";
import ActiveLocationsStatus from "../../../../components/Subscription/ActiveLocationsStatus";
import MaxActiveLocationsReachedAlert from "../../../../components/Subscription/MaxActiveLocationsReachedAlert";
import LocationDrawer from "./LocationDrawer";

const LocationsCard = () => {
    const {t} = useTranslation('common');
    const { Search } = Input;
    const {activeAccount} = useAccounts();
    const [locationsData, setLocationsData] = useState([]);
    const [totalLocations, setTotalLocations] = useState(0);
    const [filters, setFilters] = useState({active: '', locationName: ''});

    const {
        getLocations,
        editLocation,
        isDataLoaded,
        payload: {
            locations
        }
    } = useLocations();

    const {
        getBudgetData,
        payload: {
            unlimited_budget,
            current_daily_limit,
            budget_used_today
        }
    } = useDailyBudget();

    const isSmallDevice = useSmallDeviceSize();

    const inactiveBudget = () => {
        if (!unlimited_budget) {
            const dailyBudgetExceeded = budget_used_today >= current_daily_limit;
            if (dailyBudgetExceeded || current_daily_limit === 0) {
                return true;
            }
        }
        return false;
    }

    const filterLocations = () => {
        const activeFilter = filters.active;
        const locationNameFilter = filters.locationName;

        let results;
        if (locationNameFilter && locationNameFilter !== "") {
            results = locations.filter(location => {
                const searchName = location.name.toLowerCase();
                return (searchName.indexOf(locationNameFilter.toLowerCase()) >= 0);
            });
        } else {
            results = locations;
        }

        if (activeFilter && activeFilter !== "") {
            results = results.filter(location => {
                if (activeFilter === "1") {
                    return location.active === true;
                } else if (activeFilter === "0") {
                    return location.active === false;
                } else {
                    return false;
                }
            });
        }

        setLocationsData(results);
        let totalLocationsCount = 0;
        if (results) {
            totalLocationsCount = results.length;
        }
        setTotalLocations(totalLocationsCount);
    };

    const onChange = event => {
        const target = event.target;
        const value = target.value;
        setFilters(prevState => ({ ...prevState, locationName: value }));
    };

    const onFilterActive = event => {
        setFilters(prevState => ({ ...prevState, active: event.target.value }));
    };

    useEffect(() => {
        getBudgetData(activeAccount.companyId);
        getLocations(activeAccount.companyId);
    }, [activeAccount]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setLocationsData(locations);
    }, [locations]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        filterLocations();
    }, [filters]); // eslint-disable-line react-hooks/exhaustive-deps

    const tableColumns = [
        {
            title: t('pages.company.locations-card.table.name'),
            dataIndex: 'name',
            key: 'name',
            ellipsis: true,
            sorter: (a, b) => ('' + a.name).localeCompare(b.name)
        },
        {
            title: t('pages.company.locations-card.table.address'),
            dataIndex: 'address',
            key: 'address',
            ellipsis: true,
            hideOnResponsive: true,
            sorter: (a, b) => ('' + a.address).localeCompare(b.address),
            render: (text, record) => record.delivery_location ? '' : record.address
        },
        {
            title: t('pages.company.locations-card.table.postcode'),
            dataIndex: 'postcode',
            key: 'postcode',
            ellipsis: true,
            hideOnResponsive: true,
            sorter: (a, b) => ('' + a.postcode).localeCompare(b.postcode),
            render: (text, record) => record.delivery_location ? '' : record.postcode
        },
        {
            title: t('pages.company.locations-card.table.city'),
            dataIndex: 'city',
            key: 'city',
            ellipsis: true,
            hideOnResponsive: true,
            sorter: (a, b) => ('' + a.city).localeCompare(b.city)
        },
        {
            title: t('pages.company.locations-card.table.phone'),
            dataIndex: 'phone',
            key: 'phone',
            ellipsis: true,
            hideOnResponsive: true,
            sorter: (a, b) => ('' + a.phone).localeCompare(b.phone)
        },
        // Don't show the status column if we don't have permission.
        (activeAccount.permissions.manage_location_activation.view) ? {
            title: <>
                {t('pages.company.locations-card.table.status')} &nbsp;
                <StatusInfo />
            </>,
            className: 'status-state-cell',
            align: (isSmallDevice) ? 'right' : 'left',
            dataIndex: 'status',
            key: 'status',
            sorter: (a, b) => ('' + a.status).localeCompare(b.status),
            render: (text, record) => {
                if (inactiveBudget()) {
                    return <Badge color="orange" text={t('badges.no-budget')}/>
                }
                return record.active ? <Badge color="green" text={t('badges.active')}/> : <Badge color="red" text={t('badges.inactive')}/>
            }
        } : {},
        {
            align: 'right',
            title: t('pages.company.locations-card.table.actions'),
            dataIndex: 'actions',
            key: 'actions',
            render: (text, record) => {
                if (isSmallDevice) {
                    return <Button className="small-button" icon={<EditOutlined />} disabled={!hasPermission(activeAccount, 'manage_locations', 'edit')} onClick={() => editLocation(activeAccount.companyId, record.id)}></Button>
                } else {
                    return <Button icon={<EditOutlined />} disabled={!hasPermission(activeAccount, 'manage_locations', 'edit')} onClick={() => editLocation(activeAccount.companyId, record.id)}>{t('button.text.edit')}</Button>
                }
            }
        }
    ];

    let searchInputWidth = '80%';
    if (isSmallDevice) {
        searchInputWidth = '100%';
    }

    return <>
        <LocationDrawer/>
        <Card
            className="company-locations-card"
            title={<span>
                {t('pages.company.locations-card.title')}{' '}
                <ActiveLocationsStatus />
            </span>}
        >
            <MaxActiveLocationsReachedAlert
                style={{'marginBottom': '14px'}}
                message={t('components:locations-card.max-active-locations-reached-warning.message')}
            />
            <Row gutter={[16, 16]} type="flex" justify="space-around" align="middle" style={{borderBottom: '1px solid #e8e8e8', paddingBottom: '16px', marginBottom: '16px'}}>
                <Col sm={{span: 24}} md={{span: 12}}>
                    <Search
                        style={{ width: searchInputWidth }}
                        placeholder={t('pages.company.locations-card.search.placeholder')}
                        disabled={!isDataLoaded}
                        onChange={onChange}
                    />
                </Col>

                <Col sm={24} md={12}>
                    { !inactiveBudget() && isDataLoaded ?
                    <Radio.Group onChange={onFilterActive} defaultValue="none">
                        <Radio.Button value="1"><Badge color="green" text={t('badges.active')} /></Radio.Button>
                        <Radio.Button value="0"><Badge color="red" text={t('badges.inactive')} /></Radio.Button>
                        <Radio.Button value="">{t('filters.none')}</Radio.Button>
                    </Radio.Group> : <></>}
                </Col>
            </Row>

            <Media query="(min-width: 950px)">
                {matches => {

                    let filteredColumns = tableColumns.filter((column) => {
                        return matches || column.hideOnResponsive !== true;
                    });

                    return <Table
                        tableLayout="fixed"
                        dataSource={locationsData}
                        columns={filteredColumns}
                        loading={!isDataLoaded}
                        rowKey="id"
                        showSorterTooltip="false"
                        pagination={{
                            total: totalLocations,
                            defaultPageSize:10,
                            showSizeChanger:true,
                            showLessItems:true
                        }}
                    />
                }}
            </Media>
        </Card>
    </>;
};

export default LocationsCard;
