import React, {useEffect, useMemo, useState} from "react";
import {Button, DatePicker, Form, InputNumber, Radio, Select, Skeleton, Space} from "antd";
import {CheckOutlined} from "@ant-design/icons";
import {useTranslation} from "react-i18next";
import {useForm} from "antd/es/form/Form";
import {useCreateRentalMatchTransition} from "../../queries/rentalMatches";
import {TransitionStatusDetails} from "../../types";
import TextArea from "antd/es/input/TextArea";
import moment from "moment/moment";

const RentalMatchTransitionForm = ({rentalMatch, onFinish, transitionName}) => {

    const { t } = useTranslation('common');
    const [form] = useForm();
    const [transition, setTransition] = useState({
        transition_name: transitionName,
        arguments: null
    });
    const createTransition = useCreateRentalMatchTransition();

    useEffect(() => {
        form.resetFields();
    }, [rentalMatch]);

    const onValuesChange = (values) => {
        const tempTransition = {...transition}
        tempTransition.arguments = {...transition.arguments, ...values}
        setTransition(tempTransition);
    };

    const onInnerFinish = async (values) => {
        if (transition.arguments.priceIncl) {
            transition.arguments.priceIncl = Math.round(transition.arguments.priceIncl * 100);
        }
        if (transition.arguments.priceExcl) {
            transition.arguments.priceExcl = Math.round(transition.arguments.priceExcl * 100);
        }

        await createTransition.mutateAsync({
            rentalMatchId: rentalMatch.id,
            transition: transition,
        });
        if (onFinish) {
            onFinish(values);
        }
    };

    const statusDetails = [
        {
            label: t('entities:rental-match.transition.status.details.product_not_in_stock'),
            value: TransitionStatusDetails.PRODUCT_NOT_IN_STOCK
        },
        {
            label: t('entities:rental-match.transition.status.details.no_rental_to_private_person'),
            value: TransitionStatusDetails.NO_RENTAL_TO_PRIVATE_PERSON
        },
        {
            label: t('entities:rental-match.transition.status.details.else'),
            value: TransitionStatusDetails.ELSE
        }
    ];

    return (
        <>
            <Form
                form={form}
                layout="vertical"
                onValuesChange={onValuesChange}
                onFinish={onInnerFinish}
            >
                {transitionName === 'mark_reserved' ? (
                    <>
                        <Form.Item
                            label={t('entities:offer.properties.price-excl')}
                            name="priceExcl"
                            rules={[
                                {required: true, message: t('form.errors.required')}
                            ]}
                            normalize={(value) => value ? parseFloat(value) : null}
                        >
                            <InputNumber
                                min={0}
                                decimalSeparator=","
                            />
                        </Form.Item>
                        <Form.Item
                            label={t('entities:offer.properties.price-incl')}
                            name="priceIncl"
                            rules={[
                                {required: true, message: t('form.errors.required')},
                                ({ getFieldValue }) => ({
                                    validator(rule, value) {
                                        if (value < getFieldValue('priceExcl')) {
                                            return Promise.reject(
                                                t('components:offer-form.fields.price-incl.errors.must-be-greater-than-price-excl')
                                            );
                                        }
                                        return Promise.resolve();
                                    }
                                })
                            ]}
                            normalize={(value) => value ? parseFloat(value) : null}
                        >
                            <InputNumber
                                min={0}
                                decimalSeparator=","
                            />
                        </Form.Item>

                        <Form.Item
                            label={t('entities:offer.properties.date-from')}
                            name="dateFrom"
                            rules={[
                                {required: true, message: t('form.errors.required')},
                                () => ({
                                    validator(rule, value) {
                                        if (value < moment()) {
                                            return Promise.reject(
                                                t('components:offer-form.fields.date-from.errors.must-be-in-the-future')
                                            );
                                        }
                                        return Promise.resolve();
                                    }
                                })
                            ]}
                        >
                            <DatePicker
                                format="DD-MM-YYYY HH:mm"
                                showTime={true}
                            />
                        </Form.Item>
                        <Form.Item
                            label={t('entities:offer.properties.date-until')}
                            name="dateUntil"
                            rules={[
                                ({ getFieldValue }) => ({
                                    validator(rule, value) {
                                        if (value && value < getFieldValue('dateFrom')) {
                                            return Promise.reject(
                                                t('components:offer-form.fields.date-until.errors.must-be-greater-than-date-from')
                                            );
                                        }
                                        return Promise.resolve();
                                    }
                                })
                            ]}
                        >
                            <DatePicker
                                format="DD-MM-YYYY HH:mm"
                                showTime={true}
                            />
                        </Form.Item>
                    </>
                ) : (
                    <>
                        <Form.Item
                            label={t('entities:rental-match.transition.status.detail')}
                            name="statusDetail"
                            rules={[
                                {required: true, message: t('form.errors.required')}
                            ]}
                        >
                            <Radio.Group
                                optionType="default"
                                buttonStyle="solid"
                            >
                                <Space direction="vertical">
                                    {statusDetails.map(statusDetail => {
                                        return <Radio value={statusDetail.value}>
                                            {statusDetail.label}
                                        </Radio>
                                    })}
                                </Space>
                            </Radio.Group>
                        </Form.Item>

                        <Form.Item
                            name="statusDescription"
                            label={t('entities:rental-match.transition.status.description')}
                        >
                            <TextArea rows={6} />
                        </Form.Item>
                    </>
                )}

                <div className="actions">
                    <Button htmlType="submit" type="primary">
                        <CheckOutlined/>
                        {t('button.text.save')}
                    </Button>
                </div>
            </Form>
        </>
    );
};

export default RentalMatchTransitionForm;