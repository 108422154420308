import * as Types from "./Types";

export const initialState = {
    isDataLoaded: false,
    isReplyFormDataLoaded: false,
    isReplyFormSaving: false,
    isReplyFormOpen: false,
    replyFormPayload: {},
    payload: {}
};

export default function (state, action) {
    switch (action.type) {
        case Types.SET_IS_DATA_LOADED:
            return {
                ...state,
                isDataLoaded: action.isDataLoaded
            };
        case Types.SET_DATA:
            return {
                ...state,
                payload: action.payload,
                isDataLoaded: true
            };
        case Types.SET_REPLY_FORM_DATA:
            return {
                ...state,
                replyFormPayload: action.payload,
                isReplyFormDataLoaded: true
            };
        case Types.SET_IS_REPLY_FORM_DATA_LOADED:
            return {
                ...state,
                isReplyFormDataLoaded: action.isReplyFormDataLoaded
            };
        case Types.SET_IS_REPLY_FORM_SAVING:
            return {
                ...state,
                isReplyFormSaving: action.isReplyFormSaving
            };
        case Types.REPLY_FORM_OPEN:
            return {
                ...state,
                isReplyFormOpen: true,
            };
        case Types.REPLY_FORM_CLOSE:
            return {
                ...state,
                isReplyFormOpen: false,
            };
        default:
            return state;
    }
};
