import React from "react";
import {PremiumInfo} from "components/Premium";
import {useTranslation} from 'react-i18next';
import Drawer from "components/Drawer";
import {useOpeningHours} from "hooks";
import OpeningHoursForm from "./OpeningHoursForm";

const OpeningHoursDrawer = () => {
    const {t} = useTranslation('common');

    const {
        closeEditForm,
        isEditFormOpen,
    } = useOpeningHours();

    return (
        <Drawer
            title={<><PremiumInfo/> {t('pages.company.opening-hours-form.title')}</>}
            onClose={() => closeEditForm()}
            open={isEditFormOpen}
            width={520}
        >
            <OpeningHoursForm/>
        </Drawer>
    );
};

export default OpeningHoursDrawer;
