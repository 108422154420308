import {useQuery} from "react-query";
import client from "services/HurenApiClient.js";

const fetchQuotationExtraValues = (quotationId) => async () => {
    const res = await client.get(`/api/v2/quotations/${quotationId}/extra-form-values`);
    return res.data.items;
};

const useGetQuotationExtraValues = (quotationId) => {
    return useQuery(['quotations', parseInt(quotationId), 'extra-form-values'], fetchQuotationExtraValues(quotationId));
}

export default useGetQuotationExtraValues;