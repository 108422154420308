import React from 'react';
import {InfoCircleOutlined} from "@ant-design/icons";
import {Popover} from "antd";
import {useTranslation} from "react-i18next";

export const LegacyOfferPrice = () => {
    const { t } = useTranslation('common');

    return (
        <Popover content={t('components:legacy-offer-price.popover.content')}>
            <span>
                {t('components:legacy-offer-price.text')}
                <InfoCircleOutlined />
            </span>
        </Popover>
    );
};
