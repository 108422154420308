import {useGlobalStore} from 'store';

import bindActions from 'store/bindActions';
import testOverlap from 'store/testOverlap';
import reviewsReducer from 'store/reviews';

const { Actions } = reviewsReducer;

const useReviews = () => {
    const {state, dispatch} = useGlobalStore();

    const {reviews} = state;

    // Test if there's an overlapping between the actions and store
    testOverlap(reviews, Actions);

    return {
        ...bindActions(Actions, dispatch),
        ...reviews
    };
};

export default useReviews;
