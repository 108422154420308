import React from "react";
import {useTranslation} from "react-i18next";
import {useAccounts, useSmallDeviceSize} from "hooks";
import {Button, Drawer, Form, Input, notification} from "antd";
import {CheckOutlined} from "@ant-design/icons";

const PasswordForm = () => {
    const [form] = Form.useForm();
    const {getFieldValue, setFields} = form;

    const {t} = useTranslation('common');
    const {
        activeAccount,
        changePassword,
        isEditFormOpen,
        closeEditForm
    } = useAccounts();

    const isSmallDevice = useSmallDeviceSize();

    const onFinish = (values) => {
        let oldPass = getFieldValue('oldPassword');
        let newPass = getFieldValue('newPassword');
        changePassword(activeAccount, oldPass, newPass)
            .then((res) => {
                notification.open({
                    message: t('notification.messages.password-changed.title'),
                    description: t('notification.messages.password-changed.description'),
                });

                closeEditForm();
            })
            .catch(e => {
                setFields([
                    {
                        name: 'oldPassword',
                        errors: [t('form.errors.password-incorrect')]
                    }
                ]);
                console.log(e);
            });
    };

    let passwordDrawerWidth = 520;
    if (isSmallDevice) {
        passwordDrawerWidth = '100%';
    }

    return (
        <Drawer
            title={t('pages.account.password-form.title')}
            placement="right"
            onClose={() => closeEditForm()}
            open={isEditFormOpen}
            width={passwordDrawerWidth}
        >
            <Form
                form={form}
                layout="vertical"
                onFinish={onFinish}
            >
                <Form.Item
                    name="oldPassword"
                    label={t('pages.account.password-form.items.old-password.label')}
                    rules={[
                        {required: true, message: t('form.errors.required')}
                    ]}
                >
                    <Input.Password placeholder={t('pages.account.password-form.items.old-password.placeholder')}/>
                </Form.Item>

                <Form.Item
                    name="newPassword"
                    hasFeedback
                    label={t('pages.account.password-form.items.new-password.label')}
                    rules={[
                        {required: true, message: t('form.errors.required')}
                    ]}
                >
                    <Input.Password placeholder={t('pages.account.password-form.items.new-password.placeholder')}/>
                </Form.Item>

                <Form.Item
                    name="newPasswordConfirmation"
                    hasFeedback
                    label={t('pages.account.password-form.items.new-password-confirmation.label')}
                    dependencies={['newPassword']}
                    rules={[
                        {required: true, message: t('form.errors.required')},
                        ({ getFieldValue }) => ({
                            validator(rule, value) {
                                if (!value || getFieldValue('newPassword') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(t('form.errors.password-match'));
                            },
                        }),
                    ]}
                >
                    <Input.Password placeholder={t('pages.account.password-form.items.new-password-confirmation.placeholder')}/>
                </Form.Item>

                <div className="actions">
                    <Button onClick={() => closeEditForm()} style={{marginRight: '8px'}}>
                        {t('button.text.cancel')}
                    </Button>
                    <Button htmlType="submit" type="primary">
                        <CheckOutlined/>
                        {t('button.text.change-password')}
                    </Button>
                </div>
            </Form>
        </Drawer>
    );
}

export default PasswordForm;