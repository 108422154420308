import axios from "axios";

var MockAdapter = require('axios-mock-adapter');

var mock = new MockAdapter(axios, {delayResponse: 500});

mock.onGet('/api/dashboard/stats').reply(200, {
    "payload": {
        "reviews": {
            "aggregatedRating": 9.3,
            "count": 782
        },
        "advertisements": {
            "totalCount": 130,
            "onlineCount": 120,
            "offlineCount": 10
        },
        "billing": {
            "currentMonthCosts": 147.38,
            "previousMonthCosts": 261.76
        },
        "clicks": {
            "currentMonthCount": 292,
            "previousMonthCount": 523
        }
    }
});

mock.onGet('/api/advertisements/budget').reply(200, {
    "payload": {
        "currentDailyBudget": 140.00,
        "todayUsedBudget": 123.11
    }
});

mock.onGet('/api/advertisements/budget-form').reply(req => {
    const unlimitedBudget = (Math.random() >= 0.5);
    const hasMinimumDailyBudget = (Math.random() >= 0.5);

    return [200, {
        "payload": {
            "dailyBudget": 140.00,
            "unlimitedDailyBudget": unlimitedBudget,
            "minimumDailyBudget": hasMinimumDailyBudget ? 5 : 0
        }
    }];
});

mock.onPut('/api/advertisements/budget-form').reply(req => {
    return [200, {
        "success": true,
        "payload": JSON.parse(req.data)
    }];
});

mock.onGet('/api/company/contact-details').reply(200, {
    "payload": {
        "phoneNumber": "0203800293",
        "phoneDisplay": (Math.random() >= 0.5 ? "SHOW_LOCATION_PHONENUMBER" : "SHOW_GENERAL_PHONENUMBER"),
        "email": "info@aanhanger.nl",
        "website": "https://www.aanhanger.nl",
        "facebook": "aanhanger",
    }
});

mock.onPut('/api/company/contact-details').reply(req => {
    return [200, {
        "success": true,
        "payload": JSON.parse(req.data)
    }];
});

mock.onGet('/api/company/profile').reply(200, {
    "payload": {
        "companyName": "Rental Company Ltd.",
        "profileText": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eleifend urna et tortor mattis vestibulum. Quisque rutrum lorem leo, eu commodo lectus hendrerit vel. Ut ut bibendum elit. Vestibulum consectetur sollicitudin turpis ac sodales. Nulla convallis sollicitudin ex in hendrerit. Quisque a tristique nisi.",
        "deliveryText": "Curabitur a imperdiet magna, non iaculis erat. Mauris faucibus erat non diam aliquet, ut eleifend nisl egestas. Aenean finibus nisi ut sodales sollicitudin."
    }
});

mock.onPut('/api/company/profile').reply(req => {
    return [200, {
        "success": true,
        "payload": JSON.parse(req.data)
    }];
});

mock.onGet('/api/company/opening-hours').reply(200, {
    "payload": {
        "additionalText": "Magna, non iaculis erat. Mauris faucibus erat non diam aliquet, ut eleifend nisl egestas. Aenean finibus nisi ut sodales sollicitudin.",
        "openingHours": [
            {
                "timeFrom": "09:00",
                "timeTo": "17:00",
                "weekday": "mon"
            },
            {
                "timeFrom": "09:00",
                "timeTo": "17:00",
                "weekday": "tue"
            },
            {
                "timeFrom": "09:00",
                "timeTo": "17:00",
                "weekday": "wed"
            },
            {
                "timeFrom": "09:00",
                "timeTo": "21:00",
                "weekday": "thu"
            },
            {
                "timeFrom": "09:00",
                "timeTo": "17:00",
                "weekday": "fri"
            },
            {
                "timeFrom": "09:00",
                "timeTo": "12:00",
                "weekday": "sat"
            },
        ]
    }
});

mock.onPut('/api/company/opening-hours').reply(req => {
    return [200, {
        "success": true,
        "payload": JSON.parse(req.data)
    }];
});

const locations = [
    {
        "name": "Aanhanger B.V. Gerritsen",
        "location": "Amsterdam (Amstel)",
        "address": "Vijzelstraat 55",
        "zipcode": "2014ZM",
        "city": "Amsterdam",
        "city_id": 5,
        "phone": "0203300912",
        "country": "Nederland"
    },
    {
        "name": "Aanhanger B.V. Hogeweg",
        "location": "Amersfoort",
        "address": "Hogeweg 14",
        "zipcode": "3827MA",
        "city": "Amersfoort",
        "city_id": 4,
        "phone": "0337622278",
        "country": "Nederland"
    },
    {
        "name": "Aanhanger B.V.",
        "location": "Leusden",
        "address": "Biezenkamp 12",
        "zipcode": "3712AP",
        "city": "Leusden",
        "city_id": 3,
        "country": "Nederland"
    },
    {
        "name": "Broers & Co",
        "location": "Woudenberg",
        "address": "Asschatterweg 382",
        "zipcode": "3515AM",
        "city": "Woudenberg",
        "city_id": 2,
        "phone": "0316627362",
        "country": "Nederland"
    },
    {
        "name": "Aanhanger B.V. Nijkerk",
        "location": "Nijkerk",
        "address": "Polderbaan 42",
        "zipcode": "3919PW",
        "city": "Nijkerk",
        "city_id": 1,
        "country": "Nederland"
    }
];
mock.onGet('/api/company/locations').reply(req => {

    let {items_per_page, page} = req.params;
    items_per_page = items_per_page ?? 10;
    page = page ?? 1;

    let responseLocations = [];

    let i = 1;
    while (responseLocations.length < items_per_page) {
        let location = Object.assign({}, locations[Math.floor(Math.random() * locations.length)]);
        location.id = i++;
        responseLocations.push(location);
    }

    return [200, {
        "payload": {
            "total": 89,
            "currentPage": page,
            "pageSize": items_per_page,
            "locations": responseLocations
        }
    }];
});

mock.onGet(/\/api\/company\/locations\/\d+/).reply(req => {
    const location = Object.assign({}, locations[Math.floor(Math.random() * locations.length)]);
    return [200, {
        "payload": location
    }];
});

mock.onPut(/\/api\/company\/location\/\d+/).reply(req => {
    return [200, {
        "success": true,
        "payload": JSON.parse(req.data)
    }];
});

mock.onGet('/api/cities').reply(req => {
    return [200, {
        "payload": {
            "cities": [
                {
                    "id": 1,
                    "name": "Nijkerk"
                },
                {
                    "id": 2,
                    "name": "Woudenberg"
                },
                {
                    "id": 3,
                    "name": "Leusden"
                },
                {
                    "id": 4,
                    "name": "Amersfoort"
                },
                {
                    "id": 5,
                    "name": "Amsterdam"
                }
            ]
        }
    }]
});

mock.onGet('/api/faq').reply(req => {
    return [200, {
        "payload": {
            "faq": [
                {
                    "title": "General",
                    "items": [
                        {
                            "Q": "How do I get higher in the search results",
                            "A": "Activate unlimited budget"
                        },
                        {
                            "Q": "How come, I'm not visible",
                            "A": "That sounds awesome, once you're invisible you can become a superhero."
                        },
                        {
                            "Q": "Why is my printer broken",
                            "A": "This happens more often. Though it could be a traumatic experience, this ain't our problem."
                        }
                    ]
                },
                {
                    "title": "Settings and updates",
                    "items": [
                        {
                            "Q": "How to keep my data up-to-date",
                            "A": "Just do it"
                        },
                        {
                            "Q": "How can I add a new rental item",
                            "A": "Don't worry, we already did it for you"
                        },
                        {
                            "Q": "I've lost my password, where can I change it?",
                            "A": "At huren.nl we trust our users, just send us a e-mail and we will remove your password entirely so everybody can login without any."
                        }
                    ]
                }
            ]
        }
    }]
});

if (process.env.NODE_ENV !== 'test') mock.restore();

export default axios;
