import React from "react";
import {Descriptions, Skeleton} from "antd";
import Card from "components/Card";
import useFormatting from "../../hooks/useFormatting";
import {useTranslation} from "react-i18next";
import moment from "moment";

export const QuotationRentalRequestInformationCard = (props) => {

    const { t } = useTranslation('common');
    const { formatDateTime, humanizer } = useFormatting();

    const quotation = props.quotation;
    const rentalMatch = props.rentalMatch;

    return (
        <Card title={t('components.quotation-card.quotation-information.title')}>
            {!quotation ?
                <Skeleton active={true} loading={true}/> :
                <>
                    <Descriptions
                        column={1}
                        layout="horizontal"
                        className="label-50-percent"
                    >
                        <Descriptions.Item label={t('entities:quotation.properties.status')}>
                            {t('entities:quotation.status.' + quotation.status)}
                        </Descriptions.Item>
                        <Descriptions.Item label={t('entities:quotation.properties.product')}>
                            {quotation.rental_item ? quotation.rental_item.name : quotation.clickbox_category.title}
                        </Descriptions.Item>
                        <Descriptions.Item label={t('entities:quotation.properties.quantity')}>
                            {quotation.quantity}
                        </Descriptions.Item>
                        <Descriptions.Item label={t('entities:quotation.properties.street')}>
                            {quotation.street} {quotation.house_number} {quotation.additional}
                        </Descriptions.Item>
                        <Descriptions.Item label={t('entities:quotation.properties.postcode')}>
                            {quotation.postcode}
                        </Descriptions.Item>
                        <Descriptions.Item label={t('entities:quotation.properties.location')}>
                            {quotation.city}
                        </Descriptions.Item>
                        <Descriptions.Item label={t('entities:quotation.properties.date_from')}>
                            {quotation.date_from ? formatDateTime(quotation.date_from) : '-'}
                        </Descriptions.Item>
                        <Descriptions.Item label={t('entities:quotation.properties.date_until')}>
                            {quotation.date_until ? formatDateTime(quotation.date_until) : '-'}
                        </Descriptions.Item>
                        <Descriptions.Item label={t('entities:quotation.properties.rental_term')}>
                            {(quotation.date_from && quotation.date_until) ? humanizer(moment(quotation.date_from).diff(moment(quotation.date_until))) : t('entities:quotation.for-unknown-rental-duration') }
                        </Descriptions.Item>
                        <Descriptions.Item label={t('entities:quotation.properties.extra')}>
                            {quotation.extra}
                        </Descriptions.Item>
                        <Descriptions.Item label={t('entities:quotation.properties.company_location')}>
                            {rentalMatch.rental_service_provider_location ? rentalMatch.rental_service_provider_location.name : ''}
                        </Descriptions.Item>
                    </Descriptions>
                </>
            }
        </Card>
    );
}