import React from "react";
import {PremiumInfo} from "components/Premium";
import {useTranslation} from 'react-i18next';
import Drawer from "components/Drawer";
import {useContactDetails} from "hooks";
import ContactDetailsForm from "./ContactDetailsForm";

const ContactDetailsDrawer = () => {
    const {t} = useTranslation('common');

    const {
        closeEditForm,
        isEditFormOpen,
    } = useContactDetails();

    return (
        <Drawer
            title={<><PremiumInfo/> {t('pages.company.contact-details-form.title')}</>}
            placement="right"
            onClose={() => closeEditForm()}
            open={isEditFormOpen}
            width={520}
        >
            <ContactDetailsForm/>
        </Drawer>
    );
};

export default ContactDetailsDrawer;
