import React from 'react';
import {useTranslation} from "react-i18next";
import Drawer from "components/Drawer";
import {useLocations} from "hooks";
import LocationForm from "./LocationForm";

const LocationDrawer = () => {
    const {t} = useTranslation('common');

    const {
        isEditFormOpen,
        editFormPayload,
        closeEditForm,
    } = useLocations();

    const {
        id,
    } = editFormPayload;

    return <Drawer
        title={id == null ? t('pages.company.locations-form.title-create') : t('pages.company.locations-form.title')}
        open={isEditFormOpen}
        onClose={() => closeEditForm()}
        width={520}
        bodyStyle={{ marginBottom: 80 }}
    >
        <LocationForm/>
    </Drawer>;
};

export default LocationDrawer;
