import {useMutation, useQuery, useQueryClient} from "react-query";
import {OfferItemType, OfferType} from "../types";
import client from "services/HurenApiClient";
import {createQueryString} from "../helpers/queryString";

/**
 * Transforms an offer object into something the API understands
 */
const getRequestBody = (offer) => {
    const requestBody = {...offer, offer_items: []};

    offer.offer_items.forEach(offerItem => {
        const offerItemRequestBody = {...offerItem};
        delete offerItemRequestBody.id;
        if (offerItemRequestBody.type === OfferItemType.RENTAL_ITEM) {
            offerItemRequestBody.rental_item_id = offerItemRequestBody.rental_item.id;
            delete offerItemRequestBody.name;
        } else if (offerItemRequestBody.type === OfferItemType.OTHER) {
            offerItemRequestBody.tax_rate_id = offerItemRequestBody.tax_rate.id;
        }

        delete offerItemRequestBody.rental_item;
        delete offerItemRequestBody.tax_rate;

        requestBody.offer_items.push(offerItemRequestBody);
    });

    return requestBody;
}

const createOffer = async ({rentalMatchId, offer}) => {
    const requestBody = getRequestBody(offer);
    return client.post(`/api/v2/rental-matches/${rentalMatchId}/offers`, requestBody);
};
export const useCreateOffer = () => {
    const queryClient = useQueryClient();
    return useMutation(createOffer, {
        onSuccess: async (res) => {
            const rentalMatchId = res.data.rental_match.id;
            await queryClient.invalidateQueries(['rental-matches', parseInt(rentalMatchId), 'offers']);
        }
    });
};

const updateOffer = async ({offerId, offer}) => {
    const requestBody = getRequestBody(offer);
    return client.patch(`/api/v2/offers/${offerId}`, requestBody);
};
export const useUpdateOffer = () => {
    const queryClient = useQueryClient();
    return useMutation(updateOffer, {
        onSuccess: async (res) => {
            const rentalMatchId = res.data.rental_match.id;
            await queryClient.invalidateQueries(['rental-matches', parseInt(rentalMatchId), 'offers']);
        }
    });
};
const fetchEnabledTransitions = (id) => async () => {
    const res = await client.get(`/api/v2/offers/${id}/enabled-transitions`);
    return res.data.items;
};
export const useGetEnabledTransitions = (offerId, options) => {
    return useQuery(
        ['offers', parseInt(offerId), 'enabled-transitions'],
        fetchEnabledTransitions(offerId),
        options
    );
};

const createOfferTransition = async ({offerId, transition}) => {
    return client.post(`/api/v2/offers/${offerId}/transitions`, transition);
};
export const useCreateOfferTransition = () => {
    const queryClient = useQueryClient();
    return useMutation(createOfferTransition, {
        onSuccess: async (res, {rentalMatchId, offerId, transition}) => {
            await queryClient.invalidateQueries(['offers', parseInt(offerId), 'enabled-transitions']);
            await queryClient.invalidateQueries(['rental-matches', rentalMatchId, 'offers']);
        }
    });
};

const fetchOffer = (id) => async () => {
    const res = await client.get(`/api/v2/offers/${id}`);
    return res.data;
};
export const useGetOffer = (offerId, options) => {
    return useQuery(['offers', parseInt(offerId)], fetchOffer(offerId), options);
}

const fetchOfferItems = (id) => async () => {
    const res = await client.get(`/api/v2/offers/${id}/items`);
    return res.data.items;
};
export const useGetOfferItems = (offerId, options) => {
    return useQuery(['offers', parseInt(offerId), 'items'], fetchOfferItems(offerId), options);
};

const fetchOfferCollection = (queryData) => async () => {
    let url = '/api/v2/offers';
    if (queryData) {
        url += '?' + createQueryString(queryData, '');
    }
    const res = await client.get(url);
    return res.data;
};
export const useGetOfferCollection = (collectionParameters, options) => {
    const createQueryData = () => {
        const queryData = {
            page: collectionParameters.page,
            limit: collectionParameters.limit,
        };
        if (collectionParameters.searchQuery) {
            queryData.searchQuery = collectionParameters.searchQuery;
        }
        queryData.filters = {
            createdAfter: collectionParameters.filters.createdAfter.toISOString(),
            createdBefore: collectionParameters.filters.createdBefore.toISOString(),
        };
        if (collectionParameters.filters.status) {
            queryData.filters.status = collectionParameters.filters.status;
        }

        if (collectionParameters.sort) {
            queryData.sort = collectionParameters.sort;
        }

        return queryData;
    };
    const queryData = createQueryData();
    return useQuery(['offers', queryData], fetchOfferCollection(queryData), options);
};

const deleteOffer = async ({offerId}) => {
    return client.delete(`/api/v2/offers/${offerId}`);
};
export const useDeleteOffer = () => {
    const queryClient = useQueryClient();
    return useMutation(deleteOffer, {
        onSuccess: async (res, {rentalMatchId, offerId}) => {
            await queryClient.invalidateQueries(['offers']);
            await queryClient.invalidateQueries(['rental-matches', rentalMatchId, 'offers']);
        }
    });
};