import React, {useState, useEffect} from "react";
import {Trans, useTranslation} from 'react-i18next';
import {Alert, Button, Divider, Form, Input, notification, Select} from "antd";
import {useRentalItem} from "hooks";
import Drawer from "components/Drawer";
import SubmitButton from "components/SubmitButton";
import {useAccounts} from "hooks";
import Paragraph from "antd/es/typography/Paragraph";
import RentalPriceTable from "components/RentalPriceTable";

const { Option } = Select;
const { forwardRef, useImperativeHandle } = React;

const RentalItemPriceForm = forwardRef((props, ref) => {
    const [form] = Form.useForm();
    const {getFieldValue, validateFields} = form;
    const { TextArea } = Input;
    const [formOpen, setFormOpen] = useState(false);
    const [rentalPrices, setRentalPrices] = useState([]);
    const [mainStaticContentBlockBody, setMainStaticContentBlockBody] = useState(null);
    const [extraPriceText, setExtraPriceText] = useState(null);
    const [showByDefaultPriceIncludingVat, setShowByDefaultPriceIncludingVat] = useState(false);
    const [pricingModelName, setPricingModelName] = useState(null);
    const [taxRate, setTaxRate] = useState(21)
    
    const {
        putRentalItemPricing
    } = useRentalItem();

    const {t} = useTranslation('common');
    const {activeAccount} = useAccounts();

    // Allows use of the openForm function from the parent component.
    useImperativeHandle(ref, () => ({
        openForm(rentalPrices, mainStaticContentBlockBody, extraPriceText, showByDefaultPriceIncludingVat, taxRate, pricingModelName) {
            setRentalPrices(rentalPrices);
            setMainStaticContentBlockBody(mainStaticContentBlockBody);
            setExtraPriceText(extraPriceText);
            setShowByDefaultPriceIncludingVat(showByDefaultPriceIncludingVat);
            setTaxRate(taxRate)
            setPricingModelName(pricingModelName);

            setFormOpen(true);
        }
    }));

    const closeForm = () => {
        setFormOpen(false);
        form.resetFields();
        setRentalPrices([]);
        setMainStaticContentBlockBody(null);
        setExtraPriceText(null);
        setShowByDefaultPriceIncludingVat(false);
        setPricingModelName(null);
    };

    const onFinish = (values) => {
        let params = new URLSearchParams(location.search); // eslint-disable-line
        putRentalItemPricing(
            activeAccount.companyId,
            params.get('id'),
            rentalPrices,
            values['extraPriceText'],
            showByDefaultPriceIncludingVat,
            pricingModelName
        )
            .then((res) => {
                notification.open({
                    message: t('notification.messages.saved.title'),
                    description: t('notification.messages.saved.description'),
                });
                
                closeForm();
            })
            .catch(e => {
                console.log(e);
                notification.open({
                    message: t('notification.messages.error.title'),
                    description: t('notification.messages.error.description'),
                });
                
                closeForm();
            });
    };

    const onDataChange = (values) => {
        if (!('type' in values) || !('data' in values)) {
            return;
        }

        if (values['type'] === 'rentalPrices') {
            setRentalPrices(values['data']);
        } else if (values['type'] === 'vatStatus') {
            setShowByDefaultPriceIncludingVat(values['data']);
        }
    }
    const onValuesChange = (values) => {
        setPricingModelName(values.pricingModelName);
    };

    let isValid = true;

    if (pricingModelName === 'fixed' && rentalPrices.length !== 1) {
        isValid = false;
    }

    return (
        <Drawer
            form={form}
            title={t('pages.rentalitems.rental-item.pricing-form.title')}
            placement="right"
            onClose={() => closeForm()}
            open={formOpen}
            width={800}
        >
            <Form
                form={form}
                layout="vertical"
                onFinish={onFinish}
                onValuesChange={onValuesChange}
                initialValues={{
                    rentalPrices: rentalPrices,
                    mainStaticContentBlockBody: mainStaticContentBlockBody,
                    extraPriceText: extraPriceText,
                    pricingModelName: pricingModelName,
                }}
            >

                <Form.Item
                    key="pricingModelName"
                    name="pricingModelName"
                    label={t('pages.rentalitems.rental-item.pricing-form.pricing-model-name')}
                >
                    <Select>
                        <Select.Option value="fixed">{t('entities:rental-item.pricing-models.fixed')}</Select.Option>
                        <Select.Option value="duration_based">{t('entities:rental-item.pricing-models.duration_based')}</Select.Option>
                    </Select>
                </Form.Item>

                {pricingModelName === 'fixed' && rentalPrices.length !== 1 && (
                    <Alert
                        type="warning"
                        style={{'marginBottom': '14px'}}
                        message={t('pages.rentalitems.rental-item.pricing-form.pricing-model-warning')}
                    />
                )}

                <RentalPriceTable
                    pricingModelName={pricingModelName}
                    data={rentalPrices}
                    includeVAT={showByDefaultPriceIncludingVat}
                    onDataChange={onDataChange}
                    taxRate={taxRate}
                />

                <br/>

                <Form.Item 
                    key="extraPriceText" 
                    name="extraPriceText" 
                    label={t('pages.rentalitems.rental-item.pricing-form.extra-price-text-title')}
                >
                    <TextArea rows={4} />
                </Form.Item>

                <div className="actions">
                    <Button onClick={() => closeForm()} style={{marginRight: '8px'}}>
                        {t('button.text.cancel')}
                    </Button>
                    <SubmitButton disabled={!isValid}>
                        {t('button.text.save')}
                    </SubmitButton>
                </div>
            </Form>
        </Drawer>
    );
});

export default RentalItemPriceForm;
