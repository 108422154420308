import React from "react";
import Media from "react-media";
import {Badge, Button, Table, Space} from "antd";
import moment from "moment";
import {MessageOutlined, EyeOutlined} from "@ant-design/icons";
import {navigate} from "@reach/router";
import {useTranslation} from "react-i18next";
import {useSmallDeviceSize} from "hooks";
import {hasPermission} from "helpers";
import {useAccounts, useChat} from "hooks";
import useFormatting from "../../../hooks/useFormatting";
import {OfferStatusTag} from "../../../components/OfferStatusTag";
import {useFetchStatus} from "../../../queries/me";

export const OffersTable = ({offers, total, onChange, loading}) => {
    const { t } = useTranslation('common');
    const isSmallDevice = useSmallDeviceSize();
    const {activeAccount} = useAccounts();
    const { doOpenChat } = useChat();

    const { data: status } = useFetchStatus();

    const { humanizer, formatDateTime, formatDate } = useFormatting();

    const getQuotationProductName = (quotation) => {
        let productName = quotation.clickbox_category.title;
        if (quotation.rental_item) {
            productName = `${productName} | ${quotation.rental_item.name}`;
        }
        return productName;
    };

    const tableColumns = [
        {
            title: t('entities:offer.properties.opened-at'),
            dataIndex: 'opened_at',
            key: 'opened_at',
            ellipsis: true,
            sorter: true,
            render: (text, record) => record.opened_at ?
                <>{formatDateTime(record.opened_at)}</> :
                <>-</>
        },
        {
            title: t('entities:offer.properties.expires-at'),
            dataIndex: 'opened_at',
            key: 'expires_at',
            ellipsis: true,
            hideOnResponsive: true,
            sorter: (a, b) => ('' + a.expires_at).localeCompare(b.expires_at),
            render: (text, record) => record.expires_at ?
                <>{formatDate(record.expires_at)} ({t('entities:offer.validity-periods.' + record.validity_period)})</> :
                <>-</>
        },
        {
            title: t('tables.rental-matches.column.name'),
            dataIndex: 'first_name',
            key: 'name',
            hideOnResponsive: true,
            sorter: true,
            render: (text, record) => record.rental_match.quotation.first_name + ' ' + record.rental_match.quotation.last_name
        },
        {
            title: t('tables.rental-matches.column.product'),
            dataIndex: 'clickbox_category_name',
            key: 'product_name',
            sorter: true,
            render: (text, record) => getQuotationProductName(record.rental_match.quotation)
        },
        {
            title: t('tables.rental-matches.column.rental-date'),
            dataIndex: 'rental_start_date',
            key: 'date_from',
            hideOnResponsive: true,
            sorter: true,
            render: (text, offer) => {
                if (offer.date_from === null) {
                    return '-';
                }
                return formatDateTime(offer.rental_match.quotation.date_from);
            }
        },
        {
            title: t('tables.rental-matches.column.rental-duration'),
            dataIndex: 'rental_duration',
            key: 'rental_duration',
            hideOnResponsive: true,
            render: (text, offer) => {
                if (offer.date_from === null) {
                    return t('entities:quotation.for-unknown-rental-duration');
                }
                if (offer.date_until === null) {
                    return '-';
                }
                return humanizer(moment(offer.date_from).diff(moment(offer.date_until)));
            }
        },
        {
            title: t('tables.rental-matches.column.status'),
            dataIndex: 'status',
            key: 'status',
            sorter: (a, b) => ('' + a.status).localeCompare(b.status),
            hideOnResponsive: true,
            render: (text, offer) => {
                var statusEl;
                if (offer.status) {
                    statusEl = <OfferStatusTag status={offer.status} />;
                }

                return statusEl;
            }
        },
        {
            align: 'right',
            title: t('tables.rental-matches.column.actions'),
            dataIndex: 'actions',
            key: 'actions',
            render: (text, record) => {
                let unreadMessagesCount = 0;
                if (status && status.rental_matches) {
                    let rentalMatchStatus = status.rental_matches.find(obj => {
                        return obj.id === record.rental_match.id
                    });
                    if (rentalMatchStatus) {
                        unreadMessagesCount = rentalMatchStatus.unread_messages_count;
                    }
                }

                var buttonLabel = t('button.text.view');
                if (isSmallDevice) {
                    buttonLabel = '';
                }
                return <Space>
                    <Button
                        style={{marginLeft:'0.25rem'}}
                        className={isSmallDevice ? 'small-button' : ''}
                        disabled={!hasPermission(activeAccount, 'manage_offers', 'edit')}
                        onClick={() => navigate(`/rental-matches/${record.rental_match.id}#offers`)}
                    >
                        {buttonLabel}
                    </Button>
                    <Button
                        size="middle"
                        onClick={(e) => {
                            doOpenChat(record.rental_match.conversation.id);
                        }}
                    >
                        <Badge size="small" count={unreadMessagesCount}>
                            <MessageOutlined/>
                        </Badge>
                    </Button>
                </Space>
            }
        }
    ];

    return (
        <Media query="(min-width: 950px)">
            {matches => {
                let filteredColumns = tableColumns.filter((column) => {
                    return matches || column.hideOnResponsive !== true;
                });

                return <Table
                    tableLayout="auto"
                    dataSource={offers}
                    columns={filteredColumns}
                    loading={loading}
                    rowKey="id"
                    onChange={onChange}
                    pagination={{
                        showLessItems: true,
                        total: total,
                        showSizeChanger: true
                    }}
                />
            }}
        </Media>
    )
};