import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {
    Button,
    Col,
    Row,
    Form,
    Input,
    Divider,
    Descriptions,
    Radio,
    Select,
    Upload,
    InputNumber, DatePicker, Popover
} from "antd";
import {InboxOutlined, InfoCircleOutlined} from "@ant-design/icons";
import useFormatting from "../../hooks/useFormatting";
import {OfferType} from "../../types";
import client from "services/HurenApiClient";
import OfferItemsCollectionForm from "./OfferItemsCollectionForm";
import {getDefaultTotals, transformOfferToValues, getOfferTotals} from "../../helpers/offers";
import {OFFER_VALIDITY_PERIODS} from "../../types";
import moment from "moment";
import TextArea from "antd/es/input/TextArea";

const OfferForm = ({rentalMatch, onFinish, offer, disabled, mode, onCancel}) => {
    const { t } = useTranslation('common');
    const { formatCents } = useFormatting();
    const [form] = Form.useForm();
    const [type, setType] = useState(OfferType.DETAILED);
    const [initialValues, setInitialValues] = useState({
        attachments: {
            fileList: []
        }
    });
    const [totals, setTotals] = useState(getDefaultTotals());
    const [attachmentIdsMap, setAttachmentIdsMap] = useState({});

    useEffect(() => {
        if (!offer) {
            offer = {
                validity_period: OFFER_VALIDITY_PERIODS[0],
            };
            if (rentalMatch) {
                offer.date_from = rentalMatch.quotation.date_from ? moment(rentalMatch.quotation.date_from) : null;
                offer.date_until = rentalMatch.quotation.date_until ? moment(rentalMatch.quotation.date_until) : null;
            }
        }
        setType(offer.type);
        const initialValues = transformOfferToValues(offer);
        setInitialValues(initialValues);
        form.setFieldsValue(initialValues);
        calculateTotals();
    }, [offer, rentalMatch]);

    /**
     * Offer totals are recalculated every time the list of offer items changes
     */
    useEffect(() => {
        calculateTotals();
    }, [type]);

    const typeOptions = [
        {
            label: t('entities:offer.types.detailed'),
            value: OfferType.DETAILED
        },
        {
            label: t('entities:offer.types.simple'),
            value: OfferType.SIMPLE
        },
    ];

    /**
     * Transforms the form values back into an offer object
     */
    const transformValuesToOffer = (values) => {
        const offer = {offer_items: [], ...values, attachment_ids: []};

        if (offer.attachments) {
            offer.attachment_ids = values.attachments.fileList.map(file => {
                // For existing attachments (when in edit mode), the uid will not be in the id map, because it is
                // actually the id of the attachment
                if (!attachmentIdsMap[file.uid]) {
                    return file.uid;
                }
                return attachmentIdsMap[file.uid];
            });
            delete offer.attachments;
        }

        if (values.price_incl) {
            offer.price_incl = Math.round(values.price_incl * 100);
            offer.price_excl = Math.round(values.price_excl * 100);
        }

        return offer;
    };

    /**
     * Whenever the offer form is submitted, we transform the form values back into an offer and bubble it up to the
     * parent
     */
    const onInnerFinish = (values) => {
        if (!onFinish) {
            return;
        }

        const offer = transformValuesToOffer(values);
        onFinish(offer);
    };

    const calculateTotals = () => {
        const values = form.getFieldsValue();
        const newOffer = transformValuesToOffer(values);
        const newTotals = getOfferTotals(newOffer);
        setTotals(newTotals);

        return newTotals;
    }

    const onChangeType = (e) => {
        setType(e.target.value);
    }

    const onUploadAttachment = ({file, onSuccess, onError}) => {
        const formData = new FormData();
        formData.append("file", file || "");
        client.post(`/api/v2/offer-attachments`, formData).then((res) => {
            setAttachmentIdsMap(prevState => {
                return {... prevState, [file.uid]: res.data.id};
            });
            onSuccess(res, file)
        }).catch(err => {
            onError(err);
        });
    };

    const onRemoveAttachment = (file) => {
        const attachmentId = attachmentIdsMap[file.uid];
        // If the attachment id is not in the id map, it means we're in edit mode and it was already attached to the
        // offer when we started editing. We don't want to immediately delete these, since the user might still cancel
        // the edit. Orphaned attachments are deleted after a certain amount of time though.
        if (attachmentId) {
            client.delete(`/api/v2/offer-attachments/${attachmentId}`);
        }
    };

    /**
     * Taken from https://ant.design/components/form/#components-form-demo-validate-other
     */
    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e?.fileList;
    };

    return (
        <>
            <Form
                layout="horizontal"
                form={form}
                initialValues={initialValues}
                onFinish={onInnerFinish}
                labelAlign="left"
                labelCol={{span: 12}}
                wrapperCol={{span:12}}
                onValuesChange={calculateTotals}
            >
                <Form.Item
                    name="remarks"
                    label={t('entities:offer.properties.remarks')}
                    rules={[]}
                    disabled={disabled}
                >
                    <TextArea placeholder={t('components:offer-form.remarks.placeholder')}/>
                </Form.Item>
                <Form.Item
                    label={t('entities:offer.properties.validity-period')}
                    name="validity_period"
                    rules={[
                        {required: true, message: t('form.errors.required')}
                    ]}
                >
                    <Select>
                        {OFFER_VALIDITY_PERIODS.map(validityPeriod => {
                            return <Select.Option key={validityPeriod} value={validityPeriod}>
                                {t('entities:offer.validity-periods.' + validityPeriod)}
                            </Select.Option>
                        })}
                    </Select>
                </Form.Item>
                <Form.Item
                    label={
                        <Popover content={t('components:offer-form.attachments.info')}>
                            <span>
                                {t('entities:offer.properties.attachments')}{' '}
                                <InfoCircleOutlined />
                            </span>
                        </Popover>
                    }
                    rules={[]}
                    disabled={disabled}
                    name={["attachments", "fileList"]}
                    valuePropName="fileList"
                    getValueFromEvent={normFile}
                >
                    <Upload.Dragger
                        multiple={true}
                        customRequest={onUploadAttachment}
                        onRemove={onRemoveAttachment}
                    >
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">{t('components:offer-form.attachments-uploader.text')}</p>
                        <p className="ant-upload-hint">
                            {t('components:offer-form.attachments-uploader.hint')}
                        </p>
                    </Upload.Dragger>
                </Form.Item>


                <Form.Item
                    label={
                        <Popover content={t('components:offer-form.type.info')}>
                            <span>
                                {t('entities:offer.properties.type')}{' '}
                                <InfoCircleOutlined />
                            </span>
                        </Popover>
                    }
                    name="type"
                >
                    <Radio.Group options={typeOptions} onChange={onChangeType} optionType="button" buttonStyle="solid" />
                </Form.Item>

                {type === OfferType.SIMPLE ?
                    <>
                        <Form.Item
                            label={t('entities:offer.properties.price-excl')}
                            name="price_excl"
                            rules={[
                                {required: true, message: t('form.errors.required')}
                            ]}
                            normalize={(value) => value ? parseFloat(value) : null}
                        >
                            <InputNumber
                                min={0}
                                decimalSeparator=","
                                onChange={calculateTotals}
                            />
                        </Form.Item>
                        <Form.Item
                            label={t('entities:offer.properties.price-incl')}
                            name="price_incl"
                            rules={[
                                {required: true, message: t('form.errors.required')},
                                ({ getFieldValue }) => ({
                                    validator(rule, value) {
                                        if (value < getFieldValue('price_excl')) {
                                            return Promise.reject(
                                                t('components:offer-form.fields.price-incl.errors.must-be-greater-than-price-excl')
                                            );
                                        }
                                        return Promise.resolve();
                                    }
                                })
                            ]}
                            normalize={(value) => value ? parseFloat(value) : null}
                        >
                            <InputNumber
                                min={0}
                                decimalSeparator=","
                                onChange={calculateTotals}
                            />
                        </Form.Item>

                        <Form.Item
                            label={t('entities:offer.properties.date-from')}
                            name="date_from"
                            rules={[
                                {required: true, message: t('form.errors.required')},
                                () => ({
                                    validator(rule, value) {
                                        if (value < moment()) {
                                            return Promise.reject(
                                                t('components:offer-form.fields.date-from.errors.must-be-in-the-future')
                                            );
                                        }
                                        return Promise.resolve();
                                    }
                                })
                            ]}
                        >
                            <DatePicker
                                format="DD-MM-YYYY HH:mm"
                                showTime={true}
                            />
                        </Form.Item>
                        <Form.Item
                            label={t('entities:offer.properties.date-until')}
                            name="date_until"
                            rules={[
                                ({ getFieldValue }) => ({
                                    validator(rule, value) {
                                        if (value && value < getFieldValue('date_from')) {
                                            return Promise.reject(
                                                t('components:offer-form.fields.date-until.errors.must-be-greater-than-date-from')
                                            );
                                        }
                                        return Promise.resolve();
                                    }
                                })
                            ]}
                        >
                            <DatePicker
                                format="DD-MM-YYYY HH:mm"
                                showTime={true}
                            />
                        </Form.Item>
                    </> :
                    <>
                        <Form.Item
                            name="offer_items"
                            wrapperCol={{span: 24}}
                            rules={[
                                {required: true, message: t('components:offer-form.offer-items-table.empty-text')}
                            ]}
                        >
                            <OfferItemsCollectionForm rentalMatch={rentalMatch} onChange={calculateTotals}/>
                        </Form.Item>
                    </>
                }

                <Row gutter={[16, 16]}>
                    <Col span={18}></Col>
                    <Col span={6} align="right">
                        <Divider/>
                        <Descriptions
                            layout="horizontal"
                            column={1}
                            className="label-50-percent content-align-right"
                        >
                            <Descriptions.Item label={t('components:offer-form.totals-table.excl')}>
                                {formatCents(totals.exVat)}
                            </Descriptions.Item>
                            <Descriptions.Item label={t('components:offer-form.totals-table.vat')}>
                                {formatCents(totals.vat)}
                            </Descriptions.Item>
                        </Descriptions>
                        <Divider/>
                        <Descriptions
                            layout="horizontal"
                            column={1}
                            className="label-50-percent content-align-right"
                        >
                            <Descriptions.Item label={t('components:offer-form.totals-table.incl')}>
                                {formatCents(totals.incVat)}
                            </Descriptions.Item>
                        </Descriptions>
                    </Col>
                </Row>

                <Row>
                    <Col span={24} align="right">
                        <div className="actions">
                            {onCancel &&
                                <Button
                                    type="secondary"
                                    onClick={onCancel}
                                >
                                    {t('components:offer-form.cancel')}
                                </Button>
                            }

                            <Button
                                disabled={disabled}
                                htmlType="submit"
                                type="primary"
                            >
                                {t('components:offer-form.submit.' + mode)}
                            </Button>
                        </div>
                    </Col>
                </Row>
            </Form>
        </>
    );
};

export default OfferForm;