import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Button, Divider, Input, Select} from 'antd';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import styled from 'styled-components'

const BlockContainer = styled.div`
  display:flex;
  flex-direction: column;
  gap: 0.5rem;
`

const {Option} = Select;

const RentalItemContentBlockFormItem = (props) => {
  const {contentBlock, itemIndex, handleSave, deleteRow} = props

  const maxCharacters = 5000

  // Quill doesn't format HTML correctly the first time it renders for some reason, so we trigger a second render
  // so that the HTML gets formatted correctly
  const [firstRender, setFirstRender] = useState(true);
  if (firstRender) {
    setFirstRender(false);
  }

  const {t} = useTranslation('common')

  const remove = (itemIndex) => {
    deleteRow(itemIndex);
  }

  const save = (content, itemIndex) => {
    handleSave(content, itemIndex)
  }

  let titleElement = <p>{t('pages.rentalitems.rental-item.content-blocks-form.block.title')}: {contentBlock.title}</p>
  if (contentBlock.type === 'generic') {
    titleElement = <>
      <p>{t('pages.rentalitems.rental-item.content-blocks-form.block.title')}:</p>
      <Input value={contentBlock.title} onChange={(e) => {
        save({...contentBlock, title: e.target.value}, itemIndex)
      }}/>
    </>
  }

  let formItemBody = <BlockContainer>
    {titleElement}

    <p>{t('pages.rentalitems.rental-item.content-blocks-form.block.body')}:</p>
    <ReactQuill
      theme="snow"
      formats={['bold', 'italic', 'strike', 'underline', 'list']}
      modules={{
        toolbar: [
          ["bold", "italic", "underline"],
          [{'list': 'ordered'}, {'list': 'bullet'}],
        ]
      }}
      value={contentBlock.body}
      defaultValue={contentBlock.body}
      onChange={(value, delta, source) => {
        // Quill triggers an onchange event with source=api the first time the value is set. We should not propagate a save
        // when this happens
        if (source === 'api') {
          return;
        }
        save({...contentBlock, body: value}, itemIndex);
      }}
    />
    {contentBlock.body.length > maxCharacters &&
    <p
      style={{color: 'red'}}>{t('pages.rentalitems.rental-item.content-blocks-form.errors.no_longer_then', {length: maxCharacters})}</p>}
    <Button style={{ marginLeft: 'auto' }} danger onClick={() => remove(itemIndex)}>{t('pages.rentalitems.rental-item.content-blocks-form.block.button')}</Button>
  </BlockContainer>

  if (!contentBlock.type) {
    formItemBody = <BlockContainer>
      <p>Select content block type:</p>
      <Select labelInValue label={t('pages.rentalitems.rental-item.content-blocks-form.select.title')}
              placeholder={"Kies iets"}
              defaultValue="generic"
              onSelect={(e) => {
                save({...contentBlock, type: e.value, title: e.label}, itemIndex)
              }}>
        <Option value="generic">{t('pages.rentalitems.rental-item.content-blocks-form.select.options.generic')}</Option>
        <Option value="deposit">{t('pages.rentalitems.rental-item.content-blocks-form.select.options.deposit')}</Option>
        <Option
          value="own_risk">{t('pages.rentalitems.rental-item.content-blocks-form.select.options.own_risk')}</Option>
        <Option
          value="insurance">{t('pages.rentalitems.rental-item.content-blocks-form.select.options.insurance')}</Option>
        <Option
          value="accessories">{t('pages.rentalitems.rental-item.content-blocks-form.select.options.accessories')}</Option>
        <Option
          value="driving_abroad">{t('pages.rentalitems.rental-item.content-blocks-form.select.options.driving_abroad')}</Option>
        <Option
          value="extra_information">{t('pages.rentalitems.rental-item.content-blocks-form.select.options.extra_information')}</Option>
        <Option
          value="technical_information">{t('pages.rentalitems.rental-item.content-blocks-form.select.options.technical_information')}</Option>
        <Option value="payment">{t('pages.rentalitems.rental-item.content-blocks-form.select.options.payment')}</Option>
        <Option
          value="pickup_and_dropoff">{t('pages.rentalitems.rental-item.content-blocks-form.select.options.pickup_and_dropoff')}</Option>
      </Select>
    </BlockContainer>
  }

  return (
    <>
      {formItemBody}
      <Divider/>
    </>
  )
}

export default RentalItemContentBlockFormItem;
