import {useGlobalStore} from 'store';

import bindActions from 'store/bindActions';
import testOverlap from 'store/testOverlap';
import dailyBudgetReducer from 'store/dailyBudget';

const { Actions } = dailyBudgetReducer;

const useDailyBudget = () => {
    const {state, dispatch} = useGlobalStore();

    const {dailyBudget} = state;

    // Test if there's an overlapping between the actions and store
    testOverlap(dailyBudget, Actions);

    return {
        ...bindActions(Actions, dispatch),
        ...dailyBudget,
    };
};

export default useDailyBudget;
