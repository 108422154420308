import * as Types from "./Types";

export const initialState = {
    isEditFormOpen: false,
    isEditFormSaving: false,
    userAccounts: [],
    activeAccount: null,
    isDataLoaded: false
};

export default function (state, action) {
    switch (action.type) {
        case Types.ACCOUNTS_LOADED:
            return {
                ...state,
                userAccounts: action.userAccounts,
                isDataLoaded: true
            };
        case Types.ACCOUNT_CHOSEN:
            return {
                ...state,
                activeAccount: action.activeAccount
            };
        case Types.EDIT_FORM_OPEN:
            return {
                ...state,
                isEditFormOpen: true,
            };
        case Types.EDIT_FORM_CLOSE:
            return {
                ...state,
                isEditFormOpen: false,
            };
        case Types.SET_IS_EDIT_FORM_SAVING:
            return {
                ...state,
                isEditFormSaving: action.isEditFormSaving
            };
        default:
            return state;
    }
};
