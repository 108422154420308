import React from "react";
import {useTranslation} from "react-i18next";
import Drawer from "components/Drawer";
import {useReviews} from "hooks";
import 'moment/locale/nl';
import ReplyForm from "./ReplyForm";

const ReplyDrawer = () => {
    const {t} = useTranslation('common');

    const {
        isReplyFormOpen,
        closeReplyForm,
    } = useReviews();

    return (
        <Drawer
            title={t('pages.company.review-reply-form.title')}
            placement="right"
            onClose={() => closeReplyForm()}
            open={isReplyFormOpen}
            width={520}
        >
            <ReplyForm/>
        </Drawer>
    );
};

export default ReplyDrawer;
