import * as Types from "./Types";

export const openChat = (conversationId) => {
  return {
    type: Types.OPEN_CHAT,
    payload: {
      conversationId: conversationId,
      open: true,
    }
  };
};

export const closeChat = () => {
  return {
    type: Types.OPEN_CHAT,
    payload: {
      conversationId: null,
      open: false,
    }
  };
};

export const doOpenChat = (conversationId) => {
  return async function (dispatch) {
    dispatch(openChat(conversationId));
  };
};

export const doCloseChat = () => {
  return async function (dispatch) {
    dispatch(closeChat());
  };
};
