
export const canManageQuotationOffers = (quotation) => {
    if (quotation.status === 'withdrawn') {
        return false;
    }
    if (quotation.has_order) {
        return false;
    }

    return true;
};