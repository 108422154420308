import React from 'react';
import {useTranslation} from "react-i18next";
import LoggedInLayout from "layout/LoggedInLayout";
import {PremiumInfo} from "components/Premium";
import AdvertisementSteps from "./components/AdvertisementsWizard/AdvertisementSteps";

const AdvertisementWizard = () => {
    const { t } = useTranslation('common');

    return <LoggedInLayout pageTitle={<><PremiumInfo/> {t('pages.advertisements.advertisements-wizard.title')}</>}>
        <AdvertisementSteps />
    </LoggedInLayout>;
};

export default AdvertisementWizard;
