import client from "services/HurenApiMockClient";
import * as Types from "./Types";

export const setIsDataLoaded = (loaded) => {
    return {
        type: Types.SET_IS_DATA_LOADED,
        isDataLoaded: loaded
    };
};

export const openEditForm = () => {
    return {
        type: Types.EDIT_FORM_OPEN
    };
};

export const closeEditForm = () => {
    return {
        type: Types.EDIT_FORM_CLOSE
    };
};

export const setData = (payload) => {
    return {
        type: Types.SET_DATA,
        payload: payload
    };
};

export const setIsEditFormSaving = (saving) => {
    return {
        type: Types.SET_IS_EDIT_FORM_SAVING,
        isEditFormSaving: saving
    };
};

export const getBudgetFormData = () => {
    return async function (dispatch) {
        dispatch(setIsDataLoaded(false));

        return client.get('/api/advertisements/budget-form').then(res => {
            dispatch(setData(res.data.payload));
        });
    };
};

export const putDailyBudget = (data) => {
    return async function (dispatch) {
        dispatch(setIsEditFormSaving(true));
        return client.put('/api/advertisements/budget-form', data).then(res => {
            dispatch(setIsEditFormSaving(false));
        });
    };
};
