import React from 'react';
import {
    Button,
    Col,
    Row,
    Skeleton
} from "antd";
import LoggedInLayout from "layout/LoggedInLayout";
import {ContactDetailsCard} from "./components/ContactDetailsCard";
import {ProfileCard} from "./components/ProfileCard";
import {LogoCard} from "./components/LogoCard";
import {OpeningHoursCard} from "./components/OpeningHoursCard";
import {FeaturesCard} from "./components/FeaturesCard";

import {useTranslation} from "react-i18next";
import {useAccounts} from "hooks";
import {hasPermission} from "../../helpers";

const CompanySettings = () => {
    const { t } = useTranslation('common');
    const { activeAccount } = useAccounts();

    return <LoggedInLayout pageTitle={t('pages.company.settings.title')} headerExtras={
        [
            <Button key="1" target="_blank" href={'https://huren.nl/m' + activeAccount.companyId }>
                {t('pages.company.settings.button-text')}
            </Button>,
        ]
    }>
        <Row gutter={[16, 16]}>
            <Col md={{span: 24}} lg={{span: 12}}>
                { hasPermission(activeAccount, 'manage_contact_details', 'view') ? <ContactDetailsCard /> : <></> }
            </Col>
            <Col md={{span: 24}} lg={{span: 12}}>
                { hasPermission(activeAccount, 'manage_logo', 'view') ? <LogoCard /> : <></> }
            </Col>
        </Row>
        <Row gutter={[16, 16]}>
            <Col md={{span: 24}} lg={{span: 12}}>
                { hasPermission(activeAccount, 'manage_company_profile', 'view')  ? <ProfileCard /> : <></> }
            </Col>
            <Col md={{span: 24}} lg={{span: 12}}>
                { hasPermission(activeAccount, 'manage_opening_hours', 'view') ? <OpeningHoursCard /> : <></> }
            </Col>
        </Row>
        <Row gutter={[16, 16]}>
            <Col md={{span: 24}} lg={{span: 12}}>
                <FeaturesCard /> 
            </Col>
        </Row>
    </LoggedInLayout>;
};

export default CompanySettings;
