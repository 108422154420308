import {useGlobalStore} from 'store';

import bindActions from 'store/bindActions';
import testOverlap from 'store/testOverlap';
import promoReducer from 'store/promo';

const { Actions } = promoReducer;

const usePromo = () => {
    const {state, dispatch} = useGlobalStore();

    const {promo} = state;

    // Test if there's an overlapping between the actions and store
    testOverlap(promo, Actions);

    return {
        ...bindActions(Actions, dispatch),
        ...promo
    };
};

export default usePromo;
