import {Button, Table} from "antd";
import React from "react";
import {calculatePriceIncVat} from "../../../helpers/taxes";
import {EditOutlined} from "@ant-design/icons";
import {useTranslation} from "react-i18next";
import useFormatting from "../../../hooks/useFormatting";

export const OfferItemsTable = ({offerItems, loading}) => {

    const { t } = useTranslation('common');
    const { formatDateTime, formatCents } = useFormatting();

    return (
        <Table
            dataSource={offerItems}
            loading={loading}
            locale={{emptyText: t('components:offer-form.offer-items-table.empty-text')}}
            rowKey={() => Math.random()} // Hacky hack, just don't use row selection
            pagination={false}
        >
            <Table.Column
                title={t('entities:offer-item.properties.name')}
                key="name"
                dataIndex="name"
            />
            <Table.Column
                title={t('entities:offer-item.properties.quantity')}
                key="quantity"
                dataIndex="quantity"
            />
            <Table.Column
                title={t('entities:offer-item.properties.date-from')}
                key="date_from"
                dataIndex="date_from"
                render={(value, record) => formatDateTime(record.date_from)}
            />
            <Table.Column
                title={t('entities:offer-item.properties.date-until')}
                key="date_until"
                dataIndex="date_until"
                render={(value, offerItem) => {
                    if (offerItem.date_until === null) {
                        return '-';
                    }
                    return formatDateTime(offerItem.date_until);
                }}
            />
            <Table.Column
                title={t('entities:offer-item.properties.price-ex-vat')}
                key="priceExVat"
                dataIndex="price"
                render={(value, record) => formatCents(value)}
            />
            <Table.Column
                title={t('entities:offer-item.properties.price-inc-vat')}
                key="priceIncVat"
                dataIndex="price"
                render={(value, record) => formatCents(calculatePriceIncVat(value, record.tax_rate.percentage))}
            />
        </Table>
    );
}