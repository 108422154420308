import {useGlobalStore} from 'store';

import bindActions from 'store/bindActions';
import testOverlap from 'store/testOverlap';
import cancellationReducer from 'store/cancellation';

const { Actions } = cancellationReducer;

const useCancellation = () => {
    const {state, dispatch} = useGlobalStore();

    const {cancellation} = state;

    // Test if there's an overlapping between the actions and store
    testOverlap(cancellation, Actions);

    return {
        ...bindActions(Actions, dispatch),
        ...cancellation,
    };
};

export default useCancellation;
