import React from "react";
import {useTranslation} from "react-i18next";
import Drawer from "components/Drawer";
import {useProfile} from "hooks";
import ProfileForm from "./ProfileForm";

const ProfileDrawer = () => {
    const {t} = useTranslation('common');

    const {
        closeEditForm,
        isEditFormOpen,
    } = useProfile();

    return (
        <Drawer
            title={t('pages.company.profile-form.title')}
            placement="right"
            onClose={() => closeEditForm()}
            open={isEditFormOpen}
            width={520}
            bodyStyle={{ marginBottom: 80 }}
        >
            <ProfileForm/>
        </Drawer>
    );
};

export default ProfileDrawer;
