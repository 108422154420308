import React, {useState} from "react";
import {CheckOutlined, LoadingOutlined, PlusOutlined} from '@ant-design/icons';
import {Button, Divider, Form, message, notification, Upload} from "antd";
import Text from "antd/es/typography/Text";
import {useAccounts, useLogo} from "hooks";
import {useTranslation} from "react-i18next";
import InputCharacterCount from "components/InputCharacterCount";

const Dragger = Upload;

const LogoForm = () => {
    const {t} = useTranslation('common');
    const {activeAccount} = useAccounts();
    const [formState, setFormState] = useState({loading: false, filename: null, newLogo: null, error: null});
    const [form] = Form.useForm();

    const {
        putCompanyLogo,
        closeEditForm,
        payload: {
            tagline,
            logo
        }
    } = useLogo();

    const beforeUpload = (file) => {
        const isValidFormat = file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png' || file.type === 'image/gif';
        if (!isValidFormat) {
            message.error(t('pages.company.company-logo-form.validation.image-format'));
            setFormState({loading: false, filename: null, newLogo: null, error: t('pages.company.company-logo-form.validation.image-format')})
        }
        const sizeValid = file.size / 1024 < 150;
        if (!sizeValid) {
            message.error(t('pages.company.company-logo-form.validation.size'));
            setFormState({loading: false, filename: null, newLogo: null, error: t('pages.company.company-logo-form.validation.image-format')})
        }

        return isValidFormat && sizeValid;
    }

    const onFinish = (values) => {
        // Check if we gave an uploaded file.
        if (formState.newLogo !== null) {
            // Add the uploaded file to the values.
            values.logo = formState.newLogo;
            values.filename = formState.filename;
        } else {
            values.logo = null;
            values.filename = null;
        }
        putCompanyLogo(activeAccount.companyId, values)
            .then((res) => {
                notification.open({
                    message: t('notification.messages.saved.title'),
                    description: t('notification.messages.saved.description'),
                });

                setFormState({loading: false, filename: null, newLogo: null, error: null});
                form.resetFields();
                closeEditForm();
            })
            .catch(e => {
                console.log(e);
                notification.open({
                    message: t('notification.messages.error.title'),
                    description: t('notification.messages.error.description'),
                });
                setFormState({loading: false, filename: null, newLogo: null, error: null});
                form.resetFields();
                closeEditForm();
            });
    };

    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    }

    const dragger = (
        formState.newLogo !== null ?
            <img src={formState.newLogo} alt="logo" style={{ width: '100%' }} />
            :
            logo ? <img src={logo} alt="logo" style={{ width: '100%' }} /> : <div>
                <p className="ant-upload-drag-icon">
                    {formState.loading ? <LoadingOutlined/> : <PlusOutlined/>}
                </p>
                <p className="ant-upload-text">{t('pages.company.company-logo-form.items.logo.help')}</p>
            </div>
    );

    const handleChange = info => {
        if (info.file.status === 'uploading') {
            setFormState({loading: true, newLogo: null, filename: null, error: null});
            return;
        }
        if (info.file.status === 'done') {
            getBase64(info.file.originFileObj, image => {
                setFormState({loading: false, newLogo: image, filename: info.file.name, error: null});
            });
        }
    }

    // Dummy request to get the uploader to NOT send an XHR request.
    const dummyRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
            onSuccess("ok");
        }, 0);
    };

    return (
        <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            initialValues={{
                tagline: tagline,
                logo: logo
            }}
        >
            <Text>
                {t('pages.company.company-logo-form.content.intro')}
                <ul style={{margin: '10px 0'}}>
                    <li>{t('pages.company.company-logo-form.content.list.sizes')}</li>
                    <li>{t('pages.company.company-logo-form.content.list.filesize')}</li>
                    <li>{t('pages.company.company-logo-form.content.list.formats')}</li>
                    <li>{t('pages.company.company-logo-form.content.list.background')}</li>
                    <li>{t('pages.company.company-logo-form.content.list.company-name')}</li>
                    <li>{t('pages.company.company-logo-form.content.list.restrictions')}</li>
                </ul>
            </Text>

            <Divider />
            <Form.Item
                label={t('pages.company.company-logo-form.items.logo.label')}
                help={formState.error !== null ? formState.error : ''}
                valuePropName='filelist'
            >
                <Dragger
                    name="logo"
                    listType="picture-card"
                    accept=".png,.jpg,.jpeg,.gif"
                    className="logo-uploader"
                    customRequest={dummyRequest}
                    beforeUpload={beforeUpload}
                    onChange={handleChange}
                    showUploadList={false}
                    maxCount={1}
                    multiple={false}
                >
                    {dragger}
                </Dragger>
            </Form.Item>

            <Form.Item
                name="tagline"
                label={t('pages.company.company-logo-form.items.tagline.label')}
                extra={t('pages.company.company-logo-form.items.tagline.help')}
                rules={[
                    { required: true },
                    ({ getFieldValue }) => ({
                        validator(rule, value) {

                            let emailInstances = value.toString().match('(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))') ?? [];
                            let phoneInstances = value.toString().match('((\\+|00(\\s|\\s?\\-\\s?)?)31(\\s|\\s?\\-\\s?)?(\\(0\\)[\\-\\s]?)?|0)[1-9]((\\s|\\s?\\-\\s?)?[0-9])((\\s|\\s?-\\s?)?[0-9])((\\s|\\s?-\\s?)?[0-9])\\s?[0-9]\\s?[0-9]\\s?[0-9]\\s?[0-9]\\s?[0-9]') ?? [];
                            let urlInstances = value.toString().match('https?:\\/\\/(www\\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\\.[a-zA-Z0-9()]{1,6}\\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)') ?? [];

                            if (emailInstances.length < 1 && phoneInstances.length < 1 && urlInstances.length < 1) {
                                return Promise.resolve();
                            }

                            return Promise.reject(t('form.errors.contact-details'));
                        },
                    })
                ]}
            >
                <InputCharacterCount max={50} />
            </Form.Item>

            <div className="actions">
                <Button onClick={() => closeEditForm()} style={{marginRight: '8px'}}>
                    {t('button.text.cancel')}
                </Button>
                <Button htmlType="submit" type="primary">
                    <CheckOutlined/>
                    {t('button.text.save')}
                </Button>
            </div>
        </Form>
    );
};

export default LogoForm;
