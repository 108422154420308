import React, {useEffect} from 'react';
import {Card, Col, DatePicker, Radio, Row, Switch, TreeSelect} from "antd";
import {useTranslation} from "react-i18next";
import {CheckOutlined, CloseOutlined} from "@ant-design/icons";
import moment from "moment";
import {useCategories, useSmallDeviceSize} from "hooks";

const {RangePicker} = DatePicker;

const FilterCard = ({
        period = 'day',
        startDate,
        endDate,
        showChart = false,
        showChartToggle = true,
        showGroupByDayWeekMonth = true,
        toggleChart = show => {},
        changePeriod = e => {},
        changeDateRange = dates => {},
        categorySelected = e => {},
    }) => {
    const { t } = useTranslation('common');

    const {
        getCategories,
        categories
    } = useCategories();

    useEffect(() => {
        getCategories();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    let treeSelectWidth = '50%';
    if (useSmallDeviceSize()) {
        treeSelectWidth = '100%';
    }

    return <Card
        size="small"
        title={t('pages.statistics.filter-settings.title')}
    >
        <Row gutter={[16, 16]}>
            <Col sm={24} md={8} style={{ width: '100%' }}>
                <TreeSelect
                    showSearch
                    style={{ width: treeSelectWidth }}
                    listHeight={500}
                    dropdownStyle={{ maxHeight: 500, overflow: 'auto' }}
                    placeholder={t('treeselect.placeholder')}
                    allowClear
                    autoClearSearchValue
                    multiple
                    onChange={categorySelected}
                    treeData={categories}
                    treeNodeFilterProp="title"
                />
            </Col>
            {showGroupByDayWeekMonth && (
                <Col sm={24} md={8} style={{textAlign: 'right'}}>
                    <Radio.Group defaultValue={period} onChange={changePeriod}>
                        <Radio.Button value="day">{t('units.time.day')}</Radio.Button>
                        <Radio.Button value="week">{t('units.time.week')}</Radio.Button>
                        <Radio.Button value="month">{t('units.time.month')}</Radio.Button>
                    </Radio.Group>
                </Col>
            )}
            <Col sm={24} md={{span: showGroupByDayWeekMonth ? 8 : 16}} align="right">
                <RangePicker format="DD-MM-YYYY" inputReadOnly={true} defaultValue={[startDate, endDate]} onChange={changeDateRange} ranges={
                    {
                        [t('ranges.dates.this-month')] : [moment().startOf('month'), moment().endOf('month')],
                        [t('ranges.dates.previous-month')]: [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                        [t('ranges.dates.this-year')]: [moment().startOf('year'), moment().endOf('year')],
                        [t('ranges.dates.previous-year')]: [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')],
                    }
                } />
            </Col>
        </Row>
        {showChartToggle && (
        <Row gutter={[16, 16]}>
            <Col sm={16} md={8}>
                <Switch
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    defaultChecked={showChart}
                    onChange={(show, event) => {
                        toggleChart(show);
                    }}
                /> <label>{t('pages.statistics.filter-settings.show-graph')}</label>
            </Col>
        </Row>
        )}
    </Card>;

};

export default FilterCard
