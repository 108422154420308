import client from "services/HurenApiClient";
import * as Types from "./Types";
import {clearCart} from "../advertisementsCart/Actions";

export const setIsDataLoaded = (loaded) => {
    return {
        type: Types.SET_IS_DATA_LOADED,
        isDataLoaded: loaded
    };
};

export const setIsEditFormSaving = (saving) => {
    return {
        type: Types.SET_IS_EDIT_FORM_SAVING,
        isEditFormSaving: saving
    };
};
export const closeEditForm = () => {
    return {
        type: Types.EDIT_FORM_CLOSE
    };
};

export const openEditForm = () => {
    return {
        type: Types.EDIT_FORM_OPEN
    };
};

export const setData = (payload) => {
    return {
        type: Types.SET_DATA,
        payload: payload
    };
};

export const setEditFormData = (payload) => {
    return {
        type: Types.SET_EDIT_FORM_DATA,
        payload: payload
    };
};

export const setIsEditFormDataLoaded = (loaded) => {
    return {
        type: Types.SET_IS_EDIT_FORM_DATA_LOADED,
        isEditFormDataLoaded: loaded
    };
};

export const getEditFormData = (companyId, advertisementId) => {
    return async function(dispatch) {
        dispatch(setIsEditFormDataLoaded(false));

        return client.get('/company/' + companyId + '/advertisements/' + advertisementId).then(res => {
            dispatch(setEditFormData(res.data));
        });
    }
};

export const createAdvertisements = (companyId, advertisements) => {
    return async function (dispatch) {
        return client.post('/company/' + companyId + '/advertisements', {
            "advertisements": advertisements
        }).then(res => {
            dispatch(clearCart());
        });
    };
};

export const putAdvertisement = (companyId, advertisementId, data) => {
    return async function (dispatch) {
        dispatch(setIsEditFormSaving(true));
        return client.put('/company/' + companyId + '/advertisements/' + advertisementId, {
            "advertisement_form" : data
        }).then(res => {
            dispatch(setIsEditFormSaving(false));
        }).catch((error) => {
            dispatch(setIsEditFormSaving(false));
        });
    };
};

export const editAdvertisement = (companyId, advertisementId) => {
    return async function (dispatch) {
        getEditFormData(companyId, advertisementId)(dispatch);
        dispatch(openEditForm());
    };
};

export const getAdvertisements = (companyId) => {
    return async function (dispatch) {
        dispatch(setIsDataLoaded(false));

        return client.get('/company/' + companyId + '/advertisements').then(res => {
            dispatch(setData(res.data));
        });
    };
};
