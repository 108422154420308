import React, { useEffect, useState } from "react";
import { CheckOutlined } from "@ant-design/icons";
import {
  Button,
  Input,
  Select,
  notification,
  Form,
  Checkbox,
  InputNumber,
} from "antd";
import { useTranslation } from "react-i18next";
import { useFeatures } from "hooks";
import { useAccounts } from "hooks";
import styled from "styled-components";

const Group = styled.div`
  margin-bottom: 1rem;

  .ant-descriptions-title {
    margin-bottom: 0.5rem;
  }
`;

export const CheckboxGroup = styled(Checkbox.Group)`
  .ant-checkbox-group-item {
    display: block;
  }
`;

const FeaturesForm = () => {
  const { t } = useTranslation("common");
  const { activeAccount } = useAccounts();
  const [fields, setFields] = useState({});
  const [form] = Form.useForm();

  const {
    closeEditForm,
    putCompanyFeatures,
    payload: { all_features, selected_features },
  } = useFeatures();

  const resetFields = () => {
    form.resetFields();
    setFields(selected_features || {});
  };

  useEffect(() => {
    if (selected_features) {
      resetFields();
    }
  }, [selected_features]);

  const onChange = (key, value) => {
    setFields({ ...fields, [key]: value });
  };

  const onGroupChange = (key, value) => {
    // value is a group checkbox boolean value
    if (value) {
      // we turned the group on
      // based on field type we set the default field value
      let newVal = "";
      if (
        all_features?.properties[key].type === "array" ||
        (all_features?.properties[key].type === "string" &&
          all_features?.properties[key].hasOwnProperty("enum"))
      )
        newVal = [];
      else if (all_features?.properties[key].type === "number")
        newVal = 0;
      setFields((prev) => ({ ...prev, [key]: newVal }));
    } else {
      // we turned the group off so we clear this group key from fields
      setFields({
        ...Object.entries(fields).reduce(
          (prev, [k, v]) => (k !== key ? { ...prev, [k]: v } : prev),
          {}
        ),
      });
    }
  };

  const onFinish = () => {
    putCompanyFeatures(activeAccount, fields)
      .then(() => {
        notification.open({
          message: t("notification.messages.saved.title"),
          description: t("notification.messages.saved.description"),
        });
        closeEditForm();
      })
      .catch(() => {
        notification.open({
          message: t("notification.messages.error.title"),
          description: t("notification.messages.error.description"),
        });
        closeEditForm();
      });
  };

  return (
    <Form form={form} onFinish={onFinish} initialValues={fields}>
      {all_features?.properties &&
        Object.entries(all_features.properties).map(
          ([key, value]) => {
            let field = <></>;
            if (value.type === "array" && value?.items?.enum) {
              field = (
                <CheckboxGroup
                  onChange={(values) => onChange(key, values)}
                  // value={fields[key] || []}
                  options={value.items.enum}
                  disabled={!fields.hasOwnProperty(key)}
                />
              );
            } else if (value.type === "string" && value.enum) {
              field = (
                <Select
                  style={{ width: "100%" }}
                  value={fields[key]}
                  disabled={!fields.hasOwnProperty(key)}
                  onSelect={(value) => onChange(key, value)}
                >
                  {value.enum.map((option) => (
                    <Select.Option key={key + option} value={option}>
                      {option}
                    </Select.Option>
                  ))}
                </Select>
              );
            } else if (value.type === "string" && !value.enum) {
              field = (
                <Input
                  style={{ width: "100%" }}
                  value={fields[key] || ""}
                  disabled={!fields.hasOwnProperty(key)}
                  onChange={(e) => onChange(key, e.target.value)}
                />
              );
            } else if (value.type === "number" && !value.enum) {
              field = (
                <InputNumber
                  style={{ width: "100%" }}
                  value={fields[key] || 0}
                  disabled={!fields.hasOwnProperty(key)}
                  onChange={(value) => onChange(key, value)}
                />
              );
            } else if (value.type === "boolean") {
              field = (
                <Select
                  style={{ width: "100%" }}
                  value={fields[key]}
                  disabled={!fields.hasOwnProperty(key)}
                  onSelect={(value) => onChange(key, value)}
                >
                  <Select.Option value={true}>
                    {t("yes-no.yes")}
                  </Select.Option>
                  <Select.Option value={false}>
                    {t("yes-no.no")}
                  </Select.Option>
                </Select>
              );
            }

            return (
              <Group key={key}>
                <div className="ant-descriptions-title">
                  <Checkbox
                    checked={fields.hasOwnProperty(key)}
                    style={{ marginRight: "0.5rem" }}
                    onChange={(e) =>
                      onGroupChange(key, e.target.checked)
                    }
                  />
                  {value.title}
                </div>
                <Form.Item
                  rules={[
                    {
                      required: fields.hasOwnProperty(key),
                      message: t("form.errors.required"),
                    },
                  ]}
                  key={key}
                  name={key}
                >
                  {field}
                </Form.Item>
              </Group>
            );
          }
        )}
      <div className="actions">
        <Button
          onClick={() => {
            resetFields();
            form.resetFields();
            closeEditForm();
          }}
          style={{ marginRight: "8px" }}
        >
          {t("button.text.cancel")}
        </Button>
        <Button htmlType="submit" type="primary">
          <CheckOutlined />
          {t("button.text.save")}
        </Button>
      </div>
    </Form>
  );
};

export default FeaturesForm;
