import React from 'react';
import {
    Row,
    Col,
    Statistic,
    Divider,
    Progress
} from "antd";
import {Money} from '../Money';
import {InfoPopover} from "../InfoPopover";
import {useTranslation} from "react-i18next";

const DailyBudgetInfo = ({unlimited_budget, current_daily_limit, budget_used_today}) => {
    const {t} = useTranslation('common');

    const leftover = current_daily_limit - budget_used_today;
    const percent = parseInt((budget_used_today / current_daily_limit) * 100);

    return <>
        <Row>
            <Col span={22}>
                <Statistic
                    title={t('components.daily-budget-info.current-daily-limit')}
                    value={unlimited_budget ? t('components.daily-budget-info.no-daily-limit') : current_daily_limit}
                    precision={2}
                    prefix={unlimited_budget ? '' : "€"}
                    decimalSeparator=","
                    groupSeparator=""
                />
            </Col>
            <Col span={2} align="right">
                <InfoPopover
                    content={t('components.daily-budget-info.info-popover.content')}
                />
            </Col>
        </Row>
        {!unlimited_budget ?
            <>
                <Divider/>
                <Progress percent={percent} strokeColor="#ff7800"/>
                <Row style={{marginTop: '5px'}}>
                    <Col span={12}>
                        <label>{t('components.daily-budget-info.used')}:</label>&nbsp;
                        <Money value={budget_used_today} />
                    </Col>
                    <Col span={12} style={{textAlign: 'right'}}>
                        <label>{t('components.daily-budget-info.leftover')}:</label>&nbsp;
                        <Money value={leftover}/>
                    </Col>
                </Row>
            </>
            :
            <></>
        }
    </>;
};

export default DailyBudgetInfo;


