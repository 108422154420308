import React, {useState} from "react";
import {Button, Dropdown, Menu, Pagination, Table, Drawer, Space, Modal, notification} from "antd";
import {OfferDetails} from "./OfferDetails";
import {useTranslation} from "react-i18next";
import Media from "react-media";
import {useSmallDeviceSize} from "hooks";
import useFormatting from "../../../hooks/useFormatting";
import {navigate} from "@reach/router";

import {EditOutlined, DeleteOutlined, EllipsisOutlined, InteractionOutlined} from "@ant-design/icons";
import {canDeleteOffer, canEditOffer, isInTerminalState} from "../../../helpers/offers";
import OfferTransitionForm from "../../../components/OfferTransitionForm/OfferTransitionForm";
import {OfferStatusTag} from "../../../components/OfferStatusTag";
import {useDeleteOffer} from "../../../queries/offers";
import {LegacyOfferPrice} from "../../../components/OfferForm/LegacyOfferPrice";

export const OffersTable = ({offers, loading, showActions}) => {
    const { t } = useTranslation('common');
    const { formatCents, formatDateTime, formatDate } = useFormatting();
    const deleteOffer = useDeleteOffer();


    const [statusDrawerData, setStatusDrawerData] = useState({
        visible: false,
        offer: null
    });

    const openStatusDrawer = (offer) => {
        setStatusDrawerData({
            visible: true,
            offer: offer,
        });
    };

    const onFinishOfferTransitionForm = (transition) => {
        closeStatusDrawer();
    };

    const closeStatusDrawer = () => {
        setStatusDrawerData(prevState => {
            return { ...prevState, visible: false};
        });
    };

    const isSmallDevice = useSmallDeviceSize();

    const statusButton = (record) => {
        return <Button size={isSmallDevice ? 'medium' : 'small'} disabled={isInTerminalState(record)} onClick={(e) => { openStatusDrawer(record);  if (!isSmallDevice) {e.stopPropagation();} }}>
            <InteractionOutlined/> {t('entities:offer.actions.change-status')}
        </Button>
    };

    const tableColumns = [
        {
            title: t('entities:offer.properties.id'),
            dataIndex: 'id',
            hideOnResponsive: true,
            key: 'id',
            ellipsis: true
        },
        {
            title: t('entities:offer.properties.opened-at'),
            dataIndex: 'opened_at',
            hideOnResponsive: true,
            key: 'opened_at',
            ellipsis: true,
            sorter: (a, b) => ('' + a.opened_at).localeCompare(b.opened_at),
            render: (text, record) => record.opened_at ?
                <>{formatDateTime(record.opened_at)}</> :
                <>-</>
        },
        {
            title: t('entities:offer.properties.expires-at'),
            dataIndex: 'opened_at',
            hideOnResponsive: true,
            key: 'opened_at',
            ellipsis: true,
            sorter: (a, b) => ('' + a.expires_at).localeCompare(b.expires_at),
            render: (text, record) => record.expires_at ?
                <>{formatDate(record.expires_at)} ({t('entities:offer.validity-periods.' + record.validity_period)})</> :
                <>-</>
        },
        {
            title: t('entities:offer.properties.price-excl'),
            dataIndex: 'price_excl',
            key: 'price_excl',
            hideOnResponsive: true,
            ellipsis: true,
            render: priceExcl => priceExcl ? formatCents(priceExcl) : <LegacyOfferPrice />
        },
        {
            title: t('entities:offer.properties.price-incl'),
            dataIndex: 'price_incl',
            key: 'price_incl',
            ellipsis: true,
            render: priceIncl => priceIncl ? formatCents(priceIncl) : <LegacyOfferPrice />
        },
        {
            title: t('entities:offer.properties.status'),
            dataIndex: 'status',
            key: 'status',
            ellipsis: true,
            sorter: (a, b) => ('' + a.status).localeCompare(b.status),
            render: (text, offer) => {
                if (offer.status) {
                    return <Space direction="vertical" style={{width: '100%'}}>
                        <OfferStatusTag status={offer.status} />
                        { (!isSmallDevice) ? statusButton(offer) : <></> }
                    </Space>;
                }
            }
        },
    ];

    const openOfferDeleteModal = (offer) => {
        Modal.confirm({
            title: t('components:offer-delete-modal.title'),
            content: t('components:offer-delete-modal.content'),
            okText: t('components:offer-delete-modal.ok-text'),
            cancelText: t('components:offer-delete-modal.cancel-text'),
            onOk: async () => {
                await deleteOffer.mutateAsync({
                    offerId: offer.id,
                    rentalMatchId: offer.rental_match.id,
                });
                notification.success({
                    message: t('components:offer-delete-modal.success-notification.message')
                });
            },
        });
    };

    if (showActions) {

        const editButton = (record) => {
            return <Button
                disabled={!canEditOffer(record)}
                onClick={(e) => {
                    e.stopPropagation();
                    navigate(`/rental-matches/${record.rental_match.id}/offers/${record.id}/edit`)
                }}
            >
                <EditOutlined/>{isSmallDevice && ' ' + t('entities:offer.actions.edit')}
            </Button>
        };

        const deleteButton = (record) => {
            return <Button
                danger
                disabled={!canDeleteOffer(record)}
                onClick={(e) => { e.stopPropagation(); openOfferDeleteModal(record); }}
            >
                <DeleteOutlined/>{isSmallDevice && ' ' + t('entities:offer.actions.delete')}
            </Button>
        };

        const mobileActionsContainer = (record) => {
            const menu = {
                items: [
                    {
                        label: editButton(record),
                    },
                    {
                        label: statusButton(record),
                    },
                    {
                        label: deleteButton(record),
                    }
                ]
            };

            return <>
                <Dropdown menu={menu} placement="bottomRight" trigger={['click']}>
                    <Button style={{float: 'right'}} onClick={e => e.stopPropagation()}><EllipsisOutlined /></Button>
                </Dropdown>
            </>;
        }

        const desktopActionsContainer = (record) => {
            return <Space direction="vertical" justify="end" style={{width: '100%', alignItems: 'end'}}>
                {editButton(record)}
                {deleteButton(record)}
            </Space>;
        }


        tableColumns.push({
            title: t('tables.common.column.actions'),
            dataIndex: 'actions',
            key: 'actions',
            render: (value, record) => {
                return <div className="actions">
                    { (!isSmallDevice) ? desktopActionsContainer(record) : mobileActionsContainer(record) }
                </div>
            }
        });
    }

    let drawerWidth = 520;
    if (isSmallDevice) {
        drawerWidth = '100%';
    }

    return (
        <>
            <Drawer
                title={t('components:offer-transition-drawer.title')}
                placement="right"
                open={statusDrawerData.visible}
                width={drawerWidth}
                layout="vertical"
                onClose={closeStatusDrawer}
            >
                {statusDrawerData.offer !== null && <OfferTransitionForm onFinish={onFinishOfferTransitionForm} offer={statusDrawerData.offer}/>}
            </Drawer>

            <Media query="(min-width: 950px)">
                {matches => {
                    let filteredColumns = tableColumns.filter((column) => {
                        return matches || column.hideOnResponsive !== true;
                    });

                    return <Table
                        layout="fixed"
                        dataSource={offers}
                        columns={filteredColumns}
                        loading={loading}
                        rowKey="id"
                        expandable={{
                            expandedRowRender: offer => {
                                return <OfferDetails offer={offer}></OfferDetails>
                            },
                            rowExpandable: offer => true,
                            expandRowByClick: true
                        }}
                        pagination={<Pagination
                            total={offers?.length}
                            pageSize={10}
                            showLessItems={true}
                        />}

                    />
                }}
            </Media>
        </>
    );
}