import {useQuery} from "react-query";
import client from "services/HurenApiClient";
import {createQueryString} from "../helpers/queryString";

const getAccounts = (queryData) => async () => {
    let url = '/api/v2/accounts';
    if (queryData) {
        url += '?' + createQueryString(queryData, '');
    }
    const res = await client.get(url);
    return res.data;
};



export const useGetAccounts = (collectionParameters, options) => {
    const createQueryData = () => {
        const queryData = {
            page: collectionParameters.page,
            limit: collectionParameters.limit,
            filters: {
                group_code: collectionParameters.filters.group_code ?? ''
            },
            searchQuery: collectionParameters.searchQuery ?? ''
        }
        if (collectionParameters.sort) {
            queryData.sort = collectionParameters.sort
        }
        return queryData;
    };

    const queryData = createQueryData();
    return useQuery(['accounts', queryData], getAccounts(queryData), options)
}
 const getAccountGroups = () => async () => {
    let url = '/api/v2/account-groups';
    const res = await client.get(url);
    return res.data;
};

export const useGetAccountGroups = () => {
    return useQuery('account-groups', getAccountGroups())
}
