import React from "react";
import {useTranslation} from 'react-i18next';
import {Badge, Button, Col, Row, Table} from "antd";
import moment from "moment";
import Media from "react-media";
import useFormatting from "../../hooks/useFormatting";
import { QuotationRentalRequestInformationCard } from "./QuotationRentalRequestInformationCard";
import {QuotationRenterInformationCard} from "./QuotationRenterInformationCard";
import {QuotationExtraInformationCard} from "./QuotationExtraInformationCard";
import {QuotationStatusTag} from "../QuotationStatusTag";
import {useChat} from "../../hooks";
import {useFetchStatus} from "../../queries/me";
import {MessageOutlined} from "@ant-design/icons";

export const QuotationCard = (props) => {

    const { t } = useTranslation('common');
    const { humanizer, formatDateTime } = useFormatting();
    const { data: status } = useFetchStatus();
    const { doOpenChat } = useChat();

    const quotation = props.quotation;
    const rentalMatch = props.rentalMatch;

    let unreadMessagesCount = 0;
    if (rentalMatch && status && status.rental_matches) {
        let rentalMatchStatus = status.rental_matches.find(obj => {
            return obj.id === rentalMatch.id
        });
        if (rentalMatchStatus) {
            unreadMessagesCount = rentalMatchStatus.unread_messages_count;
        }
    }

    const tableColumns = [
        {
            title: t('tables.quotation.column.created-at'),
            dataIndex: 'created_at',
            key: 'created_at',
            ellipsis: true,
            hideOnResponsive: true,
            render: (text) => text ?
                <>{formatDateTime(text)}</> :
                <></>
        },
        {
            title: t('tables.quotation.column.name'),
            dataIndex: 'first_name',
            key: 'quotation.first_name',
            hideOnResponsive: true,
            render: (text, record) => record.first_name + ' ' + record.last_name
        },
        {
            title: t('tables.quotation.column.product'),
            dataIndex: 'clickbox_category_name',
            key: (text, record) => record.rental_item ? 'rental_item.name' : 'clickbox_category.title',
            render: (text, record) => record.rental_item ? record.rental_item.name : record.clickbox_category.title
        },
        {
            title: t('tables.quotation.column.rental-date'),
            dataIndex: 'date_from',
            key: 'date_from',
            render: (text) => text
                ? <>{formatDateTime(text)}</> :
                <>-</>
        },
        {
            title: t('tables.quotation.column.rental-duration'),
            dataIndex: 'rental_duration',
            key: 'rental_duration',
            render: (text, quotation) => {
                if (quotation.date_from === null || quotation.date_until === null) {
                    return t('entities:quotation.for-unknown-rental-duration');
                }
                return humanizer(moment(quotation.date_from).diff(moment(quotation.date_until)));
            }
        },
        {
            title: t('tables.quotation.column.status'),
            dataIndex: 'status',
            key: 'status',
            hideOnResponsive: true,
            render: (text, record) => {
                return <QuotationStatusTag quotation={record} />
            }
        },
        {
            title: '',
            align: 'right',
            render: (text, record) => {
                return <Button
                    disabled={!rentalMatch || !rentalMatch.conversation}
                    size="middle"
                    onClick={(e) => {
                        e.stopPropagation();
                        doOpenChat(rentalMatch.conversation.id);
                    }}
                >
                    <Badge size="small" count={unreadMessagesCount}>
                        <MessageOutlined/>
                    </Badge>
                </Button>;
            }
        }

    ];

    return <Media query="(min-width: 950px)">
        {matches => {
            let filteredColumns = tableColumns.filter((column) => {
                return matches || column.hideOnResponsive !== true;
            });

            return <Table
                loading={!quotation}
                dataSource={quotation ? [quotation] : []}
                columns={filteredColumns}
                pagination={false}
                rowKey="id"
                expandable={{
                    expandedRowRender: record => {
                        return <>
                            <Row gutter={[16, 16]}>
                            <Col md={12} sm={24}>
                                <QuotationRenterInformationCard quotation={quotation}/>
                            </Col>
                            <Col md={12} sm={24}>
                                <QuotationRentalRequestInformationCard rentalMatch={rentalMatch} quotation={quotation}/>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12} sm={24}>
                                <QuotationExtraInformationCard quotation={quotation}/>
                            </Col>
                        </Row>
                    </>
                    },
                    rowExpandable: record => true,
                    expandRowByClick: true
                }}></Table>
        }}
    </Media>
}