import React, {useEffect, useRef} from 'react';
import {useTranslation} from "react-i18next";
import {useAccounts, useCancellation, useDailyBudget, useSubscription} from "hooks";
import {Button, Card, Descriptions} from "antd";
import OriginalTextSkeleton from "components/TextSkeleton";
import {Money} from "components/Money";
import moment from "moment";
import {EditOutlined} from "@ant-design/icons";
import {ContactForm} from "components/ContactForm";
import {hasPermission} from "helpers";
import CancellationForm from "./CancellationForm";
import {useGetSubscriptionFeatures} from "../../../../queries/subscription";

const TextSkeleton = props => {
    const {
        isSubscriptionDataLoaded,
    } = useSubscription();
    return <OriginalTextSkeleton {...props} loading={!isSubscriptionDataLoaded}/>
}

const SubscriptionCard = () => {
    const {t} = useTranslation('common');
    const {activeAccount} = useAccounts();

    const {
        getSubscriptionData,
        isSubscriptionDataLoaded,
        payload: {
            error,
            description,
            price_excl,
            periods,
            periodic,
            start_date,
            end_date,
            termination_date,
            plan_name
        }
    } = useSubscription();

    const { openCancellationForm } = useCancellation();

    const { data: subscriptionFeatures } = useGetSubscriptionFeatures();

    const maxActiveLocations = subscriptionFeatures?.max_active_locations;
    const maxActiveRentalItems = subscriptionFeatures?.max_active_rental_items;

    const {
        getBudgetData,
        isDataLoaded,
        payload: {
            minimum_daily_budget
        }
    } = useDailyBudget();

    const contactFormRef = useRef();

    useEffect(() => {
        getSubscriptionData(activeAccount.companyId)
        getBudgetData(activeAccount.companyId)
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const openContactForm = () => {
        contactFormRef.current.openForm();
    }



    return <>
        <ContactForm ref={contactFormRef} title={t('forms.contact-form.title.change-subscription')} type="subscription" />
        <CancellationForm />

            <Card
                loading={!isSubscriptionDataLoaded}
                size="small"
                className="subscription-card"
                title={t('pages.advertisements.subscription-card.title')}
                actions={[
                    <Button disabled={!hasPermission(activeAccount, 'manage_subscription', 'edit')} onClick={() => openContactForm()}>
                        <EditOutlined/> {t('button.text.change-subscription')}
                    </Button>,
                    <Button disabled={!hasPermission(activeAccount, 'manage_subscription', 'edit') || termination_date !== null} onClick={() => openCancellationForm()}>
                        <EditOutlined />
                        {t('button.text.cancellation')}
                    </Button>
                ]}
            >

                {error === null ?
                <Descriptions column={1} className="label-50-percent">
                    <Descriptions.Item key={1}
                                       label={t('pages.advertisements.subscription-card.label.subscription-type.label')}>
                        <TextSkeleton>{plan_name}</TextSkeleton>
                    </Descriptions.Item>
                    <Descriptions.Item key={1}
                                       label={t('pages.advertisements.subscription-card.label.max-active-locations.label')}>
                        <OriginalTextSkeleton>{maxActiveLocations}</OriginalTextSkeleton>
                    </Descriptions.Item>
                    <Descriptions.Item key={1}
                                       label={t('pages.advertisements.subscription-card.label.max-active-rental-items.label')}>
                        <OriginalTextSkeleton>{maxActiveRentalItems}</OriginalTextSkeleton>
                    </Descriptions.Item>
                    <Descriptions.Item key={1}
                                       label={t('pages.advertisements.subscription-card.label.subscription-costs.label')}>
                        <TextSkeleton><Money value={price_excl}/></TextSkeleton>
                    </Descriptions.Item>
                    <Descriptions.Item key={2}
                                       label={t('pages.advertisements.subscription-card.label.minimal-daily-limit.label')}>
                        <OriginalTextSkeleton loading={!isDataLoaded}><Money value={minimum_daily_budget}/></OriginalTextSkeleton>
                    </Descriptions.Item>
                    <Descriptions.Item key={3} label={t('pages.advertisements.subscription-card.label.period.label')}>
                        <TextSkeleton>{periods} {t('pages.advertisements.subscription-card.label.period.periods.' + periodic)}</TextSkeleton>
                    </Descriptions.Item>
                    <Descriptions.Item key={4}
                                       label={t('pages.advertisements.subscription-card.label.start-date.label')}>
                        <TextSkeleton>{moment(start_date).format('DD MMM YYYY')}</TextSkeleton>
                    </Descriptions.Item>
                    <Descriptions.Item key={5} label={t('pages.advertisements.subscription-card.label.end-date.label')}>
                        <TextSkeleton>{moment(end_date).format('DD MMM YYYY')}</TextSkeleton>
                    </Descriptions.Item>
                    <Descriptions.Item key={6}
                                       label={t('pages.advertisements.subscription-card.label.notice-period.label')}>
                        <TextSkeleton>{t('pages.advertisements.subscription-card.label.notice-period.before')} {moment(end_date).subtract(1, 'month').format('DD MMM YYYY')} {t('pages.advertisements.subscription-card.label.notice-period.month')}</TextSkeleton>
                    </Descriptions.Item>
                    <Descriptions.Item key={7} label={t('pages.advertisements.subscription-card.label.status.label')}>
                        <TextSkeleton>{termination_date !== null ? t('pages.advertisements.subscription-card.label.status.inactive', { 'date': termination_date}) : t('pages.advertisements.subscription-card.label.status.active')}</TextSkeleton>
                    </Descriptions.Item>
                    <Descriptions.Item key={8}
                                       label={t('pages.advertisements.subscription-card.label.description.label')}>
                        <TextSkeleton>{description}</TextSkeleton>
                    </Descriptions.Item>
                </Descriptions> : <TextSkeleton>{t('pages.advertisements.subscription-card.no-subscription')}</TextSkeleton> }
            </Card>
    </>
}

export default SubscriptionCard;