import * as Types from "./Types";

export const initialState = {
  isDataLoaded: false,
  payload: {},
};

export default function (state, action) {
  switch (action.type) {
    case Types.SET_IS_DATA_LOADED:
      return {
        ...state,
        isDataLoaded: action.isDataLoaded,
      };
    case Types.SET_DATA:
      return {
        ...state,
        payload: action.payload,
        isDataLoaded: true,
      };
    default:
      return state;
  }
}
