import React, { useEffect, useState } from "react";
import LoggedInLayout from "layout/LoggedInLayout";
import { useTranslation } from "react-i18next";
import { Button, Card, Dropdown, Menu, Table } from "antd";
import { FilterCard } from "./components/FilterCard";
import { ProductsTotals } from "./components/Totals";
import { InfoPopover } from "components/InfoPopover";
import { useAccounts, useStatistics } from "hooks";
import moment from "moment";
import Media from "react-media";
import { saveAs } from "file-saver";
import client from "services/HurenApiClient";
import {
  DownloadOutlined,
  DownOutlined,
  FileExcelOutlined,
  FilePdfOutlined,
} from "@ant-design/icons";
import { getStatisticsUrl, b64toBlob } from "helpers";

const ProductsStatistics = () => {
  const { t } = useTranslation("common");
  const { activeAccount } = useAccounts();
  const [filters, setFilters] = useState({
    categories: [],
    startDate: moment().subtract(1, "month"),
    endDate: moment(),
  });

  const {
    getProductsStatistics,
    getProductsTotals,
    isTotalsDataLoaded,
    isTotalsDataError,
    payload: { rows },
    totals,
  } = useStatistics();

  const changeDateRange = (dates) => {
    if (dates != null) {
      setFilters({
        ...filters,
        startDate: dates[0],
        endDate: dates[1],
      });
    }
  };

  const categorySelected = (categories) => {
    setFilters({ ...filters, categories: categories });
  };

  useEffect(() => {
    getProductsStatistics(
      activeAccount.companyId,
      filters.startDate,
      filters.endDate,
      filters.categories
    );
    getProductsTotals(
      activeAccount.companyId,
      filters.startDate,
      filters.endDate,
      filters.categories
    );
  }, [filters]); // eslint-disable-line react-hooks/exhaustive-deps

  const download = (type) => {
    const url = getStatisticsUrl(
      "product/download",
      activeAccount.companyId,
      filters.period,
      filters.startDate,
      filters.endDate,
      filters.categories
    );
    const contentType =
      type === "pdf"
        ? "application/pdf;charset=utf-8"
        : "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    client.get(url + "&type=" + type + "&stats=product").then((res) => {
      let blob = b64toBlob(res.data.file, contentType);
      saveAs(blob, res.data.filename);
    });
  };

  const tableHeader = (title, hint) => (
    <>
      {title}
      <InfoPopover content={hint} />
    </>
  );

  const tableColumns = [
    {
      title: t("pages.statistics.products.table.product"),
      dataIndex: "product",
      key: "product",
      sorter: (a, b) => ("" + a.product).localeCompare(b.product),
    },
    {
      title: tableHeader(
        t("pages.statistics.products.table.quotations"),
        t("pages.statistics.products.table.hint.quotations")
      ),
      dataIndex: "quotations",
      key: "quotations",
      hideOnResponsive: true,
      sorter: (a, b) => a.quotations - b.quotations,
    },
    {
      title: tableHeader(
        t("pages.statistics.products.table.product_calls"),
        t("pages.statistics.products.table.hint.product_calls")
      ),
      dataIndex: "product_calls",
      key: "product_calls",
      hideOnResponsive: true,
      sorter: (a, b) => a.product_calls - b.product_calls,
    },
  ];

  var dataTable;
  if (isTotalsDataLoaded && !isTotalsDataError) {
    dataTable = (
      <Media query="(min-width: 950px)">
        {(matches) => {
          let filteredColumns = tableColumns.filter((column) => {
            return matches || column.hideOnResponsive !== true;
          });
          return (
            <Table
              dataSource={rows}
              rowKey="product"
              columns={filteredColumns}
              pagination={{
                showSizeChanger: true,
              }}
            />
          );
        }}
      </Media>
    );
  } else {
    dataTable = <>{t("pages.statistics.products.table.error")}</>;
  }

  const exportDropdownMenu = {
    items: [
      {
        key: '1',
        label: (
            <a onClick={() => download("pdf")}>
              <FilePdfOutlined style={{ marginRight: "5px" }} />
              PDF document
            </a>
        )
      },
      {
        key: '2',
        label: (
            <a onClick={() => download("excel")}>
              <FileExcelOutlined style={{ marginRight: "5px" }} />
              Excelsheet
            </a>
        )
      }
    ]
  };

  return (
    <LoggedInLayout
      pageTitle={t("pages.statistics.products.title")}
      headerExtras={[
        <Dropdown
          disabled={!(isTotalsDataLoaded && !isTotalsDataError)}
          key="export"
          menu={exportDropdownMenu}
        >
          <Button>
            <DownloadOutlined />
            Exporteer
            <DownOutlined />
          </Button>
        </Dropdown>,
      ]}
    >
      <FilterCard
        startDate={filters.startDate}
        endDate={filters.endDate}
        showChart={false}
        showChartToggle={false}
        showGroupByDayWeekMonth={false}
        changeDateRange={changeDateRange}
        categorySelected={categorySelected}
      />

      <br />

      <ProductsTotals stats={totals} loading={!isTotalsDataLoaded} />

      <br />

      <Card
        size="small"
        title={t("pages.statistics.table.title")}
        className="full-table"
      >
        {dataTable}
      </Card>
    </LoggedInLayout>
  );
};

export default ProductsStatistics;
