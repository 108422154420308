import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import common_en from "./en/common";
import entities_en from "./en/entities";
import components_en from "./en/components"
import pages_en from "./en/pages";
import notifications_en from "./en/notifications";
import common_nl from "./nl/common";
import entities_nl from "./nl/entities";
import components_nl from "./nl/components"
import pages_nl from "./nl/pages";
import notifications_nl from "./nl/notifications";

i18n
    .use(initReactI18next)
    .init({
        lng: 'nl',
        fallbackLng: 'en',
        debug: process.env.NODE_ENV === 'development',
        react: {
            transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'ol', 'ul', 'li'],
        },
        resources: {
            en: {
                common: common_en,
                entities: entities_en,
                components: components_en,
                pages: pages_en,
                notifications: notifications_en,
            },
            nl: {
                common: common_nl,
                entities: entities_nl,
                components: components_nl,
                pages: pages_nl,
                notifications: notifications_nl,
            }
        }
    });

export default i18n;
