import React from 'react'
import { useTranslation } from 'react-i18next'
import {Radio} from "antd";
import {useLocale} from "hooks";

const LanguageSelector = () => {
    const { i18n } = useTranslation()
    const {setLocale} = useLocale();

    const changeLanguage = (event) => {
        i18n.changeLanguage(event.target.value);
        setLocale(event.target.value);
    }

    return (
        <Radio.Group className="language-selector" onChange={changeLanguage} defaultValue="nl" size="small" buttonStyle="solid">
            <Radio.Button value="nl">NL</Radio.Button>
            <Radio.Button value="en">EN</Radio.Button>
        </Radio.Group>
    )
}

export default LanguageSelector;