import React from "react";
import {Descriptions, Skeleton} from "antd";
import Card from "components/Card";
import useFormatting from "../../hooks/useFormatting";
import {useTranslation} from "react-i18next";


export const QuotationRenterInformationCard = (props) => {

    const { t } = useTranslation('common');
    const { formatDateTime } = useFormatting();

    const quotation = props.quotation;

    const gender = quotation.gender ? quotation.gender : 0;

    return (
        <Card title={t('components.quotation-card.renter-information.title')}>
            {!quotation ?
                <Skeleton active={true} loading={true}/> :
                <Descriptions
                    column={1}
                    className="label-50-percent"
                >
                    <Descriptions.Item label={t('entities:quotation.properties.id')}>
                        {quotation.id}
                    </Descriptions.Item>
                    <Descriptions.Item label={t('entities:quotation.properties.created_at')}>
                        {formatDateTime(quotation.created_at)}
                    </Descriptions.Item>
                    <Descriptions.Item label={t('entities:quotation.properties.type')}>
                        {t('entities:quotation.types.type-' + quotation.type)}
                    </Descriptions.Item>
                    <Descriptions.Item label={t('entities:quotation.properties.company_name')}>
                        {quotation.company_name}
                    </Descriptions.Item>
                    <Descriptions.Item label={t('entities:quotation.properties.gender')}>
                        {t('salutations.gender-' + gender)}
                    </Descriptions.Item>
                    <Descriptions.Item label={t('entities:quotation.properties.first_name')}>
                        {quotation.first_name}
                    </Descriptions.Item>
                    <Descriptions.Item label={t('entities:quotation.properties.last_name')}>
                        {quotation.last_name}
                    </Descriptions.Item>
                    <Descriptions.Item label={t('entities:quotation.properties.phone')}>
                        {quotation.phone}
                    </Descriptions.Item>
                    <Descriptions.Item label={t('entities:quotation.properties.email')}>
                        {quotation.email}
                    </Descriptions.Item>
                </Descriptions>
            }
        </Card>
    );
}