import {useGlobalStore} from 'store';

import bindActions from 'store/bindActions';
import testOverlap from 'store/testOverlap';
import advertisementsCartReducer from 'store/advertisementsCart';

const { Actions } = advertisementsCartReducer;

const useAdvertisementsCart = () => {
    const {state, dispatch} = useGlobalStore();

    const {advertisementsCart} = state;

    // Test if there's an overlapping between the actions and store
    testOverlap(advertisementsCart, Actions);

    return {
        ...bindActions(Actions, dispatch),
        ...advertisementsCart,
    };
};

export default useAdvertisementsCart;
