import React from "react";
import {useTranslation} from "react-i18next";
import {Badge, Tag} from "antd";
import {InfoPopover} from "./InfoPopover";

const StatusInfo = () => {
    const {t} = useTranslation('common');

    return <InfoPopover
        content={<>
            <div>
                <Tag style={{marginBottom: '3px'}}>
                    <Badge color="green" text={t('badges.active')}/>
                </Tag>
            </div>

            <div>
                <Tag style={{marginBottom: '3px'}}>
                    <Badge color="red" text={t('badges.inactive')}/>
                </Tag>
            </div>
            
            <div>
                <Tag style={{marginBottom: '3px'}}>
                    <Badge color="orange" text={t('badges.no-budget')}/>
                </Tag>
            </div>
        </>}
    />;
};

export default StatusInfo;