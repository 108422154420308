import React, {useEffect} from 'react';
import {useTranslation} from "react-i18next";
import {useAccounts, useInvoices} from "hooks";
import {Button, Card, notification, Pagination, Table} from "antd";
import {saveAs} from "file-saver";
import Media from "react-media";
import {Money} from "components/Money";
import moment from "moment";
import client from "services/HurenApiClient";
import {b64toBlob} from "helpers";

const InvoicesCard = () => {
    const {t} = useTranslation('common');

    const {activeAccount} = useAccounts();
    const {
        getInvoices,
        isDataLoaded,
        payload: {
            invoices,
            total
        }
    } = useInvoices();

    useEffect(() => {
        getInvoices(activeAccount.companyId);
    }, [activeAccount]); // eslint-disable-line react-hooks/exhaustive-deps

    const downloadPDF = (identifier) => {
        client.get('/company/' + activeAccount.companyId + '/invoices/' + identifier + '/download').then(res => {
            let blob = b64toBlob(res.data.file, "application/pdf;charset=utf-8");
            saveAs(blob, res.data.filename);
        }).catch(e => {
            notification.open({
                message: t('notification.messages.invoice-not-found.title'),
                description: t('notification.messages.invoice-not-found.description'),
            });
        });
    }

    const tableColumns = [
        {
            title: t('pages.account.invoices-card.table.invoice-code'),
            dataIndex: 'invoice_code',
            key: 'invoice_code',
            ellipsis: true,
            render: (text, record) => <Button type="link" onClick={() => downloadPDF(record.identifier)}>{text}</Button>
        },
        {
            title: t('pages.account.invoices-card.table.price-excl'),
            dataIndex: 'price_excl',
            key: 'price_excl',
            ellipsis: true,
            hideOnResponsive: true,
            render: (text, record) => record.price_excl !== null ? <Money value={record.price_excl} /> : <></>
        },
        {
            title: t('pages.account.invoices-card.table.price-incl'),
            dataIndex: 'price_incl',
            key: 'price_incl',
            ellipsis: true,
            render: (text, record) => record.price_incl !== null ? <Money value={record.price_incl} /> : <></>
        },
        {
            title: t('pages.account.invoices-card.table.invoice-date'),
            dataIndex: 'invoice_date',
            key: 'invoice_date',
            ellipsis: true,
            render: (text, record) => moment(record.invoice_date).format('D-M-YYYY')
        },
        {
            title: t('pages.account.invoices-card.table.payment-method'),
            dataIndex: 'payment_method',
            key: 'payment_method',
            ellipsis: true,
            hideOnResponsive: true,
            render: (text, record) => {
                if (record.payment_url) {
                    return <a href={record.payment_url} target="_blank" rel="noreferrer noopener">{t('invoices.payment_url')}</a>
                } else {
                    return record.payment_method !== null ? t('invoices.payment.' + record.payment_method) : ''
                }
            }
        },
        {
            title: t('pages.account.invoices-card.table.status'),
            dataIndex: 'status',
            key: 'status',
            ellipsis: true,
            render: (text, record) => t('invoices.status.' + record.status)
        },
    ];

    return <Card
        size="small"
        className="company-invoices-card"
        title={t('pages.account.invoices-card.title')}
    >
        <Media query="(min-width: 950px)">
            {matches => {
                let filteredColumns = tableColumns.filter((column) => {
                    return matches || column.hideOnResponsive !== true;
                });

                return <Table
                    tableLayout="fixed"
                    dataSource={invoices}
                    columns={filteredColumns}
                    loading={!isDataLoaded}
                    rowKey="identifier"
                    pagination={(<Pagination
                        total={total}
                        pageSize={10}
                        showSizeChanger={true}
                        showLessItems={true}
                    />)}
                />
            }}
        </Media>
    </Card>
};

export default InvoicesCard;