import {useGlobalStore} from 'store';

import bindActions from 'store/bindActions';
import testOverlap from 'store/testOverlap';
import logoReducer from 'store/logo';

const { Actions } = logoReducer;

const useLogo = () => {
    const {state, dispatch} = useGlobalStore();

    const {logo} = state;

    // Test if there's an overlapping between the actions and store
    testOverlap(logo, Actions);

    return {
        ...bindActions(Actions, dispatch),
        ...logo
    };
};

export default useLogo;
