import client from "services/HurenApiClient";
import * as Types from "./Types";

export const setIsDataLoaded = (loaded) => {
    return {
        type: Types.SET_IS_DATA_LOADED,
        isDataLoaded: loaded
    };
};

export const setData = (payload) => {
    return {
        type: Types.SET_DATA,
        categories: payload.categories
    };
};

export const setIsClickboxCategoryDataLoaded = (loaded) => {
    return {
        type: Types.SET_IS_CLICKBOX_CATEGORY_DATA_LOADED,
        isDataLoaded: loaded
    };
};

export const setClickboxCategories = (payload) => {
    return {
        type: Types.SET_CLICKBOX_CATEGORY_DATA,
        clickboxCategories: payload.categories
    };
};

export const getCategories = () => {
    return async function (dispatch) {
        dispatch(setIsDataLoaded(false));

        return client.get('/categories').then(res => {
            dispatch(setData(res.data));
        });
    };
};

export const searchCategories = (companyId, categoryTreeId, linked) => {
    return async function (dispatch) {
        dispatch(setIsClickboxCategoryDataLoaded(false));

        return client.get('/categories/' + categoryTreeId + '?companyId=' + encodeURIComponent(companyId) + '&linked=' + encodeURIComponent(linked)).then(res => {
            dispatch(setClickboxCategories(res.data));
        });
    };
}