import React from 'react';
import {Redirect} from "@reach/router";
import TokenStorage from "services/TokenStorage";

const AuthenticatedRoute = ({ component: Component, ...rest }) => {
    const isLoggedIn = TokenStorage.isAuthenticated();

    return isLoggedIn ? <Component {...rest} /> : <Redirect from="" to="/check" noThrow />;
};

export default AuthenticatedRoute;
