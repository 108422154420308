export const getActiveTaxRates = (taxRates) => {
    return taxRates.filter(taxRate => taxRate.active === true);
}

export const getTaxRateById = (taxRates, id) => {
    return taxRates.find(taxRate => taxRate.id === id);
}

export const getActiveTaxRateByCode = (taxRates, code) => {
    return getActiveTaxRates(taxRates).find(taxRate => taxRate.code === code);
}

export const calculateVat = (priceExVat, taxPercentage) => {
    return Math.round(priceExVat * (taxPercentage / 100));
}

export const calculatePriceIncVat = (priceExVat, taxPercentage) => {
    return Math.round(priceExVat * (1 + taxPercentage / 100));
}