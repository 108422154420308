import React from 'react';
import {Tag} from "antd";
import {useTranslation} from "react-i18next";

export const QuotationStatusTag = ({ quotation }) => {
    const { t } = useTranslation('common');

    if (quotation.status === 'withdrawn') {
        return <Tag color="#ff4d4f">{t('entities:rental-match.quotation-status.withdrawn')}</Tag>;
    }
    if (quotation.has_order) {
        if (quotation.order) {
            if (quotation.order.state === 'awaiting_payment') {
                return <Tag color="#0192c3">{t('entities:rental-match.quotation-status.awaiting-payment')}</Tag>;
            }
            if (quotation.order.state === 'canceled') {
                return <Tag color="#ff4d4f">{t('entities:rental-match.quotation-status.canceled')}</Tag>;
            }
            return <Tag color="#75b118">{t('entities:rental-match.quotation-status.rented-through-you')}</Tag>;
        } else {
            return <Tag color="#ff4d4f">{t('entities:rental-match.quotation-status.rented-through-other-company')}</Tag>;
        }
    }

    return <Tag color="#ff7800">{t('entities:rental-match.quotation-status.not-rented-yet')}</Tag>;
};