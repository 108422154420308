import React from "react";
import {Table} from "antd";
import moment from "moment";
import {Money} from "../../../components/Money";
import {LegacyOrderPrice} from "../../../components/Order/LegacyOrderPrice";
import useFormatting from "../../../hooks/useFormatting";
import {calculatePriceIncVat} from "../../../helpers/taxes";

export const OrderSummary = ({order: order}) => {
    const { humanizer, formatDateTime, formatDate } = useFormatting();

    const orderItems = [];

    if (order?.type === 'detailed') {
        for (var i = 0; i < order.order_items.length; i++) {
            const item = order.order_items[i];

            let name = item.name;
            let image;
            if (item.type === 'rental_item') {
                name = item.rental_item.name;
                if (item.rental_item._links.image) {
                    image = item.rental_item._links.image.href;
                } else {
                    image = order.clickbox_category._links.image.href;
                }
            }
            const price_excl = item.price/100;
            let price_incl = price_excl;
            if (order.rental_service_provider.rental_shop_type !== 'ota' && item.tax_rate) {
                price_incl = calculatePriceIncVat(item.price/100, item.tax_rate.percentage);
            }

            let duration;
            if (item.date_from && item.date_until) { 
                duration = humanizer(moment(order.date_from).diff(moment(order.date_until)));
            } else {
                if (order.rental_item.allow_time_selection_in_quotation_form) {
                    duration = formatDateTime(moment(order.date_from));
                } else {
                    duration = formatDate(moment(order.date_from));
                }
            } 

            const orderItem = {
                id: item.id,
                name: name,
                image: image,
                price_excl: price_excl,
                price_incl: price_incl,
                quantity: item.quantity,
                duration: duration
            };
            orderItems.push(orderItem);
        }
    } else {
        var duration;
        if (order?.date_from && order?.date_until) {
            duration = humanizer(moment(order.date_from).diff(moment(order.date_until)));
        } else if(order?.date_from) {
            duration = formatDateTime(moment(order.date_from));
        }
        
        const simpleOrderItem = {
            id: 1,
            name: order?.clickbox_category.title,
            image: order?.clickbox_category._links.image.href,
            quantity: order?.quantity,
            duration: duration,
            price_excl: order?.price_excl/100,
            price_incl: order?.price_incl/100
        };
            
        if (order?.rental_item) {
            simpleOrderItem['name'] = order?.rental_item.name;
        }

        orderItems.push(simpleOrderItem);
    }

    return <Table
        dataSource={orderItems}
        rowKey={"id"}
        pagination={false}
        summary={() => {
            return (
                <>
                    <Table.Summary.Row>
                        <Table.Summary.Cell index={0}>
                            <strong>
                                Totaal
                            </strong>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={1}>
                            <div style={{ textAlign: 'right' }}>
                                {order?.type === 'legacy' ?
                                    <LegacyOrderPrice />
                                    :
                                    <strong>
                                        <Money value={order?.price_incl/100} /><br/>
                                        <small><Money value={order?.price_excl/100} /> excl. btw</small>
                                    </strong>
                                }
                            </div>
                        </Table.Summary.Cell>
                    </Table.Summary.Row>
                </>
            );
        }}
    >
        <Table.Column
            title={"Product"}
            dataIndex={"name"}
            render={(text, record) => {
                return <div style={{ display: 'flex', flexDirection: 'row', gap: '6px' }}>
                    <div style={{ width: '64px', height: '47px' }}>
                        {record.image && <img width={64} height={47} src={record.image} alt={text}/>}
                    </div>
                    <div>
                        {(record.quantity) && record.quantity+"x"} {text}<br/>
                        <small>{record.duration && record.duration}</small>
                    </div>
                </div>;
            }}
        />
        <Table.Column
            title={"Prijs"}
            dataIndex={"price_incl"}
            align={"right"}
            render={(text, record) => {
                if (order?.type === 'legacy') {
                    return <>
                        <LegacyOrderPrice />
                    </>;
                }

                if (!record.price_excl) {
                    return <></>;
                }

                return <>
                    <Money value={text} /><br/>
                    <small><Money value={record?.price_excl} /> excl. btw</small>
                </>
            }}
        />
    </Table>
};