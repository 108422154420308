import {useGlobalStore} from 'store';

import bindActions from 'store/bindActions';
import testOverlap from 'store/testOverlap';
import paymentDetailsReducer from 'store/paymentDetails';

const { Actions } = paymentDetailsReducer;

const usePaymentDetails = () => {
    const {state, dispatch} = useGlobalStore();

    const {paymentDetails} = state;

    // Test if there's an overlapping between the actions and store
    testOverlap(paymentDetails, Actions);

    return {
        ...bindActions(Actions, dispatch),
        ...paymentDetails,
    };
};

export default usePaymentDetails;
