import {useMutation, useQuery, useQueryClient} from "react-query";
import client from "services/HurenApiClient";
import {createQueryString} from "../helpers/queryString";

const fetchEnabledTransitions = (id) => async () => {
    const res = await client.get(`/api/v2/orders/${id}/enabled-transitions`);
    return res.data.items;
};
export const useGetEnabledTransitions = (orderId, options) => {
    return useQuery(
        ['orders', parseInt(orderId), 'enabled-transitions'],
        fetchEnabledTransitions(orderId),
        options
    );
};

const createOrderTransition = async ({orderId, transition}) => {
    return client.post(`/api/v2/orders/${orderId}/transitions`, transition);
};
export const useCreateOrderTransition = () => {
    const queryClient = useQueryClient();
    return useMutation(createOrderTransition, {
        onSuccess: async (res, {rentalMatchId, orderId, transition}) => {
            await queryClient.invalidateQueries(['orders', parseInt(orderId)]);
            await queryClient.invalidateQueries(['rental-matches', rentalMatchId]);
        }
    });
};

const fetchOrderCollection = (queryData) => async () => {
    let url = '/api/v2/orders';
    if (queryData) {
        url += '?' + createQueryString(queryData, '');
    }
    const res = await client.get(url);
    return res.data;
};
export const useGetOrderCollection = (collectionParameters, options) => {
    const createQueryData = () => {
        const queryData = {
            page: collectionParameters.page,
            limit: collectionParameters.limit,
            filters: {}
        };
        if (collectionParameters.searchQuery) {
            queryData.searchQuery = collectionParameters.searchQuery;
        }
        if (collectionParameters.filters.createdAfter) {
            queryData.filters.createdAfter = collectionParameters.filters.createdAfter.toISOString();
        }
        if (collectionParameters.filters.createdBefore) {
            queryData.filters.createdBefore = collectionParameters.filters.createdBefore.toISOString();
        }

        if (collectionParameters.filters.state) {
            queryData.filters.state = collectionParameters.filters.state;
        }

        if (collectionParameters.sort) {
            queryData.sort = collectionParameters.sort;
        }

        return queryData;
    };
    const queryData = createQueryData();
    return useQuery(['orders', queryData], fetchOrderCollection(queryData), options);
};

export const createExportQueryData = (collectionParameters, outputType) => {
    const queryData = {
        page: collectionParameters.page,
        limit: collectionParameters.limit,
        filters: {}
    };
    if (collectionParameters.searchQuery) {
        queryData.searchQuery = collectionParameters.searchQuery;
    }
    if (collectionParameters.filters.createdAfter) {
        queryData.filters.createdAfter = collectionParameters.filters.createdAfter.toISOString();
    }
    if (collectionParameters.filters.createdBefore) {
        queryData.filters.createdBefore = collectionParameters.filters.createdBefore.toISOString();
    }

    if (collectionParameters.filters.state) {
        queryData.filters.state = collectionParameters.filters.state;
    }

    if (collectionParameters.sort) {
        queryData.sort = collectionParameters.sort;
    }

    queryData['_output'] = outputType;

    return queryData;
};

const fetchOrder = (id) => async () => {
    const res = await client.get(`/api/v2/orders/${id}`);
    return res.data;
};
export const useGetOrder = (orderId, options) => {
    return useQuery(['orders', parseInt(orderId)], fetchOrder(orderId), options);
}

const fetchOrderExtraFormValues = (id) => async () => {
    const res = await client.get(`/api/v2/orders/${id}/extra-form-values`);
    return res.data;
};
export const useGetOrderExtraFormValues = (orderId, options) => {
    return useQuery(['orders-extra-form-values', parseInt(orderId)], fetchOrderExtraFormValues(orderId), options);
}