import React from 'react';
import { Popover } from "antd";
import {useTranslation, Trans} from "react-i18next";
import { CrownTwoTone } from '@ant-design/icons';
import { Link } from "@reach/router";

const PremiumIcon = React.forwardRef((props, ref) => {

    let size;
    switch(props.size){
        case 'header': size = '18pt'; break;
        case 'small': size = '12pt'; break;
        default: size = '15pt';
    }
    return <CrownTwoTone
                 ref={ref}
                 {...props}
                 type="crown"
                 theme="twoTone"
                 twoToneColor="#ff7800"
                 style={{fontSize: size, marginRight: '5px', ...props.style}}
           />
});

const PremiumInfo = ({size, verticalAlign}) => {
    const { t } = useTranslation('common');

    let iconStyle = {
        cursor: 'pointer'
    };

    if(typeof verticalAlign !== 'undefined'){
        iconStyle.verticalAlign = verticalAlign;
    }

    return <Popover
                style={{cursor: 'pointer'}}
                content={<Trans t={t} ns="common" i18nKey="components.premium.popover.content">
                    This functionality is only available for <a
                        href="https://www.huren.nl/voor-verhuurbedrijven"
                        target="_blank"
                        rel="noopener noreferrer"
                    >paid accounts</a>. We are happy to inform you about a possible collaboration. <Link to="/help/contact">Please contact us.</Link>
                </Trans>}
                title={(<><PremiumIcon />{t('components.premium.popover.title')}</>)}
           >
                <PremiumIcon size={size} style={iconStyle}/>
           </Popover>
    ;
};

const PremiumBadge = () => {
    const { t } = useTranslation('common');

    return  <div style={{
        fontWeight: 'bold',
        textTransform: 'uppercase',
        backgroundColor: '#fff',
        padding: '3px'
    }}>
        <PremiumIcon /> {t('components.premium.badge.title')}
    </div>;
};


export { PremiumInfo, PremiumIcon, PremiumBadge};
