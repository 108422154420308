import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import {useAccounts, useSmallDeviceSize} from "hooks";
import {Button, Drawer, Form, Input, notification, Select, Skeleton} from "antd";
import {MailOutlined} from "@ant-design/icons";
import SubmitButton from "../SubmitButton";
import client from "services/HurenApiClient";

const { forwardRef, useImperativeHandle } = React;

const ContactForm = forwardRef((props, ref) => {
    const [form] = Form.useForm();
    const {t} = useTranslation('common');
    const {accountsLoaded, activeAccount} = useAccounts();
    const [formOpen, setFormOpen] = useState(false);

    const { TextArea } = Input;

    // Allows use of the openForm function from the parent component.
    useImperativeHandle(ref, () => ({
        openForm() {
            setFormOpen(true);
        }
    }));

    const closeForm = () => {
        setFormOpen(false);
    };

    const onFinish = (values) => {
        values.company = activeAccount.companyId;
        client.post('/contact', values).then(res => {
            notification.open({
                message: t('notification.messages.message-sent.title'),
                description: t('notification.messages.message-sent.description'),
            });

            closeForm();
        });
    };


    const isSmallDevice = useSmallDeviceSize();
    let drawerWidth = 520;
    if (isSmallDevice) {
        drawerWidth = '100%';
    }

    return (
        <Drawer
            form={form}
            title={props.title}
            placement="right"
            onClose={() => closeForm()}
            open={formOpen}
            width={drawerWidth}
        >
            <Skeleton loading={!accountsLoaded}>
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={onFinish}
                    initialValues={{
                        name: (activeAccount.firstName + ' ' + activeAccount.lastName),
                        email: activeAccount.email,
                        phone: activeAccount.phoneNumber,
                        subject: props.subject ?? ''
                    }}
                >

                    <Form.Item
                        name="subject"
                        label={t('forms.contact-form.items.subject.label')}
                        required={true}
                        rules={[
                            {required: true, message: t('form.errors.required')}
                        ]}
                    >
                        {props.type === 'subscription' ?
                            <Select>
                                <Select.Option value={t('forms.contact-form.items.subject.options.new')}>{t('forms.contact-form.items.subject.options.new')}</Select.Option>
                                <Select.Option value={t('forms.contact-form.items.subject.options.extend')}>{t('forms.contact-form.items.subject.options.extend')}</Select.Option>
                                <Select.Option value={t('forms.contact-form.items.subject.options.change')}>{t('forms.contact-form.items.subject.options.change')}</Select.Option>
                                <Select.Option value={t('forms.contact-form.items.subject.options.other')}>{t('forms.contact-form.items.subject.options.other')}</Select.Option>
                            </Select> :
                            <Input type="text" />
                        }
                    </Form.Item>

                    <Form.Item
                        name="message"
                        label={t('forms.contact-form.items.message.label')}
                    >
                        <TextArea rows={4} />
                    </Form.Item>

                    <Form.Item
                        name="name"
                        label={t('forms.contact-form.items.name.label')}
                        required={true}
                        rules={[
                            {required: true, message: t('form.errors.required')}
                        ]}
                    >
                        <Input type="text" />
                    </Form.Item>

                    <Form.Item
                        name="email"
                        label={t('forms.contact-form.items.email.label')}
                        required={true}
                        rules={[
                            {type: 'email', message: t('form.errors.invalid-email')},
                            {required: true, message: t('form.errors.required')}
                        ]}
                    >
                        <Input type="text" />
                    </Form.Item>

                    <Form.Item
                        name="phone"
                        label={t('forms.contact-form.items.phone.label')}
                        required={true}
                        rules={[
                            {required: true, message: t('form.errors.required')},
                            {pattern: "[0-9]{3,12}", message: t('form.errors.invalid-phone')}
                        ]}
                    >
                        <Input type="text" />
                    </Form.Item>

                    <div className="actions">
                        <Button onClick={() => closeForm()} style={{marginRight: '8px'}}>
                            {t('button.text.cancel')}
                        </Button>
                        <SubmitButton disabled={!accountsLoaded}>
                            <MailOutlined /> {t('button.text.send')}
                        </SubmitButton>
                    </div>
                </Form>
            </Skeleton>
        </Drawer>
    );
});

export default ContactForm;