import {useGlobalStore} from 'store';

import bindActions from 'store/bindActions';
import testOverlap from 'store/testOverlap';
import statisticsReducer from 'store/statistics';

const { Actions } = statisticsReducer;

const useStatistics = () => {
    const {state, dispatch} = useGlobalStore();

    const {statistics} = state;

    // Test if there's an overlapping between the actions and store
    testOverlap(statistics, Actions);

    return {
        ...bindActions(Actions, dispatch),
        ...statistics
    };
};

export default useStatistics;
