import {useGlobalStore} from 'store';

import bindActions from 'store/bindActions';
import testOverlap from 'store/testOverlap';
import locationsReducer from 'store/locations';

const { Actions } = locationsReducer;

const useLocations = () => {
    const {state, dispatch} = useGlobalStore();

    const {locations} = state;

    // Test if there's an overlapping between the actions and store
    testOverlap(locations, Actions);

    return {
        ...bindActions(Actions, dispatch),
        ...locations
    };
};

export default useLocations;
