import * as Types from "./Types";

export const initialState = {
    cartItems: [],
};

export default function (state, action) {
    switch (action.type) {
        case Types.ADD_ITEM:
            return {
                ...state,
                cartItems: [...state.cartItems, action.cartItem]
            };
        case Types.REMOVE_ITEM:
            return {
                ...state,
                cartItems: state.cartItems.filter(elem => elem.id !== action.cartItem.id)
            };
        case Types.SET_ITEMS:
            return {
                ...state,
                cartItems: action.cartItems
            };
        case Types.CLEAR_CART:
            return {
                ...state,
                cartItems: []
            };
        default:
            return state;
    }
};
