import * as Types from "./Types";

export const initialState = {
    isDataLoaded: false,
    isClickboxCategoryDataLoaded: false,
    categories: [],
    clickboxCategories: []
};

export default function (state, action) {
    switch (action.type) {
        case Types.SET_IS_DATA_LOADED:
            return {
                ...state,
                isDataLoaded: action.isDataLoaded
            };
        case Types.SET_DATA:
            return {
                ...state,
                categories: action.categories,
                isDataLoaded: true
            };
        case Types.SET_IS_CLICKBOX_CATEGORY_DATA_LOADED:
            return {
                ...state,
                isClickboxCategoryDataLoaded: action.isDataLoaded
            };
        case Types.SET_CLICKBOX_CATEGORY_DATA:
            return {
                ...state,
                clickboxCategories: action.clickboxCategories,
                isClickboxCategoryDataLoaded: true
            };
        default:
            return state;
    }
};
