import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {Button, Card, Empty, Table, Tag} from "antd";
import {PlusOutlined} from "@ant-design/icons";
import LoggedInLayout from "../../layout/LoggedInLayout";
import {useGetAccounts} from "../../queries/users";
import UserOverviewFilters from "./components/UserOverviewFilters";
import {navigate} from "@reach/router";
import {hasPermission} from "../../helpers";
import {useAccounts} from "hooks";
import {InvitationDrawer} from "./components/InvitationDrawer";

const UserOverview = () => {

    const {activeAccount} = useAccounts();

    const { t } = useTranslation('pages');
    const [collectionParameters, setCollectionParameters] = useState({
        page: 1,
        limit: 10,
        sort: null,
        searchQuery: "",
        filters: {
            group_code: ""
        },
        invitationDrawerOpen: false
    });

    const onFilter = (groupCode, searchQuery) => {
        setCollectionParameters(prevState => {
            return {...prevState,
                filters: {
                    group_code: groupCode
                },
                searchQuery: searchQuery
            }
        })
    }

    const onChangeTable = (pagination, f, sorter) => {
        let sort = null;
        if (sorter.columnKey && sorter.order) {
            sort = (sorter.order === 'descend' ? '-' : '') + sorter.columnKey;
        }
        setCollectionParameters(prevState => {
            return {... prevState,
                page: pagination.current,
                limit: pagination.pageSize,
                sort: sort}
        })
    };

    const { data: accounts, isLoading: accountsAreLoading } = useGetAccounts(collectionParameters);

    const filters = {
        searchQuery: collectionParameters.searchQuery   ?? "",
        group_code: collectionParameters.filters.group_code     ?? ""
    }

    const toggleInvitationDrawer = () => {
        setCollectionParameters(prevState => {
            return {... prevState,
                invitationDrawerOpen: !collectionParameters.invitationDrawerOpen
            }
        })
    }

    return (
        <LoggedInLayout
            pageTitle={t("users.title")}
            headerExtras={
                [
                    <Button type="primary" onClick={() => toggleInvitationDrawer()} disabled={!hasPermission(activeAccount, 'manage_users' ,'create')}>
                        <PlusOutlined />
                        {t('users.invite_button')}
                    </Button>,
                ]
            }
        >
            <InvitationDrawer open={collectionParameters.invitationDrawerOpen} close={toggleInvitationDrawer} />

            <Card
                size={'small'}
                style={{ marginBottom: '8px'}}
                title={t('users.title')}
            >
                <UserOverviewFilters filters={filters}
                                     onChange={onFilter}/>
                <Table
                    dataSource={accounts? accounts.items : []}
                    loading={accountsAreLoading}
                    onChange={onChangeTable}
                    rowKey={"id"}
                    locale={{emptyText: <Empty/>}}
                    scroll={{ x: 1080 }}
                    pagination={{ total: accounts?.total,
                        defaultPageSize: 10,
                        showSizeChanger: true,
                        pageSizeOptions: ['10', '20', '50', '100']}}
                >
                    <Table.Column
                        title={'ID'}
                        dataIndex={'id'}
                        key={'id'}
                    />
                    <Table.Column
                        title={t('users.firstName')}
                        dataIndex={["user", "first_name"]}
                        key={'first_name'}
                        sorter={true}
                    />
                    <Table.Column
                        title={t('users.lastName')}
                        dataIndex={["user", "last_name"]}
                        key={'last_name'}
                        sorter={true}
                    />
                    <Table.Column
                        title={t('users.email')}
                        dataIndex={['user', 'email']}
                        key={'email'}
                        sorter={true}
                    />
                    <Table.Column
                        title={t('users.groups')}
                        dataIndex={'groups'}
                        key={'groups'}
                        render={(text, entry) => {
                            return (
                                entry.groups?.map(group => {
                                    return <Tag key={group.id}>{group.name}</Tag>
                                })
                            )
                        }}
                    />

                </Table>
            </Card>
        </LoggedInLayout>
    )
}

export default UserOverview