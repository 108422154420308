import React, {useState, useEffect} from "react";
import {Trans, useTranslation} from 'react-i18next';
import {Button, Form, notification, Switch} from "antd";
import Drawer from "components/Drawer";
import SubmitButton from "components/SubmitButton";
import RentalFeatureTable from "components/RentalFeatureTable";
import {useAccounts, useRentalItem} from "hooks";

const { forwardRef, useImperativeHandle } = React;

const RentalItemConditionFeatureForm = forwardRef((props, ref) => {
    const [form] = Form.useForm();
    const [formOpen, setFormOpen] = useState(false);
    const [userGeneratedFeatures, setUserGeneratedFeatures] = useState([]);
    const [systemFeatures, setSystemFeatures] = useState([]);
    const [features, setFeatures] = useState([]);

    const {t} = useTranslation('common');
    const {activeAccount} = useAccounts();
    const {putRentalItemFeatures} = useRentalItem();

    // Allows use of the openForm function from the parent component.
    useImperativeHandle(ref, () => ({
        openForm(id, features, usergenerated_features, system_features) {
            setFeatures(features);
            setUserGeneratedFeatures(usergenerated_features);
            setSystemFeatures(system_features);
            setFormOpen(true);
        }
    }));

    const closeForm = () => {
        setFeatures([]);
        setUserGeneratedFeatures([]);
        setSystemFeatures([]);
        setFormOpen(false);
    };

    const onFinish = (values) => {
        let params = new URLSearchParams(location.search); // eslint-disable-line
        putRentalItemFeatures(activeAccount.companyId, params.get('id'), 'condition', features, userGeneratedFeatures)
            .then((res) => {
                notification.open({
                    message: t('notification.messages.saved.title'),
                    description: t('notification.messages.saved.description'),
                });
                
                closeForm();
            })
            .catch(e => {
                console.log(e);
                notification.open({
                    message: t('notification.messages.error.title'),
                    description: t('notification.messages.error.description'),
                });
                
                closeForm();
            });
    }

    const onDataChange = (values) => {
        if (values.data) {
            setFeatures(values.data.filter(f => f.type == 'system'));
            setUserGeneratedFeatures(values.data.filter(f => f.type == 'user_generated'));
        }
    }

    return (
        <Drawer
            form={form}
            title={t('pages.rentalitems.rental-item.condition-features-form.title')}
            placement="right"
            onClose={() => closeForm()}
            open={formOpen}
            width={960}
        >
            <Form
                form={form}
                layout="vertical"
                onFinish={onFinish}
            >
                <RentalFeatureTable features={features} userGeneratedFeatures={userGeneratedFeatures} systemFeatures={systemFeatures} onDataChange={onDataChange} />
                <br/><br/>
                <div className="actions">
                    <Button onClick={() => closeForm()} style={{marginRight: '8px'}}>
                        {t('button.text.cancel')}
                    </Button>
                    <SubmitButton>
                        {t('button.text.save')}
                    </SubmitButton>
                </div>
            </Form>
        </Drawer>
    );
});

export default RentalItemConditionFeatureForm;