import React from "react";

import {useAccounts} from "hooks";
import TokenStorage from "services/TokenStorage";
import TokenCheck from "../../pages/auth/TokenCheck";

const AccountRoute = ({ component: Component, ...rest }) => {
    const isLoggedIn = TokenStorage.isAuthenticated();
    const { activeAccount } = useAccounts();

    if (isLoggedIn && activeAccount !== null) {
        return <Component {...rest} />;
    }

    return <TokenCheck />
};

export default AccountRoute;
